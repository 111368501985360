import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Io, Api } from "@durinn.v3/helpers/api";
import { retry, timeout } from "rxjs/operators";
import { Me } from "@durinn.v3/helpers/me";

@Component({
	selector: "update-profile",
	templateUrl: "./update-profile.component.html",
	styleUrls: ["./update-profile.component.css"],
})
export class UpdateProfileComponent implements OnInit {
	call: Io;
	loading = true;

	form = {
		name: "",
		phone: "",
		username: "",
		variables: {
			cpf: "",
			cep: "",
			address: "",
			complement: "",
			neighborhood: "",
			city: "",
			state: "",
		},
	};

	fields = [
		{
			label: "Nome",
			name: "name",
			type: "text",
			validate: "Por favor, preencha seu nome corretamente!",
			required: true,
		},
		{
			label: "Telefone",
			name: "phone",
			type: "text",
			validate: "Por favor, preencha seu telefone corretamente!",
			mask: "(00) 00000-0000",
			placeholder: "(00) 00000-0000",
			required: true,
		},
		{
			label: "E-mail",
			name: "username",
			type: "email",
			validate: "Por favor, preencha seu e-mail corretamente!",
			placeholder: "email@email.com.br",
			required: true,
		},
	];

	address = [
		{
			label: "Endereço",
			name: "address",
			type: "text",
			validate: "Por favor, preencha seu endereço corretamente!",
			required: true,
		},
		{
			label: "Complemento",
			name: "complement",
			type: "text",
			validate: "Por favor, preencha seu complemento corretamente!",
			required: false,
		},
		{
			label: "Bairro",
			name: "neighborhood",
			type: "text",
			validate: "Por favor, preencha seu bairro corretamente!",
			required: true,
		},
		{
			label: "Cidade",
			name: "city",
			type: "text",
			validate: "Por favor, preencha sua cidade corretamente!",
			required: true,
		},
	];

	constructor(public api: Api, public me: Me, private http: HttpClient) {}

	ngOnInit(): void {
		const self = this;
		this.call = self.api.new().set("success", true);

		self.me.get().subscribe((data) => {
			self.loading = false;
			if (data) {
				for (const item in data) {
					if (item in self.form) {
						self.form[item] = data[item];
					}
				}

				for (const item of data.Variables) {
					if (item.field in self.form.variables) {
						self.form.variables[item.field] = item.variable;
					}
				}
			}
		});
	}

	async submitForm($event) {
		await this.me.update(this.form, this.call);
	}

	async cepChanged() {
		const self = this;
		const cep = (self.form.variables.cep || "").replace(/[^0-9]/g, "");

		self.http
			.get(`https://viacep.com.br/ws/${cep}/json/`)
			.pipe(retry(5))
			.pipe(timeout(3000))
			.subscribe(
				(data: any) => {
					self.form.variables.address = data.logradouro;
					self.form.variables.neighborhood = data.bairro;
					self.form.variables.city = data.localidade;
					self.form.variables.state = data.uf.toUpperCase();

					console.log(`data`, data, self.form.variables);

					// cep: "92120-210"
					// logradouro: "Rua Marechal Rondon"
					// complemento: ""
					// bairro: "Niterói"
					// localidade: "Canoas"
					// uf: "RS"
					// unidade: ""
					// ibge: "4304606"
					// gia: ""
				},
				(error) => {
					console.error(error);
				}
			);
	}
}
