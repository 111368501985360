import { BasePage } from "../../../../bases/base.page";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";

type Sicknote = {
	id: number;
	date: Date;
	time: string;
	file: string | null;
};

type DistanceReturn = {
	distance: number;
	registeredBy: "ADMIN" | "PHONE";
};

type TimeRegister = {
	id_time: number;
	register_date: Date;
	entry_date: Date | null;
	entry_latitude: number | null;
	entry_longitude: number | null;
	entry_odometer: number | null;
	entry_note: string | null;
	departure_date: Date | null;
	departure_latitude: number | null;
	departure_longitude: number | null;
	departure_odometer: number | null;
	departure_note: string | null;
	deleted: boolean;
	error: boolean;
	error_description: string | null;
};

type ClosingReportResponse = {
	[date: string]: {
		date: string;
		holiday: boolean;
		absence: boolean;
		total: number;
		traveled_km: DistanceReturn;
		times: TimeRegister[];
		sicknote: Sicknote | null;
	};
};

@Component({
	selector: "app-closure",
	templateUrl: "./closure.component.html",
	styleUrls: ["./closure.component.css"],
})
export class ClosureComponent extends BasePage {
	public report: ClosingReportResponse = {};
	public objectValues = Object.values;
	public objectKeys = Object.keys;
	public id: number = null;
	public user: any = null;
	public moment = moment;
	public route = "users";
	public Math = Math;

	public workload = {
		id_workload: null,
		register_date: null,
		user_id: null,
		admin_id: null,
		monday: "07:20:00",
		tuesday: "07:20:00",
		wednesday: "07:20:00",
		thursday: "07:20:00",
		friday: "07:20:00",
		saturday: "00:00:00",
		sunday: "00:00:00",
	};

	protected load = this.api.new();
	protected loadReport = this.api.new();
	protected loadHourBank = this.api.new();
	protected loadWorkload = this.api.new();

	public form = {
		from: moment().subtract(1, "month").format("21/MM/YYYY"),
		to: moment().format("20/MM/YYYY"),
		user: "",
	};

	times = [0, 1];
	hour_bank = { seconds: 0, entry_date: null, departure_date: null };
	total_km = 0;

	ngOnInit() {
		this.id = (this.mineUser || { id: null }).id;
		this.getUser();
		this.getReport();
		this.getWorkload();
	}

	async getUser() {
		const self = this;
		self.load.get(self.route + "/" + self.id).subscribe((response) => {
			const data = response.return;

			if (data) {
				data.variables = {};
				self.user = data;
			}
		});
	}

	async getWorkload() {
		const self = this;
		self.loadWorkload
			.get(self.route + "/" + self.id + "/workload")
			.subscribe((response) => {
				self.workload = response.return;
			});
	}

	async getReport() {
		const self = this;
		this.loadReport
			.get(
				`${self.route}/${self.id}/reports/closing-report/${moment(
					self.form.from,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD")}/${moment(
					self.form.to,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD")}`,
				{}
			)
			.subscribe((data) => {
				self.report = data.return;

				const values: any = Object.values(self.report);

				self.times = [0, 1];
				for (const day of values) {
					if (day.times.length > self.times.length) {
						self.times = Array(day.times.length).fill(
							day.times.length
						);
					}

					self.total_km += day.traveled_km.distance;
				}
			});
		this.loadHourBank
			.get(
				`${self.route}/${self.id}/hour-bank/${moment(
					self.form.from,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD")}/${moment(
					self.form.to,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD")}`,
				{}
			)
			.subscribe((data) => {
				self.hour_bank = data.return;
			});
	}

	rowStyle(day) {
		if (day.total > 60 * 60 * 10) {
			return { "background-color": "rgba(255,0,0,0.10)" };
		}

		if (day.total > 60 * 60 * 8.5) {
			return { "background-color": "rgba(255,127,80,0.1)" };
		}

		if (day.holiday) {
			return { "background-color": "rgba(186,85,211,0.1)" };
		}

		if (moment(day.date).isoWeekday() == 7) {
			return { "background-color": "#efefef" };
		}
	}

	async getExcel() {
		const self = this;
		const data = (await self.me.getCacheData()) as any;

		if (data) {
			window.location.href =
				this.api.url +
				`${self.route}/${
					self.id
				}/reports/closing-report/file/${moment(
					self.form.from,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD")}/${moment(
					self.form.to,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD")}?_authusername=${btoa(
					data.cpf
				)}&_authpassword=${btoa(data.password)}`;
		}
	}
}
