import { Component } from "@angular/core";
import { PageBase } from "@durinn/v.3/app/bases/page.base";
import {
	DurinnV2ListPageTableComponent,
	HeadColumns,
} from "@durinn/v.3/app/components/template/tables/list-page-table/list-page-table.component";

@Component({
	selector: "durinn-v2-customer-list-view",
	templateUrl: "./list.component.html",
	styleUrls: ["./list.component.css"],
})
export class DurinnV2CustomerListViewComponent extends PageBase {
	headColumns: HeadColumns =
		DurinnV2ListPageTableComponent.defaultHeadColumnsWith("Costumer", [
			{ index: "Costumer", column: "document", name: "CPF / CNPJ" },
			{
				index: "CostumerEmail",
				column: "email",
				name: "E-mail",
				orderControlHidden: true,
			},
			{
				index: "CostumerPhone",
				column: "phone",
				name: "Telefone",
				orderControlHidden: true,
				mask: "(00) 00000-0000",
				placeholder: "(00) 00000-0000",
				style: { minWidth: "160px !important", width: "160px !important" },
			},
		]);
}
