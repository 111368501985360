import { Component } from "@angular/core";
import {
	EditPage,
	EditPageInterface,
} from "../../../../../bases/edit.page";
import * as moment from "moment";

@Component({
	selector: "app-editar-equipe",
	templateUrl: "./editar-equipe.component.html",
	styleUrls: ["./editar-equipe.component.css"],
})
export class EditarEquipeComponent
	extends EditPage
	implements EditPageInterface {
	deliveryManCall = this.api.new().set("success", true);

	permissions = {
		DELETE_TEAMS: false,
		EDIT_TEAMS: false,
		CREATE_TEAMS: false,
	};

	form: any = {
		Deliverymen: [],
		Users: [],
		Members: [],
		variables: {},
	};

	fields = [
		{
			label: "Nome",
			name: "name",
			type: "text",
			validate: "Por favor, preencha seu nome corretamente!",
			required: true,
		},
		{
			label: "Descrição",
			name: "description",
			type: "text",
			validate: "Por favor, preencha a descrição corretamente!",
			required: false,
		},
	];

	back_to = "/equipes";
	route = "team";

	afterGet() {
		const self = this;

		self.form.Deliverymen = [];
		self.form.Users = [];

		for (const item of (self.form || {}).Members || []) {
			if (item.user_id && item.Deliveryman) {
				self.form.Deliverymen.push(item.Deliveryman);
			}

			if (item.admin_id && item.User) {
				self.form.Users.push({
					...item.User,
					fixed: item.fixed,
					expiresAt: item.expiresAt,
				});
			}
		}

		console.log(self.form);
	}

	async submitForm() {
		const self = this;
		if (self.id) {
			self.call
				.put(self.route + "/" + this.id, this.form)
				.subscribe((response) => {
					self.app.alert(`Sucesso`, response.message, `success`);
				});
		} else {
			self.call
				.post(self.route + "/", this.form)
				.subscribe((response) => {
					self.app
						.alert(`Sucesso`, response.message, `success`)
						.then(() => {
							self.router.navigateByUrl(
								"/equipes/editar/" + response.return.id_team
							);
						});
				});
		}
	}

	deliveryManId = null;

	addDeliveryMan() {
		this.deliveryManCall
			.post("team/" + this.id + "/deliveryman/" + this.deliveryManId, {})
			.subscribe(() => {
				this.get();
			});
	}

	async removeDeliveryman(id) {
		const self = this;
		if (await self.app.confirm()) {
			self.deliveryManCall
				.delete("team/" + this.id + "/deliveryman/" + id, {})
				.subscribe(() => {
					self.get();
				});
		}
	}

	userId = null;
	
	addUser() {
		const obj = JSON.parse(JSON.stringify(this.newUser));
		
		if(obj.expiresAt){
			obj.expiresAt = moment(obj.expiresAt, 'DD/MM/YYYY HH:mm').toDate();
		}
		
		this.deliveryManCall
			.post("team/" + this.id + "/user/" + this.newUser.userId, obj)
			.subscribe(() => {
				this.get();
				this.newUser.modal = false;
			});
	}

	async removeUser(id) {
		const self = this;
		if (await self.app.confirm()) {
			self.deliveryManCall
				.delete("team/" + this.id + "/user/" + id, {})
				.subscribe(() => {
					self.get();
				});
		}
	}
	
	addUserModal(){
		this.newUser = {
			userId: null,
			fixed: '0',
			expiresAt: moment().add(1,'hour').format('DD/MM/YYYY HH:mm'),
			modal: true
		};
	}
	
	newUser = {
		userId: null,
		fixed: '0',
		expiresAt: moment().add(1,'hour').format('DD/MM/YYYY HH:mm'),
		modal: false
	};
	
	get iAmOnTeam(){
		const self = this;
		const me: any = self.me.getCacheData() || {};
		
		for (const item of (self.originalForm || {}).Members || []) {
			if (item.admin_id == me.id){
				return true;
			}
		}
		
		return false;
	}
}
