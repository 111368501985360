import { Subject } from "rxjs";
import { io } from "socket.io-client";
import * as pako from "pako";
import config from "../../../config";

export type UserInterface = any;

export type SendEventArguments = {
	message?: string;
	data?: any;
	to?: string[];
	emitter?: UserInterface | null;
	ignoreMine?: boolean;
};

let subjects = {};
const query: any = {};

declare const window: any;

try{
	const user = JSON.parse(localStorage.getItem(config.me.cache_name));
	
	if(user){
		query.id = user.id;
		query.name = user.name;
	}
}catch (e) {
	console.error(e);
}


window.socket_connection_error = 0;

if(!window.socket){
	window.socket = io("https://socket-io.desenvolvimento.ninja:3900", {
		query: query,
		auth: { token: "samuca" }
	});
	
	window.socket.on("connect", function () {
		console.log("socket.io connected", window.socket.connected, window.socket.id);
		SocketIo.rejoinAll();
		window.socket_connection_error = 0;
		window.socket_redundancy.disconnect();
	});
	
	window.socket.on("connect_error", function (e) {
		console.log("socket.io connect_error", true, e);
		window.socket.removeAllListeners();
		
		window.socket_connection_error += 1;
		
		if(window.socket_connection_error >= 5){
			window.socket_redundancy.connect();
		}
		
		subjects = {};
	});
	
	window.socket.on("disconnect", function () {
		console.log("socket.io disconnect", true, window.socket.id);
		window.socket.removeAllListeners();
		
		window.socket_connection_error += 1;
		
		if(window.socket_connection_error >= 5){
			window.socket_redundancy.connect();
		}
		
		subjects = {};
	});
}

if(!window.socket_redundancy){
	window.socket_redundancy = io("https://socket-redundancy.desenvolvimento.ninja:3900", {
		query: query,
		auth: { token: "samuca" },
		autoConnect: false,
		reconnection: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax: 5000,
		reconnectionAttempts: Infinity,
	});
	
	window.socket_redundancy.on("connect", function () {
		console.log("socket.io - socket_redundancy - connected", window.socket.connected, window.socket.id);
		SocketIo.rejoinAll();
	});
	
	window.socket_redundancy.on("disconnect", function () {
		console.log("socket.io - socket_redundancy - disconnect", true, window.socket.id);
		window.socket.removeAllListeners();
		subjects = {};
	});
}

export class SocketIo {
	static LOG = true;
	
	constructor() {}
	
	private static joined: {[channel: string]: any} = {};
	
	private static get user(): UserInterface | false {
		try {
			return JSON.parse(localStorage.getItem(config.me.cache_name));
		} catch (e) {
			return false;
		}
	}
	
	static get socket() {
		const socket = window.socket;
		const socket_redundancy = window.socket_redundancy;
		
		if(socket_redundancy && socket_redundancy.connected){
			return socket_redundancy;
		}
		
		return socket;
	}
	
	static get channel() {
		let ENV;
		
		if (document.URL.indexOf("localhost") > -1) {
			ENV = "development";
		} else if (document.URL.indexOf(config.api.is_acceptance) > -1) {
			ENV = "test";
		} else {
			ENV = "production";
		}
		
		return config.name + ":" + ENV + ":";
	}
	
	static on(event: string) {
		const self = this;
		
		event = self.channel + event;
		
		if(subjects[event]){
			return subjects[event];
		}
		
		subjects[event] = new Subject();
		
		if (SocketIo.LOG) {
			console.info(`SocketIo`, `listening to`, event, SocketIo.socket.id);
		}
		
		SocketIo.socket.removeAllListeners(event).on(event, async (args) => {
			// Se for um socket comúm, com emmiter e estiver marcado ignoreMine
			// Se for minha própria mensagem, ignorar.
			if (typeof args == "object") {
				const user = self.user;
				const sendEvent = args as SendEventArguments;
				
				if (sendEvent.ignoreMine && sendEvent.emitter && user) {
					if (user.id == sendEvent.emitter.id) {
						if (SocketIo.LOG) {
							console.error(`SocketIo`, event, `ignorado`, args, user.id, SocketIo.socket.id);
						}
						
						return false;
					}
				}
				
				if(args.gzip){
					const data = pako.inflate(args.gzip, { to: "string" });
					
					try{
						args.data = JSON.parse(data);
					}catch (e) {
						args.data = data;
					}
				}
			}
			
			if (SocketIo.LOG) {
				console.info(`SocketIo`, event, args, SocketIo.socket.id);
			}
			
			subjects[event].next(args);
		});
		
		return subjects[event];
	}
	
	static off(event: string) {
		if (SocketIo.LOG) {
			console.info(`SocketIo`, `unlistening to`, event, SocketIo.socket.id);
		}
		
		SocketIo.socket.off(event);
		SocketIo.socket.removeAllListeners(event);
		delete subjects[event];
	}
	
	static emit(event: string) {
		return new Emit(event);
	}
	
	static broadcast(event: string) {
		return new Broadcast(event);
	}
	
	static join(room: string) {
		const self = this;
		
		const args = {
			channel: this.channel + room,
			emitter: self.user || null,
		};
		
		if(self.joined[args.channel]){
			return;
		}
		
		if (SocketIo.LOG) {
			console.info(`SocketIo`, "join", args);
		}
		
		self.joined[args.channel] = args;
		return SocketIo.socket.emit("join", args);
	}
	
	static rejoinAll(){
		const self = this;
		
		for(const index in self.joined){
			console.info(`SocketIo`, "rejoin", 'join', self.joined[index]);
			SocketIo.socket.emit("join", self.joined[index]);
		}
	}
	
	static leave(room: string) {
		const self = this;
		const args = {
			channel: this.channel + room,
			emitter: self.user || null,
		};
		
		if(!self.joined[args.channel]){
			return;
		}
		
		if (SocketIo.LOG) {
			console.info(`SocketIo`, "leave", args);
		}
		
		delete self.joined[args.channel];
		return SocketIo.socket.emit("leave", args);
	}
	
	static sendCustomEmit(event: string, args: SendEventArguments) {
		if (SocketIo.LOG) {
			console.info(
				`SocketIo`,
				"sendCustomEmit",
				"emit:" + this.channel + event,
				args
			);
		}
		
		return SocketIo.socket.emit("emit:" + this.channel + event, {
			message: args.message,
			emitter: args.emitter || null,
			data: args.data || null,
			ignoreMine: args.ignoreMine !== false,
		});
	}
	
	static sendCustomBroadcast(event: string, args: SendEventArguments) {
		if (SocketIo.LOG) {
			console.info(
				`SocketIo`,
				"sendCustomBroadcast",
				"broadcast:" + this.channel + event,
				args
			);
		}
		
		return SocketIo.socket.emit("broadcast:" + this.channel + event, {
			message: args.message,
			data: args.data || null,
			emitter: args.emitter || null,
			ignoreMine: args.ignoreMine !== false,
		});
	}
}

class Event {
	args: SendEventArguments = {
		message: undefined,
		data: null,
		emitter: null,
		to: [],
		ignoreMine: true,
	};
	
	constructor(protected event: string) {
		if (this.user) {
			this.args.emitter = this.user;
			this.args.to = [
				SocketIo.channel + "enterprise-" + this.user.enterpriseId,
			];
		}
	}
	
	setMessage(message: string) {
		this.args.message = message;
		return this;
	}
	
	setFrom(user: UserInterface) {
		const self = this;
		
		self.args.emitter = user;
		
		if (self.args.to.length == 0 && user.enterpriseId) {
			self.args.to = [SocketIo.channel + "enterprise-" + user.enterpriseId];
		}
		
		return this;
	}
	
	setTo(to: string[]) {
		this.args.to = to;
	}
	
	addTo(to: string) {
		this.args.to.push(to);
	}
	
	setData(data: any) {
		this.args.data = data || null;
		return this;
	}
	
	ignoreMyOwn() {
		this.args.ignoreMine = true;
		return this;
	}
	
	dontIgnoreMyOwn() {
		this.args.ignoreMine = false;
		return this;
	}
	
	protected get user(): UserInterface | false {
		try {
			return JSON.parse(localStorage.getItem(config.me.cache_name));
		} catch (e) {
			return false;
		}
	}
}

export class Emit extends Event {
	send() {
		return SocketIo.sendCustomEmit(this.event, this.args);
	}
}

export class Broadcast extends Event {
	send() {
		return SocketIo.sendCustomBroadcast(this.event, this.args);
	}
}
