import { Routes } from "@angular/router";
import { SideNavItem } from "@durinn/v.3/app/template/template/side-nav/side-nav-routes.config";
import { DurinnV2ProfileComponent } from "@durinn/v.3/app/views/with-layout/me/profile/profile.component";
import { DurinnV2ProfileSettingsComponent } from "@durinn/v.3/app/views/with-layout/profile/settings/settings.component";
import { DurinnV2SubsidiaryListViewComponent } from "@durinn/v.3/app/views/with-layout/subsidiary/list/list.component";
import { DurinnV2SubsidiaryComponent } from "@durinn/v.3/app/views/with-layout/subsidiary/subsidiary.component";
import { DurinnV2TeamListViewComponent } from "@durinn/v.3/app/views/with-layout/team/list/list.component";
import { DurinnV2TeamSettingsComponent } from "@durinn/v.3/app/views/with-layout/team/settings/settings.component";
import { DurinnV2TeamComponent } from "@durinn/v.3/app/views/with-layout/team/team.component";
import { DurinnV2UserListViewComponent } from "@durinn/v.3/app/views/with-layout/user/list/list.component";
import { DurinnV2UserSettingsComponent } from "@durinn/v.3/app/views/with-layout/user/settings/settings.component";
import { DurinnV2UserComponent } from "@durinn/v.3/app/views/with-layout/user/user.component";
import { DurinnV3NotificationsComponent } from "./notifications/notifications.component";

export const sideMenu: SideNavItem[] = [
	{
		path: "/home",
		title: "Início",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "home",
		submenu: [],
	},
	{
		title: "Lojas",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "home",
		submenu: [
			{
				path: "/lojas",
				title: "Lojas",
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "home",
				submenu: [],
			},
		],
	},
	{
		title: "Usuários",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "user",
		submenu: [
			{
				path: "/usuarios",
				title: "Usuários",
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "user-add",
				submenu: [],
			},
			{
				path: "/equipes",
				title: "Equipes",
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "team",
				submenu: [],
			},
		],
	},
	{
		path: "/configuracoes",
		title: "Configurações",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "setting",
		submenu: [
			{
				path: "/configuracoes/cadastro-usuarios",
				title: "Usuários",
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "user-add",
				permissions: ["SETTINGS"],
			},
			{
				path: "/configuracoes/cadastro-equipes",
				title: "Equipes",
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "team",
				submenu: [],
				permissions: ["SETTINGS"],
			},
			{
				path: "/perfis",
				title: "Perfis",
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "user-add",
				submenu: [],
				permissions: ["CRUD_PROFILES"],
			},
		],
	},
];
export const routes: Routes = [
	{
		path: "perfil",
		component: DurinnV2ProfileComponent,
		data: {
			title: "Meu Perfil ",
		},
	},
	{
		path: "notificacoes",
		component: DurinnV3NotificationsComponent,
		data: {
			title: "Central de Notificações ",
		},
	},
	{
		path: "usuarios",
		data: {
			title: "Usuários",
		},
		children: [
			{
				path: "",
				component: DurinnV2UserListViewComponent,
			},
			{
				path: "editar/:id",
				redirectTo: ":id",
			},
			{
				path: ":id",
				data: {
					title: "Editar",
				},
				children: [
					{
						path: "",
						component: DurinnV2UserComponent,
					},
				],
			},
			{
				path: "novo",
				component: DurinnV2UserComponent,
				data: {
					title: "Adicionar",
				},
			},
		],
	},
	{
		path: "equipes",
		data: {
			title: "Equipes",
		},
		children: [
			{
				path: "",
				component: DurinnV2TeamListViewComponent,
			},
			{
				path: "editar/:id",
				redirectTo: ":id",
			},
			{
				path: ":id",
				data: {
					title: "Editar",
				},
				children: [
					{
						path: "",
						component: DurinnV2TeamComponent,
					},
				],
			},
			{
				path: "novo",
				component: DurinnV2TeamComponent,
				data: {
					title: "Adicionar",
				},
			},
		],
	},
	{
		path: "lojas",
		data: {
			title: "Lojas",
		},
		children: [
			{
				path: "",
				component: DurinnV2SubsidiaryListViewComponent,
			},
			{
				path: "filiais/:id",
				component: DurinnV2SubsidiaryComponent,
			},
			{
				path: ":id",
				data: {
					title: "Editar",
				},
				component: DurinnV2SubsidiaryComponent,
			},
		],
	},
	{
		path: "perfis",
		data: {
			title: "Perfis",
		},
		children: [
			{
				path: "",
				component: DurinnV2ProfileSettingsComponent,
			},
		],
	},
	{
		path: "configuracoes",
		data: {
			title: "Configurações",
		},
		children: [
			{
				data: {
					title: "Usuários",
				},
				path: "cadastro-usuarios",
				component: DurinnV2UserSettingsComponent,
			},
			{
				data: {
					title: "Equipes",
				},
				path: "cadastro-equipes",
				component: DurinnV2TeamSettingsComponent,
			},
		],
	},
];
