import { Component, HostListener } from "@angular/core";
import {
	URAS_SCHEDULE_STATUS_OPTIONS,
	URAS_SCHEDULE_STATUS_TRANSLATION,
} from "src/app/constants";
import { CrudPage } from "src/app/bases/crud.page";
import Swal from "sweetalert2";

@Component({
	selector: "app-agendas-uras",
	templateUrl: "./agendas-uras.component.html",
	styleUrls: ["./agendas-uras.component.css"],
})
export class AgendasUrasComponent extends CrudPage {
	filters: Record<string, any> = { UrasSchedule: {}, User: {} };
	order = { UrasSchedule: [["date", "desc"]] };
	route = "uras-schedule";
	keypressed = null;
	tab = "all";
	limit = 50;

	local_filter = {
		from: this.moment().subtract(12, "month").format("DD/MM/YYYY 08:00"),
		to: this.moment().format("DD/MM/YYYY 18:00"),
	};

	statusOptions = URAS_SCHEDULE_STATUS_OPTIONS;
	statusTranslation = URAS_SCHEDULE_STATUS_TRANSLATION;

	async exportToExcel() {
		const self = this;

		let args: any = { order: self.order };

		self.massTerminate.model = '';

		if (Object.values(self.getFilters()).length > 0) {
			const where = {};
			const items = self.getFilters();

			for (const item in items) {
				where[item] = { "Op.and": items[item] };
			}

			args = where;
		}

		self.call.openLink(self.route + `/export-to-excel/`, {where: JSON.stringify(args)});
	}

	massTerminate = {
		modal: false,
		call: this.api.new().set('success', true),
		model: ''
	};

	doMassTerminate(){
		const self = this;
		self.massTerminate.call.post(self.route + '/massTerminate', {lines: self.massTerminate.model.split(`\n`)}).subscribe(data => {
			console.log(data);
			self.massTerminate.modal = false;
			self.get(1);
		});
	}

  @HostListener("window:keydown", ["$event"])
	handleKeyboardEvent(event: KeyboardEvent) {
		this.keypressed = event.keyCode;
		if (this.keypressed == 13) {
			if (document.activeElement instanceof HTMLElement) {
				document.activeElement.blur();
			}
			this.get(1);
		}
	}

  protected getFilters() {
  	const self = this;

  	const from = self.moment(self.local_filter.from, "DD/MM/YYYY HH:mm");
  	const to = self.moment(self.local_filter.to, "DD/MM/YYYY HH:mm");

  	if (from.isValid() && to.isValid()) {
  		self.filters.UrasSchedule["Op.and"] = [
  			{ date: { "Op.gte": from.toDate() } },
  			{ date: { "Op.lte": to.toDate() } },
  		];
  	} else {
  		self.filters.UrasSchedule["Op.and"] = [];
  	}

  	return super.getFilters();
  }

  changeTab(tab, same = false) {
  	const self = this;
  	const prevTab = self.tab;
  	self.tab = tab;

  	if (tab != prevTab || same == true) {
  		if (tab == "all") {
  			self.filters = {
  				UrasSchedule: {},
  				User: {},
  			};
  		}

  		if (tab == "pending") {
  			self.filters = {
  				UrasSchedule: {
  					"Op.or": [
  						{ status: { "Op.is": null } },
  						{ status: { "Op.eq": 0 } },
  					],
  				},
  				User: {},
  			};
  		}

  		if (tab == "not-downloaded") {
  			self.filters = {
  				UrasSchedule: {
  					status: { "Op.eq": 2 }
  				},
  				User: {},
  			};
  		}

  		if (tab == "downloaded-calls") {
  			self.filters = {
  				UrasSchedule: {
  					status: { "Op.eq": 1 }
  				},
  				User: {},
  			};
  		}

  		if (tab == "downloaded") {
  			self.filters = {
  				UrasSchedule: {
  					status: { "Op.eq": 3 }
  				},
  				User: {},
  			};
  		}

  		self.get(1);
  	}
  }

  filtersClean() {
  	const self = this;
  	self.changeTab(self.tab, true);
  }

  openAddSchedule() {

  }

  async see(schedule) {
  	const confirm = await Swal.fire({
  		title: "Agendas",
  		html:
        "Você deseja ver a <b> agenda " +
        schedule.id +
        "</b> de <b>" +
        ((schedule.User || {}).name || "Nenhum").split(" ")[0] +
        "</b>?",
  		icon: "question",
  		showCloseButton: true,
  		showCancelButton: true,
  		focusConfirm: false,
  		confirmButtonText: "Ver Agenda",
  		cancelButtonText: "Cancelar",
  	});
  	if (confirm.value) {
  		const win = window.open("agendas/uras/editar/" + schedule.id, "_blank");
  		win.focus();
  	}
  }
}
