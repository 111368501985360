import { CrudPage } from "../../../bases/crud.page";
import { Io } from "@durinn.v3/helpers/api";
import { Component } from "@angular/core";

declare const google: any;

@Component({
	selector: "app-bases",
	templateUrl: "./bases.component.html",
	styleUrls: ["./bases.component.css"],
})
export class BasesComponent extends CrudPage {
	get google(){
		if(typeof (window as any).google !== 'undefined'){
			return (window as any).google;
		}
		
		if(typeof google !== 'undefined'){
			return google;
		}
		
		return false;
	}
	
	items = [];
	filters = {
		Branch: {
			name: null,
			formattedAddress: null,
		},
	};

	permissions = {
		CREATE_BRANCHES: false,
	};

	order = {
		Branch: [["name", "asc"]],
	};

	call: Io;
	edit_call = this.api.new().set("success", true);
	delete_call: Io;
	route = "branch";

	newBranch: any = {
		visible: false,
		object: {},
	};

	open(item) {
		this.newBranch.object = Object.assign({}, item);
		this.newBranch.visible = true;
	}

	send() {
		const self = this;
		const observable = self.newBranch.object.id
			? self.edit_call.put(
				self.route + "/" + self.newBranch.object.id,
				Object.assign({}, self.newBranch.object)
			  )
			: self.edit_call.post(
				self.route,
				Object.assign({}, self.newBranch.object)
			  );

		observable.subscribe(() => {
			self.newBranch.object = {};
			self.newBranch.visible = false;
			self.ngOnInit();
		});
	}

	fillAddress(address) {
		this.newBranch.object.formattedAddress = address.formatted_address;
		this.newBranch.object.latitude = address.geometry.location.lat();
		this.newBranch.object.longitude = address.geometry.location.lng();
	}
}
