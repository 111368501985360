import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { EditPage } from "src/app/bases/edit.page";
import * as moment from "moment";

@Component({
	selector: "app-edit-customers",
	templateUrl: "./edit-customers.component.html",
	styleUrls: ["./edit-customers.component.css"],
})
export class EditCustomersComponent extends EditPage {
	back_to = "clientes";
	route = "customer";

	form = {
		email: "",
		phone: "",
		picture: "",
		Emails: [],
		Phones: [],
		Address: [],
		Permissions: [],
	};

	fields = [
		{
			name: "name",
			type: "text",
			label: "Nome",
			required: true,
			validate: "Por favor, preencha com um nome válido!",
		},
		{
			type: "text",
			required: true,
			name: "document",
			label: "CPF / CNPJ",
			validate: "Por favor, preencha com um documento válido!",
		},
	];

	new = {
		email: "",
		phone: "",
	};

	permissions = {
		EDIT_CUSTOMERS: false,
		MASTER: false,
	};

  @ViewChild("addressNumber", { static: false })
  	addressNumberRef: ElementRef<HTMLInputElement>;

  address = {
  	city: "",
  	state: "",
  	street: "",
  	zipcode: "",
  	neighborhood: "",
  	addressNumber: "",
  };

  addMail() {
  	const self = this;

  	if (!self.form.Emails) {
  		self.form.Emails = [];
  	}

  	self.form.Emails.push({
  		email: self.new.email,
  	});
  }

  removeEmail(i) {
  	const self = this;

  	if (!self.form.Emails) {
  		self.form.Emails = [];
  	}

  	self.form.Emails.splice(i, 1);
  }

  addPhone() {
  	const self = this;

  	if (!self.form.Phones) {
  		self.form.Phones = [];
  	}

  	self.form.Phones.push({
  		phone: self.new.phone,
  	});

  	self.new.phone = null;
  }

  removePhone(i) {
  	const self = this;

  	if (!self.form.Phones) {
  		self.form.Phones = [];
  	}

  	self.form.Phones.splice(i, 1);
  }

  isVisible = false;

  addAddress() {
  	const self = this;

  	if (!self.form.Address) {
  		self.form.Address = [];
  	}

  	self.isVisible = false;
  	self.form.Address.push({ ...self.address });
  	self.address = {} as any;
  }

  removeAddress(i) {
  	const self = this;

  	if (!self.form.Address) {
  		self.form.Address = [];
  	}

  	self.form.Address.splice(i, 1);
  }

  public getAddressByZipcode(zipcode: string) {
  	const self = this;
  	if (zipcode && zipcode.length === 8) {
  		const url = `https://viacep.com.br/ws/${zipcode}/json/`;
  		self.api.http.get(url).subscribe((data: any) => {
  			self.address.street = data.logradouro;
  			self.address.city = data.localidade;
  			self.address.neighborhood = data.bairro;
  			self.address.state = data.uf;
  			self.addressNumberRef.nativeElement.focus();
  		});
  	}
  }

  handleCancel() {
  	this.isVisible = false;
  	this.address = {} as any;
  }

  isCollaboratorsIndeterminate = false;
  isTimesheetAddModalVisible = false;
  isAllCollaboratorsChecked = true;
  timesheetCall = this.api.new();
  collaborators = [];
  moment = moment;
  window = window;

  params: any = {
  	from: moment().startOf("month").format("DD/MM/YYYY"),
  	to: moment().endOf("month").format("DD/MM/YYYY"),
  };

  resetParams() {
  	const self = this;

  	self.params = {
  		from: moment().startOf("month").format("DD/MM/YYYY"),
  		to: moment().endOf("month").format("DD/MM/YYYY"),
  	};

  	self.collaborators = [];
  }

  isDatesValid() {
  	const self = this;

  	if (self.params.from.length != 10) {
  		return false;
  	}

  	if(self.params.to.length && self.params.to.length != 10) {
  		return false;
  	}

  	if (self.params.to.length == 10 && !moment(self.params.to, "DD/MM/YYYY").isValid()) {
  		return false;
  	}
    
  	return moment(self.params.from, "DD/MM/YYYY").isValid();
  }

  isParamsValid() {
  	const self = this;

  	if (self.isAllCollaboratorsChecked) {
  		if (!self.collaborators.length) {
  			return false;
  		}
  	} else {
  		if (!self.isCollaboratorsIndeterminate) {
  			return false;
  		}
  	}

  	return self.isDatesValid();
  }

  updateAllCollaboratorsChecked() {
  	const self = this;

  	self.isCollaboratorsIndeterminate = false;

  	if (self.isAllCollaboratorsChecked) {
  		self.collaborators = self.collaborators.map((item) => ({
  			...item,
  			checked: true,
  		}));
  	} else {
  		self.collaborators = self.collaborators.map((item) => ({
  			...item,
  			checked: false,
  		}));
  	}
  }

  updateSingleCollaboratorChecked(): void {
  	const self = this;

  	if (self.collaborators.every((item) => !item.checked)) {
  		self.isCollaboratorsIndeterminate = false;
  		self.isAllCollaboratorsChecked = false;
  	} else if (self.collaborators.every((item) => item.checked)) {
  		self.isCollaboratorsIndeterminate = false;
  		self.isAllCollaboratorsChecked = true;
  	} else {
  		self.isCollaboratorsIndeterminate = true;
  	}
  }

  getTimesheetData() {
  	const self = this;

  	const body = {
  		from: moment(self.params.from, "DD/MM/YYYY")
  			.startOf("day")
  			.format("YYYY-MM-DD HH:mm:ss"),
  		to: self.params.to.length
  			? moment(self.params.to, "DD/MM/YYYY")
  				.endOf("day").format("YYYY-MM-DD HH:mm:ss")
  			: null,
  		customerId: self.id,
  	};

  	if (self.isDatesValid()) {
  		self.timesheetCall
  			.post(`${self.route}/search/collaborators`, body)
  			.subscribe((response) => {
  				self.isAllCollaboratorsChecked = true;
  				self.isCollaboratorsIndeterminate = false;
  				self.collaborators = response.return.map((item) => ({
  					...item,
  					checked: true,
  				}));
  			});
  	}
  }

  openTimesheetAddModal() {
  	const self = this;
  	self.getTimesheetData();
  	self.isTimesheetAddModalVisible = true;
  }

  timesheetAction() {
  	const self = this;
  	const body = { 
  		...self.params, 
  		customerId: self.id
  	};

  	body.to = moment(body.to, "DD/MM/YYYY")
  		.endOf("day")
  		.format("YYYY-MM-DD HH:mm:ss");
  	body.from = moment(body.from, "DD/MM/YYYY")
  		.startOf("day")
  		.format("YYYY-MM-DD HH:mm:ss");

  	body.ids = self.collaborators
  		.map((item) => {
  			if (item.checked) {
  				return {
  					userId: item.userId,
  					adminId: item.adminId,
  				};
  			}
  		})
  		.filter((item) => item);
	
  	self.timesheetCall.timeout = 90 * 1000 * 1000;

  	self.timesheetCall
  		.post(`${self.route}/collaborators/export/pdf`, body)
  		.subscribe((response) => {
  			if (response.return) {
  				window.location.href = response.return;
  			}
  			self.isTimesheetAddModalVisible = false;
  		});
  }
}
