import { Component } from "@angular/core";

@Component({
	selector: "durinn-v2-user-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.css"],
})
export class DurinnV2UserSettingsComponent {
	userDefaultFields = [
		{ field: "Nome", mask: "", required: true },
		{ field: "CPF", mask: "000.000.000-00", required: true },
		{ field: "Telefone", mask: "+00 (00) 00000-0000", required: false },
		{ field: "E-mail", mask: "", required: false },
	];
}
