import languageEncoding from "detect-file-encoding-and-language";
import { Component, OnInit } from "@angular/core";
import { Api } from "@durinn.v3/helpers/api";
import { Me } from "@durinn.v3/helpers/me";
import { Router } from "@angular/router";
import * as moment from "moment";
import swal from "sweetalert2";
import * as XLSX from "xlsx";
import utf8 from "utf8";

const FIELDS = {
	"CEP": "cep",
	"CEP/CÓDIGO POSTAL": "cep",
	"CIDADE": "city",
	"COD CIDADE": "city_code",
	"CODIGO CIDADE": "city_code",
	"CODIGO OPERADORA": "code",
	"CODIGOS BARRA": "barcodes",
	"COMPLEMENTO ENDEREÇO": "complement",
	"CONTRATO": "contract",
	"NumeroContrato": "contract",
	"CodOperadora": "code",
	"CodigoOS": "os",
	"CPF TECNICO": "cpf",
	"CPF TÉCNICO": "cpf",
	"DATA ABERTURA": "opening_date",
	"DATA AGENDA": "date",
	"DATA BACKEND": "backend_date",
	"DATA DE ABERTURA": "opening_date",
	"DataAbertura": "opening_date",
	"DataTratadoBackend": "backend_date",
	"DOCUMENTO TITULAR": "holder_document",
	"EQUIPAMENTOS": "barcodes",
	"ENDERECO": "pre_address",
	"ENDEREÇO": "pre_address",
	"END. COMPLETO": "pre_address",
	"FIM AGENDA": "end",
	"HABILIDADE DE TRABALHO": "work_skill",
	"INICIO AGENDA": "start",
	"INTERVALO DE TEMPO": "interval_time",
	"NOME TITULAR": "holder",
	"NomeCompleto": "holder",
	"OBSERVACAO": "note",
	"OBSERVAÇÃO": "note",
	"OCORRENCIA": "os",
	"OS": "os",
	"TELEFONES": "phones",
	"TELEFONES TITULAR": "phones",
	"TELEFONES TITULAR 2": "phones2",
	"TELEFONES TITULAR 3": "phones3",
	"TELEFONES TITULAR 4": "phones4",
	"TIPO AGENDA": "type",
};


@Component({
	selector: "app-importar-net2",
	templateUrl: "./importar-net2.component.html",
	styleUrls: ["./importar-net2.component.css"],
})
export class ImportarNet2Component implements OnInit {
	moment = moment;
	loading = false;

	load = [];
	import = [];
	users = [];
	imports = [];
	reading_file = false;
	result = null;

	call = this.api.new();
	call2 = this.api.new();

	page = 1;
	pages = 0;

	constructor(private api: Api, private me: Me, private router: Router) {}

	ngOnInit() {
		this.get();
	}

	async onFileChanged(event) {
		const self = this;
		const reader = new FileReader();

		const file = event.target.files[0];

		if (file.name.indexOf(".xlsx") > -1) {
			reader.readAsBinaryString(file);
			reader.onload = (e: any) => {
				/* create workbook */
				const binarystr: string = e.target.result;
				const wb: XLSX.WorkBook = XLSX.read(binarystr, {
					type: "binary",
				});

				/* selected the first sheet */
				const wsname: string = wb.SheetNames[0];
				const ws: XLSX.WorkSheet = wb.Sheets[wsname];

				/* save data */
				const data: string[][] = XLSX.utils.sheet_to_json(ws, {
					header: 1,
					raw: false,
				}); // to get 2d array pass 2nd parameter as object {header: 1}
				console.log(`excel cru`, data); // Data will be logged in array format containing objects

				const lines = [];

				for (const line of data) {
					lines.push( (line.join(";")).split("\n").join("") );
				}

				self.result = lines.join("\n");
				self.reading_file = false;
				self.generate();
			};
		} else {
			self.reading_file = true;

			const fileInfo = await languageEncoding(event.target.files[0]);
			reader.readAsText(
				event.target.files[0],
				fileInfo.encoding || "ISO-8859-1"
			);

			reader.onload = (x) => {
				self.result = reader.result;
				self.reading_file = false;
				self.generate();
			};
		}
	}

	generate() {
		const self = this;
		self.import = [];

		try {
			if (typeof self.result !== "string") {
				return swal.fire("Importação", "Erro no arquivo", "error");
			}

			const _import = self.result.split("\n");

			for (const item of _import) {
				if (item.substr(0, 11).toUpperCase() != "OBRIGATÓRIO") {
					self.import.push(
						item
							.replace(/(\r\n|\n|\r)/gm, "")
							.replace(/\s\s+/g, " ")
							.split(";")
					);
				}
			}

			swal.fire({
				title: "Importar",
				text: `Você deseja importar essas agendas?`,
				icon: "question",
				showCancelButton: true,
				confirmButtonColor: "#DD6B55",
				confirmButtonText: "Importar",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.value === true) {
					self.build();
				}
			});
		} catch (e) {
			swal.fire("Importação", "Envie um arquivo .csv", "error");
			console.error(e, self.result);
		}
	}

	async build() {
		const self = this;

		self.loading = true;
		self.load = [];

		const header: { [a: number]: string } = {
			/* 0: 'contract' */
		};

		for (const item in self.import[0]) {
			for (const row in FIELDS) {
				if (self.import[0][item].toUpperCase().trim() === row.toUpperCase().trim()) {
					header[item] = FIELDS[row];
				}
			}
		}

		self.import.shift();

		function checkUTF8(text) {
			let utf8Text = text;
			try {
				// Try to convert to utf-8
				utf8Text = decodeURIComponent(escape(text));
				// If the conversion succeeds, text is not utf-8
			}catch(e) {
				// console.log(e.message); // URI malformed
				// This exception means text is utf-8
			}
			return utf8Text; // returned text is always utf-8
		}

		for (const line of self.import) {
			const obj: any = {};

			for (const item in header) {
				obj[header[item]] = line[item];
				// typeof line[item] === "string" && !checkUTF8(line[item])
				// ? utf8.encode(line[item])
				// : line[item];
			}

			let pre_address = "";

			const address_array = (obj.pre_address || "").split(" ");

			for (const item of address_array) {
				pre_address += item + " ";
				if (/^\d+$/.test(item)) {
					break; 
				}
			}

			const complement = obj.complement
				? obj.complement
				: (obj.pre_address || "")
					.toLowerCase()
					.replace(pre_address.toLowerCase(), "");

			if (pre_address.substr(0, 2) == "R ") {
				pre_address = "RUA " + pre_address.substr(2);
			}

			if (pre_address.substr(0, 3) == "TV ") {
				pre_address = "TRAVESSA " + pre_address.substr(3);
			}

			pre_address = pre_address.replace("apt", "");

			let address = pre_address;

			if (obj.city) {
				address += " - " + obj.city;
			}

			if (obj.cep) {
				address += " - " + obj.cep;
			}

			if (obj.interval_time) {
				const intervalArray = obj.interval_time.split(" - ");
				obj.start = intervalArray[0];
				obj.end = intervalArray[1];
			}

			const meData = (await self.me.getCacheData()) as any;

			if (meData) {
				address +=
					meData.enterprise_id == 1
						? ", Rio Grande do Sul, Brasil"
						: ", Santa Catarina, Brasil";
			}

			// address = address.match(/[a-z]+|[^a-z]+/gi).join(" ");
			// address = address.replace(/  +/g, " ");

			obj.address = address.toLowerCase();
			obj.pre_address = address.toLowerCase();
			obj.complement = complement;
			// A data pode vir como "DD/MM/YYYY" ou "3/29/22"

			if (typeof obj.date == "string") {
				obj.date = moment(
					obj.date,
					obj.date.length == 10 ? "DD/MM/YYYY" : "M/D/YY"
				).format("DD/MM/YYYY");
			} else {
				obj.date = moment(obj.date || new Date()).format("DD/MM/YYYY");
			}

			if (typeof obj.opening_date == "string") {
				obj.opening_date = moment(
					obj.opening_date,
					obj.opening_date.length == 10 ? "DD/MM/YYYY" : "M/D/YY"
				).format("DD/MM/YYYY");
			} else {
				obj.opening_date = moment(obj.opening_date || new Date()).format("DD/MM/YYYY");
			}

			if (typeof obj.backend_date == "string") {
				obj.backend_date = moment(
					obj.backend_date,
					obj.backend_date.length == 10 ? "DD/MM/YYYY" : "M/D/YY"
				).format("DD/MM/YYYY");
			} else {
				obj.backend_date = moment(obj.backend_date || new Date()).format("DD/MM/YYYY");
			}

			if(obj.start){
				obj.start = obj.start.padStart(5, '0'); //8:00
			}

			if(obj.end) {
				obj.end = obj.end.padStart(5, '0'); //8:00
			}

			obj.loading = true;

			if (obj.work_skill) {
				if (!obj.note) {
					obj.note = ``;
				}

				obj.note += `Habilidade de Trabalho: ${obj.work_skill}`;
			}

			if (obj.barcodes) {
				obj.barcodes = obj.barcodes.split("/");
				for (const item in obj.barcodes) {
					obj.barcodes[item] = obj.barcodes[item].trim();
				}
			}

			if (obj.contract || obj.type) {
				const item = obj;
				self.load.push(obj);
			}
		}

		if (self.load.length == 0) {
			return swal.fire(
				"Nenhuma linha válida foi encontrada",
				"Confira se o cabeçalho da tabela ou suas linhas estão no formato correto!",
				"error"
			);
		}
		
		for(const item of self.load){
			if(!item.contract){
				return swal.fire(
					"Erro",
					`Nenhum contrato encontrado na linha ${JSON.stringify(item)}`,
					"error"
				);
			}
			
			if(!item.cpf){
				console.log('item', item);
				return swal.fire(
					"Erro",
					`O CONTRATO ${item.contract} com OS ${item.os} não tem nenhum técnico associado. Adicione a coluna "CPF TECNICO"`,
					"error"
				);
			}
		}

		console.log(`self.load`, self.load);
		self.send();
	}

	async send() {
		const self = this;
		console.log("load", self.load);
		self.call.post("importer/new", self.load).subscribe((data) => {
			if (!data.return.error) {
				return swal.fire(
					"Importação",
					"Importação feita com sucesso!",
					"success"
				);
			} else {
				self.router.navigateByUrl(
					"/agendas/importar/" + data.return.token
				);
			}
		});
	}

	async get(page = 1) {
		const self = this;
		self.call2
			.get("importer/" + (page - 1) * 10 + "/3")
			.subscribe((data) => {
				self.pages = Math.ceil(data.return.count / 10);
				self.imports = data.return.rows;
			});
	}
}
