import { CrudPage } from "src/app/bases/crud.page";
import { Component } from "@angular/core";

@Component({
	selector: "app-customers",
	templateUrl: "./customers.component.html",
	styleUrls: ["./customers.component.css"],
})
export class CustomersComponent extends CrudPage {
	route = "customer";
	items = [];

	filters = {
		Customer: {
			name: null,
			document: null,
		},
		CustomerEmail: {
			email: null,
		},
		CustomerPhone: {
			phone: null,
		},
	};

	order = {
		Customer: [["name", "asc"]],
		CustomerEmail: [[]],
		CustomerPhone: [[]],
	};
}
