import { Component, OnInit } from '@angular/core';
import {DashboardBase} from "../../../../../../durinn/bases/dashboard.base";
import * as moment from 'moment';

@Component({
	selector: 'app-agendas-uras-dashboard',
	templateUrl: './agendas-uras-dashboard.component.html',
	styleUrls: ['./agendas-uras-dashboard.component.css']
})
export class AgendasUrasDashboardComponent extends DashboardBase {
	Object = Object;

	data = null;
	call = this.api.new();
	from = moment().startOf('month').format('DD/MM/YYYY');
	to = moment().endOf('month').format('DD/MM/YYYY');

	ngOnInit() {
		super.ngOnInit();
		this.get();
	}

	perUser = [];

	get(){
		const self = this;
		const perUser = {};

		self.call.get('uras-schedule/overview/' + moment(this.from, 'DD/MM/YYYY').toISOString() + '/' + moment(this.to, 'DD/MM/YYYY').toISOString()).subscribe(data => {
			self.data = data.return;

			const indexes = ['total', 'baixado_chamado', 'baixado', 'nao_baixado'];

			for(const index of indexes){
				for(const item of self.data[index]){
					if(!perUser[item.id]){
						perUser[item.id] = {
							adminId: item.id,
							name: item.name,
							total: 0,
							baixado_chamado: 0,
							baixado: 0,
							nao_baixado: 0,
						};
					}

					perUser[item.id][index] = item.count;
				}
			}

			self.perUser = Object.values(perUser);
			self.perUser.sort((a,b) => (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0));
		});
	}

	exportToExcel() {
		const self = this;
		const from = moment(this.from, 'DD/MM/YYYY').toISOString();
		const to = this.to != null ? moment(this.to, 'DD/MM/YYYY').toISOString() : null;

		const route = to
			? `uras-schedule/export/xlsx/${from}/${to}`
			: `uras-schedule/export/xlsx/${from}`;

		self.call.openLink(route);
	}

	exportUserSchedule(item: { adminId: number }) {
		const self = this;
		const from = moment(this.from, 'DD/MM/YYYY').toISOString();
		const to = this.to != null ? moment(this.to, 'DD/MM/YYYY').toISOString() : null;

		const route = to
			? `uras-schedule/export/xlsx/${item.adminId}/${from}/${to}`
			: `uras-schedule/export/xlsx/${item.adminId}/${from}`;

		self.call.openLink(route);
	}
}
