import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProfileInterface } from "@backend-interfaces/models";
import { Api } from "@durinn/v.3/helpers/api";

export type Permission = {
	name: string;
	description: string;
	musthave: string[];
	encompasses: string[] | true;
	checked: boolean;
	category: string;
	categoryDescription: string;
};

@Component({
	selector: "user-permissions-table",
	templateUrl: "./user-permissions-table.component.html",
	styleUrls: ["./user-permissions-table.component.css"],
})
export class UserPermissionsTableComponent implements OnInit {
	@Input() user: any;
	@Input() profiles = true;
	@Input() maxHeight = 600;
	@Output() changed = new EventEmitter();
	@Output() profileChanged = new EventEmitter();

	call = this.api.new();
	profilesCall = this.api.new().silent(true);

	PERMISSIONS: { [name: string]: Permission & { disabled: boolean } } = {};
	PROFILES: ProfileInterface[] = [];

	profile = null;

	constructor(public api: Api) { }

	Object = Object;

	ngOnInit() {
		const self = this;

		self.call.get(`permissions/`).subscribe((data) => {
			self.PERMISSIONS = data.return;
			self.checkPermissions();
			self.emitCheckedPermissions();
		});

		self.profilesCall.get(`permissions/profiles`).subscribe((data) => {
			self.PROFILES = data.return;
		});
	}

	ngOnChanges() {
		this.checkPermissions();
	}

	get isMasterChecked(){
		const self = this;

		for (const item in self.PERMISSIONS) {
			if(item == 'MASTER' && self.PERMISSIONS[item].checked){
				return true;
			}
		}

		return false;
	}

	checkPermissions() {
		const self = this;

		if (!self.user) {
			return false;
		}
		
		if (self.user.Profile) {
			self.profile = self.user.Profile;
		}

		if (!self.user.Permissions) {
			self.user.Permissions = [];
		}

		if (self.user.id) {
			for (const item in self.PERMISSIONS) {
				self.PERMISSIONS[item].checked = false;
			}
		}

		for (const item of self.user.Permissions) {
			if (self.PERMISSIONS[item.permission]) {
				self.PERMISSIONS[item.permission].checked = true;
			}
		}
	}

	emit(item) {
		const checkedPermissions = [];

		this.calcEncompasses(item);

		for (const item of Object.values(this.PERMISSIONS)) {
			if (item.checked) {
				checkedPermissions.push(item);
			}
		}

		this.changed.emit(checkedPermissions);
	}

	emitCheckedPermissions() {
		const checkedPermissions = [];
		for (const item of Object.values(this.PERMISSIONS)) {
			if (item.checked) {
				checkedPermissions.push(item);
			}
		}
		this.changed.emit(checkedPermissions);
	}

	validMustHave(item) {
		const self = this;
		for (const name of item.musthave) {
			if (!self.PERMISSIONS[name] || !self.PERMISSIONS[name].checked) {
				if (item.checked) {
					item.checked = false;
					self.emit(item);
				}
				return false;
			}
		}

		return true;
	}

	calcEncompasses(permissionChecked) {
		const self = this;
		if (Array.isArray(permissionChecked.encompasses)) {
			for (const encompass of permissionChecked.encompasses) {
				if(self.PERMISSIONS[encompass] && permissionChecked){
					self.PERMISSIONS[encompass].checked = permissionChecked.checked;
					
					if (self.PERMISSIONS[encompass].name != permissionChecked.name) {
						self.PERMISSIONS[encompass].disabled = permissionChecked.checked;
					}
				}
			}
		}

		if (permissionChecked && permissionChecked.encompasses === true) {
			for (const item in this.PERMISSIONS) {
				self.PERMISSIONS[item].checked = permissionChecked.checked;

				if (self.PERMISSIONS[item].name != permissionChecked.name) {
					self.PERMISSIONS[item].disabled = permissionChecked.checked;
				}
			}
		}
	}
	
	profileChange() {
		const self = this;
		
		this.profileChanged.emit(this.profile);

		for (const item in self.PERMISSIONS) {
			self.PERMISSIONS[item].checked = !!self.profile.Permissions.find(permission => self.PERMISSIONS[item].name == permission.permission);
			
			console.log(item, self.PERMISSIONS[item].checked);
			
			
			if (self.PERMISSIONS[item].checked) {
				self.calcEncompasses(self.PERMISSIONS[item]);
			}
		}

		self.emitCheckedPermissions();
	}

	getDescriptions(item) {
		const self = this;
		const permissions = [];

		for (let i = 1; i <= item.encompasses.length; i++) {
			const element = item.encompasses[i - 1];
			
			if (self.PERMISSIONS[element]) {
				const text = self.PERMISSIONS[element].description;
				permissions.push(`${i} - ${text}`);
			}
		}

		return permissions.join('; ');
	}
}
