import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "formatname",
})
export class FormatnamePipe implements PipeTransform {
	transform(value: string, args?: any): any {
		value = value.toLowerCase();
		const array = value.split(" ");
		return array[1]
			? array[0] +
					" " +
					(array[1][0] || "") +
					(array[1][1] || "") +
					(array[1][2] || "") +
					"."
			: array[0];
	}
}
