import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "durinn-card",
	templateUrl: "./durinn-card.component.html",
	styleUrls: ["./durinn-card.component.css"],
})
export class DurinnCardComponent implements OnInit {
	@Input() title = "";
	@Input() loading = false;
	@Input() style: any = { padding: 0 };

	getStyle() {
		const self = this;
		return self.loading
			? { ...self.style, ...{ padding: null } }
			: self.style;
	}

	constructor() {}

	ngOnInit() {}
}
