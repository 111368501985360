import { Component, ElementRef, ViewChild } from "@angular/core";
import * as moment from "moment";
import { FilterOrderBase } from "../filter-order.base";

@Component({
	selector: "durinn-v2-filter-order-date",
	templateUrl: "./filter-order-date.component.html",
	styleUrls: ["./filter-order-date.component.css"],
})
export class FilterOrderDateComponent extends FilterOrderBase {
	value = "";
	modalVisible = false;

	type = "date";

	from = "";
	to = "";

  @ViewChild("inputTo", { static: false }) inputTo: ElementRef;
  @ViewChild("inputFrom", { static: false }) inputFrom: ElementRef;

  ngOnInit() {}

  setFocus() {
  	this.inputFrom.nativeElement.focus();
  }

  isInvalid() {
  	const self = this;

  	if (self.type == "date" && [10, 13, 16].indexOf(self.from.length) > -1) {
  		return false;
  	}

  	if (
  		self.type == "range" &&
      [10, 13, 16].indexOf(self.from.length) > -1 &&
      [10, 13, 16].indexOf(self.to.length) > -1
  	) {
  		return false;
  	}

  	return true;
  }

  handleOk() {
  	const self = this;

  	if (self.isInvalid()) {
  		return false;
  	}

  	if (self.type == "date") {
  		if (self.from.length == 10) {
  			self.page.filters[self.index][self.column] = {
  				"Op.between": [
  					moment(self.from, "DD/MM/YYYY").startOf("day").toDate(),
  					moment(self.from, "DD/MM/YYYY").endOf("day").toDate(),
  				],
  			};
  		}

  		if (self.from.length == 13) {
  			self.page.filters[self.index][self.column] = {
  				"Op.between": [
  					moment(self.from, "DD/MM/YYYY HH").startOf("hour").toDate(),
  					moment(self.from, "DD/MM/YYYY HH").endOf("hour").toDate(),
  				],
  			};
  		}

  		if (self.from.length == 16) {
  			self.page.filters[self.index][self.column] = {
  				"Op.between": [
  					moment(self.from, "DD/MM/YYYY HH:mm").startOf("minute").toDate(),
  					moment(self.from, "DD/MM/YYYY HH:mm").endOf("minute").toDate(),
  				],
  			};
  		}

  		self.value = self.from;
  	}

  	if (self.type == "range") {
  		const between = [];

  		if (self.from.length == 10) {
  			between.push(moment(self.from, "DD/MM/YYYY").startOf("day").toDate());
  		}

  		if (self.from.length == 13) {
  			between.push(
  				moment(self.from, "DD/MM/YYYY HH").startOf("hour").toDate()
  			);
  		}

  		if (self.from.length == 16) {
  			between.push(
  				moment(self.from, "DD/MM/YYYY HH:mm").startOf("minute").toDate()
  			);
  		}

  		if (self.to.length == 10) {
  			between.push(moment(self.to, "DD/MM/YYYY").endOf("day").toDate());
  		}

  		if (self.to.length == 13) {
  			between.push(moment(self.to, "DD/MM/YYYY HH").endOf("hour").toDate());
  		}

  		if (self.to.length == 16) {
  			between.push(
  				moment(self.to, "DD/MM/YYYY HH:mm").endOf("minute").toDate()
  			);
  		}

  		self.value = self.from + " - " + self.to;
  		self.page.filters[self.index][self.column] = { "Op.between": between };
  	}

  	self.changedModel();
  	self.modalVisible = false;
  }
}
