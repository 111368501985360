import { Component, Input, OnInit } from "@angular/core";
import { FinanceiroComponent } from "../../financeiro/financeiro.component";
import * as moment from "moment";
import { TotalMesComponent } from "../total-mes/total-mes.component";

@Component({
	selector: "app-resumo-financeiro",
	templateUrl: "./resumo-financeiro.component.html",
	styleUrls: ["./resumo-financeiro.component.css"],
})
export class ResumoFinanceiroComponent extends TotalMesComponent {
	@Input() data = null;
	@Input() previousData = null;

	Math = Math;
	Object = Object;

	total = 0;
	previousTotal = 0;

	devices = {
		aparelho: 0,
		previous_aparelho: 0,
		nagra: 0,
		previous_nagra: 0,
		chip: 0,
		previous_chip: 0,
	};

	codes = {};
	previous_codes = {};

	ngOnInit(): void {
		this.search.from =
			this.from || moment().subtract(1, "month").format("21/MM/YYYY");
		this.search.to = this.to || moment().format("20/MM/YYYY");
		super.ngOnInit();
		if (this.from && this.to) {
			this.getData().subscribe((data) => this.calc());
		}
	}
}
