import { Component } from "@angular/core";
import { FiltersType, Orders } from "@durinn/v.3/app/bases/listpage.base";
import { PageBase } from "@durinn/v.3/app/bases/page.base";
import {
	DurinnV2ListPageTableComponent,
	HeadColumns,
} from "@durinn/v.3/app/components/template/tables/list-page-table/list-page-table.component";

@Component({
	selector: "durinn-v2-provider-list-view",
	templateUrl: "./list.component.html",
	styleUrls: ["./list.component.css"],
})
export class DurinnV2ProviderListViewComponent extends PageBase {
	order: Orders = { Provider: [["name", "asc"]] };
	filters: FiltersType = { Provider: {} };

	phoneStyles = {
		minWidth: "160px !important",
		width: "160px !important",
	};

	headColumns: HeadColumns =
		DurinnV2ListPageTableComponent.defaultHeadColumnsWith("Provider", [
			{ index: "Provider", column: "cpfcnpj", name: "CPF / CNPJ" },
			{ index: "ProviderEmail", column: "email", name: "E-mail" },
			{
				index: "ProviderPhone",
				column: "phone",
				name: "Telefone",
				mask: "(00) 00000-0000",
				style: this.phoneStyles,
			},
		]);

	showEmails(item) {
		const emails = item.Emails.map((x) => x.email);
		return (emails || []).join(", ");
	}

	showPhones(item) {
		const emails = item.Phones.map((x) => x.phone);
		return (emails || []).join(", ");
	}
}
