import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "@durinn/v.3/app/views/no-layout/login/login.component";

const routes: Routes = [
	{
		path: "**",
		component: LoginComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class NoLayoutRoutingModule {}
