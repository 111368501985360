import { Input, SimpleChanges } from "@angular/core";
import { PageBase } from "./page.base";

export interface ListBaseInterface {
  route: string;
  order: Orders;
  filters: FiltersType;
}

export type IncludesType = null | 'auto' | { model: string; as ?: string; required ?: boolean; attributes ?: any[] }[];
export type FiltersType = { [index: string]: { [subindex: string]: any } };
export type Orders = { [index: string]: string[] | string[][] };
export type ParamsType = Record<string, any>;

export class ListPageBase extends PageBase {
	protected getOnInit = true; // Se devemos chamar o método get no NgOnInit

	route = "";

	items = [];
	data = null;

	attributes: string[] | null = null;
	order: Orders = {};
	params: ParamsType = {};
	filters: FiltersType = {};
	includes: IncludesType = null;
	defaultFilters: FiltersType = null;

	call = this.api.new();
	delete_call = this.api.new().set("success", true);

	page = 1;
	pages = 1;
	totalRowsCount = 0;

	limit = 50;
	offset = 0;

  @Input() reloadInt = 0;

  ngOnInit() {
  	const self = this;

  	if (self.route && self.getOnInit) {
  		self.get();
  	}
  }

  ngOnChanges(changes: SimpleChanges) {
  	const self = this;

  	if (changes.reloadInt) {
  		if (changes.reloadInt.previousValue != changes.reloadInt.currentValue) {
  			self.get();
  		}
  	}
  }

  async get(page = 1, offset ?: number, limit ?: number): Promise<any> {
  	const self = this;


  	if(!limit){
  		limit = self.limit;
  	}

  	const _page = page - 1;

  	if(!offset){
  		offset = _page * self.limit;
  	}

  	const where = {};

  	for (const index in self.filters) {
  		where[index] = { "Op.and": self.getFilters()[index] };
  	}

	  let includes: IncludesType = null;

	  if(self.includes){
		  if(self.includes == 'auto'){
			  includes = [];

			  for (const index in self.filters) {
				  if(includes.indexOf(index as any) == -1){
					  includes.push(index as any);
				  }
			  }

			  includes = includes.map(x => {
  				return {model: x}; 
  			}) as any;
		  }else{
			  includes = self.includes;
		  }
	  }

  	self.page = page;
  	return new Promise((resolve, reject) => {
  		self.call
  			.post(
  				self.route + `/search/${offset}/` + limit,
  				{where: where, order: self.order, includes: includes, attributes: self.attributes || null, params: self.params || {}}
  			).subscribe(
  				(data) => {
  					self.data = data.return;
  					self.items = data.success
  						? self.foreachItems(data.return.rows)
  						: [];
  					self.totalRowsCount = data.return.count;
  					self.pages = data.return.count;
  					self.offset = offset;
  					self.listLoaded();
  					resolve(data.return);
  				},
  				(error) => reject(error)
  			);
  	});
  }

  public listLoaded() {}

  public foreachItems(items: any[]) {
  	return items;
  }

  justNumberColumns = ['document', 'phone'];
  
  isJustNumberColumn(column){
	  const self = this;
	  
	  if(column.indexOf('.') == -1){
		  if(self.justNumberColumns.indexOf(column) != -1){
			  return true;
		  }
	  }
	  
	  const arr = column.split('.');
	  column = arr[arr.length - 1];
	
	  if(self.justNumberColumns.indexOf(column) != -1){
		  return true;
	  }
	  
	  return false;
  }

  public getFilters(): any {
  	const self = this;
  	const filters = {};

  	for (const filter in self.filters) {
  		filters[filter] = [];

  		for (const item in self.filters[filter]) {
  			if (self.filters[filter][item]) {
  				if (typeof self.filters[filter][item] === "string") {
					  if(self.isJustNumberColumn(item)){
						  filters[filter].push({
							  [`Op.justNumbers:${filter}:${item}`]: `%${self.filters[filter][item]
								  .split(" ")
								  .join("%")}%`,

						  });
					  }else{
						  filters[filter].push({
							  [item]: {
								  "Op.like": `%${self.filters[filter][item]
									  .split(" ")
									  .join("%")}%`,
							  }
						  });
					  }
  				} else {
  					filters[filter].push({
  						[item]: self.filters[filter][item],
  					});
  				}
  			}
  		}
  	}

  	return filters;
  }

  public resetFilters() {
  	const self = this;
  	for (const item in self.filters) {
  		for (const index in self.filters[item]) {
  			self.filters[item][index] = null;
  		}
  	}

  	if (self.defaultFilters) {
	    for (const item in self.defaultFilters) {
		    for (const index in self.defaultFilters[item]) {
  				if(!self.filters[item]){
  					self.filters[item] = {};
  				}
				
			    self.filters[item][index] = self.defaultFilters[item][index] || null;
		    }
	    }
  	}

  	this.get();
  }

  async delete(id) {
  	const self = this;
  	if (await self.app.confirm()) {
  		self.delete_call
  			.delete(self.route + "/" + id)
  			.subscribe((data) => this.get());
  	}
  }
}
