import { ThemeConstantService } from "@durinn/v.3/app/template/services/theme-constant.service";
import { NoLayoutRoutingModule } from "./no-layout-routing.module";
import { DurinnModule } from "@durinn/v.3/durinn.module";
import { SharedModule } from "@template/shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";


@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		NoLayoutRoutingModule,
		DurinnModule,
	],
	declarations: [],
	providers: [ThemeConstantService],
})
export class NoLayoutModule {}
