import { HeadColumns } from "@durinn.v3/app/components/template/tables/list-page-table/list-page-table.component";
import { FiltersType, Orders } from "@durinn.v3/app/bases/listpage.base";
import Delivery from "@views/with-layout/agendas/agendas.component";
import { EditPage } from "../../../bases/edit.page";
import {Component, ElementRef, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {SocketIo} from "@durinn.v3/helpers/SocketIo";
import {
	UserPermissionsTableComponent
} from "@durinn.v3/app/components/users/user-permissions-table/user-permissions-table.component";

@Component({
	selector: 'app-agendamento',
	templateUrl: './agendamento.component.html',
	styleUrls: ['./agendamento.component.css']
})
export class AgendamentoComponent extends EditPage {
	@ViewChild("editCloseButton", { static: false }) editCloseButton: ElementRef;
	order: Orders = { Delivery: [['start', 'desc']] };
	historyCall = this.api.new();
	route = "contract-schedule";
	filters: FiltersType = {};
	isGetCepLoading = false;
	delivery = Delivery;
	moment = moment;
	reloadInt = 0;
	tries = null;

	formOriginal = {
		contract: null,
		id: null
	};

	form = {
		formatted_address: null,
		disconnectionDate: null,
		disconnectionType: null,
		orderServiceType: null,
		businessPhones: null,
		addressNumber: null,
		neighborhood: null,
		complement: null,
		status: 'Aberto',
		cellPhones: null,
		terminals: null,
		quantity: null,
		contract: null,
		customer: null,
		Histories: [],
		zipcode: null,
		phones: null,
		others: null,
		state: null,
		email: null,
		city: null,
		note: null,
		base: null,
		id: null
	};

	history = {
		note: null,
		createdAt: null,
		createdBy: null,
		CreatedBy: null,
		situation: null,
		contractScheduleId: null,
		origin: null
	};

	deliveryO = {
		args: {}
	};

	historySitutionOptions = [
		{ label: 'Aguardando Agendamento', value: 'Aguardando Agendamento' },
		{ label: 'Agendamento Realizado', value: 'Agendamento Realizado' },
		{ label: 'Cliente entregou no ATP da Claro', value: 'Cliente entregou no ATP da Claro' },
		{ label: 'Cliente solicitou o retorno da ligação mais tarde', value: 'Cliente solicitou o retorno da ligação mais tarde' },
		{ label: 'Cliente alega que já devolveu os equipamentos', value: 'Cliente alega que já devolveu os equipamentos' },
		{ label: 'Cliente alega que perdeu os equipamentos', value: 'Cliente alega que perdeu os equipamentos' },
		{ label: 'Ativo sem sucesso', value: 'Ativo sem sucesso' },
		{ label: 'Ativo com sucesso, mas não quis agendar', value: 'Ativo com sucesso, mas não quis agendar' },
		{ label: 'Ativo com sucesso, mas não conhece o titular', value: 'Ativo com sucesso, mas não conhece o titular' },
		{ label: 'Ativo pediu que não ligue mais para o número', value: 'Ativo pediu que não ligue mais para o número' },
		{ label: 'Entregue na loja da Claro', value: 'Entregue na loja da Claro' },
	];

	permissions = {
		CREATE_CONTRACT_SCHEDULE: false,
		UPDATE_CONTRACT_SCHEDULE: false,
		DELETE_CONTRACT_SCHEDULE: false
	};

	headColumnsTypesOptions = [
		{ label: "COMBO MULTI", value: "COMBO MULTI" },
		{ label: "MALLING", value: "MALLING" },
		{ label: "DX22", value: "DX22" },
		{ label: "VOX", value: "VOX" },
		{ label: "DESC TOA", value: "DESC TOA" },
		{ label: "ENTREGA DE CAMERA", value: "ENTREGA DE CAMERA" },
		{ label: "ENTREGA DE STREAMINGS", value: "ENTREGA DE STREAMINGS" },
		{ label: "ENTREGA DE CONTROLE", value: "ENTREGA DE CONTROLE" },
		{ label: "ENTREGA DE EXAMES", value: "ENTREGA DE EXAMES" },
		{ label: "CORTE", value: "CORTE" },
		{ label: "FIELD", value: "FIELD" },
		{ label: "NOTURNA", value: "NOTURNA" },
		{ label: "AGENDA FUTURA", value: "AGENDA FUTURA" },
		{ label: "AGENDAMENTO E-MAIL", value: "AGENDAMENTO E-MAIL" },
		{ label: "AGENDAMENTO P.A", value: "AGENDAMENTO P.A" },
		{ label: "RETORNO DE CARTA", value: "RETORNO DE CARTA" },
		{ label: "AGENDAMENTO TOA", value: "AGENDAMENTO TOA" },
		{ label: "REAGENDAMENTO LINK", value: "REAGENDAMENTO LINK" },
		{ label: "INSTALACAO CONTACT", value: "INSTALACAO CONTACT" },
	];

	headColumnsMadeOptions = [
		{ label: "Perdida", value: -1 },
		{ label: "Ativo", value: 0 },
		{ label: "Realizada", value: 1 },
		{ label: "Baixada", value: 2 },
		{ label: "Esperando NET", value: 7 },
		{ label: "Ausente Cancelado", value: 8 }
	];

	headColumnsConcludedCodeOptions = [
		{ label: "Ativo", value: 1 },
		{ label: "Realizado", value: 2 },
		{ label: "Atrasado", value: 3 },
		{ label: "Tempo Apertado", value: 4 },
		{ label: "Perdida", value: 5 },
		{ label: "Baixada", value: 6 },
		{ label: "Agendamento Não Cumprido", value: 100 },
		{ label: "Endereço Não Localizado", value: 101 },
		{ label: "Área De Risco", value: 102 },
		{ label: "Ausente", value: 106 },
		{ label: "Entrada Não Autorizada", value: 107 },
		{ label: "Desistência da desconexão", value: 109 },
		{ label: "Reagendamento Solicitado Pelo Cliente", value: 111 },
		{ label: "Mudou-se", value: 306 },
		{ label: "Serviço Concluído", value: 409 },
		{ label: "Desconexão C/RET Equipamento", value: 428 },
		{ label: "Desconexão S/RET Equipamento", value: 429 },
		{ label: "Retirada De Equipamentos", value: 430 },
		{ label: "Equipamento Já Retirado por outro Técnico", value: 431 },
		{ label: "Entrega de Chip com Sucesso", value: 706 },
		{ label: "Entregue na Loja da Claro", value: 998 },
		{ label: "Agendamento Fake", value: 999 },
		{ label: "Desistência de Serviço", value: 302 },
		{ label: "Não Solicitou o Serviço", value: 312 },
		{ label: "Entrega de Exames com Sucesso", value: 10 },
		{ label: "Sem acesso ao DG", value: 110 }
	];

	headColumns: HeadColumns = [
		{ index: "Delivery", column: "id_delivery", name: "ID", type: 'number' },
		{ index: "Delivery", column: "start", name: "Data", type: 'date'},
		{ name: "Confirmado" },
		// { index: "Delivery", column: "opening_date", name: "Abertura", type: 'date' },
		// { index: "Delivery", column: "backend_date", name: "Backend", type: 'date' },
		{ index: "Delivery", column: "contract", name: "Contrato" },
		{ index: "Delivery", column: "os", name: "OS" },
		{ index: "Delivery", column: "type", name: "Tipo", type: 'select', options: this.headColumnsTypesOptions },
		{ name: "Endereço" },
		{ index: "Deliveryman", column: "name", name: "Responsável" },
		{ index: "Delivery", column: "made", name: "Realizada", type: 'select', options: this.headColumnsMadeOptions },
		{ index: "Delivery", column: "conclude_code", name: "Código NET", type: 'select', options: this.headColumnsConcludedCodeOptions },
		{ index: "Delivery", column: "tries", name: "Tentativas" },
		// { name: "Aging",  },
		// { name: "Das" },
		// { name: "Até" },
		{}
	];

	seeing: {id:number, name: string}[] = [];

	get usersSeeingPage(){
		return this.seeing.map(x => x.name).join(', ');
	}

	ngOnInit() {
		super.ngOnInit();

		const self = this;
		SocketIo.join('ContractSchedule-' + this.id);
		this.socketSafeOn('ContractSchedule-' + this.id + '-changed', (args) => {
			self.seeing = args;
			console.log('ContractSchedule-' + this.id + '-changed', args);
		});
		this.socketSafeOn('updated-ContractSchedule-' + this.id, () => this.get());
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		SocketIo.leave('ContractSchedule-' + this.id);
	}

	afterGet() {
		const self = this;
		self.formOriginal = Object.assign({}, self.form);
	}

	get canEdit() {
		const self = this;

		const conditions = [
			!self.form.id && !!self.permissions.CREATE_CONTRACT_SCHEDULE,
			self.form.id && !!self.permissions.UPDATE_CONTRACT_SCHEDULE
		];

		return conditions.includes(true);
	}

	get canDelete() {
		const self = this;

		const conditions = [
			self.form.id && !!self.permissions.DELETE_CONTRACT_SCHEDULE
		];

		return conditions.includes(true);
	}

	get isDisabled() {
		const self = this;

		const conditions = [
			!!self.form.customer,
			!!self.form.contract
		];

		return conditions.includes(false);
	}

	get isHistoryDisabled() {
		const self = this;

		const conditions = [
			!self.history.note,
			!self.history.situation
		];

		return conditions.includes(true);
	}

	getAddressByZipcode() {
		const self = this;
		self.isGetCepLoading = false;
		const zipcode = (self.form.zipcode || "").replace(/[^0-9]/g, "");
		if (zipcode && zipcode.length == 8) {
			self.isGetCepLoading = true;
			self.api.http.get(`https://viacep.com.br/ws/${zipcode}/json/`).subscribe((response: any) => {
				self.form.formatted_address = response.logradouro;
				self.form.neighborhood = response.bairro;
				self.form.city = response.localidade;
				self.form.state = response.uf;
				self.isGetCepLoading = false;
			}, (err) => {
				console.error(err);
				self.isGetCepLoading = false;
			}
			);
		}
	}

	onSubmit() {
		const self = this;

		if (self.form.id) {
			self.call.put(`${self.route}/${self.form.id}`, self.form).subscribe(response => {
				const item = response.return;
				if (item) {
					self.router.routeReuseStrategy.shouldReuseRoute = function () {
						return false;
					};
					self.router.navigateByUrl(`/agendamentos/editar/${item.id}`);
					self.onHistoryReset();
					self.afterGet();
				}
			});
		} else {
			self.call.post(self.route, self.form).subscribe(response => {
				const item = response.return;
				if (item) {
					self.router.navigateByUrl(`/agendamentos/editar/${item.id}`);
					self.onHistoryReset();
				}
			});
		}
	}

	async onDelete() {
		const self = this;

		if (self.form.id) {
			if (await self.app.confirm()) {
				self.call.delete(`${self.route}/${self.form.id}`).subscribe(() => {
					self.router.navigateByUrl(`/agendamentos`);
				});
			}
		}
	}

	async onHistorySubmit() {
		const self = this;

		const message = 'Você tem certeza de que deseja adicionar a tentativa de contato?';
		if (await self.app.confirm('Confirmação', message)) {
			self.history.CreatedBy = Object.assign({}, self.mineUser);
			self.history.contractScheduleId = self.form.id || null;
			self.history.createdBy = (self.mineUser as any).id;
			self.history.createdAt = new Date();
			if (self.form.id) {
				self.onHistorySave();
			} else {
				self.form.Histories.push(self.history);
				self.onHistoryReset();
			}
		}
	}

	onHistorySave() {
		const self = this;
		self.historyCall.post(`${self.route}/history`, self.history)
			.subscribe(response => {
				self.form.Histories = [...response.return];
				self.form.status = self.history.situation;
				self.onHistoryReset();
			});
	}

	async removeHistory(item, index: number) {
		const self = this;

		if (await self.app.confirm()) {
			if (self.form.id) {
				self.call.delete(`${self.route}/${item.id}/history`).subscribe(response => {
					self.form.Histories = [...response.return.rows];
					self.form.status = response.return.status;
				});
			} else {
				self.form.Histories.splice(index, 1);
			}
		}
	}

	onHistoryReset() {
		const self = this;
		self.history = {
			note: null,
			createdAt: null,
			createdBy: null,
			CreatedBy: null,
			situation: null,
			contractScheduleId: null,
			origin: null
		};
	}

	getHistory(item) {
		const description = item.note || 'Sem Observação';
		const name = (item.CreatedBy || {}).name || 'Via Importação';
		const situation = `Situação: ${item.situation || 'Não Registrada'}`;
		const date = moment(item.createdAt).format('DD/MM/YYYY');
		return `${name} - ${date} - ${situation} - ${description} - ${item.origin ? (`Origem: ${item.origin}`) : ''}`;
	}

	loadTries(item: any) {
		const self = this;
		self.tries = null;

		self.call
			.get("delivery/" + item.id_delivery + "/tries")
			.subscribe((response) => {
				if (response.success) {
					self.tries = response.return;
				} else {
					self.tries = [];
				}
			});
	}

	openNewDelivery() {
		const self = this;

		self.deliveryO.args = {
			contract: self.form.contract,
			holder: self.form.customer,
			type: 'AGENDAMENTO P.A',
		};
	}

	closeNewDelivery() {
		const self = this;
		self.deliveryO.args = {};

		if (self.editCloseButton) {
			self.editCloseButton.nativeElement.click();
		}
	}

	deliverySent(event) {
		const self = this;
		self.reloadInt += 1;
		self.closeNewDelivery();
	}
}
