import { Component, OnInit } from "@angular/core";
import {
	EditPage,
	EditPageInterface,
} from "../../../../../bases/edit.page";

@Component({
	selector: "app-usuario",
	templateUrl: "./usuario.component.html",
	styleUrls: ["./usuario.component.css"],
})
export class UsuarioComponent extends EditPage implements EditPageInterface {
	form = {
		cpf: "",
		username: "",
		picture: "",
		variables: {
			cep: "",
			address: "",
			complement: "",
			neighborhood: "",
			city: "",
			state: "RS",
		},
		Permissions: []
	};

	permissions = {
		EDIT_USERS: false,
		MASTER: false,
	};

	fields = [
		{
			label: "Nome",
			name: "name",
			type: "text",
			validate: "Por favor, preencha seu nome corretamente!",
			required: true,
		},
		{
			label: "Telefone",
			name: "phone",
			type: "text",
			validate: "Por favor, preencha seu telefone corretamente!",
			mask: "(00) 00000-0000",
			placeholder: "(00) 00000-0000",
			required: true,
		},
		{
			label: "CPF",
			name: "cpf",
			type: "text",
			validate: "Por favor, preencha seu cpf corretamente!",
			placeholder: "000.000.000-00",
			mask: "000.000.000-00",
			required: true,
		},
		{
			label: "E-mail",
			name: "email",
			type: "email",
			validate: "Por favor, preencha seu e-mail corretamente!",
			placeholder: "e-mail",
			required: false,
		},
	];

	variables = [];

	address = [
		{
			label: "Endereço",
			name: "address",
			type: "text",
			validate: "Por favor, preencha seu endereço corretamente!",
			required: false,
		},
		{
			label: "Complemento",
			name: "complement",
			type: "text",
			validate: "Por favor, preencha seu complemento corretamente!",
			required: false,
		},
		{
			label: "Bairro",
			name: "neighborhood",
			type: "text",
			validate: "Por favor, preencha seu bairro corretamente!",
			required: false,
		},
		{
			label: "Cidade",
			name: "city",
			type: "text",
			validate: "Por favor, preencha sua cidade corretamente!",
			required: false,
		},
	];

	back_to = "/usuarios";
	route = "users";
	
	
	permissionsChanged(array: any[]) {
		
		this.form.Permissions = [];
		console.log(array.map((x) => x.name));
		
		this.form.Permissions = array.map((x) => {
			return { permission: x.name };
		});
		
		console.log(`this.form.Permissions`, this.form.Permissions);
	}
}
