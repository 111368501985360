import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Injector,
	Input,
	QueryList,
	ViewChildren,
} from "@angular/core";
import { ProviderInterface, UserInterface } from "@backend-interfaces/models";
import {
	ModelBaseInterface,
	ModelPageBase,
} from "@durinn/v.3/app/bases/modelpage.base";

@Component({
	selector: "durinn-v2-edit-provider",
	templateUrl: "./edit-provider.component.html",
	styleUrls: ["./edit-provider.component.css"],
})
export class DurinnV2EditProviderComponent
	extends ModelPageBase
	implements ModelBaseInterface {
  @Input() page: DurinnV2EditProviderComponent = this;
  @Input() tabs = ["Fornecedor"];

  route = "provider";
  backPage = "/fornecedores";
  variableUrl = "provider-fieldstofill";
  permission = "CRUD_PROVIDERS";

  @ViewChildren("email") emailsView: QueryList<ElementRef>;
  @ViewChildren("phone") phonesView: QueryList<ElementRef>;

  form: Partial<ProviderInterface> & { variables: any } = { variables: {} };

  constructor(
  	injector: Injector,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  	super(injector);
  }

  userLoaded(user: UserInterface & { Variables: any }) {
  	super.userLoaded(user);
  }

  getAdressByzipcode() {
  	const self = this;
  	const zipcode: string = self.form.zipcode;
  	if (zipcode.length !== 8) {
  		return;
  	}

  	self.api.http
  		.get(`https://viacep.com.br/ws/${zipcode}/json/`)
  		.subscribe((response: any) => {
  			self.form.street = response.logradouro;
  			self.form.neighborhood = response.bairro;
  			self.form.state = response.uf;
  			self.form.city = response.localidade;
  		});
  }

  removeEmail(index: number) {
  	const self = this;
  	self.form.Emails.splice(index, 1);
  }

  removePhone(index: number) {
  	const self = this;
  	self.form.Phones.splice(index, 1);
  }

  addPhone(e) {
  	if (e.key === "Enter" || e.keyCode === 13) {
  		const self = this;
  		if (this.form.Phones[this.form.Phones.length - 1].phone === "") {
  			return;
  		}
  		self.form.Phones.push({ phone: "" } as any);
  		self.changeDetectorRef.detectChanges();
  		self.phonesView.last.nativeElement.focus();
  	}
  }

  addEmail(e) {
  	if (e.key === "Enter" || e.keyCode === 13) {
  		const self = this;
  		if (this.form.Emails[this.form.Emails.length - 1].email === "") {
  			return;
  		}
  		self.form.Emails.push({ email: "" } as any);
  		self.changeDetectorRef.detectChanges();
  		self.emailsView.last.nativeElement.focus();
  	}
  }

  ngOnInit() {
  	super.ngOnInit();
  	console.log(this.page);
  }

  changeEmail(index: number, event) {
  	this.form.Emails[index].email = event.target.value;
  }

  changePhone(index: number, event) {
  	this.form.Phones[index].phone = event.target.value;
  }

  modelLoaded(model) {
  	super.modelLoaded(model);
  	if (!(this.form.Emails || []).length) {
  		this.form.Emails = [{ email: "" } as any];
  	}

  	if (!(this.form.Phones || []).length) {
  		this.form.Phones = [{ phone: "" } as any];
  	}
  }
}
