import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "durinn-thead-filter-order",
	templateUrl: "./filter-order.component.html",
	styleUrls: ["./filter-order.component.css"],
})
export class FilterOrderComponent implements OnInit {
  @Input() placeholder = "";
  @Input() type = "text";
  @Input() mask = undefined;
  @Input() name = "";
  @Input() options: { label: string; value: string | number }[] = [];
  @Input() order = [["name", "asc"]];
  @Input() dropSpecialCharacters = false;

  @Input() selectFirst = true;
  @Input() model: string = null;
  @Input() route: string = null;
  @Input() filter: string = null;

  @Output() enter = new EventEmitter();
  @Output() orderChanged = new EventEmitter();
  @Output() selectChanged = new EventEmitter();

  input = null;
  selected = false;
  _order = "asc";

  constructor() {}

  ngOnInit() {
  	// this.selected = this.order[0][0] == this.name;
  	// this._order = this.order[0][1];
  }

  ngOnChanges() {
  	this.selected =
      this.order && this.order[0] && this.order[0][0] == this.name;
  	this._order = this.order && this.order[0] && this.order[0][1];
  }

  changed() {
  	this.enter.emit(this.input);
  }

  selectChange(event) {
  	this.selectChanged.emit(event.id);
  }

  orderEmit() {
  	this.orderChanged.emit([
  		[this.name, this._order == "asc" ? "desc" : "asc"],
  	]);
  }
}
