import { Component, OnInit } from "@angular/core";
import {
	EditPage,
	EditPageInterface,
} from "../../../../../../../bases/edit.page";
import * as moment from "moment";
import { Device_Type } from "../../../lotes/pages/editar-lote/editar-lote.component";
import { Device_Scans_Type } from "../../../../almoxarifado.component";
import { DEVICESTATUS_CATEGORY } from "../../../../../../../constants";

@Component({
	selector: "app-editar-caixa",
	templateUrl: "./editar-caixa.component.html",
	styleUrls: ["./editar-caixa.component.css"],
})
export class EditarCaixaComponent
	extends EditPage
	implements EditPageInterface {
	form: { [s: string]: any } = {};
	route = "warehousebox";
	back_to = "/almoxarifado/caixas";
	moment = moment;

	devices_type = Device_Type;
	Device_Scans_Type = Device_Scans_Type;
	DEVICESTATUS_CATEGORY = DEVICESTATUS_CATEGORY;

	filters: any = {
		type: "",
		barcode: "",
		status: "",
		category: -1,
		branch: null,
	};

	hidden(item) {
		const self = this;

		if (self.filters.type) {
			if (
				(item.type || "")
					.toUpperCase()
					.indexOf((self.filters.type || "").toUpperCase()) == -1
			) {
				return true;
			}
		}

		if (self.filters.barcode) {
			if (
				(item.barcode || "")
					.toUpperCase()
					.indexOf((self.filters.barcode || "").toUpperCase()) == -1
			) {
				return true;
			}
		}

		if (+self.filters.category === 0 || +self.filters.category === 1) {
			if (
				item.LastScan &&
				+self.filters.category != item.LastScan.category
			) {
				return true;
			}
		}

		return false;
	}
}
