import {PageBase} from "@durinn.v3/app/bases/page.base";
import {Injector} from "@angular/core";
import {Api} from "@durinn.v3/helpers/api";

export class RelatoriosBase extends PageBase {
	io: Api;

	constructor(public injector: Injector) {
		super(injector);
		this.io = injector.get(Api);
	}

	deviceTypesCall = this.api.new();
	types = [];

	ngOnInit() {
		const self = this;
		self.apiCallsManager.call(
			self.deviceTypesCall,
			'post',
			`devicetype/search/0/1000`,
			{}
		).subscribe((data) => {
			self.types = data.return.rows.map((x) => x.name);
		});
	}
}