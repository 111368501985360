import { CrudPage } from "src/app/bases/crud.page";
import { Component, Input } from "@angular/core";
import * as moment from "moment";

@Component({
	selector: "app-workload",
	templateUrl: "./workload.component.html",
	styleUrls: ["./workload.component.css"],
})
export class WorkloadComponent extends CrudPage {
	onModalCall = this.api.new();
	@Input() userType: string;
	isModalVisible = false;
	@Input() id: number;
	defaultWorkload: {};
	route = "workload";
	moment = moment;

	filters = {
		Workload: {},
	};

	order = {
		Workload: [["from", "desc"]],
	};

	params: any = {
		from: moment().startOf("month").format("DD/MM/YYYY"),
		to: null,
	};

	ngOnInit() {
		const id = this.isDeliveryman
			? { user_id: this.id }
			: { admin_id: this.id };
		this.filters.Workload = { ...id };
		this.getDefaultWorkload();
		super.ngOnInit();
	}

	get isDeliveryman() {
		const self = this;
		return self.userType == "deliverymen";
	}

	get isLoading() {
		const self = this;
		return self.call.loading || self.delete_call.loading;
	}

	get onModalTitle() {
		const self = this;
		return self.params.id_workload
			? "Editar Carga Horária"
			: "Adicionar Carga Horária";
	}

	get isModalParamsValid() {
		const self = this;

		const to = self.params.to ? moment(self.params.to, "DD/MM/YYYY") : null;
		const from = moment(self.params.from, "DD/MM/YYYY");

		if (self.params.to) {
			return from.isValid() && to.isValid();
		}

		return from.isValid();
	}

	getDefaultWorkload() {
		const self = this;
		self.call
			.get(`${self.route}/default-workload`)
			.subscribe((response) => {
				self.defaultWorkload = response.return;
			});
	}

	onModalParamsReset() {
		const self = this;
		self.params = {
			from: moment().startOf("month").format("DD/MM/YYYY"),
			...self.defaultWorkload,
			to: null,
		};
	}

	onModalOpen() {
		const self = this;
		self.onModalParamsReset();
		self.isModalVisible = true;
	}

	onModalUpdate(item) {
		const self = this;
		const params = { ...item };
		params.from = moment(params.from).format("DD/MM/YYYY");

		if (params.to) {
			params.to = moment(params.to).format("DD/MM/YYYY");
		}

		self.isModalVisible = true;
		self.params = params;
	}

	onModalSubmit() {
		const self = this;
		const body = { ...self.params };

		body.from = moment(body.from, "DD/MM/YYYY")
			.startOf("day")
			.toISOString();

		if (body.to) {
			body.to = moment(body.to, "DD/MM/YYYY").endOf("day").toISOString();
		} else {
			body.to = null;
		}

		if (body.id_workload) {
			self.onModalCall
				.put(`${self.route}/${body.id_workload}`, body)
				.subscribe((response) => {
					self.isModalVisible = false;
					self.onModalParamsReset();
					self.app.alert("Sucesso", response.message, "success");
					self.get();
				});
		} else {
			const id = self.isDeliveryman
				? { user_id: self.id }
				: { admin_id: self.id };
			self.onModalCall
				.post(self.route, { ...body, ...id })
				.subscribe((response) => {
					self.isModalVisible = false;
					self.onModalParamsReset();
					self.app.alert("Sucesso", response.message, "success");
					self.get();
				});
		}
	}
}
