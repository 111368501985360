import { DashboardBase } from "../../../../../../../durinn/bases/dashboard.base";
import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-caixas-dashboard-card",
	templateUrl: "./caixas-dashboard-card.component.html",
	styleUrls: ["./caixas-dashboard-card.component.css"],
})
export class CaixasDashboardCardComponent extends DashboardBase {
	call = this.api.new();

	cards = [
		{
			title: "Com Técnico",
			index: "collected",
			class: "ant-avatar-red",
			icon: "user",
		},
		{
			title: "Com Técnico",
			subtitle: "+ de 14 dias",
			index: "collectedDays",
			class: "ant-avatar-red",
			icon: "user",
		},
		{
			title: "A Caminho",
			subtitle: "",
			index: "going_to_base",
			class: "ant-avatar-blue",
			icon: "home",
		},
		{
			title: "Entregue",
			subtitle: "Na Base",
			index: "delivered_to_base",
			class: "ant-avatar-blue",
			icon: "home",
		},
		{
			title: "Na Base",
			subtitle: "",
			index: "in_base",
			class: "ant-avatar-blue",
			icon: "home",
		},
		{
			title: "Entregues",
			subtitle: "",
			index: "delivered",
			class: "ant-avatar-green",
			icon: "environment",
		},
	];

	ngOnInit() {
		super.ngOnInit();
		this.getCount();
	}

	data;
	getCount() {
		this.call.get(`warehousebox/dashboard`).subscribe((data) => {
			this.data = data.return;
		});
	}

	downloadCsv(type) {
		this.api.openLink(`warehousebox/download-csv/${type}`);
	}
}
