import { Injectable } from "@angular/core";
import swal, { SweetAlertIcon } from "sweetalert2";

@Injectable()
export class App {
	constructor() {}
	
	alert(title: string, message: string, type: SweetAlertIcon = "info") {
		return swal.fire(title, message, type);
	}
	
	fire(options) {
		return swal.fire(options);
	}

	confirm(
		title = "Confirmação",
		message = "Você tem certeza de que deseja fazer isso?",
		confirmButtonText = "OK",
		cancelButtonText = "Cancelar"
	) {
		return new Promise((resolve) => {
			swal
				.fire({
					title: title,
					html: message,
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#d33",
					// cancelButtonColor: '#d33',
					confirmButtonText: confirmButtonText,
					cancelButtonText: cancelButtonText,
				})
				.then((result) => {
					if (result.value) {
						resolve(true);
					}
				});
		});
	}

	prompt(
		title: string,
		message = "",
		confirmButtonText = "OK",
		cancelButtonText = "Cancelar"
	): Promise<string | null> {
		return new Promise((resolve) => {
			swal
				.fire({
					title: title,
					html: message || undefined,
					icon: "question",
					input: "text",
					inputAttributes: {
						autocapitalize: "off",
					},
					showCancelButton: true,
					confirmButtonColor: "#d33",
					// cancelButtonColor: '#d33',
					confirmButtonText: confirmButtonText,
					cancelButtonText: cancelButtonText,
				})
				.then((result) => {
					if (result.value) {
						resolve(result.value);
					} else {
						resolve(null);
					}
				});
		});
	}
}
