type meter = number;
export default class Geolocation {
	static distance(
		lat1: number,
		lon1: number,
		lat2: number,
		lon2: number
	): meter {
		const R = 6371; // Radius of the earth in km
		const dLat = Geolocation.deg2rad(lat2 - lat1); // deg2rad below
		const dLon = Geolocation.deg2rad(lon2 - lon1);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(Geolocation.deg2rad(lat1)) *
				Math.cos(Geolocation.deg2rad(lat2)) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c; // Distance in km
		return d * 1000; // Distance in m;
	}

	static middlePoint(lat1, lng1, lat2, lng2) {
		//-- Longitude difference
		const dLng = Geolocation.deg2rad(lng2 - lng1);

		//-- Convert to radians
		lat1 = Geolocation.deg2rad(lat1);
		lat2 = Geolocation.deg2rad(lat2);
		lng1 = Geolocation.deg2rad(lng1);

		const bX = Math.cos(lat2) * Math.cos(dLng);
		const bY = Math.cos(lat2) * Math.sin(dLng);
		const lat3 = Math.atan2(
			Math.sin(lat1) + Math.sin(lat2),
			Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY)
		);
		const lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);

		//-- Return result
		return {
			latitude: Geolocation.rad2deg(lat3),
			longitude: Geolocation.rad2deg(lng3),
		};
	}

	static deg2rad(deg: number) {
		return deg * (Math.PI / 180);
	}

	static rad2deg(deg: number) {
		return deg * (180 / Math.PI);
	}
}
