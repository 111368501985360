import {Component, Input, OnInit} from '@angular/core';
import {CrudPage} from "../../../../../../bases/crud.page";

@Component({
	selector: 'app-email-campaign-view-list',
	templateUrl: './email-campaign-view-list.component.html',
	styleUrls: ['./email-campaign-view-list.component.css']
})
export class EmailCampaignViewListComponent extends CrudPage implements OnInit {
  @Input() route:string;

  filters:any = {
  	EmailCampaignSent: {
  	},
  	Delivery: {
  	},
  };

  order = {
  	EmailCampaignSent: [["sendAt", "desc"]],
  	Delivery: [["confirmed_date", "desc"]],
  };

  emailPopup(item){
  	const win = window.open("", item.title, "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600");
  	win.document.body.innerHTML = item.body;
  }
}
