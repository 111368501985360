import {Injectable} from "@angular/core";
import {Api, Io} from "@durinn.v3/helpers/api";
import {ActivatedRoute} from "@angular/router";
import {ApiCallsManager} from "@durinn.v3/helpers/ApiCallsManager";

@Injectable()
export class Variables {
	call = this.api.new();
	loading;
	
	variables = {};
	
	constructor(protected api: Api, protected apiCallsManager: ApiCallsManager) {
		this.get();
	}
	
	protected get(){
		const self = this;
		self.loading = new Promise(resolve => {
			self.apiCallsManager.call(self.call, 'get', 'enterprise/variables', {}).subscribe((response) => {
				for(const item of response.return){
					let json = null;
					
					try{
						json = JSON.parse(item.variable);
					}catch (e) {
					
					}
					
					self.variables[item.field] = {...item, json};
				}
				
				console.log('System Variables', self.variables);
				resolve(self.variables);
			});
		});
	}
	
	
	/**
	 * Salva uma variável de configuração do sistema do cliente
	 * @param variable
	 * @param content
	 * @param call
	 */
	save(variable: string, content: string | number | null, call ?: Io): Promise<any>{
		const self = this;
		return new Promise(async (resolve, reject) => {
			(call || self.api.new())
				.put(`enterprise/variable/${variable}`, {variable: content})
				.subscribe(
					response => {
						const item = response.return;
						
						let json = null;
						try{
							json = JSON.parse(item.variable); 
						}catch (e) {}
						
						self.variables[item.field] = {...item, json};
						resolve(response.return);
					},
					error => reject(error)
				);
		});
	}
}
