import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppLoaderComponent } from "./app-loader.component";

@NgModule({
	exports: [AppLoaderComponent],
	imports: [CommonModule],
	declarations: [AppLoaderComponent],
	providers: [],
})
export class AppLoaderModule {}
