import { CrudPage } from "src/app/bases/crud.page";
import { Component, Input } from "@angular/core";
import * as moment from "moment";

@Component({
	selector: "app-hours-bank-payment",
	templateUrl: "./hours-bank-payment.component.html",
	styleUrls: ["./hours-bank-payment.component.css"],
})
export class HoursBankPaymentComponent extends CrudPage {
	isHoursBankPaymentAddModalVisible = false;
	hoursBankPaymentCall = this.api.new();
	route = "hours-bank-payment";
  @Input() userType: string;
  @Input() id: number;
  moment = moment;

  filters = {
  	HoursBankPayment: {},
  };

  ngOnInit() {
  	const id =
      this.userType == "deliverymen"
      	? { userId: this.id }
      	: { adminId: this.id };
  	this.filters.HoursBankPayment = { ...id };
  	super.ngOnInit();
  }

  order = {
  	HoursBankPayment: [["createdAt", "desc"]],
  };

  params: any = {
  	paymentDate: moment().format("DD/MM/YYYY"),
  	paymentValue: 0,
  	paidHours: "",
  	paidBy: null,
  	PaidBy: null,
  	file: null,
  	note: "",
  };

  resetParams() {
  	const self = this;
  	self.params = {
  		paymentDate: moment().format("DD/MM/YYYY"),
  		paymentValue: 0,
  		paidHours: "",
  		paidBy: null,
  		PaidBy: null,
  		file: null,
  		note: "",
  	};
  }

  isParamsValid() {
  	const self = this;

  	const paymentDate = moment(self.params.paymentDate, "DD/MM/YYYY");
  	const paidHours = self.params.paidHours.length >= 1;
  	const paymentValue = self.params.paymentValue >= 0;
  	const paidBy = self.params.paidBy != null;
  	return paymentDate.isValid() && paidHours && paymentValue && paidBy;
  }

  openHoursBankPaymentModal() {
  	const self = this;
  	self.resetParams();
  	self.isHoursBankPaymentAddModalVisible = true;
  }

  async hoursBankPaymentPrint(hoursBankPaymentId: number) {
  	const self = this;

  	const data = (await self.me.getCacheData()) as any;

  	if (data) {
  		window.open(
  			self.api.url +
            `${
            	self.route
            }/${hoursBankPaymentId}/export/html?_authusername=${btoa(
            	data.cpf
            )}&_authpassword=${btoa(data.password)}`,
  			"_blank"
  		);
  	}
  }

  hoursBankPaymentUpdate(item) {
  	const self = this;
  	const params = { ...item };
  	params.paymentDate = moment(params.paymentDate).format("DD/MM/YYYY");
  	params.paidHours = self.getFormattedHours(params.paidMinutes);
  	self.isHoursBankPaymentAddModalVisible = true;
  	self.params = params;
  }

  getMinutes(time: string) {
  	let minutes = 0;
  	const hours = time.split(":");
  	minutes += Number(hours[0]) * 60;

  	if (hours[1]) {
  		minutes += Number(hours[1]);
  	}

  	return minutes;
  }

  getFormattedHours(minutes: number) {
  	const hours = Math.trunc(minutes / 60);
  	const rest = minutes % 60;
  	return `${(hours + "").padStart(2, "0")}:${(rest + "").padStart(2, "0")}`;
  }

  hoursBankPaymentAction() {
  	const self = this;
  	const body = { ...self.params };

  	body.paymentDate = moment(body.paymentDate, "DD/MM/YYYY").toISOString();
  	body.paidMinutes = self.getMinutes(body.paidHours);

  	if (!body.paymentValue) {
  		body.paymentValue = 0;
  	}

  	if (body.id) {
  		self.hoursBankPaymentCall
  			.put(`${self.route}/${body.id}`, {
  				paymentValue: body.paymentValue,
  				paymentDate: body.paymentDate,
  				paidMinutes: body.paidMinutes,
  				paidBy: body.paidBy,
  				file: body.file,
  				note: body.note,
  			})
  			.subscribe((response) => {
  				self.app.alert("Pagamento", response.message).then(() => {
  					self.isHoursBankPaymentAddModalVisible = false;
  					self.resetParams();
  					self.get();
  				});
  			});
  	} else {
  		const id =
        self.userType == "deliverymen"
        	? { userId: self.id }
        	: { adminId: self.id };
  		self.hoursBankPaymentCall
  			.post(self.route, { ...body, ...id })
  			.subscribe((response) => {
  				self.app.alert("Pagamento", response.message).then(() => {
  					self.isHoursBankPaymentAddModalVisible = false;
  					self.resetParams();
  					self.get();
  				});
  			});
  	}
  }

  changeListener($event): void {
  	this.readThis($event.target);
  }

  readThis(inputValue: any): void {
  	const file: File = inputValue.files[0];
  	const myReader: FileReader = new FileReader();

  	myReader.onloadend = () => {
  		this.params.file = myReader.result as string;
  	};

  	myReader.readAsDataURL(file);
  }
}
