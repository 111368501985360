import { Component, ElementRef, ViewChild } from "@angular/core";
import {
	ModelBaseInterface,
	ModelPageBase,
} from "@durinn/v.3/app/bases/modelpage.base";
@Component({
	selector: "durinn-v2-edit-customers",
	templateUrl: "./edit.component.html",
	styleUrls: ["./edit.component.css"],
})
export class DurinnV2EditCustomersComponent
	extends ModelPageBase
	implements ModelBaseInterface {
	route = "costumer";
	backPage = "clientes";
	permission = "EDIT_COSTUMERS";
	variableUrl = "costumer-fieldstofill";
	selectedTab = "cliente";

	form = {
		email: "",
		phone: "",
		formattedAddress: "",
		username: "",
		picture: "",
		Emails: [],
		Phones: [],
		Address: [],
		variables: {},
		Permissions: [],
	};

	fields = [
		{
			label: "Nome",
			name: "name",
			type: "text",
			validate: "Por favor, preencha com um nome válido!",
			required: true,
		},
		{
			label: "CPF / CNPJ",
			name: "document",
			type: "text",
			validate: "Por favor, preencha com um documento válido!",
			mask: "CPF_CNPJ",
			required: true,
		},
	];

	new = {
		email: "",
		phone: "",
	};

  @ViewChild("addressNumber", { static: false })
  	addressNumberRef: ElementRef<HTMLInputElement>;

  address = {
  	street: "",
  	zipcode: "",
  	city: "",
  	neighborhood: "",
  	state: "",
  	addressNumber: "",
  };

  _userSelected = null;
  userSelected(user) {
  	this._userSelected = user;
  }

  /**
   * Função para adiconar apos cadastrar
   */
  addMail() {
  	const self = this;

  	if (!self.form.Emails) {
  		self.form.Emails = [];
  	}

  	self.form.Emails.push({
  		email: self.new.email,
  	});
  }

  removeEmail(i) {
  	const self = this;

  	if (!self.form.Emails) {
  		self.form.Emails = [];
  	}

  	self.form.Emails.splice(i, 1);
  }

  addPhone() {
  	const self = this;

  	if (!self.form.Phones) {
  		self.form.Phones = [];
  	}

  	self.form.Phones.push({
  		phone: self.new.phone,
  	});

  	self.new.phone = null;
  }

  removePhone(i) {
  	const self = this;

  	if (!self.form.Phones) {
  		self.form.Phones = [];
  	}

  	self.form.Phones.splice(i, 1);
  }

  isVisible = false;

  addAddress() {
  	const self = this;

  	if (!self.form.Address) {
  		self.form.Address = [];
  	}

  	self.isVisible = false;
  	self.form.Address.push({ ...self.address });
  	self.address = {} as any;
  }

  removeAddress(i) {
  	const self = this;

  	if (!self.form.Address) {
  		self.form.Address = [];
  	}

  	self.form.Address.splice(i, 1);
  }

  public getAddressByZipcode(zipcode: string) {
  	const self = this;
  	if (zipcode && zipcode.length === 8) {
  		const url = `https://viacep.com.br/ws/${zipcode}/json/`;
  		self.api.http.get(url).subscribe((data: any) => {
  			self.address.street = data.logradouro;
  			self.address.city = data.localidade;
  			self.address.neighborhood = data.bairro;
  			self.address.state = data.uf;
  			self.addressNumberRef.nativeElement.focus();
  		});
  	}
  }

  handleCancel() {
  	this.isVisible = false;
  	this.address = {} as any;
  }

  get tabs() {
  	return ["Cliente"];
  }

  ready = {
  	Cliente: true,
  };

  reloadAllExcept(index: string) {
  	const self = this;
  	self.ngZone.run(() => {
  		for (const i in self.ready) {
  			if (i != index) {
  				self.ready[i] = false;
  			}
  		}

  		setTimeout(() => {
  			for (const i in self.ready) {
  				self.ready[i] = true;
  			}
  		}, 200);
  	});
  }

  get isLoadingATab() {
  	const self = this;
	  
	  if(self.call.loading){
		  return true;
	  }

  	for (const i in self.ready) {
  		if (!self.ready[i]) {
  			return true;
  		}
  	}

  	return false;
  }
}
