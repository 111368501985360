import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Api, Io } from "@durinn/v.3/helpers/api";
import { Me, User } from "@durinn/v.3/helpers/me";
import {NotificationSetting} from "@durinn.v3/app/components/users/user-notification-setting/user-notification-setting.component";

@Component({
	selector: "durinn-v2-update-profile",
	templateUrl: "./update-profile.component.html",
	styleUrls: ["./update-profile.component.css"],
})
export class DurinnV2UpdateProfileComponent implements OnInit {
	call: Io;
	loading = true;
	callVariables = this.api.new();

	form: any = {
		name: "",
		phone: "",
		username: "",
		variables: {},
	};

	fields = [
		{
			label: "Nome",
			name: "name",
			type: "text",
			validate: "Por favor, preencha seu nome corretamente!",
			required: true,
		},
		{
			label: "CPF",
			name: "username",
			type: "text",
			validate: "Por favor, preencha seu cpf corretamente!",
			placeholder: "000.000.000-00",
			mask: "000.000.000-00",
			required: true,
		},
		{
			label: "Telefone",
			name: "phone",
			type: "text",
			validate: "Por favor, preencha seu telefone corretamente!",
			mask: "(00) 00000-0000",
			placeholder: "(00) 00000-0000",
			required: true,
		},
	];

	variables: { field: string; mask: string; required: boolean }[] = [];

	constructor(public api: Api, public me: Me, public http: HttpClient) {}

	ngOnInit(): void {
		const self = this;
		this.call = self.api.new().set("success", true);

		self.callVariables
			.get(`enterprise/variable/user-fieldstofill`)
			.subscribe((data) => {
				if (data.return) {
					self.variables = JSON.parse(data.return.variable);
				}
			});

		self.me.get(false).subscribe((data: User) => {
			self.loading = false;
			if (data) {
				self.form = data;

				for (const item of data.Variables) {
					if (item.field in self.form.variables) {
						self.form.variables[item.field] = item.variable;
					}
				}
			}
		});
	}

	async submitForm() {
		await this.me.update(this.form, this.call);
	}

	userNotificationsChange(array: NotificationSetting[]) {
		const self = this;

		self.form.NotificationSettings = array.map((x) => {
			return { notification: x.name, checked: x.checked };
		}) as any;
	}
}
