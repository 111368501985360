import { DashboardBase } from "./dashboard.base";

export interface ListBaseInterface {
	route: string;
	order: { [a: string]: string[][] };
	filters: { [a: string]: { [b: string]: any } };
}

export type FiltersType = { [index: string]: { [subindex: string]: any } };

export class ListBasePage extends DashboardBase {
	items = [];
	route = "";
	filters: FiltersType = {};
	order = {};

	call = this.api.new();
	delete_call = this.api.new().set("success", true);

	page = 1;
	pages = 1;
	limit = 10;
	offset = 0;

	ngOnInit() {
		this.get();
	}

	async get(page = 1): Promise<any> {
		const self = this;
		const _page = page - 1;
		const offset = _page * self.limit;
		const where = {};

		for (const index in self.filters) {
			where[index] = { "Op.and": self.getFilters()[index] };
		}

		self.page = page;
		return new Promise((resolve, reject) => {
			self.call
				.post(self.route + `/search/${offset}/` + self.limit, {
					where: where,
					order: self.order,
				})
				.subscribe(
					(data) => {
						self.items = data.success
							? self.foreachItems(data.return.rows)
							: [];
						self.pages = data.return.count;
						self.offset = offset;
						self.listLoaded();
						resolve(data.return);
					},
					(error) => reject(error)
				);
		});
	}

	public listLoaded() {}

	public foreachItems(items: any[]) {
		return items;
	}

	public getFilters(): any {
		const self = this;
		const filters = {};

		for (const filter in self.filters) {
			filters[filter] = [];

			for (const item in self.filters[filter]) {
				if (self.filters[filter][item]) {
					if (typeof self.filters[filter][item] === "string") {
						filters[filter].push({
							[item]: {
								"Op.or": [
									{
										"Op.like": `%${self.filters[filter][
											item
										]
											.split(" ")
											.join("%")}%`,
									},
									{
										"Op.like": `${self.filters[filter][item]
											.split(" ")
											.join("%")}`,
									},
								],
							},
						});
					} else {
						filters[filter].push({
							[item]: self.filters[filter][item],
						});
					}
				}
			}
		}

		return filters;
	}

	async delete(id) {
		const self = this;
		if (await self.app.confirm()) {
			self.delete_call
				.delete(self.route + "/" + id)
				.subscribe((data) => this.get());
		}
	}
}
