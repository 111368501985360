import { Component } from "@angular/core";
import { NotificationSetting } from "@durinn/v.3/app/components/users/user-notification-setting/user-notification-setting.component";
import { Api } from "@durinn/v.3/helpers/api";
import { Me } from "@durinn/v.3/helpers/me";

@Component({
	selector: "durinn-v2-profile",
	templateUrl: "./profile.component.html",
	styleUrls: ["./profile.component.css"],
})
export class DurinnV2ProfileComponent {
	call = this.api.new().set("success", true);
	form = { NotificationSettings: [] };
  
	constructor(public api: Api, public me: Me) {}

	ngOnInit(): void {
		const self = this;

		self.me.get(false).subscribe((response) => {
			if (response) {
				self.form = {...{NotificationSettings: []}, ...response};
			}
		});
	}

	userNotificationsChange(array: NotificationSetting[]) {
		const self = this;

		self.form.NotificationSettings = array.map((x) => {
		  return { notification: x.name, checked: x.checked };
	  }) as any;
	}

	async onSubmit() {
		const self = this;
		await self.me.update(self.form, self.call);
	}
}
