import {Injectable} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {UserInterface} from "@backend-interfaces/models";
import {Observable, Observer, Subject} from "rxjs";
import {take} from "rxjs/operators";
import config from "../../../config";
import {Api, Io, Response} from "./api";
import {SocketIo} from "./SocketIo";
import {typeOfDevice} from "@durinn.v3/helpers/functions";

export type User = UserInterface & { Variables: any };

@Injectable()
export class Me {
	public data = this.getCacheData();
	readonly load = new Subject();
	
	constructor(public api: Api, public route: ActivatedRoute) {
	}
	
	public getCacheData(): User | false {
		try {
			return JSON.parse(localStorage.getItem(config.me.cache_name));
		} catch (e) {
			return false;
		}
	}
	
	get(cache = true, io?: Io): Observable<User | false> {
		const self = this;
		return Observable.create(async (observer: Observer<User | false>) => {
			self.load.subscribe((data: User | false) => observer.next(data));
			
			if (cache) {
				const cached = await self.getCacheData();
				if (cached) {
					if (cached && cached.enterpriseId) {
						SocketIo.join("enterprise-" + cached.enterpriseId);
					}
					
					self.data = cached && cached.id ? cached : null;
					observer.next(cached && cached.id ? cached : false);
				} else {
					self.refresh(io).subscribe((data) => {
						if (data && data.enterpriseId) {
							SocketIo.join("enterprise-" + data.enterpriseId);
						}
						
						self.data = data && data.id ? data : null;
						observer.next(data && data.id ? data : false);
					});
				}
			} else {
				self.refresh(io).subscribe((data) => {
					if (data && data.enterpriseId) {
						SocketIo.join("enterprise-" + data.enterpriseId);
					}
					
					self.data = data && data.id ? data : null;
					observer.next(data && data.id ? data : false);
				});
			}
		});
	}
	
	refresh(io?: Io): Observable<User | false> {
		const self = this;
		
		return Observable.create(async (observer: Observer<User | false>) => {
			(io ? io : self.api.new().silent())
				.get("me")
				.subscribe(async (response) => {
					console.log(`slf.me.refresh`, response);
					if (response.return && response.return.enterpriseId) {
						SocketIo.join("enterprise-" + response.return.enterpriseId);
					}
					
					await localStorage.setItem(
						config.me.cache_name,
						JSON.stringify(response.return)
					);
					self.load.next(response.return);
					self.data = response.return;
					observer.next(response.return);
				});
		});
	}
	
	async update(args: any, io?: Io): Promise<Response> {
		const self = this;
		const api: Io = io ? io : self.api.new();
		const data = await self.get().pipe(take(1)).toPromise();
		
		for (const item in args) {
			data[item] = args[item];
		}
		
		const update = await api.put("me", args).pipe(take(1)).toPromise();
		
		if (update.success) {
			await self.refresh().pipe(take(1)).toPromise();
		}
		
		return update;
	}
	
	async login(
		username: string,
		password: string,
		io?: Io
	): Promise<User | false> {
		const self = this;
		const object: any = {};
		
		object[config.me.login_field || 'username'] = username;
		object.password = password;
		object[`${typeOfDevice()}Browserid`] = self.api.browserid;
		
		await localStorage.setItem(config.me.cache_name, JSON.stringify(object));
		
		return new Promise((resolve, reject) => {
			(io ? io : self.api.new().silent()).post("me/login").subscribe(
				async (response) => {
					if (response.return && response.return.enterpriseId) {
						SocketIo.join("enterprise-" + response.return.enterpriseId);
					}
					
					await localStorage.setItem(config.me.cache_name, JSON.stringify(response.return));
					self.load.next(response.return);
					self.data = response.return;
					
					resolve(response.return as User);
				},
				error => reject(error)
			);
		});
	}
	
	loginTwitter() {
		return new TwitterLogin(this);
	}
	
	logout(io?: Io) {
		return new Promise(async (resolve) => {
			await localStorage.removeItem(config.me.cache_name);
			this.get(false, io).subscribe((data) => resolve(`Logged out`));
		});
	}
	
	recoverPassword(field: string, io?: Io) {
		const self = this;
		const api: Io = io ? io : self.api.new().set("success", true);
		return api.get("me/recover-password/" + config.system.recover_password, {
			field: field,
		});
	}
	
	updatePassword(
		form: {
			password: string;
			newPassword: string;
			confirmPassword: string;
		},
		io?: Io
	) {
		const self = this;
		const api: Io = io ? io : self.api.new().set("success", true);
		const post = api.put("me/change-password", form);
		
		return new Observable<Response>(observer => {
			post.subscribe(async (data) => {
				if (data.success) {
					const object = data.return;
					object.password = form.newPassword;
					
					self.data = object;
					await localStorage.setItem(config.me.cache_name, JSON.stringify(object));
					
					observer.next(object);
				} else {
					observer.error(data);
				}
			});
		});
	}
	
	hasPermission(
		permission: string,
		field?: string,
		key?: string
	): Observable<boolean> {
		const self = this;
		return Observable.create((observer: Observer<boolean>) => {
			self.get().subscribe((data) => {
				if (!data) {
					observer.next(false);
				} else {
					let returned = false;
					
					if ((data as any).master) {
						returned = true;
						observer.next(true);
					}
					
					for (const item of data.Permissions) {
						let _return = false;
						
						if (item.permission == permission || item.permission == `MASTER`) {
							_return = true;
						}
						
						if (field) {
							_return = item.field == field;
						}
						
						if (key) {
							_return = item.key == key;
						}
						
						if (_return) {
							returned = true;
							observer.next(true);
						}
					}
					
					if (!returned) {
						observer.next(false);
					}
				}
			});
		});
	}
}

class TwitterLogin {
	constructor(private me: Me) {
	}
	
	exec(io?: Io): Promise<User | false> {
		const self = this.me;
		const call = io ? io : self.api.new().silent();
		return new Promise(async (resolve, reject) => {
			call.get("me/twitter/token").subscribe(async (data) => {
				if (data.return) {
					window.location.href =
						"https://api.twitter.com/oauth/authenticate?oauth_token=" +
						data.return;
					resolve(false);
				}
			});
		});
	}
	
	listen(io?: Io): Promise<User | false> {
		const self = this;
		const call = io ? io : self.me.api.new().silent();
		return new Promise(async (resolve, reject) => {
			self.me.route.queryParams.subscribe((params) => {
				self.me
					.get()
					.pipe(take(1))
					.toPromise()
					.then(async (data) => {
						if (!data && params["oauth_token"] && params["oauth_verifier"]) {
							call
								.get(
									"me/twitter/consult/" +
									params["oauth_verifier"] +
									"/" +
									params["oauth_token"]
								)
								.subscribe(async (data) => {
									if (data.success) {
										resolve(
											await self.me.login(
												data.return.username,
												data.return.password
											)
										);
									} else {
										reject(data.message);
									}
								});
						}
					});
			});
		});
	}
}
