import { Component, OnInit } from "@angular/core";
import { Api } from "@durinn.v3/helpers/api";
import { Me } from "@durinn.v3/helpers/me";
import { Router } from "@angular/router";
import * as moment from "moment";
import swal from "sweetalert2";
import utf8 from "utf8";

const FIELDS = {
	CONTRATO: "contract",
	ENDERECO: "pre_address",
	"END. COMPLETO": "pre_address",
	CIDADE: "city",
	CEP: "cep",
	OBSERVACAO: "note",
	OBSERVAÇÃO: "note",
	"CODIGO OPERADORA": "code",
	"DATA BACKEND": "backend_date",
	"DATA ABERTURA": "opening_date",
	"DATA DE ABERTURA": "opening_date",
	"DATA AGENDA": "date",
	"INICIO AGENDA": "start",
	"FIM AGENDA": "end",
	"TIPO AGENDA": "type",
	"NOME TITULAR": "holder",
	"DOCUMENTO TITULAR": "holder_document",
	"TELEFONES TITULAR": "phones",
	"TELEFONES TITULAR 2": "phones2",
	"TELEFONES TITULAR 3": "phones3",
	"TELEFONES TITULAR 4": "phones4",
	"CODIGOS BARRA": "barcodes",
	EQUIPAMENTOS: "barcodes",
	"CPF TECNICO": "cpf",
	OCORRENCIA: "os",
	OS: "os",
	"COD CIDADE": "city_code",
	"CODIGO CIDADE": "city_code",
};

@Component({
	selector: "app-importar",
	templateUrl: "./importar.component.html",
	styleUrls: ["./importar.component.css"],
})
export class ImportarComponent implements OnInit {
	moment = moment;
	loading = false;

	load = [];
	import = [];
	users = [];
	imports = [];
	reading_file = false;
	result = null;

	call = this.api.new();
	call2 = this.api.new();

	page = 1;
	pages = 0;

	constructor(private api: Api, private me: Me, private router: Router) {}

	ngOnInit() {
		this.get();
	}

	onFileChanged(event) {
		const self = this;
		const reader = new FileReader();

		self.reading_file = true;
		reader.readAsText(event.target.files[0]);

		reader.onload = (x) => {
			self.result = reader.result;
			self.reading_file = false;
			self.generate();
		};
	}

	generate() {
		const self = this;
		self.import = [];

		try {
			if (typeof self.result !== "string") {
				return swal.fire("Importação", "Erro no arquivo", "error");
			}

			const _import = self.result.split("\n");

			for (const item of _import) {
				if (item.substr(0, 11).toUpperCase() != "OBRIGATÓRIO") {
					self.import.push(
						item
							.replace(/(\r\n|\n|\r)/gm, "")
							.replace(/\s\s+/g, " ")
							.split(";")
					);
				}
			}

			swal.fire({
				title: "Importar",
				text: `Você deseja importar essas agendas?`,
				icon: "question",
				showCancelButton: true,
				confirmButtonColor: "#DD6B55",
				confirmButtonText: "Importar",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.value === true) {
					self.build();
				}
			});
		} catch (e) {
			swal.fire("Importação", "Envie um arquivo .csv", "error");
			console.error(e, self.result);
		}
	}

	async build() {
		const self = this;

		self.loading = true;
		self.load = [];

		const header: { [a: number]: string } = {
			/* 0: 'contract' */
		};

		for (const item in self.import[0]) {
			for (const row in FIELDS) {
				if (self.import[0][item].toUpperCase().trim() === row) {
					header[item] = FIELDS[row];
				}
			}
		}

		self.import.shift();

		for (const line of self.import) {
			const obj: any = {};

			for (const item in header) {
				obj[header[item]] =
					typeof line[item] === "string"
						? utf8.encode(line[item])
						: line[item];
			}

			let pre_address = "";

			const address_array = (obj.pre_address || "").split(" ");

			for (const item of address_array) {
				pre_address += item + " ";
				if (/^\d+$/.test(item)) {
					break; 
				}
			}

			const complement = (obj.pre_address || "")
				.toLowerCase()
				.replace(pre_address.toLowerCase(), "");

			if (pre_address.substr(0, 2) == "R ") {
				pre_address = "RUA " + pre_address.substr(2);
			}

			if (pre_address.substr(0, 3) == "TV ") {
				pre_address = "TRAVESSA " + pre_address.substr(3);
			}

			pre_address = pre_address.replace("apt", "");

			let address = pre_address;

			if (obj.city) {
				address += " - " + obj.city;
			}

			if (obj.cep) {
				address += " - " + obj.cep;
			}

			const meData = (await self.me.getCacheData()) as any;

			if (meData) {
				address +=
					meData.enterprise_id == 1
						? ", Rio Grande do Sul, Brasil"
						: ", Santa Catarina, Brasil";
			}

			address = address.match(/[a-z]+|[^a-z]+/gi).join(" ");
			address = address.replace(/  +/g, " ");

			obj.address = address.toLowerCase();
			obj.pre_address = address.toLowerCase();
			obj.complement = complement;
			obj.date = moment(obj.date || new Date(), "DD/MM/YYYY").format(
				"DD/MM/YYYY"
			);
			obj.loading = true;

			if (obj.barcodes) {
				obj.barcodes = obj.barcodes.split("/");
				for (const item in obj.barcodes) {
					obj.barcodes[item] = obj.barcodes[item].trim();
				}
			}

			if (obj.contract || obj.type) {
				const item = obj;
				self.load.push(obj);
			}
		}

		if (self.load.length == 0) {
			return swal.fire(
				"Nenhuma linha válida foi encontrada",
				"Confira se o cabeçalho da tabela ou suas linhas estão no formato correto!",
				"error"
			);
		}

		self.send();
	}

	async send() {
		const self = this;
		console.log("load", self.load);
		self.call.post("importer/new", self.load).subscribe((data) => {
			if (!data.return.error) {
				return swal.fire(
					"Importação",
					"Importação feita com sucesso!",
					"success"
				);
			} else {
				self.router.navigateByUrl(
					"/agendas/importar/" + data.return.token
				);
			}
		});
	}

	async get(page = 1) {
		const self = this;
		self.call2
			.get("importer/" + (page - 1) * 10 + "/3")
			.subscribe((data) => {
				self.pages = Math.ceil(data.return.count / 10);
				self.imports = data.return.rows;
			});
	}
}
