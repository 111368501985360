import { ListBasePage } from "../../../../../durinn/bases/listpage.base";
import { Component } from "@angular/core";
import * as moment from "moment";

@Component({
	selector: "app-holidays-crud-box",
	templateUrl: "./holidays-crud-box.component.html",
	styleUrls: ["./holidays-crud-box.component.css"],
})
export class HolidaysCrudBoxComponent extends ListBasePage {
	items = [];
	filters = {
		Holiday: {
			date: "",
		},
	};

	order = {
		Holiday: [["date", "desc"]],
	};

	limit = 5;
	route = "holidays";

	modals = {
		new: {
			call: this.api.new().set("success", true),
			visible: false,
			date: "",
		},
	};

	moment = moment;

	async create() {
		const self = this;
		this.modals.new.call
			.post(
				`holidays/by-date/` +
					moment(this.modals.new.date, "DD/MM/YYYY").toDate(),
				{}
			)
			.subscribe((data) => {
				self.ngOnInit();
				self.modals.new.visible = false;
			});
	}
}
