import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CrudPage} from "../../../../../../../bases/crud.page";

import * as moment from "moment";

@Component({
	selector: 'app-import-view-list',
	templateUrl: './import-view-list.component.html',
	styleUrls: ['./import-view-list.component.css']
})
export class ImportViewListComponent extends CrudPage implements OnInit {
  @Output() reloaded = new EventEmitter();
  @Input() route:string;
  @Input() id:number;

  Object = Object;

  filters:any = {
  	ToImportLine: {
  	}
  };

  order = {
  	ToImportLine: [["status", "desc"]],
  };

  TO_IMPORT_LINE_TYPE_ID_TRANSLATION = {
  	0: 'Pendente',
  	1: 'Processado'
  };

  TO_IMPORT_LINE_STATUS_ID_TRANSLATION = {
  	0: 'Pendente',
  	1: 'Sucesso',
  	2: 'Erro'
  };

  async get(page = 1) {
  	const self = this;
  	super.get(page);
  	self.reloaded.emit(true);
  }

  exportToExcel(){
  	const self = this;
  	self.api.new().openLink('to-import/' + self.id + '/export');
  }
}
