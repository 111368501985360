import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageBase} from "@durinn.v3/app/bases/page.base";
import {DELIVERY_STATUS} from "../../../../../constants";

@Component({
	selector: 'app-modal-agenda-dar-baixa',
	templateUrl: './modal-agenda-dar-baixa.component.html',
	styleUrls: ['./modal-agenda-dar-baixa.component.css']
})
export class ModalAgendaDarBaixaComponent extends PageBase {
	@Input() id: number | string;
	@Input() delivery: any;
	
	@Input() visible = false;
	@Output() visibleChange = new EventEmitter();
	
	@Output() terminated = new EventEmitter();
	
	dismiss(){
		this.visible = false;
		this.visibleChange.emit(false);
	}
	
	call = this.api.new();
	
	terminate_note = '';
	
	public async conclude() {
		const self = this;
		
		self.call
			.post("delivery/" + self.id + "/terminate", {note: self.terminate_note})
			.subscribe((response) => {
				if (response.success) {
					self.toastr.success(`Agenda baixada com sucesso!`);
					self.terminated.emit(response.return);
					self.dismiss();
				}
			});
	}
}
