import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
} from "@angular/core";
import {
	ModelBaseInterface,
	ModelPageBase,
} from "@durinn/v.3/app/bases/modelpage.base";
import {
	Permission,
	UserPermissionsTableComponent,
} from "@durinn/v.3/app/components/users/user-permissions-table/user-permissions-table.component";

@Component({
	selector: "durinn-v2-edit-profile",
	templateUrl: "./edit-profile.component.html",
	styleUrls: ["./edit-profile.component.css"],
})
export class DurinnV2EditProfileComponent
	extends ModelPageBase
	implements ModelBaseInterface, OnChanges {
  @ViewChild(UserPermissionsTableComponent, { static: false })
  	userPermissionsComponent: UserPermissionsTableComponent;

  @Input() modalVisible = false;
  @Input() form: any = {};
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();
  @Output() isConcluded: EventEmitter<boolean> = new EventEmitter<boolean>();

  route = "profile";
  permission = "CRUD_PROFILES";

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
  	if (changes.form && this.userPermissionsComponent) {
  		this.userPermissionsComponent.ngOnInit();
  	}
  }

  permissionsChanged(array: Permission[]) {
  	this.form.Permissions = array.map((x) => {
  		return { permission: x.name };
  	});
  }

  submit() {
  	const self = this;

  	const call = self.form.id
  		? self.call.put(`${this.route}/${this.form.id}`, this.form)
  		: self.call.post(`${this.route}/`, this.form);

  	call.subscribe(async (response) => {
  		self.isConcluded.emit(response.return);
  		self.modalCancel();
  		self.app.alert(`Sucesso`, response.message, `success`);
  	});
  }

  modalCancel() {
  	this.form = {};
  	this.cancel.emit(true);
  }
}
