import { CrudPage } from "src/app/bases/crud.page";
import { Component, Input } from "@angular/core";
import * as moment from "moment";

@Component({
	selector: 'app-away',
	templateUrl: './away.component.html',
	styleUrls: ['./away.component.css']
})
export class AwayComponent extends CrudPage {
	modalCall = this.api.new();
	isAddModalVisible = false;
	@Input() userType: string;
	@Input() id: number;
	moment = moment;
	route = "away";

	filters = {
		Away: {},
	};

	order = {
		Away: [["from", "desc"]],
	};

	params = {
		from: moment().startOf("month").format("DD/MM/YYYY"),
		to: moment().endOf("month").format("DD/MM/YYYY"),
    	id: null,
	};

	ngOnInit() {
		const id =
			this.userType == "deliverymen"
				? { userId: this.id }
				: { adminId: this.id };
		this.filters.Away = { ...id };
		super.ngOnInit();
	}

	get isLoading() {
		const self = this;
		return self.modalCall.loading || self.call.loading || self.delete_call.loading;
	}

	resetParams() {
		const self = this;
		self.params = {
			from: moment().startOf("month").format("DD/MM/YYYY"),
			to: moment().endOf("month").format("DD/MM/YYYY"),
			id: null
		};
	}

	isParamsValid() {
		const self = this;

		const from = moment(self.params.from, "DD/MM/YYYY");
		const to = moment(self.params.to, "DD/MM/YYYY");
		return from.isValid() && to.isValid();
	}

	openModal() {
		const self = this;
		self.resetParams();
		self.isAddModalVisible = true;
	}

	onUpdate(item) {
		const self = this;
		const params = { ...item };
		params.from = moment(params.from).format("DD/MM/YYYY");
		params.to = moment(params.to).format("DD/MM/YYYY");

		self.isAddModalVisible = true;
		self.params = params;
	}

	onSubmit() {
		const self = this;
		const body = { ...self.params };

		body.from = moment(body.from, "DD/MM/YYYY")
			.startOf("day")
			.toISOString();

		body.to = moment(body.to, "DD/MM/YYYY")
			.endOf("day")
			.toISOString();

		if (body.id) {
			self.modalCall
				.put(`${self.route}/${body.id}`, {
					from: body.from,
					to: body.to,
				})
				.subscribe(() => {
					self.isAddModalVisible = false;
					self.resetParams();
					self.get();
				});
		} else {
			const id =
				self.userType == "deliverymen"
					? { userId: self.id }
					: { adminId: self.id };
			self.modalCall
				.post(self.route, { ...body, ...id })
				.subscribe(() => {
					self.isAddModalVisible = false;
					self.resetParams();
					self.get();
				});
		}
	}
}
