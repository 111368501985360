import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-enterprises-crud-box',
	templateUrl: './enterprises-crud-box.component.html',
	styleUrls: ['./enterprises-crud-box.component.css']
})
export class EnterprisesCrudBoxComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
