import { Component, Input } from "@angular/core";
import { FilterOrderBase } from "../filter-order.base";

@Component({
	selector: "durinn-v2-filter-order-select",
	templateUrl: "./filter-order-select.component.html",
	styleUrls: ["./filter-order-select.component.scss"],
})
export class FilterOrderSelectComponent extends FilterOrderBase {
  @Input() options: { label: string; value: string }[] | string[] = ["Todos"];

  ngOnInit() {}

  isObject(option) {
  	return typeof option === "object";
  }

  getOptionValue(option): string {
  	return this.isObject(option) ? option.value : option;
  }

  getOptionLabel(option): string {
  	return this.isObject(option) ? option.label : option;
  }
}
