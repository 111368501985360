import { Component } from "@angular/core";
import {PageBase} from "@durinn.v3/app/bases/page.base";
import {
	DurinnV2ListPageTableComponent,
	HeadColumns
} from "@durinn.v3/app/components/template/tables/list-page-table/list-page-table.component";

@Component({
	selector: "app-usuarios",
	templateUrl: "./usuarios.component.html",
	styleUrls: ["./usuarios.component.css"],
})
export class UsuariosComponent extends PageBase {
	filters = {
		User: {
		},
	};

	order = {
		User: [["name", "asc"]]
	};

	headColumns: HeadColumns = DurinnV2ListPageTableComponent.defaultHeadColumnsWith('User', [
		{ index: "User", column: "phone", name: "Telefone", mask: '0*' },
		{ index: "User", column: "email", name: "E-mail" },
		{ index: "User", column: "active", name: "Ativo", type: 'select', options: [{value: '1', label: 'Sim'}, {value: {'Op.eq': 0}, label: 'Não'}] },
	]);
}
