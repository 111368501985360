import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-loader",
	templateUrl: "./app-loader.component.html",
	styleUrls: ["./app-loader.component.scss"],
})
export class AppLoaderComponent implements OnInit {
	@Input() lines: 1 | 2 = 2;
	@Input() loading = false;

	constructor() {}

	ngOnInit() {}
}
