import { Component, Input, OnInit } from "@angular/core";
import { AlmoxarifadoComponent } from "../../../../../almoxarifado/almoxarifado.component";
import * as moment from "moment";

@Component({
	selector: "app-tecnico-com-tecnico",
	templateUrl: "./tecnico-com-tecnico.component.html",
	styleUrls: ["./tecnico-com-tecnico.component.css"],
})
export class TecnicoComTecnicoComponent extends AlmoxarifadoComponent {
	@Input() deliverymanId: number;

	route = "warehouse/with-deliveryman";

	from = moment().subtract(3, "months").format("DD/MM/YYYY");
	to = moment().format("DD/MM/YYYY");

	filters: any = {
		device: {},
		Deliveryman: {
			name: "",
		},
		last_scan: {
			type: 0,
		},
	};

	order = {
		LastScan: [["register_date", "asc"]],
	};

	async get(page = 1): Promise<void> {
		this.filters.Deliveryman.id = this.deliverymanId;
		this.filters.last_scan["Op.and"] = [
			{
				register_date: {
					"Op.gte": moment(this.from, "DD/MM/YYYY").toDate(),
				},
			},
			{
				register_date: {
					"Op.lte": moment(this.to, "DD/MM/YYYY").toDate(),
				},
			},
		];
		return super.get(page);
	}

	formatDeliverymanName(name: string) {
		const arr = name.split(" ");

		return arr[0] + " " + arr[1] + ".";
	}
}
