import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	TemplateRef,
} from "@angular/core";

@Component({
	selector: "durinn-v2-page-footer",
	templateUrl: "./page-footer.component.html",
	styleUrls: ["./page-footer.component.scss"],
})
export class DurinnV2PageFooterComponent implements OnInit {
	@Input() id: number | null;

	@Input() saveButton = true;
	@Input() saveButtonLabel?: string;
	@Input() saveButtonLoading = false;
	@Input() saveButtonDisabled = false;
	@Output() saveButtonClicked = new EventEmitter();

	@Input() deleteButton = true;
	@Input() deleteButtonLoading = false;
	@Input() deleteButtonLabel = "Apagar";
	@Input() deleteButtonDisabled = false;
	@Output() deleteButtonClicked = new EventEmitter();

	@Input() loading = false;
	@Input() leftSideTemplate: TemplateRef<any>;

	constructor() {}
	ngOnInit() {}
}
