import { ActivatedRoute, Router } from "@angular/router";
import {Injectable, NgZone, OnInit} from "@angular/core";
import { SocketIo } from "@durinn.v3/helpers/SocketIo";
import { HttpClient } from "@angular/common/http";
import { Me, User } from "@durinn.v3/helpers/me";
import { Api } from "@durinn.v3/helpers/api";
import { AppComponent } from "../app.component";
import { App } from "@durinn.v3/helpers/app";
import { Io } from "@durinn.v3/helpers/api";
import * as moment from "moment";
import {Subject} from "rxjs";

export const OLD_API_LINK = "https://io.padraologistico.com.br/dashboard/";

@Injectable()
export class BasePage implements OnInit {
	permissions: { [s: string]: boolean } = {};
	mineUser: User | false;

	public window = window;
	public moment = moment;
	public subscriptions: { [name: string]: Subject<any> } = {};

	constructor(
		protected api: Api,
		protected app: App,
		protected me: Me,
		protected http: HttpClient,
		protected activatedRoute: ActivatedRoute,
		protected router: Router,
		protected appC: AppComponent,
		protected ngZone: NgZone
	) {
		const self = this;
		this.me.get().subscribe((data) => {
			self.mineUser = data;
			self.checkPermissions();
		});
	}

	ngOnInit(): void {
	}

	ngOnDestroy() {
		this.socketUnsubscribeAll();
	}

	checkPermissions() {
		const self = this;

		for (const index in self.permissions) {
			self.permissions[index] = false;
		}

		for(const item of (self.mineUser || {} as any).Permissions || []){
			self.permissions[item.permission] = true;

			if(item.permission == 'MASTER'){
				for (const index in self.permissions) {
					self.permissions[index] = true;
				}
			}
		}
	}

	hasPermission(field) {
		return (
			this.permissions[field] ||
			this.permissions.MASTER ||
			(this.mineUser as any).master
		);
	}

	socketUnsubscribeAll() {
		for (const event in this.subscriptions) {
			try {
				this.subscriptions[event].unsubscribe();
				console.log(`ngOnDestroy`, `unsubscribing`, event);
			} catch (e) {
				console.log(`ngOnDestroy`, `unsubscribe error`, e, event);
			}
		}
	}

	socketOn(event: string, subscribe: (args: any) => void): Subject<any> {
		const self = this;

		if (
			self.subscriptions[event] &&
			typeof self.subscriptions[event].subscribe === "function"
		) {
			self.subscriptions[event].subscribe((args) => {
				self.ngZone.run(() => {
					subscribe(args);
				});
			});
		} else {
			console.log("new listener", event);
			self.subscriptions[event] = SocketIo.on(event).subscribe((args) => {
				self.ngZone.run(() => {
					subscribe(args);
				});
			});
		}

		return self.subscriptions[event];
	}

	/*
     * Essa função faz com que antes de dar subscribe a um socket.Io o sistema
     * se desinscreva antes. Tornando-a segura para funções que são executadas
     * mais de uma vez pelos sistemas.
     */
	socketSafeOn(event: string, subscribe: (args: any) => void): Subject<any> {
		const self = this;

		if (self.subscriptions[event]) {
			self.subscriptions[event].unsubscribe();
			console.log("unsubscribe", event);
		}

		if (
			self.subscriptions[event] &&
			typeof self.subscriptions[event].subscribe === "function"
		) {
			self.subscriptions[event].subscribe((args) => {
				self.ngZone.run(() => {
					subscribe(args);
				});
			});
		} else {
			console.log("new listener", event);
			self.subscriptions[event] = SocketIo.on(event).subscribe((args) => {
				self.ngZone.run(() => {
					subscribe(args);
				});
			});
		}

		return self.subscriptions[event];
	}
}
