import { EditPage, EditPageInterface } from "../../../../../../bases/edit.page";
import { Component } from '@angular/core';
import * as moment from "moment";

@Component({
	selector: 'app-imports-view',
	templateUrl: './imports-view.component.html',
	styleUrls: ['./imports-view.component.css']
})
export class ImportsViewComponent extends EditPage implements EditPageInterface {
	route = "to-import";
	moment = moment;
	show = false;

	form = {
		success: 0,
		mistake: 0,
		record: 1
	};

	get processedRate() {
		const self = this;
		return (((self.form.success + self.form.mistake) / self.form.record) * 100) < 100 ? 'red' : 'green';
	}

	get processed() {
		const self = this;
		const percentage = ((self.form.success + self.form.mistake) / self.form.record) * 100;
		return `${self.form.success + self.form.mistake} - ${percentage.toFixed(2)}%`; 
	}
}
