import { sideMenu } from "@durinn/v.3/app/views/with-layout/with-layout-routing.module";
import { sideMenu as viewsSideMenu } from "@views/with-layout/with-layout-routing.module";

export interface SideNavItem {
  permissions?: string[];
  hasOneOfPermissions?: string[];
  visible?: boolean;
  submenu?: SideNavItem[];
  iconType?: "" | "nzIcon" | "fontawesome";
  iconTheme?: "" | "fab" | "far" | "fas" | "fill" | "outline" | "twotone";
  icon?: string;
  path?: string;
  title: string;
  badge?: {
	id: string;
	url: string;
	color: string;
	maxCount: number;
  }
}

export const ROUTES: SideNavItem[] = viewsSideMenu || sideMenu;
