import { FiltersType, ListBasePage } from "src/app/durinn/bases/listpage.base";
import { Component } from "@angular/core";
import * as moment from "moment";

// Puxar do Backend
const STANDARD_SCHEDULE_SETTING_TYPE = {
	STANDARD: 1,
	EXCEPTIONAL: 2
};

// Puxar do Backend
const STANDARD_SCHEDULE_SETTING_TYPE_SELECT = [
	{ label: 'Padrão', value: 1 },
	{ label: 'Excepcional', value: 2 }
];

// Puxar do Backend
const STANDARD_SCHEDULE_SETTING_TYPE_ID_TRANSLATION = {
	1: 'Padrão',
	2: 'Excepcionalmente',
};

// Puxar do Backend
const STANDARD_SCHEDULE_SETTING_WORKSHIFT_SELECT = [
	{ label: 'Das 08h às 12h', value: 'morning' },
	{ label: 'Das 12h às 18h', value: 'evening' }
];

// Puxar do Backend
const STANDARD_SCHEDULE_SETTING_WORKSHIFT_ID_LABEL_TRANSLATION = {
	morning: 'Das 08h às 12h',
	evening: 'Das 12h às 18h',
};

@Component({
	selector: 'app-schedule-settings-standard-days',
	templateUrl: './standard-days.component.html',
	styleUrls: ['./standard-days.component.css']
})
export class ScheduleSettingsStandardDaysComponent extends ListBasePage {
	STANDARD_SCHEDULE_SETTING_WORKSHIFT_ID_LABEL_TRANSLATION = STANDARD_SCHEDULE_SETTING_WORKSHIFT_ID_LABEL_TRANSLATION;
	STANDARD_SCHEDULE_SETTING_TYPE_ID_TRANSLATION = STANDARD_SCHEDULE_SETTING_TYPE_ID_TRANSLATION;
	STANDARD_SCHEDULE_SETTING_WORKSHIFT_SELECT = STANDARD_SCHEDULE_SETTING_WORKSHIFT_SELECT;
	STANDARD_SCHEDULE_SETTING_TYPE_SELECT = STANDARD_SCHEDULE_SETTING_TYPE_SELECT;
	STANDARD_SCHEDULE_SETTING_TYPE = STANDARD_SCHEDULE_SETTING_TYPE;
	route = "standard-schedule-setting";
	moment = moment;
	items = [];
	limit = 5;

	modal_call = this.api.new().set("success", true);
	isVisible = false;
	args = {
		workshift: "morning",
		quantity: null,
		date: null,
		type: 2
	};

	filters: FiltersType = {
		StandardScheduleSetting: {}
	};

	order = {
		StandardScheduleSetting: [["date", "desc"]],
	};

	get isLoading() {
		const self = this;
		return self.call.loading;
	}

	get isModalLoading() {
		const self = this;
		return self.modal_call.loading;
	}

	get isDisabled() {
		const self = this;

		const conditions = [
			self.args.quantity > 0,
			self.args.type != null,
			self.args.workshift != null
		];

		if (self.args.type == self.STANDARD_SCHEDULE_SETTING_TYPE.EXCEPTIONAL) {
			conditions.push((self.args.date || '').length == 10);
			conditions.push(self.moment(self.args.date, 'DD/MM/YYYY').isValid());
		}

		return conditions.includes(false);
	}

	async onSubmit() {
		const self = this;
		const args = self.args;
        
		if (args.type == STANDARD_SCHEDULE_SETTING_TYPE.EXCEPTIONAL) {
			args.date = self.moment(args.date, "DD/MM/YYYY").toDate();
		} else {
			delete args.date;
		}

		self.modal_call.post(self.route, args).subscribe(() => {
			self.onCancel();
			self.ngOnInit();
		});
	}

	onOpen() {
		const self = this;
		self.isVisible = true;
	}

	onCancel() {
		const self = this;
		self.isVisible = false;
		self.onReset();
	}

	onReset() {
		const self = this;
		self.args = {
			workshift: "morning",
			quantity: null,
			date: null,
			type: 2
		};
	}
}
