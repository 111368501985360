import { PERMISSIONS } from "../../../usuarios/pages/usuario/components/permissions/permissions.component";
import { ContasDefaultComponent } from "../contas-default/contas-default.component";
import { FiltersType } from "src/app/durinn/bases/listpage.base";
import { Component } from "@angular/core";

export class ContasPagarModel {
	name?: string = null;
	dueDate: Date = null;
	note?: string = null;
	bill?: string = null;
	category?: string = null;
	amount: number = null;
	proof?: string = null;
	other?: string = null;
	payToType: number = null;
	paymentDate?: Date = null;
	payUserTo?: number = null;
	paidUserBy?: number = null;
	payDeliverymanTo?: number = null;
}

@Component({
	selector: "app-contas-pagar",
	templateUrl: "../contas-default/contas-default.component.html",
	styleUrls: ["../contas-default/contas-default.component.css"],
})
export class ContasPagarComponent extends ContasDefaultComponent {
	filters: FiltersType = {
		BillToPay: this.pendingFilters,
	};
	order = { BillToPay: [["dueDate", "asc"]] };
	permission = PERMISSIONS.SEE_FINANCIAL;
	route = "bill-to-pay";

	ngOnInit() {
		super.ngOnInit();
		this.createForm(new ContasPagarModel());
		this.setValidators();
	}

	get pendingFilters() {
		return {
			paymentDate: { "Op.is": null },
			paidUserBy: { "Op.is": null },
			active: 1,
		};
	}

	get settledFilters() {
		return {
			paymentDate: { "Op.gt": 0 },
			paidUserBy: { "Op.gt": 0 },
			active: 1,
		};
	}
}
