import { NgModule } from "@angular/core";
import { Logged } from "@durinn/v.3/helpers/auth-guard";
import { NoLayoutModule } from "@views/no-layout/no-layout.module";
import { WithLayoutModule } from "@views/with-layout/with-layout.module";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { NoLayoutComponent } from "@durinn/v.3/app/views/no-layout/no-layout.component";
import { WithLayoutComponent } from "@views/with-layout/with-layout.component";

const appRoutes: Routes = [
	{
		path: "",
		redirectTo: "/home",
		pathMatch: "full",
	},
	{
		path: "",
		component: WithLayoutComponent,
		children: [
			{
				path: "",
				loadChildren: () => WithLayoutModule,
			},
		],
		canActivate: [Logged],
	},
	{
		path: "",
		component: NoLayoutComponent,
		children: [
			{
				path: "authentication",
				loadChildren: () => NoLayoutModule,
			},
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			preloadingStrategy: PreloadAllModules,
			useHash: false,
			scrollPositionRestoration: "enabled",
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}