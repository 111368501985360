import languageEncoding from "detect-file-encoding-and-language";
import { Component, OnInit } from "@angular/core";
import { Api } from "@durinn.v3/helpers/api";
import * as moment from "moment";
import swal from "sweetalert2";

@Component({
	selector: "app-importar-uras",
	templateUrl: "./importar-uras.component.html",
	styleUrls: ["./importar-uras.component.css"],
})
export class ImportarUrasComponent implements OnInit {
	call = this.api.new();
	call2 = this.api.new();
	reading_file = false;
	moment = moment;
	result = null;

	load = [];
	loading = false;

	import = [];
	imports = [];

	page = 1;
	pages = 0;

	constructor(private api: Api) {}

	ngOnInit() {
		this.get();
	}

	async onFileChanged(event) {
		const self = this;
		const reader = new FileReader();

		self.reading_file = true;

		const fileInfo = await languageEncoding(event.target.files[0]);
		reader.readAsText(event.target.files[0], fileInfo.encoding || 'ISO-8859-1');

		reader.onload = (x) => {
			self.result = reader.result;
			self.reading_file = false;
			self.generate();
		};
	}

	generate() {
		const self = this;
		self.import = [];

		try {
			if (typeof self.result !== "string") {
				return swal.fire("Importação", "Erro no arquivo", "error");
			}

			if(!self.result){
				return swal.fire("Importação", "Erro no arquivo - Nenhum registro encontrado para importar", "error");
			}

			const imports = self.result.split("\n");
			const header = imports[0].split(";");
			const headerExpected = [
				"TIPO BAIXA",
				"CONTRATO",
				"CIDADE",
				"SERIAL",
				"TIPO",
				"DATA",
			];

			for (const item of headerExpected) {
				const conditions = [
					header.includes(item),
					header.includes(item + '\r'),
					header.includes(item + '\n'),
				];

				if (!conditions.includes(true)) {
					return swal.fire(
						`Erro`,
						`Formato inválido. Por favor, escolha um arquivo válido de exportação de URAS`,
						`error`
					);
				}
			}

			for (const item of imports) {
				const value = item
					.replace(/(\r\n|\n|\r)/gm, "")
					.replace(/\s\s+/g, " ")
					.split(";");

				self.import.push(value);
			}

			swal
				.fire({
					title: "Importar",
					text: `Você deseja importar essas agendas?`,
					icon: "question",
					showCancelButton: true,
					confirmButtonColor: "#DD6B55",
					confirmButtonText: "Importar",
					cancelButtonText: "Cancelar",
				})
				.then((result) => {
					if (result.value === true) {
						self.build();
					}
				});
		} catch (e) {
			swal.fire("Importação", "Envie um arquivo .csv", "error");
			console.error(e, self.result);
		}
	}

	async build() {
		const self = this;

		const INDEXES: { [index: string]: number } = {};
		const importDate = moment().toDate();
		self.loading = true;
		self.load = [];

		for (const index in self.import[0]) {
			INDEXES[self.import[0][index]] = +index;
		}

		/*
      ATENDENTE: 8
      CIDADE: 0
      CONTRATO: 1
      DATA: 5
      H.FIM: 10
      H.INICIO: 9
      LOGIN ADM: 6
      OBSERVAÇÕES: 7
      SERIAL: 3
      TIPO: 2
      TIPO BAIXA: 4
    */

		self.import.shift();

		console.log(`self.import`, self.import);

		for (const line of self.import) {
			if (line.length >= 6) {
				const date = moment(line[INDEXES["DATA"]], "DD/MM/YYYY")
					.startOf("day")
					.toDate();
				const lowType = line[INDEXES["TIPO BAIXA"]];
				const contract = line[INDEXES["CONTRATO"]];
				const category = line[INDEXES["CATEGORIA"]];
				const serial = line[INDEXES["SERIAL"]];
				const city = line[INDEXES["CIDADE"]];
				const type = line[INDEXES["TIPO"]];

				if(contract){
					self.load.push({
						importDate,
						contract,
						category,
						lowType,
						serial,
						type,
						city,
						date,
					});
				}
			}
		}

		self.send();
	}

	async send() {
		const self = this;
		self.call.post("importer/uras/new", self.load).subscribe((data) => {
			if (!(data.return.error || []).length) {
				return swal.fire(
					"Importação",
					"Importação feita com sucesso!",
					"success"
				);
			} else {
				swal.fire(
					"Importação",
					"Aconteceram alguns erros durante a importação!",
					"error"
				);
				const csvContent = "data:text/csv;charset=utf-8," + data.return.csv;
				const encodedUri = encodeURI(csvContent);
				window.open(encodedUri);
			}
		});
	}

	async get(page = 1) {
		const self = this;
		// self.call2
		//   .get("importer/uras/" + (page - 1) * 10 + "/3")
		//   .subscribe((data) => {
		//     self.pages = Math.ceil(data.return.count / 10);
		//     self.imports = data.return.rows;
		//   });
	}
}
