export const DELIVERY_TYPES = [
	"COMBO MULTI",
	"MALLING",
	"DX22",
	"VOX",
	"DESC TOA",
	"ENTREGA DE CAMERA",
	"ENTREGA DE STREAMINGS",
	"ENTREGA DE CONTROLE",
	"ENTREGA DE EXAMES",
	"CORTE",
	"FIELD",
	"NOTURNA",
	"AGENDA FUTURA",
	"AGENDAMENTO E-MAIL",
	"AGENDAMENTO P.A",
	"AGENDAMENTO TOA",
	'REAGENDAMENTO LINK',
	'INSTALACAO CONTACT',
	'RETORNO DE CARTA'
];

export const DELIVERY_TYPES_OPTIONS = [
	{ label: "COMBO MULTI", value: "COMBO MULTI" },
	{ label: "MALLING", value: "MALLING" },
	{ label: "DX22", value: "DX22" },
	{ label: "VOX", value: "VOX" },
	{ label: "DESC TOA", value: "DESC TOA" },
	{ label: "ENTREGA DE CAMERA", value: "ENTREGA DE CAMERA" },
	{ label: "ENTREGA DE STREAMINGS", value: "ENTREGA DE STREAMINGS" },
	{ label: "ENTREGA DE CONTROLE", value: "ENTREGA DE CONTROLE" },
	{ label: "ENTREGA DE EXAMES", value: "ENTREGA DE EXAMES" },
	{ label: "CORTE", value: "CORTE" },
	{ label: "FIELD", value: "FIELD" },
	{ label: "NOTURNA", value: "NOTURNA" },
	{ label: "AGENDA FUTURA", value: "AGENDA FUTURA" },
	{ label: "AGENDAMENTO E-MAIL", value: "AGENDAMENTO E-MAIL" },
	{ label: "AGENDAMENTO P.A", value: "AGENDAMENTO P.A" },
	{ label: "RETORNO DE CARTA", value: "RETORNO DE CARTA" },
	{ label: "AGENDAMENTO TOA", value: "AGENDAMENTO TOA" },
	{ label: "REAGENDAMENTO LINK", value: 'REAGENDAMENTO LINK' },
	{ label: 'INSTALACAO CONTACT', value: 'INSTALACAO CONTACT' },
];

export const DELIVERY_STATUS = {
	1: "Ativo",
	2: "Realizado",
	3: "Atrasado",
	4: "Tempo Apertado",
	5: "Perdida",
	6: "Baixada",
	100: "Agendamento Não Cumprido",
	101: "Endereço Não Localizado",
	102: "Área De Risco",
	106: "Ausente",
	107: "Entrada Não Autorizada",
	109: "Desistência da desconexão",
	111: "Reagendamento Solicitado Pelo Cliente",
	306: "Mudou-se",
	409: "Serviço Concluído",
	428: "Desconexão C/RET Equipamento",
	429: "Desconexão S/RET Equipamento",
	430: "Retirada De Equipamentos",
	431: "Equipamento Já Retirado por outro Técnico",
	706: "Entrega de Chip com Sucesso",
	302: "Desistência de Serviço",
	312: "Não Solicitou o Serviço",
	10: "Entrega de Exames com Sucesso",
	110: "Sem acesso ao DG",
	998: "Entregue na Loja da Claro",
	999: "Agendamento Fake",
};

export const DEVICESTATUS_CATEGORY = {
	DISCONNECTION: 0,
	INSTALLATION: 1,
	TRANSLATION: {
		0: "Desconexão",
		1: "Instalação",
	},
};

export const URAS_SCHEDULE_STATUS_TRANSLATION = {
	0: "Não Executado Ainda",
	1: "Baixado / Chamado",
	2: "Não Baixado",
	3: "Baixado",
};

export const URAS_SCHEDULE_STATUS = {
	NAO_EXECUTADO_AINDA: 0,
	BAIXADO_CHAMADO: 1,
	NAO_BAIXADO: 2,
	BAIXADO: 3,
};

export const URAS_SCHEDULE_STATUS_OPTIONS = [
	{ description: "Não Executado Ainda", value: 0 },
	{ description: "Baixado / Chamado", value: 1 },
	{ description: "Não Baixado", value: 2 },
	{ description: "Baixado", value: 3 },
];
