import {Component, EventEmitter, Input, NgZone, Output} from "@angular/core";
import { CrudPage } from "../../../bases/crud.page";
import {Api, Io} from "@durinn.v3/helpers/api";
import {App} from "@durinn.v3/helpers/app";
import {Me} from "@durinn.v3/helpers/me";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {AppComponent} from "../../../app.component";
import {ApiCallsManager} from "@durinn.v3/helpers/ApiCallsManager";

@Component({
	selector: "deliveryman-select",
	templateUrl: "./deliveryman-select.component.html",
	styleUrls: ["./deliveryman-select.component.css"],
})
export class DeliverymanSelectComponent extends CrudPage {
	@Input() userId = null;
	@Input() deliveryman = null;
	@Input() showNone = true;
	@Output() selected = new EventEmitter();
	@Input() iconVersion = false;

	items = [];
	filters = {
		Cluster: {
			name: null,
		},
		Enterprise: {
			name: null,
		},
		Deliveryman: {
			name: null,
			email: null,
			phone: null,
			active: null,
		},
	};

	order = {
		Deliveryman: [["name", "asc"]],
		Enterprise: [["name", "asc"]],
		Cluster: [["name", "asc"]],
	};

	call: Io;
	delete_call: Io;
	route = "deliverymen";

	ngOnChanges() {
		if (this.deliveryman) {
			this.userId = this.deliveryman.id;
		}
	}

	async get(page = 1) {
		const self = this;
		const _page = page - 1;
		const offset = _page * self.limit;

		self.page = page;

		let args: any = { order: self.order };

		if (Object.values(self.getFilters()).length > 0) {
			const where = {};
			const items = self.getFilters();

			for (const item in items) {
				where[item] = { "Op.and": items[item] };
			}

			args = { order: self.order, where: where };
		}

		self.apiCallsManager.call(
			self.call,
			'post',
			self.route + `/search/${offset}/` + self.limit,
			args
		).subscribe((data) => {
			self.items = data.success
				? self.foreachItems(data.return.rows)
				: [];
			self.pages = data.return.count;
			self.offset = offset;

			if (self.items.length == 1) {
				self.selected.emit(self.items[0].id);
			}
		});
	}

	protected getFilters() {
		const self = this;

		if (self.filters.Deliveryman.active) {
			self.filters.Deliveryman.active = +self.filters.Deliveryman.active;
		}

		return super.getFilters();
	}
}
