import { NotificationSetting } from "@durinn/v.3/app/components/users/user-notification-setting/user-notification-setting.component";
import { Permission } from "@durinn/v.3/app/components/users/user-permissions-table/user-permissions-table.component";
import { ModelBaseInterface, ModelPageBase } from "@durinn/v.3/app/bases/modelpage.base";
import {Component, Input, TemplateRef} from "@angular/core";
import { UserInterface } from "@backend-interfaces/models";
import swal from "sweetalert2";

@Component({
	selector: "durinn-v2-edit-user",
	templateUrl: "./user.component.html",
	styleUrls: ["./user.component.css"],
})
export class DurinnV2UserComponent
	extends ModelPageBase
	implements ModelBaseInterface {
	@Input() page: DurinnV2UserComponent = this;
	@Input() headerTemplate: TemplateRef<any>;

	route = "users";
	backPage = "usuarios";
	permission = "EDIT_USERS";
	variableUrl = "user-fieldstofill";
	selectedTab = "usuario";
	profiles = [];

	passwordVisible = this.id ? false : true;
	_teamSelected = null;

	form: Partial<UserInterface> & { variables: any; Permissions: any[] } = {
		email: "",
		username: "",
		picture: "",
		password: "123456",
		subsidiaryId: null,
		profileId: null,
		variables: {},
		Teams: [],
		Permissions: [],
		NotificationSettings: [],
	};

	fields = [
		{
			label: "Nome",
			name: "name",
			type: "text",
			validate: "Por favor, preencha com um nome válido!",
			required: true,
		},
		{
			label: "CPF",
			name: "username",
			type: "text",
			validate: "Por favor, preencha com um cpf válido!",
			placeholder: "000.000.000-00",
			mask: "000.000.000-00",
			required: true,
		},
		{
			label: "Telefone",
			name: "phone",
			type: "text",
			validate: "Por favor, preencha com um telefone válido!",
			mask: "(00) 00000-0000",
			placeholder: "(00) 00000-0000",
			required: true,
		},
		{
			label: "E-mail",
			name: "email",
			type: "email",
			validate: "Por favor, preencha com um e-mail válido!",
			required: false,
		},
	];

	ngOnInit(): void {
		const self = this;

		self.me.get(true).subscribe((user) => {
			if (user) {
				if (self.id == user.id) {
					return self.router.navigateByUrl("/perfil");
				} else {
					super.ngOnInit();
				}
			}
		});
	}

	get canDelete() {
		const self = this;
		return self.hasPermission('DELETE_USERS') && !!self.id;
	}

	get canViewPassword() {
		const self = this;

		if (self.hasPermission('MASTER') && !!self.form.id) {
			return true;
		}

		if (self.hasPermission('EDIT_USERS') && !!self.form.id) {
			return (self.me.data as any).id == self.form.id;
		}

		return self.form.id == null;
	}

	get canResetPassword() {
		const self = this;

		if (self.form.id) {
			if ((self.me.data as any).id == self.form.id) {
				return false;
			}

			return self.hasPermission('EDIT_USERS');
		}

		return false;
	}

	onResetPassword() {
		const self = this;

		if (self.form.id) {
			self.call.post(`${self.route}/${self.form.id}/reset-password`).subscribe(async response => {
				if (response.success) {
					self.app.alert('Nova Senha', response.message).then(() => {
						self.router.routeReuseStrategy.shouldReuseRoute = function() {
							return false; 
						};
						self.router.navigateByUrl("/" + self.backPage + "/" + self.form.id);
					});
				}
			});
		}
	}

	readThis(inputValue: any): void {
		super.readThis(inputValue, "picture");
	}

	userHasPermission(permission: string) {
		for (const item of this.form.Permissions) {
			if (item.permission == permission) {
				return true;
			}
		}

		return false;
	}

	permissionsChanged(array: Permission[]) {
		const self = this;

		self.form.Permissions = array.map((x) => {
			return { permission: x.name };
		}) as any;
	}

	userNotificationsChange(array: NotificationSetting[]) {
		const self = this;

		self.form.NotificationSettings = array.map((x) => {
			return { notification: x.name, checked: x.checked };
		}) as any;
	}

	teamSelected(user) {
		this._teamSelected = user;
	}

	addTeam() {
		const self = this;

		if (!self.form.Teams) {
			self.form.Teams = [];
		}

		for (const item of self.form.Teams) {
			if (item.id == self._teamSelected.id) {
				return false;
			}
		}

		self.form.Teams.push(self._teamSelected);
	}

	removeTeam(i) {
		const self = this;

		if (!self.form.Teams) {
			self.form.Teams = [];
		}

		self.form.Teams.splice(i, 1);
	}


	modalVisible = false;

	profileCreated(event){
		const self = this;

		if(event.id){
			self.profiles.push(event);
			self.form.profileId = event.id;
		}

		self.modalVisible = false;
	}

	async submitForm(callbackAlert: "toast" | "swal" = "swal", reloadWindow = true) {
		const self = this;

		self.sendToApi().subscribe((response) => {
			if (callbackAlert == "swal") {
				if (self.id) {
					swal
						.fire({
							icon: "success",
							title: `Sucesso`,
							text: response.message,
							showCancelButton: true,
							cancelButtonText: "Voltar",
							confirmButtonText: "Continuar Editando"
						})
						.then((result) => {
							if (result.value) {
								if (reloadWindow) {
									window.location.reload();
								} else {
									self.ngOnInit();
								}
							} else {
								self.router.navigateByUrl("/" + self.backPage);
							}
						});
				} else {
					self.app.alert(`Sucesso`, response.message, `success`).then(() => {
						self.router.routeReuseStrategy.shouldReuseRoute = function() {
							return false; 
						};
						self.router.navigateByUrl("/" + self.backPage + "/" + response.return.id);
					});
				}
			}
		});
	}
}
