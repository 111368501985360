import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";

@Component({
	selector: "durinn-thead-filter-date-order",
	templateUrl: "./filter-date-order.component.html",
	styleUrls: ["./filter-date-order.component.css"],
})
export class FilterDateOrderComponent implements OnInit {
  @Input() placeholder = "";
  @Input() type = "text";
  @Input() mask = undefined;
  @Input() name = "";
  @Input() order = [["name", "asc"]];
  @Input() dropSpecialCharacters = false;

  @Output() enter = new EventEmitter();
  @Output() orderChanged = new EventEmitter();

  input = "";
  selected = false;
  _order = "asc";
  isVisible = false;

  _type = "date";

  de = "";
  ate = "";

  constructor() {}

  ngOnInit() {
  	// this.selected = this.order[0][0] == this.name;
  	// this._order = this.order[0][1];
  }

  ngOnChanges() {
  	this.selected =
      this.order && this.order[0] && this.order[0][0] == this.name;
  	this._order = this.order && this.order[0] && this.order[0][1];
  }

  changed() {
  	this.enter.emit(this.input);
  }

  invalid() {
  	const self = this;

  	if (self._type == "date" && [10, 13, 16].indexOf(self.de.length) > -1) {
  		return false;
  	}

  	if (
  		self._type == "range" &&
      [10, 13, 16].indexOf(self.de.length) > -1 &&
      [10, 13, 16].indexOf(self.ate.length) > -1
  	) {
  		return false;
  	}

  	return true;
  }

  handleOk() {
  	const self = this;

  	// 00/00/0000 00:00

  	if (self._type == "date") {
  		if (self.de.length == 10) {
  			self.enter.emit({
  				"Op.like": moment(self.de, "DD/MM/YYYY").format("YYYY-MM-DD") + "%",
  			});
  			self.isVisible = false;
  		}

  		if (self.de.length == 13) {
  			self.enter.emit({
  				"Op.like":
            moment(self.de, "DD/MM/YYYY HH").format("YYYY-MM-DD HH") + "%",
  			});
  			self.isVisible = false;
  		}

  		if (self.de.length == 16) {
  			self.enter.emit({
  				"Op.like":
            moment(self.de, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm") +
            "%",
  			});
  			self.isVisible = false;
  		}

  		self.input = self.de;
  	}

  	if (self._type == "range") {
  		const between = [];

  		if (self.de.length == 10) {
  			between.push(
  				moment(self.de, "DD/MM/YYYY").format("YYYY-MM-DD 00:00:00")
  			);
  		}

  		if (self.de.length == 13) {
  			between.push(
  				moment(self.de, "DD/MM/YYYY HH").format("YYYY-MM-DD HH:00:00")
  			);
  		}

  		if (self.de.length == 16) {
  			between.push(
  				moment(self.de, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:00")
  			);
  		}

  		if (self.ate.length == 10) {
  			between.push(
  				moment(self.ate, "DD/MM/YYYY").format("YYYY-MM-DD 23:59:59")
  			);
  		}

  		if (self.ate.length == 13) {
  			between.push(
  				moment(self.ate, "DD/MM/YYYY HH").format("YYYY-MM-DD HH:59:59")
  			);
  		}

  		if (self.ate.length == 16) {
  			between.push(
  				moment(self.ate, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:59")
  			);
  		}

  		self.input = self.de + " - " + self.ate;
  		self.enter.emit({ "Op.between": between });
  		self.isVisible = false;
  	}
  }

  orderEmit() {
  	this.orderChanged.emit([
  		[this.name, this._order == "asc" ? "desc" : "asc"],
  	]);
  }
}
