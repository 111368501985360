import { Injectable, Injector, NgZone, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserInterface } from "@backend-interfaces/models";
import { Api, Io } from "@durinn/v.3/helpers/api";
import { App } from "@durinn/v.3/helpers/app";
import { Me } from "@durinn/v.3/helpers/me";
import { SocketIo } from "@durinn/v.3/helpers/SocketIo";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import * as uniqid from 'uniqid';
import {ApiCallsManager} from "@durinn.v3/helpers/ApiCallsManager";
import {Variables} from "@durinn.v3/helpers/Variables";
import {ThermalPrinter} from "@durinn.v3/helpers/ThermalPrinter";

@Injectable()
export class PageBase implements OnInit, OnDestroy {
	public user: UserInterface | {} = {};
	public user_permissions: {
    [s: string]: {
      field: string | null;
      key: string | null;
      permission: string;
    };
  } = {};

	public durinnTab = '';
	public window = window;
	public moment = moment;
	public Object = Object;
	public subscriptions: { [name: string]: Subject<any> } = {};

	protected api: Api;
	protected apiCallsManager: ApiCallsManager;
	protected me: Me;
	protected app: App;
	protected activatedRoute: ActivatedRoute;
	protected router: Router;
	protected ngZone: NgZone;
	protected toastr: ToastrService;
	protected systemVariables: Variables;
	protected thermalPrinter: ThermalPrinter;

	constructor(injector: Injector) {
		const self = this;

		self.api = injector.get(Api);
		self.apiCallsManager = injector.get(ApiCallsManager);
		self.me = injector.get(Me);
		self.app = injector.get(App);
		self.activatedRoute = injector.get(ActivatedRoute);
		self.router = injector.get(Router);
		self.ngZone = injector.get(NgZone);
		self.toastr = injector.get(ToastrService);
		self.systemVariables = injector.get(Variables);
		self.thermalPrinter = injector.get(ThermalPrinter);

		self.me.get().subscribe((user) => {
			if (user) {
				self.user = user;
				for (const item of user.Permissions || []) {
					self.user_permissions[item.permission] = item;
				}
		  console.log('this.user_permissions', this.user_permissions);
				self.permissionsLoaded();
				self.userLoaded(user);
			} else {
				self.userNotLoaded();
			}
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.socketUnsubscribeAll();
	}

	userLoaded(user: UserInterface) {
	}

	userNotLoaded() {}

	permissionsLoaded() {}

	hasPermission(field): boolean {
		return !!(this.user_permissions[field] || this.user_permissions.MASTER);
	}

	socketUnsubscribeAll() {
		for (const event in this.subscriptions) {
			SocketIo.off(event);
			
			try {
				this.subscriptions[event].unsubscribe();
				console.log(`ngOnDestroy`, `unsubscribing`, event);
			} catch (e) {
				console.log(`ngOnDestroy`, `unsubscribe error`, e, event);
			}
		}
	}

	socketOn(event: string, subscribe: (args: any) => void): Subject<any> {
		const self = this;

		if (
			self.subscriptions[event] &&
      typeof self.subscriptions[event].subscribe === "function"
		) {
			self.subscriptions[event].subscribe((args) => {
				self.ngZone.run(() => {
					subscribe(args);
				});
			});
		} else {
			console.log("new listener", event);
			self.subscriptions[event] = SocketIo.on(event).subscribe((args) => {
				self.ngZone.run(() => {
					subscribe(args);
				});
			});
		}

		return self.subscriptions[event];
	}

	/*
   * Essa função faz com que antes de dar subscribe a um socket.Io o sistema
   * se desinscreva antes. Tornando-a segura para funções que são executadas
   * mais de uma vez pelos sistemas.
   */
	socketRepeatController: {[event: string]: string} = {};
	socketSafeOn(event: string, subscribe: (args: any) => void, repeatController = true): Subject<any> {
		const self = this;

		if (self.subscriptions[event]) {
			self.subscriptions[event].unsubscribe();
			console.log("unsubscribe", event);
		}

		if (
			self.subscriptions[event] &&
      typeof self.subscriptions[event].subscribe === "function"
		) {
			self.subscriptions[event].subscribe((args) => {
				self.ngZone.run(() => {
					if(repeatController){
						const uniq = uniqid();
						self.socketRepeatController[event] = uniq;

						// Esse código impede que o mesmo socket seja recebido multiplas vezes em menos de um segundo
						// E a função que ele chama também seja executada multiplas vezes
						setTimeout(() => {
							if(self.socketRepeatController[event] == uniq){
								subscribe(args);
							}
						}, 1000);
					}else{
						subscribe(args);
					}
				});
			});
		} else {
			console.log("new listener", event);
			self.subscriptions[event] = SocketIo.on(event).subscribe((args) => {
				self.ngZone.run(() => {
					if(repeatController){
						const uniq = uniqid();
						self.socketRepeatController[event] = uniq;

						// Esse código impede que o mesmo socket seja recebido multiplas vezes em menos de um segundo
						// E a função que ele chama também seja executada multiplas vezes
						setTimeout(() => {
							if(self.socketRepeatController[event] == uniq){
								subscribe(args);
							}
						}, 1000);
					}else{
						subscribe(args);
					}
				});
			});
		}

		return self.subscriptions[event];
	}

	/**
	 * Obtem uma variável de configuração do sistema do cliente
	 * @param variable
	 * @param call
	 */
	async getSystemVariable(variable: string, call ?: Io): Promise<any>{
		const self = this;
		
		if(call){
			call.loading = true;
		}
		
		if(self.systemVariables.loading){
			await self.systemVariables.loading;
		}
		
		call.loading = false;
		
		const item = self.systemVariables.variables[variable];
		return item ? item.variable : null;
	}

	/**
	 * Salva uma variável de configuração do sistema do cliente
	 * @param variable
	 * @param content
	 * @param call
	 */
	saveSystemVariable(variable: string, content: string | number | null, call ?: Io): Promise<any>{
		const self = this;
		return self.systemVariables.save(variable, content, call);
	}
}
