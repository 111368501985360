import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";

type device = { name: string; quantity: number; price: number };
type code = { value: number; count: number };

@Component({
	selector: "financeiro-aparelhos-por-dia",
	templateUrl: "./aparelhos-por-dia.component.html",
	styleUrls: ["./aparelhos-por-dia.component.css"],
})
export class AparelhosPorDiaComponent implements OnInit {
	@Input() data = null;

	items: { day: string; devices: device[]; codes: code[] }[] = [];

	total = 0;
	moment = moment;
	Object = Object;

	constructor() {}

	ngOnInit() {}

	ngOnChanges() {
		this.calc();
	}

	header = {
		codes: {},
		devices: {},
	};

	calc() {
		const self = this;
		self.items = [];

		if (!self.data) {
			return false;
		}

		self.items = Object.values(self.data);

		for (const item of self.items) {
			for (const code in item.codes) {
				self.header.codes[code] = { value: 0, count: 0 };
			}

			for (const device in item.devices) {
				self.header.devices[device] = { value: 0, count: 0 };
			}
		}

		console.log(`self.items`, self.items);
	}
}
