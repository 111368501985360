import { BasePage } from "../../../../../bases/base.page";
import { Component } from "@angular/core";
import * as moment from "moment";
import "moment/locale/pt-br";

type Sicknote = {
	id: number;
	date: Date;
	time: string;
	file: string | null;
};

type DistanceReturn = {
	distance: number;
	registeredBy: "ADMIN" | "PHONE";
};

type TimeRegister = {
	id_time: number;
	register_date: Date;
	entry_date: Date | null;
	entry_latitude: number | null;
	entry_longitude: number | null;
	entry_odometer: number | null;
	entry_note: string | null;
	departure_date: Date | null;
	departure_latitude: number | null;
	departure_longitude: number | null;
	departure_odometer: number | null;
	departure_note: string | null;
	deleted: boolean;
	error: boolean;
	error_description: string | null;
};

type ClosingReportResponse = {
	[date: string]: {
		date: string;
		holiday: boolean;
		absence: boolean;
		total: number;
		traveled_km: DistanceReturn;
		times: TimeRegister[];
		sicknote: Sicknote | null;
	};
};

@Component({
	selector: "app-relatorio-de-fechamento",
	templateUrl: "./relatorio-de-fechamento.component.html",
	styleUrls: ["./relatorio-de-fechamento.component.css"],
})
export class RelatorioDeFechamentoComponent extends BasePage {
	public tab = "closing-report";
	public route = "";
	public id = null;
	public user: any = null;
	public report: any = {};
	public moment = moment;
	public Math = Math;
	public objectKeys = Object.keys;
	public objectValues = Object.values;

	public form = {
		from: moment().subtract(1, "month").format("21/MM/YYYY"),
		to: moment().format("20/MM/YYYY"),
		user: "",
	};

	protected call = this.api.new();
	protected load = this.api.new();
	protected loadReport = this.api.new();
	protected loadHourBank = this.api.new();
	protected loadGeralHourBank = this.api.new();
	protected actionCall = this.api.new().set("success", true);

	ngOnInit() {
		const self = this;

		self.route = this.activatedRoute.snapshot.data["type"];
		self.id = this.activatedRoute.snapshot.params["id"];

		self.loadGeralHourBank
			.get(
				`${self.route}/${self.id}/hour-bank/${moment()
					.subtract(6, "month")
					.format("YYYY-MM-DD")}/${moment().format("YYYY-MM-DD")}`,
				{}
			)
			.subscribe((data) => {
				self.geral_hour_bank = data.return;
			});

		self.getUser();
		self.getReport();
	}

	async getUser() {
		const self = this;
		self.load.get(self.route + "/" + self.id).subscribe((response) => {
			const data = response.return;

			if (data) {
				data.variables = {};
				self.user = data;
			}
		});
	}

	times = [0, 1];
	hour_bank = { seconds: 0, entry_date: null, departure_date: null };
	geral_hour_bank = { seconds: 0, entry_date: null, departure_date: null };
	total_km = 0;

	groupTimeRegister(){
		const self = this;

		self.loadReport.post(
			`${self.route}/${self.id}/reports/closing-report/${moment(
				self.form.from,
				"DD/MM/YYYY"
			).format("YYYY-MM-DD")}/${moment(
				self.form.to,
				"DD/MM/YYYY"
			).format("YYYY-MM-DD")}/agroup`,
			{}
		).subscribe((data) => {
			self.getReport();
		});
	}

	async getReport() {
		const self = this;
		this.loadReport
			.get(
				`${self.route}/${self.id}/reports/closing-report/${moment(
					self.form.from,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD")}/${moment(
					self.form.to,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD")}`,
				{}
			)
			.subscribe((data) => {
				console.log(`data`, data.return);

				self.report = data.return;

				const values: any = Object.values(self.report);

				self.times = [0, 1];
				for (const day of values) {
					if (day.times.length > self.times.length) {
						self.times = Array(day.times.length).fill(
							day.times.length
						);
					}

					self.total_km += day.traveled_km.distance;
				}
			});

		this.loadHourBank
			.get(
				`${self.route}/${self.id}/hour-bank/${moment(
					self.form.from,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD")}/${moment(
					self.form.to,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD")}`,
				{}
			)
			.subscribe((data) => {
				self.hour_bank = data.return;
			});
	}

	rowStyle(day) {
		if (day.total > 60 * 60 * 10) {
			return { "background-color": "rgba(255,0,0,0.10)" };
		}

		if (day.total > 60 * 60 * 8.5) {
			return { "background-color": "rgba(255,127,80,0.1)" };
		}

		if (day.holiday) {
			return { "background-color": "rgba(186,85,211,0.1)" };
		}

		if (moment(day.date).isoWeekday() == 7) {
			return { "background-color": "#efefef" };
		}
	}

	public sicknote = {
		id: null,
		visible: false,
		date: null,
		userId: null,
		adminId: null,
		time: "",
	};
	editSicknote(date: string, sicknote: Sicknote | null) {
		this.sicknote.date = moment(date).format("DD/MM/YYYY");
		this.sicknote.time = sicknote ? sicknote.time : "";
		this.sicknote.id = sicknote ? sicknote.id : null;
		this.sicknote.visible = true;
	}
	sendSicknote() {
		const self = this;
		this.sicknote.date = moment(this.sicknote.date, "DD/MM/YYYY").toDate();

		this.sicknote.userId = null;
		this.sicknote.adminId = null;

		if (self.route == "deliverymen") {
			self.sicknote.userId = self.id;
		} else {
			self.sicknote.adminId = self.id;
		}

		if (self.sicknote.id) {
			self.actionCall
				.put("sicknote/" + self.sicknote.id, self.sicknote)
				.subscribe((data) => {
					self.sicknote.visible = false;
					self.getReport();
				});
		} else {
			self.actionCall
				.post("sicknote", self.sicknote)
				.subscribe((data) => {
					self.sicknote.visible = false;
					self.getReport();
				});
		}
	}
	deleteSicknote(id) {
		const self = this;
		if (confirm("Você tem certeza?")) {
			self.actionCall.delete("sicknote/" + id).subscribe((data) => {
				self.sicknote.visible = false;
				self.getReport();
			});
		}
	}

	public note = {
		id: null,
		day: null,
		userId: null,
		adminId: null,
		note: "",
		visible: false,
	};
	editNote(date: string, note: any | null) {
		this.note.day = moment(date).format("DD/MM/YYYY");
		this.note.note = note ? note.note : "";
		this.note.id = note ? note.id : null;
		this.note.visible = true;
	}
	sendNote() {
		const self = this;
		this.note.day = moment(this.note.day, "DD/MM/YYYY")
			.startOf("day")
			.toDate();

		this.note.userId = null;
		this.note.adminId = null;

		if (self.route == "deliverymen") {
			self.note.userId = self.id;
		} else {
			self.note.adminId = self.id;
		}

		if (self.note.id) {
			self.actionCall
				.put("time-register-note/" + self.note.id, self.note)
				.subscribe((data) => {
					self.note.visible = false;
					self.getReport();
				});
		} else {
			self.actionCall
				.post("time-register-note", self.note)
				.subscribe((data) => {
					self.note.visible = false;
					self.getReport();
				});
		}
	}
	deleteNote(id) {
		const self = this;
		if (confirm("Você tem certeza?")) {
			self.actionCall
				.delete("time-register-note/" + id)
				.subscribe((data) => {
					self.note.visible = false;
					self.getReport();
				});
		}
	}

	public traveledKm = {
		visible: false,
		traveled_km: 0,
		date: null,
		user_id: null,
	};

	editTraveledKm(date: string, distance: number | null) {
		this.traveledKm.date = moment(date).format("DD/MM/YYYY");
		this.traveledKm.traveled_km = distance;
		this.traveledKm.visible = true;
	}

	sendTraveledKm() {
		const self = this;

		self.traveledKm.date = moment(
			this.traveledKm.date,
			"DD/MM/YYYY"
		).toDate();
		self.traveledKm.user_id = self.id;

		self.actionCall
			.post("traveledkm", self.traveledKm)
			.subscribe((data) => {
				self.traveledKm.visible = false;
				self.getReport();
			});
	}

	public horary = {
		id_time: null,
		entry_date: null,
		departure_date: null,
		user_id: null,
		admin_id: null,
		visible: false,
		alteredByNote: "",
	};

	editHorary(date: string, horary: any | null) {
		if (horary) {
			this.horary.id_time = horary.id_time;
			this.horary.entry_date = moment(horary.entry_date).format(
				"DD/MM/YYYY HH:mm"
			);
			this.horary.departure_date = moment(horary.departure_date).format(
				"DD/MM/YYYY HH:mm"
			);
		} else {
			this.horary.id_time = null;
			this.horary.entry_date = moment(date).format("DD/MM/YYYY 00:00");
			this.horary.departure_date =
				moment(date).format("DD/MM/YYYY 00:00");
		}
		this.horary.visible = true;
	}

	sendHorary() {
		const self = this;

		this.horary.user_id = null;
		this.horary.admin_id = null;

		if (self.route == "deliverymen") {
			self.horary.user_id = self.id;
		} else {
			self.horary.admin_id = self.id;
		}

		this.horary.entry_date = moment(
			this.horary.entry_date,
			"DD/MM/YYYY HH:mm"
		).toDate();
		this.horary.departure_date = moment(
			this.horary.departure_date,
			"DD/MM/YYYY HH:mm"
		).toDate();

		if (self.horary.id_time) {
			self.actionCall
				.put("time-register/" + self.horary.id_time, self.horary)
				.subscribe((data) => {
					self.horary.visible = false;
					self.getReport();
				});
		} else {
			self.actionCall
				.post("time-register", self.horary)
				.subscribe((data) => {
					self.horary.visible = false;
					self.getReport();
				});
		}
	}

	deleteHorary(id) {
		const self = this;
		if (confirm("Você tem certeza?")) {
			self.actionCall.delete("time-register/" + id).subscribe((data) => {
				self.horary.visible = false;
				self.getReport();
			});
		}
	}

	absense(bool: boolean, date) {
		const self = this;
		if (bool) {
			self.actionCall
				.delete(
					`${self.route}/${self.id}/absence/${moment(
						date
					).toISOString()}`,
					{}
				)
				.subscribe((data) => {
					self.getReport();
				});
		} else {
			self.actionCall
				.post(
					`${self.route}/${self.id}/absence/${moment(
						date
					).toISOString()}`,
					{}
				)
				.subscribe((data) => {
					self.getReport();
				});
		}
	}

	ignoreHourBank(bool: boolean, date: string) {
		const self = this;
		if (bool) {
			self.actionCall
				.delete(
					`${self.route}/${self.id}/ignore-hour-bank/${moment(
						date
					).toISOString()}`,
					{}
				)
				.subscribe(() => {
					self.getReport();
				});
		} else {
			self.actionCall
				.post(
					`${self.route}/${self.id}/ignore-hour-bank/${moment(
						date
					).toISOString()}`,
					{}
				)
				.subscribe(() => {
					self.getReport();
				});
		}
	}

	async getExcel() {
		const self = this;
		const data = (await self.me.getCacheData()) as any;
		if (data) {
			window.location.href =
				this.api.url +
				`${self.route}/${
					self.id
				}/reports/closing-report/file/${moment(
					self.form.from,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD")}/${moment(
					self.form.to,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD")}?_authusername=${btoa(
					data.cpf
				)}&_authpassword=${btoa(data.password)}`;
		}
	}

	holiday(bool: boolean, date) {
		const self = this;

		const obj = {
			userId: null,
			adminId: null,
			day: moment(date.date).startOf("day").toDate(),
		};

		// time-register-holiday

		if (self.route == "deliverymen") {
			obj.userId = self.id;
		} else {
			obj.adminId = self.id;
		}

		console.log(`date.holiday`, date.holiday);

		if (bool) {
			self.actionCall
				.delete(`time-register-holiday/${date.holiday.id}`, {})
				.subscribe((data) => {
					self.getReport();
				});
		} else {
			self.actionCall
				.post(`time-register-holiday`, obj)
				.subscribe((data) => {
					self.getReport();
				});
		}
	}

	getPrintReport() {
		const self = this;

		self.call
			.get(
				this.api.url +
					`${self.route}/${
						self.id
					}/reports/closing-report/pdf/${moment(
						self.form.from,
						"DD/MM/YYYY"
					).format("YYYY-MM-DD")}/${moment(
						self.form.to,
						"DD/MM/YYYY"
					).format("YYYY-MM-DD")}`
			)
			.subscribe((response) => {
				if (response.return) {
					window.location.href = response.return;
				}
			});
	}

	getTotalSeconds(column) {
		const self = this;

		let totalMinutes = 0;

		for (const date of self.objectValues(self.report)) {
			const arr = (date as any).formated[column].split(":");
			totalMinutes += +arr[0] * 60;
			totalMinutes += +arr[1];
		}

		return totalMinutes * 60;
	}

	getHoursBankPaymentsTotalSeconds() {
		const self = this;
		let totalMinutes = 0;

		for (const date of self.objectValues(self.report)) {
			totalMinutes += (date as any).minutes.hoursBankPayments;
		}

		return totalMinutes * 60;
	}

	getTotalNormalHours() {
		const self = this;

		let totalMinutes = 0;

		for (const date of self.objectValues(self.report) as any) {
			totalMinutes +=
				date.minutes.normal > date.minutes.expected
					? +date.minutes.expected
					: +date.minutes.normal;
		}

		let totalSeconds = totalMinutes * 60;
		const hours = Math.floor(totalSeconds / 3600);

		totalSeconds %= 3600;

		const minutes = Math.floor(totalSeconds / 60);
		const seconds = totalSeconds % 60;

		return (
			hours.toString().padStart(2, "0") +
			":" +
			minutes.toString().padStart(2, "0")
		);
	}

	getTotalFormated(column) {
		const self = this;

		let totalMinutes = 0;

		for (const date of self.objectValues(self.report)) {
			const arr = (date as any).formated[column].split(":");
			totalMinutes += +arr[0] * 60;
			totalMinutes += +arr[1];
		}

		let totalSeconds = totalMinutes * 60;
		const hours = Math.floor(totalSeconds / 3600);

		totalSeconds %= 3600;

		const minutes = Math.floor(totalSeconds / 60);
		const seconds = totalSeconds % 60;

		return (
			hours.toString().padStart(2, "0") +
			":" +
			minutes.toString().padStart(2, "0")
		);
	}

	getTotalBalance() {
		const OtotalSeconds =
			this.getTotalSeconds("extra50") +
			this.getTotalSeconds("extra100") -
			this.getTotalSeconds("left") -
			this.getHoursBankPaymentsTotalSeconds();

		let totalSeconds = Math.abs(OtotalSeconds);
		const hours = Math.floor(totalSeconds / 3600);

		totalSeconds %= 3600;

		const minutes = Math.floor(totalSeconds / 60);
		const seconds = totalSeconds % 60;

		return (
			(OtotalSeconds < 0 ? "-" : "+") +
			hours.toString().padStart(2, "0") +
			":" +
			minutes.toString().padStart(2, "0")
		);
	}
}
