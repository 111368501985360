import { Component, Input, OnInit } from "@angular/core";
import { ChartOptions, } from "chart.js";
import * as moment from "moment";

@Component({
	selector: "financeiro-grafico-dia",
	templateUrl: "./grafico-dia.component.html",
	styleUrls: ["./grafico-dia.component.css"],
})
export class GraficoDiaComponent implements OnInit {
	@Input() data = null;
	@Input() previousData = null;

	total = 0;

	constructor() {}

	ngOnInit() {
		this.calc();
	}

	ngOnChanges() {
		this.calc();
	}

	calc() {
		const self = this;

		self.stackedAreaChartLabels = [];
		self.stackedAreaChartData = [
			{
				data: [],
				label: "Mês passado",
				fill: false,
				borderColor: "#999",
				pointBorderColor: "#999",
				pointBackgroundColor: "#999",
			},
			{
				data: [],
				label: "Este Mês",
				fill: false,
				borderColor: "#0474BA",
				pointBorderColor: "#0474BA",
				pointBackgroundColor: "#0474BA",
			},
		];

		if (self.data) {
			let lastDay;
			for (const day in self.data) {
				lastDay = moment(day);
				self.stackedAreaChartLabels.push(moment(day).format("DD/MM"));
				self.stackedAreaChartData[1].data.push(self.data[day].total);
			}

			self.stackedAreaChartData[1].label = lastDay.format("MM/YYYY");
		}

		if (self.previousData) {
			let lastDay;
			for (const day in self.previousData) {
				lastDay = moment(day);
				self.stackedAreaChartData[0].data.push(
					self.previousData[day].total
				);
			}
			self.stackedAreaChartData[0].label = lastDay.format("MM/YYYY");
		}
	}

	//Stacked Area Chart
	stackedAreaChartData: Array<any> = [
		{
			data: [],
			label: "Mês passado",
			fill: false,
			borderColor: "#999",
			pointBorderColor: "#999",
			pointBackgroundColor: "#999",
		},
		{
			data: [],
			label: "Este Mês",
			fill: false,
			borderColor: "#0474BA",
			pointBorderColor: "#0474BA",
			pointBackgroundColor: "#0474BA",
		},
	];
	stackedAreaChartLabels: Array<any> = [];
	stackedAreaChartLegend = true;
	stackedAreaChartType = "line";
	chartOptions: ChartOptions & { annotation?: any } = {
		responsive: true,
		maintainAspectRatio: false,
		tooltips: {
			mode: "index",
			callbacks: {
				label: function (tooltipItem, data) {
					return (
						data.datasets[tooltipItem.datasetIndex].label +
						": R$ " +
						Number(tooltipItem.yLabel)
							.toFixed(0)
							.replace(/./g, function (c, i, a) {
								return i > 0 &&
									c !== "." &&
									(a.length - i) % 3 === 0
									? "." + c
									: c;
							})
					);
				},
			},
		},
		legend: {
			position: "bottom",
		},
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
						callback: function (value: string, index, values) {
							if (parseInt(value) >= 1000) {
								return (
									"R$ " +
									value
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
								);
							} else {
								return "R$ " + value;
							}
						},
					},
				},
			],
		},
	};
}
