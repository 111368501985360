import { Component, OnInit } from "@angular/core";
import { BasePage } from "../../../../bases/base.page";
import * as moment from "moment";

@Component({
	selector: "app-rendimento-dos-tecnicos",
	templateUrl: "./rendimento-dos-tecnicos.component.html",
	styleUrls: ["./rendimento-dos-tecnicos.component.css"],
})
export class RendimentoDosTecnicosComponent extends BasePage {
	clusterId = null;
	from = moment().format("DD/MM/YYYY");
	to = moment().format("DD/MM/YYYY");

	load = this.api.new();

	Math = Math;
	Object = Object;

	ngOnInit() {
		this.get();
	}

	data;

	sent = { from: null, to: null };

	get() {
		this.sent.from = this.from;
		this.sent.to = this.to;

		this.load
			.get(
				`deliverymen/reports/performance/${moment(
					this.from,
					"DD/MM/YYYY"
				).toISOString()}/${moment(
					this.to,
					"DD/MM/YYYY"
				).toISOString()}/${this.clusterId || ""}`
			)
			.subscribe((data) => {
				console.log(`data.return`, data.return);
				this.data = data.return;
			});
	}

	getDeliverymanList() {
		const self = this;
		const response = [];

		for (const item of Object.values(
			(self.data || {}).deliverymen || {}
		) as any[]) {
			if (item.total) {
				response.push(item);
			}
		}

		response.sort(function (a, b) {
			if (a.total < b.total) {
				return -1;
			}
			if (a.total > b.total) {
				return 1;
			}
			return 0;
		});

		return response;
	}

	getAverage() {
		const self = this;
		const sum = self
			.getDeliverymanList()
			.map((x) => x.total)
			.reduce((a, b) => a + b, 0);
		const avg = sum / self.getDeliverymanList().length || 0;
		return avg;
	}
	
	generateDescriptive(id: number){
		this.load
			.get(
				`deliverymen/reports/performance/descriptive/${moment(
					this.from,
					"DD/MM/YYYY"
				).toISOString()}/${moment(
					this.to,
					"DD/MM/YYYY"
				).toISOString()}/${id}`
			)
			.subscribe((data) => {
				window.location.href = data.return.link;
			});
	}
}
