import { Component, OnInit } from "@angular/core";
import {
	EditPage,
	EditPageInterface,
} from "../../../../../bases/edit.page";
import * as moment from "moment";
import $ from "jquery";
import { DELIVERY_STATUS } from "../../../../../constants";

@Component({
	selector: "app-agenda",
	templateUrl: "./agenda.component.html",
	styleUrls: ["./agenda.component.css"],
})
export class AgendaComponent extends EditPage implements EditPageInterface {
	form: any = {
		Devices: [],
		Barcodes: [],
	};
	ngOnInit(): void {
		super.ngOnInit();
		this.loadTries();
	}

	moment = moment;
	Object = Object;

	tries = [];

	status = DELIVERY_STATUS;

	permissions = {
		EDIT_USERS: false,
		MASTER: false,
	};

	back_to = "/agendas";
	route = "delivery";
	
	conclude = false;
	terminate = false;
	reversion = false;

	afterGet() {
		const self = this;

		const arr = [];
		for (const item of self.form.Devices || []) {
			if (item.barcode) {
				arr.push(item.barcode);
			}
		}
	}

	private async loadTries() {
		const self = this;

		self.call
			.get("delivery/" + self.id + "/tries")
			.subscribe((response) => {
				if (response.success) {
					self.tries = response.return || [];
				}
			});
	}

	public deliverySaved() {
		this.ngOnInit();
		$("#editCloseButton").click();
	}

	updateGeofence() {
		const self = this;
		self.call
			.put(self.route + "/" + this.id, this.form)
			.subscribe((response) => {
				self.app
					.alert(`Sucesso`, response.message, `success`)
					.then(() => self.ngOnInit());
			});
	}
}
