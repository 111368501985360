import { Component, EventEmitter, Output } from "@angular/core";
import { CrudPage } from "../../../bases/crud.page";
import { Io } from "@durinn.v3/helpers/api";

@Component({
	selector: "app-device-type-select",
	templateUrl: "./device-type-select.component.html",
	styleUrls: ["./device-type-select.component.css"],
})
export class DeviceTypeSelectComponent extends CrudPage {
	items = [];
	filters = {
		DeviceType: {
			name: "",
		},
	};

	order = {
		DeviceType: [["name", "asc"]],
	};

	call: Io;
	delete_call: Io;
	route = "devicetype";

	newItem = "";
	indexSelected = 0;
	limit = 200;

	@Output() itemChanged = new EventEmitter();
	@Output() nameChanged = new EventEmitter();

	changed() {
		const item = this.items[this.indexSelected];
		this.itemChanged.emit(item);
		this.nameChanged.emit(item.name);
	}

	search(value: string): void {
		this.filters.DeviceType.name = value;
		this.get();
	}

	listLoaded() {
		this.changed();
	}

	addItem() {
		const self = this;

		self.call.post(self.route, { name: self.newItem }).subscribe((data) => {
			if (data.success) {
				self.app.alert(
					`Sucesso`,
					`Item adicionado com sucesso!`,
					`success`
				);
				self.items.push(data.return);
				self.indexSelected = self.items.length - 1;
			}
		});
	}
}
