import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from "@angular/core";
import {Api} from "@durinn/v.3/helpers/api";
import {columnHasValue} from "@durinn.v3/helpers/functions";
import {ApiCallsManager} from "@durinn.v3/helpers/ApiCallsManager";

@Component({
	selector: "durinn-model-select",
	templateUrl: "./model-select.component.html",
	styleUrls: ["./model-select.component.css"],
})
export class ModelSelectComponent implements OnInit {
	items = [];
	selectedUser: number | number[];
	
	@Input() call = this.api.new();
	@Input() break = false;
	@Input() limit = 10;
	@Input() reloadAfterChanges = false;
	@Input() loadAfterClick = true; // Só vai carregar as opções quando clicarmos no select

	@Input() mode: "default" | "multiple" = "default";
	@Output() selected = new EventEmitter();
	@Input() route: string;
	@Input() placeholder: string;
	@Input() model = "";
	@Input() filter: string | string[] = "name";
	@Input() endpoint = "search";
	@Input() firstLetterUpperCase = true;
	@Input() selectFirst = true;
	@Input() required = false;
	@Input() readOnly = false;
	@Input() value?: number;
	@Input() allowClear = true;
	@Input() canSelectNone = false;
	@Input() noneText = "Nenhum";
	@Input() noneValue = null;
	@Input() filters: any = {};

	@Input() labelTransform = (item, page?: ModelSelectComponent) => {
		const self = this;

		let label = '';

		if (typeof self.filter === 'string') {
			label = (item[self.filter] || '').toString();
		}else{
			label = (item[self.filter[0]] || '').toString();
		}

		if(item.active === false){
			label = `Inativo: ` + label;
		}

		return label;
	};

	// Nome da coluna com chave única para quando tiver o @Input() item não duplicar com o que vem do banco
	@Input() primaryKey = 'id';

	@Input() item: any | null = null;
	@Output() itemChange = new EventEmitter();

	constructor(private api: Api, private apiCallsManager: ApiCallsManager) {
		this.call = api.new();

		if (!this.model && this.route) {
			this.model = this.route;
		}
		if (!this.route && this.model) {
			this.route = this.model;
		}
	}

	ngOnInit() {
		const self = this;
		
		if(!self.selectedUser){
			self.selectedUser = this.mode === "multiple" ? [] : null;
		}
		
		if(!self.loadAfterClick || self.selectFirst){
			self.onSearch('' );
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		const self = this;
		
		if(!self.selectedUser){
			self.selectedUser = this.mode === "multiple" ? [] : null;
		}

		if (!this.model && this.route) {
			this.model = this.route;
		}
		if (!this.route && this.model) {
			this.route = this.model;
		}

		if (self.item) {
			self.preppendItem();
		}
		
		console.log('changes', changes);
		
		if(changes){
			if(
				(changes.route && changes.route.previousValue != changes.route.currentValue) ||
				(changes.filter && changes.filter.previousValue != changes.filter.currentValue) ||
				(changes.filters && JSON.stringify(changes.filters.previousValue) != JSON.stringify(changes.filters.currentValue))
			){
				self.onSearch('');
			}
		}
	}

	preppendItem() {
		const self = this;
		const items = JSON.parse(JSON.stringify(self.items));
		
		if(!self.item && self.mode == 'multiple'){
			self.item = [];
		}
		
		if(!self.selectedUser && self.mode == 'multiple'){
			self.selectedUser = [];
		}
		
		if(Array.isArray(self.item)){
			let different = false;
			
			const itemIds = self.item.map(x => x[self.primaryKey]);
			
			for(const id of itemIds){
				let found = false;
				
				for(const index of self.selectedUser as number[]){
					if(self.items[index][self.primaryKey] == id){
						found = true;
						break;
					}
				}
				
				if(!found){
					different = true; 
				}
			}
			
			if(different){
				self.selectedUser = [];
				
				for(let i=0;i<self.item.length;i++){
					self.selectedUser.push(i);
				}
				
				self.items = self.item;
			}
			
			for(const item of items || []){
				if(!columnHasValue(self.items, self.primaryKey, item[self.primaryKey])){
					self.items.push(item);
				}
			}
			
		}else{
			if(JSON.stringify(self.item) != JSON.stringify(self.items[self.selectedUser as number])){
				self.selectedUser = 0;
				self.items = [self.item];
			}
			
			for(const item of items || []){
				if(!columnHasValue(self.items, self.primaryKey, item[self.primaryKey])){
					self.items.push(item);
				}
			}
		}

	}
	
	_search = '';
	lastSearch = null;
	
	onSearch(value: string): void {
		const self = this;
		self._search = value;
		
		if(self._search == self.lastSearch){
			return; // Não faz nada se for a mesma busca
		}
		
		if(!self.items.length && !self._search) {
			self.doSearch(self._search);
			return;
		}
		
		setTimeout(() => {
			if(self._search == value){
				self.doSearch(self._search);
			}
		}, 1000);
	}

	doSearch(value: string): void {
		const self = this;

		if (self.break) {
			return;
		}
		
		self.lastSearch = value;

		let where = {};

		if (typeof self.filter === 'string') {
			where = {
				[self.filter]: {
					"Op.like": `%${value.split(" ").join("%")}%`,
				},
			};
		} else {
			const or = [];

			for (const item of self.filter) {
				or.push({
					[item]: {"Op.like": `%${value.split(" ").join("%")}%`},
				});
			}

			where = {'Op.or': or};
		}

		let whereValue = {};

		if (typeof self.filter === 'string') {
			whereValue = {
				[self.filter]: self.value,
			};
		} else {
			const or = [];

			for (const item of self.filter) {
				or.push({[item]: self.value});
			}

			whereValue = {'Op.or': or};
		}

		if (self.value) {

			self.apiCallsManager.call(
				self.call,
				'post',
				self.route + `/${this.endpoint}/0/1`,
				{
					where: {
						[this.firstLetterUpperCase
							? (this.model || "").charAt(0).toUpperCase() +
							(this.model || "").slice(1)
							: this.model]: {'Op.and': [self.filters, whereValue]},
					},
					order: {
						[this.firstLetterUpperCase
							? (this.model || "").charAt(0).toUpperCase() +
							(this.model || "").slice(1)
							: this.model || ""]: [self.filter],
					}
				}
			).subscribe((data2) => {
				self.apiCallsManager.call(
					self.call,
					'post',
					self.route + `/${this.endpoint}/0/` + self.limit,
					{
						where: {
							[this.firstLetterUpperCase
								? (this.model || "").charAt(0).toUpperCase() +
								(this.model || "").slice(1)
								: this.model]: {'Op.and': [self.filters, where]},
						},
						order: {
							[this.firstLetterUpperCase
								? (this.model || "").charAt(0).toUpperCase() +
								(this.model || "").slice(1)
								: this.model || ""]: [self.filter],
						}
					}).subscribe((data) => {
					self.items = data2.return.rows.concat(data.return.rows);

					if (self.items.length) {
						if (self.selectFirst) {
							self.selectedUser = 0;
						}

						if (self.item) {
							self.preppendItem();
						}

						if (self.selectFirst || self.item) {
							self.changed();
						}
					}
				});
			});
		} else {
			self.apiCallsManager.call(
				self.call,
				'post',
				self.route + `/${this.endpoint}/0/` + self.limit,
				{
					where: {
						[this.firstLetterUpperCase
							? (this.model || "").charAt(0).toUpperCase() +
							(this.model || "").slice(1)
							: this.model || ""]: {'Op.and': [self.filters, where]},
					},
					order: {
						[this.firstLetterUpperCase
							? (this.model || "").charAt(0).toUpperCase() +
							(this.model || "").slice(1)
							: this.model || ""]: [self.filter],
					}
				}
			).subscribe((data) => {
				self.items = data.return.rows;

				if (self.items.length) {
					if (self.selectFirst) {
						self.selectedUser = 0;
					}

					if (self.item) {
						self.preppendItem();
					}

					if (self.selectFirst || self.item) {
						self.changed();
					}
				}
			});
		}
	}

	changed() {
		const self = this;
		
		if (self.selectedUser === null) {
			self.selected.emit(null);
			self.itemChange.emit(null);
			return;
		}
		
		if (self.selectedUser === self.noneValue) {
			self.selected.emit(self.noneValue);
			self.itemChange.emit(self.noneValue);
			return;
		}
		
		if (JSON.stringify(self.selectedUser) === JSON.stringify([self.noneValue])) {
			self.selected.emit(self.noneValue);
			self.itemChange.emit(self.noneValue);
			return;
		}

		if (Array.isArray(self.selectedUser)) {
			const selecteds = [];

			for (const index of self.selectedUser) {
				const item = self.items[index];
				
				if(item == self.noneValue){
					self.selected.emit(self.noneValue);
					self.itemChange.emit(self.noneValue);
					return;
				}else{
					selecteds.push(item);
				}
			}
			
			console.log('selecteds', selecteds);

			self.selected.emit(selecteds);
			self.itemChange.emit(selecteds);
		} else {
			self.selected.emit(
				self.selectedUser !== null ? self.items[self.selectedUser] : null
			);
			self.itemChange.emit(
				self.selectedUser !== null ? self.items[self.selectedUser] : null
			);
		}
	}
}
