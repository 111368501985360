import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CrudPage } from "../../../bases/crud.page";
import { Io } from "@durinn.v3/helpers/api";

@Component({
	selector: "cluster-select",
	templateUrl: "./cluster-select.component.html",
	styleUrls: ["./cluster-select.component.css"],
})
export class ClusterSelectComponent extends CrudPage {
	@Input() disabled = false;
	@Input() clusterId: number;
	@Input() showAllOption = false;
	@Input() enterpriseId : number | null;
	@Output() change = new EventEmitter();

	items = [];
	filters = {
		Cluster: {
			name: null,
			description: null,
		},
	};

	order = {
		Cluster: [["name", "asc"]],
	};

	call: Io;
	delete_call: Io;
	route = "clusters";

	ngOnInit() {
		this.route = (this.enterpriseId) ? `clusters/${(this.enterpriseId)}` : `clusters`;
		super.ngOnInit();
	}

	ngOnChanges(){
		this.route = (this.enterpriseId) ? `clusters/${(this.enterpriseId)}` : `clusters`;
		this.get();
	}

	protected getFilters() {
		this.call.silent(true);
		return super.getFilters();
	}
}
