import { DELIVERY_STATUS, DELIVERY_TYPES } from "../../../../../constants";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BasePage } from "../../../../../bases/base.page";
import * as moment from "moment";

@Component({
	selector: "app-relatorio-de-performance",
	templateUrl: "./relatorio-de-performance.component.html",
	styleUrls: ["./relatorio-de-performance.component.css"],
})
export class RelatorioDePerformanceComponent extends BasePage {
  @Output() isVisibleChange = new EventEmitter();
  @Input() isVisible = false;

  search = {
  	codes: [],
  	types: [],
  	start: "",
  	end: "",
  	duplicateChecker: true
  };

  codes = [];
  moment = moment;
  call = this.api.new();
  types = DELIVERY_TYPES;

  ngOnInit() {
  	const self = this;

  	for (const i in DELIVERY_STATUS) {
  		self.codes.push({ code: i, label: DELIVERY_STATUS[i] });
  	}
  }

  isSearchDatesValid() {
  	const self = this;
  	const isStartDateValid = moment(self.search.start, "DD/MM/YYYY").isValid();
  	const isEndDateValid = moment(self.search.end, "DD/MM/YYYY").isValid();
  	return isStartDateValid && isEndDateValid;
  }

  send() {
  	const self = this;

  	self.call
  		.openLink("report/performance", {
  			start: moment(self.search.start, "DD/MM/YYYY").format("YYYY-MM-DD"),
  			end: moment(self.search.end, "DD/MM/YYYY").format("YYYY-MM-DD"),
  			codes: self.search.codes.join(","),
  			types: self.search.types.join(","),
  			duplicateChecker: self.search.duplicateChecker ? 1 : 0
  		})
  		.then(() => {
  			self.isVisibleChange.emit(false);
  			self.isVisible = false;
  		});
  }
}
