import { ThemeConstantService } from "@durinn/v.3/app/template/services/theme-constant.service";
import { NoLayoutComponent } from "@durinn/v.3/app/views/no-layout/no-layout.component";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TemplateModule } from "@template/template/template.module";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgZorroAntdModule, NZ_I18N, pt_BR } from "ng-zorro-antd";
import { NotLogged, Logged } from "@durinn.v3/helpers/auth-guard";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { ColorPickerModule } from "@iplab/ngx-color-picker";
import { BrowserModule } from "@angular/platform-browser";
import { DurinnModule } from "@durinn/v.3/durinn.module";
import { ImageToDataUrlModule } from "ngx-image2dataurl";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "@template/shared.module";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { registerLocaleData } from "@angular/common";
import { LOCALE_ID, NgModule } from "@angular/core";
import { NgxCurrencyModule } from "ngx-currency";
import { AppComponent } from "./app.component";
import ptBr from "@angular/common/locales/pt";
import { NgChartjsModule } from "ng-chartjs";
import { App } from "@durinn.v3/helpers/app";
import { Me } from "@durinn.v3/helpers/me";
import { Api } from "./durinn/helpers/api";
import { ToastrModule } from "ngx-toastr";
import { ChartsModule } from "ng2-charts";
import { AgmCoreModule } from "@agm/core";
import { NgxMaskModule } from "ngx-mask";
import {WithLayoutComponent} from "@views/with-layout/with-layout.component";

registerLocaleData(ptBr);

function getApiKey(){
	const api_pamela = `AIzaSyA3hAIk1wPS08yp9heLjbju_x4C-SatRqc`;
	const api_carlao = `AIzaSyA1XXAji3EiQE2iC79D-07TyHOis0swCaM`;
	const api_samuca = `AIzaSyCAQ-vFzk2PSFCbOmJk-5PgmMh59raNtgg`;
	
	const second = +(Math.floor(Date.now() / 1000)).toString().substr(-1);
	
	if(second % 3 === 0) {
		return api_pamela;
	}
	
	if(second % 2 === 0) {
		return api_carlao;
	}
	
	return api_samuca;
}

@NgModule({
	declarations: [AppComponent, NoLayoutComponent, WithLayoutComponent],
	imports: [
		AgmCoreModule.forRoot({apiKey: getApiKey()}),
		BrowserAnimationsModule,
		NgxMaskModule.forRoot(),
		ToastrModule.forRoot(),
		HttpClientJsonpModule,
		ImageToDataUrlModule,
		ReactiveFormsModule,
		AngularEditorModule,
		CurrencyMaskModule,
		ColorPickerModule,
		NgZorroAntdModule,
		NgxCurrencyModule,
		GooglePlaceModule,
		AppRoutingModule,
		HttpClientModule,
		NgChartjsModule,
		TemplateModule,
		BrowserModule,
		SharedModule,
		ChartsModule,
		DurinnModule,
		FormsModule,
	],
	providers: [
		ThemeConstantService,
		NotLogged,
		Logged,
		App,
		Api,
		Me,
		{
			provide: NZ_I18N,
			useValue: pt_BR,
		},
		{
			provide: LOCALE_ID,
			useValue: "pt-BR",
		}
	],
	bootstrap: [AppComponent],
	exports: [
	],
})
export class AppModule {}
