import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {PageBase} from "@durinn.v3/app/bases/page.base";
import {
	DurinnV2ListPageTableComponent,
	HeadColumns
} from "@durinn.v3/app/components/template/tables/list-page-table/list-page-table.component";

import * as moment from "moment";

export const Device_Scans_Type = {
	0: "Coletado / Com o Técnico",
	1: "Na Base",
	2: "Encaminhado CLARO",
	3: "Em Campo",
	4: "Entregue",
	5: "Inválido",
	6: "Na Caixa",
	7: "A Caminho - Base",
	8: "Entregue Na Base",
	9: "Lote Validado",
	10: "Lote Em Triagem",
	11: "Com Técnico - Perda",
	12: "Com Técnico - Inventário",
	13: "Baixa Indevida",
};

export const DEVICESTATUS_CATEGORY = {
	DISCONNECTION: 0,
	INSTALLATION: 1,
	TRANSLATION: {
		0: "Desconexão",
		1: "Instalação",
	},
};

export const Device_Scans_Type_Translate = {
	COLLECTED: "Coletados / Com o Técnico",
	IN_BASE: "Na Base",
	SENT_NET: "Enviados NET",
	DELIVERED: "Entregues",
	INVALID: "Inválido",
	IN_BOX: "Na Caixa",
	GOING_TO_BASE: "A Caminho - Base",
	DELIVERED_TO_BASE: "Entregue Na Base",
	VALIDATED_LOT: "Lote Validado",
	SCREENING_LOT: "Lote Em Triagem",
	DELIVERYMAN_LOST: "Com Técnico - Perda",
	DELIVERYMAN_INVENTORY: "Com Técnico - Inventário",
	WRONG_OCCURENCE: "Baixa Indevida",
};

export const Device_Scans_Type_Number = {
	COLLECTED: 0,
	IN_BASE: 1,
	SENT_NET: 2,
	OFF_BASE: 3,
	DELIVERED: 4,
	INVALID: 5,
	IN_BOX: 6,
	GOING_TO_BASE: 7,
	DELIVERED_TO_BASE: 8,
	VALIDATED_LOT: 9,
	SCREENING_LOT: 10,
	DELIVERYMAN_LOST: 11,
	DELIVERYMAN_INVENTORY: 12,
	WRONG_OCCURENCE: 13,
};

export const Device_Type = [
	"APARELHO",
	"NAGRA",
	"CHIP",
	"CAMERA",
	"INDEFINIDO",
];

@Component({
	selector: 'app-almoxarifado-listagem',
	templateUrl: './almoxarifado-listagem.component.html',
	styleUrls: ['./almoxarifado-listagem.component.css']
})
export class AlmoxarifadoListagemComponent extends PageBase {
	@Input() filters: {[index:string]: any} = {
		Device: {},
		WarehouseLot: {},
		LastScan: {},
		Branch: {},

	};

	@Input() order = {
		Device: [["last_scan_date", "desc"]]
	};

	@Input() bodyTemplate: TemplateRef<any>;

	Object = Object;

	moment = moment;
	Device_Scans_Type = Device_Scans_Type;
	status = Device_Scans_Type;
	devices_type = Device_Type;
	translate = Device_Scans_Type_Translate;
	typeNumber = Device_Scans_Type_Number;

	DEVICESTATUS_CATEGORY = DEVICESTATUS_CATEGORY;

	headColumns: HeadColumns = [
		{ index: "Device", column: "id", name: "ID", mask: '0*' },
		{ index: "Device", column: "barcode", name: "CÓD. DE BARRAS" },
		{ index: "Device", column: "type", name: "TIPO" },
		{ index: "WarehouseLot", column: "name", name: "LOTE" },
		{ index: "LastScan", column: "type", name: "ÚLTIMO STATUS", type: 'select', style: {backgroundColor: 'rgba(253,241,227,1)'}, options: [
			{value: {'Op.eq': 0}, label: "Coletado / Com o Técnico"},
			{value: 1, label: "Na Base"},
			{value: 2, label: "Encaminhado CLARO"},
			{value: 3, label: "Em Campo"},
			{value: 4, label: "Entregue"},
			{value: 5, label: "Inválido"},
			{value: 6, label: "Na Caixa"},
			{value: 7, label: "A Caminho - Base"},
			{value: 8, label: "Entregue Na Base"},
			{value: 9, label: "Lote Validado"},
			{value: 10, label: "Lote Em Triagem"},
			{value: 11, label: "Com Técnico - Perda"},
			{value: 12, label: "Com Técnico - Inventário"},
			{value: 13, label: "Baixa Indevida"}
		] },
		{ index: "Deliveryman", column: "name", name: "ÚLTIMO STATUS - TÉCNICO", style: {backgroundColor: 'rgba(253,241,227,1)'} },
		{ index: "Branch", column: "name", name: "ÚLTIMO STATUS - BASE", style: {backgroundColor: 'rgba(253,241,227,1)'} },
		{ index: "LastScan", column: "category", name: "ÚLTIMO STATUS - CATEGORIA", style: {backgroundColor: 'rgba(253,241,227,1)'}, type: 'select', options: [
			{value: {'Op.eq': 0}, label: "Desconexão"},
			{value: {'Op.eq': 1}, label: "Instalação"},
		] },
		{ index: "LastScan", column: "register_date", name: "ÚLTIMO STATUS - DATA", style: {backgroundColor: 'rgba(253,241,227,1)'}, type: 'date' },
		{}
	];
}
