import { Component, Input, OnInit } from "@angular/core";
import { Api } from "@durinn.v3/helpers/api";

@Component({
	selector: "app-tecnico-financeiro",
	templateUrl: "./tecnico-financeiro.component.html",
	styleUrls: ["./tecnico-financeiro.component.css"],
})
export class TecnicoFinanceiroComponent implements OnInit {
	index = "day";

	data = null;
	@Input() deliveryManId = null;

	call = this.api.new();

	constructor(public api: Api) {}

	ngOnInit() {
		this.get("day");
	}

	get(type) {
		this.index = type;
		this.data = null;
		this.call
			.get(
				`deliverymen/${this.deliveryManId}/reports/performance/${type}`
			)
			.subscribe((data) => (this.data = data.return));
	}

	getClass(obj, index) {
		for (const i in obj) {
			if (i != index && obj[i] > obj[index]) {
				return "r";
			}
		}

		return "g";
	}

	percIncrease(A, B) {
		const ret = 100 * Math.abs((A - B) / ((A + B) / 2));
		return ret.toFixed(2);
	}
}
