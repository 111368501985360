import { Component, OnInit } from "@angular/core";
import {
	EditPage,
	EditPageInterface,
} from "../../../../../bases/edit.page";

@Component({
	selector: "app-editar-cluster",
	templateUrl: "./editar-cluster.component.html",
	styleUrls: ["./editar-cluster.component.css"],
})
export class EditarClusterComponent
	extends EditPage
	implements EditPageInterface {
	deliveryManCall = this.api.new().set("success", true);

	permissions = {
		DELETE_CLUSTERS: false,
		EDIT_CLUSTERS: false,
	};

	form = {
		Deliverymen: [],
		variables: {},
	};

	fields = [
		{
			label: "Nome",
			name: "name",
			type: "text",
			validate: "Por favor, preencha seu nome corretamente!",
			required: true,
		},
		{
			label: "Descrição",
			name: "description",
			type: "text",
			validate: "Por favor, preencha a descrição corretamente!",
			required: false,
		},
	];

	codes = [
		100, 101, 102, 106, 107, 109, 111, 306, 428, 429, 430, 431, 706, 302, 312, 999
	];

	variables = [];

	back_to = "/clusters";
	route = "clusters";

	deliveryManId = null;

	ngOnInit(): void {
		super.ngOnInit();
		this.getDeviceTypes();
	}

	addDeliveryMan() {
		this.deliveryManCall
			.put("deliverymen/" + this.deliveryManId, {
				clusterId: this.id,
			})
			.subscribe(() => {
				this.get();
			});
	}

	async removeDeliveryman(id) {
		const self = this;

		if (await self.app.confirm()) {
			self.deliveryManCall
				.put("deliverymen/" + id, {
					clusterId: null,
				})
				.subscribe(() => {
					self.get();
				});
		}
	}

	deviceTypesCall = this.api.new();
	deviceTypes = [];
	async getDeviceTypes() {
		const self = this;
		self.deviceTypesCall
			.post(`devicetype/search/0/1000`, {})
			.subscribe((data) => {
				self.deviceTypes = data.return.rows;
				for (const item of self.deviceTypes) {
					self.variables.push({
						label: item.name.toLowerCase(),
						name: "value_" + item.name.toLowerCase(),
						delivered_variable:
							"DELIVERED_" + item.name.toLowerCase(),
						collected_variable:
							"COLLECTED_" + item.name.toLowerCase(),
						inbase_variable: "IN_BASE_" + item.name.toLowerCase(),
						type: "text",
						validate: "Por favor, preencha corretamente!",
						required: false,
					});
				}
			});
	}
}
