import {Api, Io} from "@durinn.v3/helpers/api";
import {Injectable, NgZone} from "@angular/core";
import { BasePage } from "./base.page";
import * as moment from "moment";
import {ApiCallsManager} from "@durinn.v3/helpers/ApiCallsManager";
import {App} from "@durinn.v3/helpers/app";
import {Me} from "@durinn.v3/helpers/me";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {AppComponent} from "../app.component";

@Injectable()
export class CrudPage extends BasePage {
	constructor(
		protected api: Api,
		protected apiCallsManager: ApiCallsManager,
		protected app: App,
		protected me: Me,
		protected http: HttpClient,
		protected activatedRoute: ActivatedRoute,
		protected router: Router,
		protected appC: AppComponent,
		protected ngZone: NgZone
	) {
		super(api,app,me,http, activatedRoute, router, appC, ngZone);
	}

	route: string;

	items = [];
	filters: { [s: string]: { [b: string]: any } };
	order: { [s: string]: string[][] };

	call: Io = this.api.new();
	delete_call: Io = this.api.new().set("success", true);
	moment = moment;

	ngOnInit() {
		this.get();
		super.ngOnInit();
	}

	offset = 0;
	page = 1;
	pages = 1;
	limit = 10;

	protected getFilters() {
		const self = this;
		const filters = {};

		for (const filter in self.filters) {
			filters[filter] = [];

			for (const item in self.filters[filter]) {
				const valor = self.filters[filter][item];

				if (valor != null) {
					if (typeof valor === "string") {
						if (valor) {
							filters[filter].push({
								[item]: {
									"Op.like": `%${self.filters[filter][item].split(" ").join("%")}%`
								},
							});
						}
					} else {
						filters[filter].push({ [item]: valor });
					}
				}
			}
		}

		console.log(`filters`, filters);

		return filters;
	}

	foreachItems(items: any[]) {
		return items;
	}

	listLoaded() {}

	async get(page = 1, _args = {}) {
		const self = this;
		const _page = page - 1;
		const offset = _page * self.limit;

		self.page = page;

		let args: any = { order: self.order };

		if (Object.values(self.getFilters()).length > 0) {
			const where = {};
			const items = self.getFilters();

			for (const item in items) {
				where[item] = { "Op.and": items[item] };
			}

			args = { order: self.order, where: where };
		}


		self.apiCallsManager.call(
			self.call,
			'post',
			self.route + `/search/${offset}/` + self.limit,
			{..._args, ...args}
		).subscribe((data) => {
			self.items = data.success
				? self.foreachItems((data.return || {}).rows)
				: [];
			self.pages = (data.return || {}).count;
			self.offset = offset;
			self.listLoaded();
		});
	}

	async delete(id) {
		const self = this;
		if (await self.app.confirm()) {
			self.delete_call
				.delete(self.route + "/" + id)
				.subscribe((data) => this.get());
		}
	}
}
