import * as moment from 'moment';

export default {
	developer: {
		name: "Desenvolvimento Ninja",
		link: "http://www.desenvolvimento.ninja/",
	},
	me: {
		cache_name: "user",
		login_field: "cpf",
		login_description: "CPF",
		password_field: "password"
	},
	system: {
		register: false,
		name: "Padrão Logístico",
		copyright: `© ${moment().format('YYYY')} Padrão Logístico`,
		logo: "assets/images/logo/logo.png",
		recover_password: "sms",
	},
	facebook: {
		clientId: "",
	},
	google: {
		clientId: "",
	},
};
