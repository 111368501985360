import { Injectable, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Api } from "@durinn/v.3/helpers/api";
import { App } from "@durinn/v.3/helpers/app";
import { Me, User } from "@durinn/v.3/helpers/me";

@Injectable()
export class DashboardBase implements OnInit {
	public user: User | {} = {};
	public user_permissions: {
    [s: string]: {
      field: string | null;
      key: string | null;
      permission: string;
    };
  } = {};

	constructor(
    public api: Api,
    public me: Me,
    public app: App,
    public activatedRoute: ActivatedRoute,
    public router: Router
	) {
		const self = this;

		self.me.get().subscribe((user) => {
			if (user) {
				self.user = user;
				for (const item of user.Permissions || []) {
					self.user_permissions[item.permission] = item;
				}

				console.log(`user_permissions`, self.user_permissions);

				self.permissionsLoaded();
				self.userLoaded(user);
			} else {
				self.userNotLoaded();
			}
		});
	}

	ngOnInit() {}

	userLoaded(user: User) {}

	userNotLoaded() {}

	permissionsLoaded() {}

	hasPermission(field): boolean {
		return !!(this.user_permissions[field] || this.user_permissions.MASTER);
	}
}
