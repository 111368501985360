import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BasePage } from "../../../../../bases/base.page";
import * as moment from "moment";

@Component({
	selector: "app-relatorio-de-agendas",
	templateUrl: "./relatorio-de-agendas.component.html",
	styleUrls: ["./relatorio-de-agendas.component.css"],
})
export class RelatorioDeAgendasComponent extends BasePage {
	@Output() isVisibleChange = new EventEmitter();
	@Input() isVisible = false;
	call = this.api.new();
	moment = moment;

	search = {
		User: null,
		userId: 0,
		start: "",
		end: "",
	};

	resetSearch() {
		const self = this;

		self.search = {
			User: null,
			userId: 0,
			start: "",
			end: "",
		};
	}

	isSearchDatesValid() {
		const self = this;
		const isStartDateValid = moment(
			self.search.start,
			"DD/MM/YYYY"
		).isValid();
		const isEndDateValid = moment(self.search.end, "DD/MM/YYYY").isValid();
		return isStartDateValid && isEndDateValid;
	}

	send() {
		const self = this;

		self.call
			.get("report/ordered-by-schedules", {
				start: moment(self.search.start, "DD/MM/YYYY").format(
					"YYYY-MM-DD"
				),
				end: moment(self.search.end, "DD/MM/YYYY").format("YYYY-MM-DD"),
				userId: self.search.userId,
			})
			.subscribe((response) => {
				window.location.href = response.return;
				self.isVisibleChange.emit(false);
				self.isVisible = false;
				self.resetSearch();
			});
	}
}
