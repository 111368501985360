import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {PageBase} from "@durinn.v3/app/bases/page.base";
import {DELIVERY_STATUS} from "../../../../../constants";

@Component({
	selector: 'app-modal-agenda-previa',
	templateUrl: './modal-agenda-previa.component.html',
	styleUrls: ['./modal-agenda-previa.component.css']
})
export class ModalAgendaPreviaComponent extends PageBase {
	@Input() delivery: any;
	@Input() deliveryChange = new EventEmitter();
	
	status = DELIVERY_STATUS;
	
	call = this.api.new();
	
	conclude = false;
	reversion = false;
	terminate = false;
	
	reload(){
		const self = this;
		self.call
			.get("delivery/" + self.delivery.id_delivery)
			.subscribe((response) => {
				self.delivery = response.return;
				self.deliveryChange.emit(self.delivery);
			});
	}
	
	update() {
		const self = this;
		self.call
			.put("delivery/" + self.delivery.id_delivery, self.delivery)
			.subscribe((response) => {
				self.toastr.success(`Agenda atualizada com sucesso`, `Sucesso`);
			});
	}
}
