import languageEncoding from "detect-file-encoding-and-language";
import {Component, OnInit} from '@angular/core';
import { Api } from "@durinn.v3/helpers/api";
import {Router} from "@angular/router";
import * as moment from 'moment';
import swal from "sweetalert2";

@Component({
	selector: 'app-importar-bringg',
	templateUrl: './importar-bringg.component.html',
	styleUrls: ['./importar-bringg.component.css']
})
export class ImportarBringgComponent implements OnInit {
	call = this.api.new();
	call2 = this.api.new();
	reading_file = false;
	result = null;

	import = [];
	imports = [];
	filename = null;

	page = 1;
	pages = 0;

	constructor(private api: Api, private router: Router) {
	}

	ngOnInit() {
		this.get();
	}

	async onFileChanged(event) {
		const self = this;
		const reader = new FileReader();

		self.reading_file = true;
		self.filename = event.target.files[0].name;
		const fileInfo = await languageEncoding(event.target.files[0]);
		reader.readAsText(event.target.files[0], fileInfo.encoding || 'ISO-8859-1');

		reader.onload = (x) => {
			self.result = reader.result;
			self.reading_file = false;
			self.generate();
		};
	}

	generate() {
		const self = this;
		self.import = [];

		try {
			if (typeof self.result !== "string") {
				return swal.fire("Importação", "Erro no arquivo", "error");
			}

			const lines = self.result.split("\n");
			const header = lines[0].split(';');

			if (header[0] !== 'ID BRINGG') {
				return swal.fire(`Erro`, `Formato inválido. Por favor, escolha um arquivo válido de exportação da Bringg`, `error`);
			}

			if (lines.length < 2) {
				return swal.fire(`Erro`, 'Arquivos com linhas insuficientes. Por favor, adicione mais linhas.', `error`);
			}

			for (const item of lines) {
				if (item.substr(0, 8).toUpperCase() != "ID dos pedidos") {
					const value = item
						.replace(/(\r\n|\n|\r)/gm, "")
						.replace(/\s\s+/g, " ");

					if (value.length > 0) {
						self.import.push(value);
					}
				}
			}

			swal.fire({
				title: "Importar",
				text: `Você deseja importar essas agendas?`,
				icon: "question",
				showCancelButton: true,
				confirmButtonColor: "#DD6B55",
				confirmButtonText: "Importar",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.value === true) {
					self.send();
				}
			});
		} catch (e) {
			swal.fire("Importação", "Envie um arquivo .csv", "error");
			console.error(e, self.result);
		}
	}

	load = [];
	loading = false;

	async build() {
		const self = this;

		self.loading = true;
		self.load = [];

		const INDEXES: {[index: string]: number} = {};

		for (const index in self.import[0]) {
			INDEXES[self.import[0][index]] = +index;
		}

		console.log(`INDEXES`, INDEXES);

		/*
            BAIRRO: 8
            CANAL: 2
            CEP: 11
            CIDADE: 9
            CLIENTE: 5
            CONTRATO: 31
            CPF: 6
            DATA CANCELAMENTO: 22
            DATA CRIAÇÃO: 16
            DATA DEVOLUÇÃO: 24
            DATA ENTREGA: 20
            DATA ENVIO BRINGG: 18
            DATA FIM JANELA: 14
            DATA INÍCIO JANELA: 12
            ENDEREÇO: 7
            EXPRESSO: 29
            HORA CANCELAMENTO: 23
            HORA CRIAÇÃO: 17
            HORA DEVOLUÇÃO: 25
            HORA ENTREGA: 21
            HORA ENVIO BRINGG: 19
            HORA FIM JANELA: 15
            HORA INÍCIO JANELA: 13
            ICCID: 26
            ID BRINGG: 0
            NOME DO TÉCNICO: 27
            OSS: 1
            STATUS: 30
            TIME: 3
            TRANSPORTADORA: 4
            UF: 10
            ÚLTIMA OCORRÊNCIA: 28
         */

		self.import.shift();

		for (const line of self.import) {

			if(line.length >= 7 && line[INDEXES['CONTRATO']]){
				const from = moment(line[INDEXES['DATA ENTREGA']], 'DD/MM/YYYY').startOf('day');
				const to = moment(line[INDEXES['DATA ENTREGA']], 'DD/MM/YYYY').endOf('day');

				self.load.push({
					contract: line[INDEXES['CONTRATO']],
					os: line[INDEXES['DOCUMENTO SD']] || line[INDEXES['OS']] || line[INDEXES['OSS']],
					barcode: line[INDEXES['ICCID']],
					deliveryman: line[INDEXES['NOME DO TÉCNICO']] || line[INDEXES['NOME TÉCNICO']] || line[INDEXES['NOME MOTORISTA']],
					holder: line[INDEXES['CLIENTE']],
					pre_address: line[INDEXES['ENDEREÇO']] + ', ' + line[INDEXES['BAIRRO']] + ' - ' + line[INDEXES['CIDADE']] + ', ' + line[INDEXES['UF']],
					latitude: -30.0277,
					longitude: -51.2287,
					start: from.toDate(),
					end: to.toDate(),
					phones: 'Não informado',
					conclude_date: moment(line[INDEXES['DATA ENTREGA']] + ' ' + line[INDEXES['HORA ENTREGA']], 'DD/MM/YYYY HH:mm').toDate(),
					backend_date: from.startOf('day').toDate(),
					opening_date: from.startOf('day').toDate()
				});
			}
		}

		self.send();
	}

	async send() {
		const self = this;

		const body = { 
			filename: self.filename,
			lines: self.import
		};

		self.call.post("importer/bringg/new", body).subscribe( async response => {
			await swal.fire("Importação", response.message, "success");
		});
	}

	async get(page = 1) {
		const self = this;
		self.call2
			.get("importer-bringg/" + (page - 1) * 10 + "/3")
			.subscribe((data) => {
				self.pages = Math.ceil(data.return.count / 10);
				self.imports = data.return.rows;
			});
	}
}
