import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DurinnModule } from "@durinn/v.3/durinn.module";
import { ColorPickerModule } from "@iplab/ngx-color-picker";
import { NgZorroAntdModule, NzAvatarModule } from "ng-zorro-antd";
import { NgxCurrencyModule } from "ngx-currency";
import { NgxMaskModule } from "ngx-mask";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ThemeConstantService } from "../../_durinn/v.3/app/template/services/theme-constant.service";
import { ClusterSelectComponent } from "@components/clusters/cluster-select/cluster-select.component";
import { DeliverymanSelectComponent } from "@components/deliverymen/deliveryman-select/deliveryman-select.component";
import { LogsTableComponent } from "@components/logs-table/logs-table.component";
import { AgmCoreModule } from "@agm/core";
import { FormathoursPipe } from "../pipes/formathours.pipe";
import { ActiveMapComponent } from "@components/deliverymen/active-map/active-map.component";
import { TeamsSelectComponent } from "@components/teams/teams-select/teams-select.component";
import { MaxPipe } from "../pipes/max.pipe";
import { FormatnamePipe } from "../pipes/formatname.pipe";
import { EditDeliveryComponent } from "@components/delivery/edit-delivery/edit-delivery.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { TypesSelectComponent } from "@components/delivery/types-select/types-select.component";
import { UsersSelectComponent } from "@components/users/users-select/users-select.component";
import { DeviceTypeSelectComponent } from "@components/devices/device-type-select/device-type-select.component";
import { HeaderComponent } from "@components/header/header.component";

@NgModule({
	exports: [
		CommonModule,
		FormsModule,
		HttpClientModule,
		NgZorroAntdModule,
		PerfectScrollbarModule,
		NgxMaskModule,
		ReactiveFormsModule,
		ClusterSelectComponent,
		DeliverymanSelectComponent,
		LogsTableComponent,
		FormathoursPipe,
		ActiveMapComponent,
		MaxPipe,
		FormatnamePipe,
		EditDeliveryComponent,
		UsersSelectComponent,
		AgmCoreModule,
		DurinnModule,
		DeviceTypeSelectComponent,
		NgxCurrencyModule,
		NzAvatarModule,
		ScrollingModule,
	],
	imports: [
		RouterModule,
		CommonModule,
		NgZorroAntdModule,
		PerfectScrollbarModule,
		NgxMaskModule,
		FormsModule,
		AgmCoreModule,
		GooglePlaceModule,
		DurinnModule,
		ReactiveFormsModule,
		NgxCurrencyModule,
		ColorPickerModule,
		NzAvatarModule,
		ScrollingModule,
	],
	declarations: [
		MaxPipe,
		ClusterSelectComponent,
		ClusterSelectComponent,
		DeliverymanSelectComponent,
		LogsTableComponent,
		FormathoursPipe,
		ActiveMapComponent,
		TeamsSelectComponent,
		FormatnamePipe,
		EditDeliveryComponent,
		TypesSelectComponent,
		UsersSelectComponent,
		DeviceTypeSelectComponent,
		HeaderComponent
	],
	providers: [ThemeConstantService],
})
export class SharedModule {}
