import { Component } from "@angular/core";

@Component({
	selector: "durinn-v2-provider-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.css"],
})
export class DurinnV2ProviderSettingsComponent {
	providerDefaultFields = [{ field: "Nome", mask: "", required: true }];
}
