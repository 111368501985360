import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "formatName",
})
export class FormatNamePipe implements PipeTransform {
	transform(value: any, substring = 1): any {
		if (typeof value === "string") {
			const arr = value.split(" ");

			return arr.length == 1
				? `${arr[0]}`
				: `${arr[0]} ${arr[1].substring(0, substring)}.`;
		}

		return value;
	}
}
