import { Component, OnInit } from "@angular/core";
import { Api } from "@durinn.v3/helpers/api";
import * as moment from "moment";
import {RelatoriosBase} from "@views/with-layout/almoxarifado/relatorios/relatorios.base";

@Component({
	selector: "app-coletados-por-tecnico",
	templateUrl: "./coletados-por-tecnico.component.html",
	styleUrls: ["./coletados-por-tecnico.component.css"],
})
export class ColetadosPorTecnicoComponent extends RelatoriosBase{
	report = {
		from: moment().format("01/MM/YYYY"),
		to: moment().format("DD/MM/YYYY"),
		type: 0,
		user_id: null,
		types: ["APARELHO"],
		category: "",
	};

	loading = false;

	setUser(event) {
		this.report.user_id = event;
	}

	call = this.io.new();
	async send() {
		const self = this;
		this.call.openLink(
			"warehouse/reports/excel/collected-by-user/" +
				self.report.user_id +
				"/" +
				moment(self.report.from, "DD/MM/YYYY").format("YYYY-MM-DD") +
				"/" +
				moment(self.report.to, "DD/MM/YYYY").format("YYYY-MM-DD") +
				"/" +
				self.report.type,
			{ types: self.report.types, category: self.report.category }
		);
	}

	private toggleTypes(type: string) {
		const self = this;
		if (self.report.types.indexOf(type) > -1) {
			self.report.types.splice(self.report.types.indexOf(type), 1);
		} else {
			self.report.types.push(type);
		}
	}
}
