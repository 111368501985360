import { Component } from "@angular/core";
import {
	DurinnV2ListPageTableComponent,
	HeadColumns
} from "@durinn.v3/app/components/template/tables/list-page-table/list-page-table.component";
import {PageBase} from "@durinn.v3/app/bases/page.base";

@Component({
	selector: "app-tecnicos",
	templateUrl: "./tecnicos.component.html",
	styleUrls: ["./tecnicos.component.css"],
})
export class TecnicosComponent extends PageBase {
	filters = {
		Deliveryman: {
		},
	};

	order = {
		Deliveryman: [["name", "asc"]]
	};

	headColumns: HeadColumns = DurinnV2ListPageTableComponent.defaultHeadColumnsWith('Deliveryman', [
		{ index: "Deliveryman", column: "phone", name: "Telefone", mask: '0*' },
		{ index: "Deliveryman", column: "email", name: "E-mail" },
		{ index: "Deliveryman", column: "active", name: "Ativo", type: 'select', options: [{value: '1', label: 'Sim'}, {value: {'Op.eq': 0}, label: 'Não'}] },
	]);
}
