import { DEVICESTATUS_CATEGORY } from "../../../constants";
import { OLD_API_LINK } from "../../../bases/base.page";
import { Component, HostListener } from "@angular/core";
import { CrudPage } from "../../../bases/crud.page";
import { Csv } from "@durinn.v3/helpers/csv";
import * as moment from "moment";
import Swal from "sweetalert2";
import * as $ from "jquery";

export const Device_Scans_Type = {
	0: "Coletado / Com o Técnico",
	1: "Na Base",
	2: "Encaminhado CLARO",
	3: "Em Campo",
	4: "Entregue",
	5: "Inválido",
	6: "Na Caixa",
	7: "A Caminho - Base",
	8: "Entregue Na Base",
	9: "Lote Validado",
	10: "Lote Em Triagem",
	11: "Com Técnico - Perda",
	12: "Com Técnico - Inventário",
	13: "Baixa Indevida",
};

export const Device_Scans_Type_Translate = {
	COLLECTED: "Coletados / Com o Técnico",
	IN_BASE: "Na Base",
	SENT_NET: "Enviados NET",
	DELIVERED: "Entregues",
	INVALID: "Inválido",
	IN_BOX: "Na Caixa",
	GOING_TO_BASE: "A Caminho - Base",
	DELIVERED_TO_BASE: "Entregue Na Base",
	VALIDATED_LOT: "Lote Validado",
	SCREENING_LOT: "Lote Em Triagem",
	DELIVERYMAN_LOST: "Com Técnico - Perda",
	DELIVERYMAN_INVENTORY: "Com Técnico - Inventário",
	WRONG_OCCURENCE: "Baixa Indevida",
};

export const Device_Scans_Type_Number = {
	COLLECTED: 0,
	IN_BASE: 1,
	SENT_NET: 2,
	OFF_BASE: 3,
	DELIVERED: 4,
	INVALID: 5,
	IN_BOX: 6,
	GOING_TO_BASE: 7,
	DELIVERED_TO_BASE: 8,
	VALIDATED_LOT: 9,
	SCREENING_LOT: 10,
	DELIVERYMAN_LOST: 11,
	DELIVERYMAN_INVENTORY: 12,
	WRONG_OCCURENCE: 13,
};

export const Device_Type = [
	"APARELHO",
	"NAGRA",
	"CHIP",
	"CAMERA",
	"INDEFINIDO",
];

@Component({
	selector: "app-almoxarifado",
	templateUrl: "./almoxarifado.component.html",
	styleUrls: ["./almoxarifado.component.css"],
})
export class AlmoxarifadoComponent extends CrudPage {
	public keypressed;

	lotsModalInt = 0;
	limit = 30;
	route = "warehouse";
	filters: any = { device: {}, last_scan: {}, last_scan_branch: {} };

	Object = Object;

	moment = moment;
	Device_Scans_Type = Device_Scans_Type;
	status = Device_Scans_Type;
	devices_type = Device_Type;
	translate = Device_Scans_Type_Translate;
	typeNumber = Device_Scans_Type_Number;

	DEVICESTATUS_CATEGORY = DEVICESTATUS_CATEGORY;

	permissions = {
		EDIT_WAREHOUSE: false,
		CREATE_WAREHOUSE_LOTS: false,
	};

	new_occurrence: any = {
		modal: false,
		call: this.api
			.new()
			.set("exception", false)
			.set("error", false)
			.set("success", true),
		form: {},
		title: "Nova Ocorrência",
		lot: "",
	};

	openNewOccurrence() {
		const self = this;

		const arr = [];
		self.new_occurrence.title = "Nova Ocorrência";

		for (const item of self.items) {
			if (item.checked) {
				self.new_occurrence.form.device = item.type;
				self.new_occurrence.form.lot = item.lot;
				arr.push(item.barcode);
			}
		}

		this.new_occurrence.form.barcodes = arr.join("\n");
		this.new_occurrence.modal = true;
	}

	openAddToLot() {
		const self = this;

		const arr = [];
		self.new_occurrence.title = "Adicionar ao Lote";

		for (const item of self.items) {
			if (item.checked) {
				self.new_occurrence.form.device = item.type;
				self.new_occurrence.form.lot = item.lot;
			}
		}

		this.new_occurrence.form.barcodes = arr.join("\n");
		this.new_occurrence.modal = true;
	}

	openRemoveFromLot() {
		const self = this;

		const arr = [];

		self.new_occurrence.title = "Remover do Lote";
		self.new_occurrence.form.lot = null;

		for (const item of self.items) {
			if (item.checked) {
				self.new_occurrence.form.device = item.type;
				arr.push(item.barcode);
			}
		}

		this.new_occurrence.form.barcodes = arr.join("\n");
		this.new_occurrence.modal = true;
	}

	sendNewOccurrence() {
		const self = this;
		this.new_occurrence.call
			.post("warehouse/lot", {
				name: self.new_occurrence.form.lot,
				note: self.new_occurrence.form.note || null,
				user_id: self.new_occurrence.form.user_id,
				scanType: self.new_occurrence.form.type,
				type: self.new_occurrence.form.device,
				devices: self.new_occurrence.form.barcodes.split("\n"),
			})
			.subscribe(async (data) => {
				self.new_occurrence.modal = false;
				self.get();

				if (!data.success) {
					if (
						await self.app.confirm(
							"Erro",
							data.message,
							"Baixar Relatório"
						)
					) {
						const rows = [
							["Código de Barras", "Resultado", "Observação"],
						];

						for (const item of data.return.errors) {
							rows.push([item.barcode, "ERRO", item.error]);
						}

						for (const item of data.return.success) {
							rows.push([
								item.barcode,
								"SUCESSO",
								"Item adicionado com sucesso",
							]);
						}

						Csv.download(rows);
					}
				}
			});
	}

	@HostListener("window:keydown", ["$event"])
	handleKeyboardEvent(event: KeyboardEvent) {
		this.keypressed = event.keyCode || event.which;
		if (this.keypressed == 13 && $(".fade.show").length == 0) {
			if (document.activeElement instanceof HTMLElement) {
				document.activeElement.blur();
			}
			this.get(1);
		}
	}

	checkAll = false;

	syncCheckAll() {
		const self = this;
		for (const item of self.items) {
			item.checked = self.checkAll;
		}
	}

	private getChecked() {
		const self = this;
		const array = [];

		for (const item of self.items) {
			if (item.checked) {
				array.push(item);
			}
		}

		return array;
	}

	async see(item) {
		const confirm = await Swal.fire({
			title: "Almoxarifado",
			html:
				"Você deseja ver o item de código de barras <b> " +
				item.barcode +
				"</b>?",
			icon: "question",
			showCloseButton: true,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: "Ver Item",
			cancelButtonText: "Cancelar",
		});
		if (confirm.value) {
			const win = window.open("carga/" + item.barcode, "_blank");
			win.focus();
		}
	}

	ngOnInit() {
		const self = this;
		// self.filters.device.lot = self.activatedRoute.snapshot.params['idLot'] || null;
		super.ngOnInit();
		if (self.filters.device.lot) {
			self._editLot.call
				.set("success", false)
				.get(`warehouselot/` + self.filters.device.lot)
				.subscribe((data) => (self._editLot.lot = data.return));
		}
	}

	async get(page = 1): Promise<void> {
		return;
		super.get(page);
	}

	private invalidate = {
		textarea: "",
		note: "",
		loading: false,
	};

	private deleteForm = {
		textarea: "",
		note: "",
		loading: false,
	};

	async doDelete() {
		const self = this;
		const devices = self.deleteForm.textarea.split(/\s+/);

		Swal.fire({
			title: "Você tem certeza?",
			html: `Você tem certeza de que deseja <b style="color: red">apagar</b> ${devices.length} aparelhos?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#d33",
			cancelButtonColor: "#ccc",
			confirmButtonText: "Apagar",
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			if (result.value) {
				self.deleteForm.loading = true;

				const api = self.api
					.new()
					.set("exception", false)
					.set("error", false)
					.set("success", true);
				api.post("warehouse/delete", {
					devices: devices
				}).subscribe(async (data) => {
					self.deleteForm = {
						textarea: "",
						note: "",
						loading: false,
					};
					self.ngOnInit();

					if (!data.success) {
						if (
							await self.app.confirm(
								"Erro",
								data.message,
								"Baixar Relatório"
							)
						) {
							const rows = [
								["Código de Barras", "Resultado", "Observação"],
							];

							for (const item of data.return.errors) {
								rows.push([item.barcode, "ERRO", item.error]);
							}

							for (const item of data.return.success) {
								rows.push([
									item.barcode,
									"SUCESSO",
									"Item adicionado com sucesso",
								]);
							}

							Csv.download(rows);
						}
					}
				});
			}
		});
	}

	async doInvalidate() {
		const self = this;
		const devices = self.invalidate.textarea.split(/\s+/);

		Swal.fire({
			title: "Você tem certeza?",
			text: `Você tem certeza de que deseja invalidar ${devices.length} aparelhos?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#d33",
			cancelButtonColor: "#ccc",
			confirmButtonText: "Invalidar",
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			if (result.value) {
				self.invalidate.loading = true;

				const api = self.api
					.new()
					.set("exception", false)
					.set("error", false)
					.set("success", true);
				api.post("warehouse/invalidate", {
					devices: devices,
					note: self.invalidate.note,
				}).subscribe(async (data) => {
					self.invalidate = {
						textarea: "",
						note: "",
						loading: false,
					};
					self.ngOnInit();

					if (!data.success) {
						if (
							await self.app.confirm(
								"Erro",
								data.message,
								"Baixar Relatório"
							)
						) {
							const rows = [
								["Código de Barras", "Resultado", "Observação"],
							];

							for (const item of data.return.errors) {
								rows.push([item.barcode, "ERRO", item.error]);
							}

							for (const item of data.return.success) {
								rows.push([
									item.barcode,
									"SUCESSO",
									"Item adicionado com sucesso",
								]);
							}

							Csv.download(rows);
						}
					}
				});
			}
		});
	}

	async getExcel(link: string) {
		const self = this;
		const api = self.api.new();
		api.get(OLD_API_LINK + "warehouse/reports/excel/" + link).subscribe(
			(response) =>
				window.open(
					"data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;base64," +
						response.return
				)
		);
	}

	private send_net = {
		textarea: "",
		note: "",
		invoiceNumber: null,
		loading: false,
	};

	async doSendNet() {
		const self = this;
		const devices = self.send_net.textarea.split(/\s+/);

		Swal.fire({
			title: "Você tem certeza?",
			text: `Você tem certeza de que deseja marcar ${devices.length} aparelhos como "Encaminhado CLARO"?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#f1c40f",
			cancelButtonColor: "#ccc",
			confirmButtonText: "Invalidar",
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			if (result.value) {
				self.send_net.loading = true;
				const api = self.api
					.new()
					.set("exception", false)
					.set("error", false)
					.set("success", true);
				api.post("warehouse/send-net", {
					devices: devices,
					invoiceNumber: self.send_net.invoiceNumber,
					note: self.send_net.note,
				}).subscribe(async (data) => {
					self.send_net = {
						textarea: "",
						note: "",
						loading: false,
						invoiceNumber: null,
					};
					self.ngOnInit();

					if (!data.success) {
						if (
							await self.app.confirm(
								"Erro",
								data.message,
								"Baixar Relatório"
							)
						) {
							const rows = [
								["Código de Barras", "Resultado", "Observação"],
							];

							for (const item of data.return.errors) {
								rows.push([item.barcode, "ERRO", item.error]);
							}

							for (const item of data.return.success) {
								rows.push([
									item.barcode,
									"SUCESSO",
									"Item adicionado com sucesso",
								]);
							}

							Csv.download(rows);
						}
					}
				});
			}
		});
	}

	private new_lot = {
		name:
			moment().format("YYYYMMDD") +
			"-" +
			new Date().getTime().toString(16).toUpperCase().substr(5, 4),
		textarea: "",
		invoiceNumber: "",
		note: "",
		type: "CHIP",
		user_id: null,
		scanType: 1,
		loading: false,
	};

	private user_selected(id) {
		this.new_lot.user_id = id;
	}

	_editLot: any = {
		modal: false,
		lot: {},
		call: this.api.new(),
	};

	editLot() {
		const self = this;
		self._editLot.modal = true;
	}

	sendEditLot() {
		const self = this;
		self._editLot.call.timeout = 990092981321;
		self._editLot.call
			.set("success", true)
			.put(`warehouselot/` + self.filters.device.lot, self._editLot.lot)
			.subscribe((data) => self.ngOnInit());
	}

	async createNewLot() {
		const self = this;
		const devices = self.new_lot.textarea.split(/\s+/);

		Swal.fire({
			title: "Você tem certeza?",
			text: `Você tem certeza de que deseja criar um lote com ${devices.length} aparelhos?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#219af0",
			cancelButtonColor: "#ccc",
			confirmButtonText: "Criar",
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			if (result.value) {
				self.new_lot.loading = true;
				const api = self.api
					.new()
					.set("exception", false)
					.set("error", false)
					.set("success", true);

				api.timeout = 990092981321;

				api.post("warehouse/lot", {
					devices: devices,
					type: self.new_lot.type,
					scanType: self.new_lot.scanType,
					user_id: self.new_lot.user_id,
					name: self.new_lot.name,
				}).subscribe(async (data) => {
					self.new_lot.name = new Date()
						.getTime()
						.toString(16)
						.substr(5, 11)
						.toUpperCase();
					self.new_lot.loading = false;
					self.new_lot.textarea = "";
					self.ngOnInit();

					if (!data.success) {
						if (
							await self.app.confirm(
								"Erro",
								data.message,
								"Baixar Relatório"
							)
						) {
							const rows = [
								["Código de Barras", "Resultado", "Observação"],
							];

							for (const item of data.return.errors) {
								rows.push([item.barcode, "ERRO", item.error]);
							}

							for (const item of data.return.success) {
								rows.push([
									item.barcode,
									"SUCESSO",
									"Item adicionado com sucesso",
								]);
							}

							Csv.download(rows);
						}
					}
				});
			}
		});
	}
}
