import {Component, EventEmitter, Input, Output, SimpleChanges, TemplateRef} from "@angular/core";
import {
	FiltersType, IncludesType,
	ListBaseInterface,
	ListPageBase,
	Orders,
	ParamsType,
} from "@durinn/v.3/app/bases/listpage.base";

export type HeadColumnsType = "text" | "number" | "email" | "tel" | "select";

export type HeaderColumns = {
	name?: string;
	colSpan?: number;
	style?: { [key: string]: string };
} [];

export type HeadColumns =
	| {
	index: string;
	column: string;
	name?: string;
	placeholder?: string;
	mask?: string;
	type?: string;
	orderControlHidden?: boolean;
	style?: { [key: string]: string };
	options?: { label: string; value: string }[] | string[];
}[]
	| {}[];

@Component({
	selector: "durinn-v2-list-page-table",
	templateUrl: "./list-page-table.component.html",
	styleUrls: ["./list-page-table.component.scss"],
})
export class DurinnV2ListPageTableComponent
	extends ListPageBase
	implements ListBaseInterface {
	static defaultHeadColumnsWith(
		defaultIndex: string,
		headColumns?: HeadColumns
	) {
		return [
			{
				index: defaultIndex,
				column: "id",
				name: "ID",
				mask: "0*",
				style: {width: "150px"},
			},
			{
				index: defaultIndex,
				column: "createdAt",
				name: "Data de Registro",
				type: "date",
				style: {width: "150px"},
			},
			{index: defaultIndex, column: "name", name: "Nome"},
		]
			.concat(headColumns || ([] as any))
			.concat([{style: {width: "150px"}}] as any);
	}
	
	protected getOnInit = false;
	
	protected visible = true; // Importante para o funcionamento dos modais
	protected getOnlyVisible = true;
	
	@Input() nzTitle: string | TemplateRef<void> = "";
	@Input() nzExtra: string | TemplateRef<void>;
	
	@Input() route: string;
	@Input() limit: number =
		+localStorage.getItem("durinn-list-table-default-limit") || 10;
	
	@Input() attributes: string[] | null = null;
	@Input() order: Orders = {};
	@Input() params: ParamsType = {};
	@Input() filters: FiltersType = {};
	@Input() includes: IncludesType = null;
	@Input() defaultFilters: FiltersType = null;
	@Input() size: "default" | "small" = "default";
	
	@Input() headerColumns: HeaderColumns = [];
	@Input() headColumns: HeadColumns = [];
	
	@Input() bodyForPattern = true;
	
	@Input() bodyForTemplate: TemplateRef<any>;
	@Input() bodyForAfterTemplate: TemplateRef<any>;
	@Input() routerLink = "";
	
	protected generatedDefaultFilters = false;
	@Input() generateDefaultFilters = true; // Caso não existam defaultFilters ele vai assumir os filtros da criação como defaultFilters
	@Input() generateDefaultFiltersOnChange = true; // Caso não existam defaultFilters ele vai assumir os filtros do onChanges como defaultFilters
	
	ngOnInit() {
		const self = this;
		
		if (!self.defaultFilters && self.generateDefaultFilters) {
			self.generatedDefaultFilters = true;
			self.defaultFilters = JSON.parse(JSON.stringify(self.filters));
		}
		
		super.ngOnInit();
	}
	
	ngOnChanges(changes?: SimpleChanges) {
		const self = this;
		
		if (changes.filters && self.generateDefaultFiltersOnChange && self.generatedDefaultFilters) {
			if (JSON.stringify(changes.filters.previousValue) != JSON.stringify(changes.filters.currentValue)) {
				/**
				 * Não podemos remover os indices para evitar erro de:
				 * ERROR TypeError: Cannot read properties of undefined (reading 'createdAt')
				 */
				self.defaultFilters = JSON.parse(JSON.stringify(self.filters));
			}
		}
		
		this.items = [];
		this.get(1);
	}
	
	limitChanged() {
		localStorage.setItem(
			"durinn-list-table-default-limit",
			this.limit.toString()
		);
		this.get();
	}
	
	async get(page = 1): Promise<any> {
		const self = this;
		
		if(self.getOnlyVisible && !self.visible) {
			return;
		}
		
		this.checkeds = {};
		
		if (self.includes == 'auto') {
			const includes = [];
			
			for (const item of self.headColumns) {
				if ((item as any).index && includes.indexOf((item as any).index) === -1) {
					includes.push((item as any).index);
				}
			}
			
			if (includes.length) {
				self.includes = includes.map(x => {
					return {model: x};
				});
			}
		}
		
		return super.get(page);
	}
	
	
	@Input() checkboxes = false;
	@Output() checkedsChange = new EventEmitter();
	@Input() checkedsTemplate: TemplateRef<any>;
	
	protected checkall = false;
	protected checkeds = {};
	
	get checkedItems() {
		const self = this;
		
		const items = [];
		for (const index in self.checkeds) {
			if (self.checkeds[index]) {
				items.push(self.items[index]);
			}
		}
		
		return items;
	}
	
	checkedItemsChanged() {
		const self = this;
		self.checkedsChange.emit(self.checkedItems);
	}
	
	checkAllChanged() {
		const self = this;
		
		for (const i in self.items) {
			self.checkeds[i] = self.checkall;
		}
		
		self.checkedItemsChanged();
	}
	
}
