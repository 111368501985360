import { Component, Input } from "@angular/core";
import * as moment from "moment";
import { Observable, Observer } from "rxjs";
import { BasePage } from "src/app/bases/base.page";

@Component({
	selector: "app-financeiro",
	templateUrl: "./financeiro.component.html",
	styleUrls: ["./financeiro.component.css"],
})
export class FinanceiroComponent extends BasePage {
	@Input() from = null;
	@Input() to = null;

	call = this.api.new();
	call2 = this.api.new();
	search = {
		from: "",
		to: "",
		cluster: null,
		audited: false,
	};
	data = false;
	previousData = false;

	ngOnInit(): void {
		this.search.from =
			this.from || moment().subtract(1, "month").format("21/MM/YYYY");
		this.search.to = this.to || moment().format("20/MM/YYYY");
		super.ngOnInit();
		if (this.from && this.to) {
			this.getData().subscribe((data) => console.log(data));
		}
	}

	get() {
		this.getData().subscribe((data) => console.log(data));
	}

	getData() {
		const self = this;
		const from = moment(this.search.from, "DD/MM/YYYY");
		const to = moment(this.search.to, "DD/MM/YYYY");
		const cluster = this.search.cluster || "";

		return Observable.create(async (observer: Observer<any>) => {
			if (from.isValid() && to.isValid()) {
				self.data = null;
				self.previousData = null;

				self.call
					.get("financial/byDay", {
						audited: self.search.audited ? 1 : 0,
						from: from.format("YYYY-MM-DD"),
						to: to.format("YYYY-MM-DD"),
						clusterId: cluster,
					})
					.subscribe((data) => {
						self.data = data.return;
						observer.next(data.return);
						console.log(`self.data`, self.data);
					});
				self.call2
					.get("financial/byDay", {
						audited: self.search.audited ? 1 : 0,
						from: from.subtract(1, "month").format("YYYY-MM-DD"),
						to: to.subtract(1, "month").format("YYYY-MM-DD"),
						clusterId: cluster,
					})
					.subscribe((data) => {
						self.previousData = data.return;
						observer.next(data.return);
						console.log(`self.previousData`, self.previousData);
					});
			}
		});
	}
}
