import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SideNavItem } from "@durinn/v.3/app/template/template/side-nav/side-nav-routes.config";
import { DurinnV2CustomerSettingsComponent } from "@durinn/v.3/app/views/with-layout/customer/settings/settings.component";
import { DurinnV2ProfileSettingsComponent } from "@durinn/v.3/app/views/with-layout/profile/settings/settings.component";
import { DurinnV2TeamSettingsComponent } from "@durinn/v.3/app/views/with-layout/team/settings/settings.component";
import { DurinnV2UserSettingsComponent } from "@durinn/v.3/app/views/with-layout/user/settings/settings.component";
import { DefaultDashboardComponent } from "./default/default-dashboard.component";
import { ProfileComponent } from "./me/profile/profile.component";
import { UsuariosComponent } from "./usuarios/usuarios.component";
import { UsuarioComponent } from "./usuarios/pages/usuario/usuario.component";
import { TecnicosComponent } from "./tecnicos/tecnicos.component";
import { TecnicoComponent } from "./tecnicos/pages/tecnico/tecnico.component";
import { ClustersComponent } from "./clusters/clusters.component";
import { EditarClusterComponent } from "./clusters/pages/editar-cluster/editar-cluster.component";
import { FinanceiroComponent } from "./financeiro/financeiro/financeiro.component";
import { AgendasComponent } from "./agendas/agendas.component";
import { ImportarComponent } from "./agendas/pages/importar/importar.component";
import { ImportarTokenComponent } from "./agendas/pages/importar-token/importar-token.component";
import { RelatorioDeFechamentoComponent } from "./relatorios/pages/relatorio-de-fechamento/relatorio-de-fechamento.component";
import { AgendaComponent } from "./agendas/pages/agenda/agenda.component";
import { AlmoxarifadoComponent } from "./almoxarifado/almoxarifado.component";
import { CargaComponent } from "./carga/carga.component";
import { NovaOcorrenciaComponent } from "./almoxarifado/pages/nova-ocorrencia/nova-ocorrencia.component";
import { EquipesComponent } from "./equipes/equipes.component";
import { EditarEquipeComponent } from "./equipes/pages/editar-equipe/editar-equipe.component";
import { LotesComponent } from "./almoxarifado/pages/lotes/lotes.component";
import { EditarLoteComponent } from "./almoxarifado/pages/lotes/pages/editar-lote/editar-lote.component";
import { ComTecnicoComponent } from "./almoxarifado/pages/com-tecnico/com-tecnico.component";
import { BasesComponent } from "./bases/bases.component";
import { StreamingsDashboardComponent } from "./almoxarifado/pages/streamings/streamings-dashboard/streamings-dashboard.component";
import { CaixasComponent } from "./almoxarifado/pages/caixas/caixas.component";
import { EditarCaixaComponent } from "./almoxarifado/pages/caixas/pages/editar-caixa/editar-caixa.component";
import { ContasPagarComponent } from "./financeiro/contas/contas-pagar/contas-pagar.component";
import { ContasReceberComponent } from "./financeiro/contas/contas-receber/contas-receber.component";
import { RendimentoDosTecnicosComponent } from "./financeiro/rendimento-dos-tecnicos/rendimento-dos-tecnicos.component";
import { ClosureComponent } from "./me/closure/closure.component";
import { CustomersComponent } from "./customers/customers.component";
import { EditCustomersComponent } from "./customers/pages/edit-customers/edit-customers.component";
import { RelatoriosComponent } from "./relatorios/relatorios.component";
import { EmailCampaignComponent } from "./campanhas/email-campaign/email-campaign.component";
import { EmailCampaignViewComponent } from "./campanhas/email-campaign-view/email-campaign-view.component";
import { AgendasUrasComponent } from "./agendas/pages/agendas-uras/agendas-uras.component";
import { AgendasUrasViewComponent } from "./agendas/pages/agendas-uras/agendas-uras-view/agendas-uras-view.component";
import { AgendasUrasDashboardComponent } from "./agendas/pages/agendas-uras/agendas-uras-dashboard/agendas-uras-dashboard.component";
import { ImportsListComponent } from "./agendas/pages/imports-list/imports-list.component";
import { ImportsViewComponent } from "./agendas/pages/imports-list/imports-view/imports-view.component";
import { HolidaysCrudBoxComponent } from "./configuracoes/components/holidays-crud-box/holidays-crud-box.component";
import { ScheduleSettingsComponent } from "./configuracoes/components/schedule-settings/schedule-settings.component";
import {PERMISSIONS} from "@views/with-layout/usuarios/pages/usuario/components/permissions/permissions.component";
import {AgendamentoListComponent} from "./agendamento/components/list/list.component";
import {AgendamentoComponent} from "./agendamento/agendamento.component";
import {
	AgendamentosDashboardComponent
} from "@views/with-layout/agendamento/components/agendamentos-dashboard/agendamentos-dashboard.component";

export const sideMenu: SideNavItem[] = [
	{
		path: "/home",
		title: "Dashboard",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "dashboard",
		submenu: [],
	},
	{
		path: "/bases",
		title: "Bases",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "home",
		submenu: [],
	},
	{
		path: "/clientes",
		title: "Clientes",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "team",
		submenu: [],
	},
	{
		path: "",
		title: "Usuarios",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "user",
		submenu: [
			{
				path: "/usuarios",
				title: "Usuarios",
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "user",
				submenu: [],
			},
			{
				path: "/tecnicos",
				title: "Técnicos",
				iconType: "fontawesome",
				iconTheme: "fas",
				icon: "fa-id-badge",
				submenu: [],
			},
			{
				path: "/clusters",
				title: "Clusters",
				iconType: "fontawesome",
				iconTheme: "fas",
				icon: "fa-map",
				submenu: [],
				permissions: [PERMISSIONS.SEE_CLUSTERS],
			},
			{
				path: "/equipes",
				title: "Equipes",
				iconType: "fontawesome",
				iconTheme: "fas",
				icon: "fa-users",
				submenu: [],
				permissions: [PERMISSIONS.SEE_CLUSTERS],
			},
		],
	},
	{
		path: "",
		title: "Agendas",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "schedule",
		submenu: [
			{
				path: "/agendas",
				title: "Agendas",
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "schedule",
				submenu: [],
			},
			{
				path: "/agendas/uras",
				title: "Uras",
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "schedule",
				submenu: [],
			},
			{
				path: "/agendas/importar",
				title: "Importar",
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "upload",
				submenu: [],
			},
			// {
			// 	path: "/agendas/importacoes",
			// 	title: "Importações",
			// 	iconType: "nzIcon",
			// 	iconTheme: "outline",
			// 	icon: "schedule",
			// 	submenu: [],
			// }
		],
	},
	{
		title: "Campanhas",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "container",
		path: "/campanhas",
		submenu: [],
	},
	{
		title: "Agendamentos",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "container",
		path: "",
		submenu: [
			{
				path: "/agendamentos/dashboard",
				title: "Dashboard",
				submenu: [],
				permissions: [PERMISSIONS.SEE_WAREHOUSE],
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "container",
			},
			{
				path: "/agendamentos",
				title: "Agendamentos",
				submenu: [],
				permissions: ['SEE_CONTRACT_SCHEDULE'],
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "container",
			}
		],
	},
	{
		path: "",
		title: "Almoxarifado",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "dropbox",
		submenu: [
			{
				path: "/almoxarifado/lotes",
				title: "Lotes",
				submenu: [],
				permissions: [PERMISSIONS.SEE_WAREHOUSE],
			},
			{
				path: "/almoxarifado/caixas",
				title: "Caixas",
				submenu: [],
				permissions: [PERMISSIONS.SEE_WAREHOUSE],
			},
			{
				path: "/almoxarifado/streamings/dashboard",
				title: "Streamings",
				submenu: [],
				permissions: [PERMISSIONS.SEE_WAREHOUSE],
			},
			{
				path: "/almoxarifado",
				title: "Almoxarifado",
				submenu: [],
				permissions: [PERMISSIONS.SEE_WAREHOUSE],
			}
		],
		permissions: [PERMISSIONS.SEE_WAREHOUSE],
	},
	{
		path: "",
		title: "Financeiro",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "dollar",
		submenu: [
			{
				path: "/financeiro/financeiro",
				title: "Financeiro",
				submenu: [],
				permissions: [PERMISSIONS.SEE_FINANCIAL],
			},
			{
				path: "/financeiro/rendimento",
				title: "Rendimento dos Técnicos",
				submenu: [],

				permissions: [PERMISSIONS.SEE_FINANCIAL],
			},
			{
				path: "/financeiro/contas-pagar",
				title: "Contas a Pagar",
				submenu: [],
				permissions: [PERMISSIONS.SEE_FINANCIAL],
			},
			{
				path: "/financeiro/contas-receber",
				title: "Contas a Receber",
				submenu: [],
				permissions: [PERMISSIONS.SEE_FINANCIAL],
			},
		],
		permissions: [PERMISSIONS.SEE_FINANCIAL],
	},
	{
		path: "/relatorios",
		title: "Relatórios",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "user",
		permissions: [PERMISSIONS.SEE_CLUSTERS],
		submenu: [],
	},
	{
		path: "",
		title: "Configurações",
		iconType: "nzIcon",
		iconTheme: "outline",
		icon: "setting",
		submenu: [
			{
				path: "/configuracoes/feriados",
				title: "Feriados",
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "setting",
				submenu: [],
				permissions: [],
			},
			{
				path: "/configuracoes/agendas",
				title: "Agendamento E-mail",
				iconType: "nzIcon",
				iconTheme: "outline",
				icon: "setting",
				submenu: [],
				permissions: [],
			},
			// {
			// 	path: "/configuracoes/cadastro-clientes",
			// 	title: "Clientes",
			// 	iconType: "nzIcon",
			// 	iconTheme: "outline",
			// 	icon: "team",
			// 	permissions: ["SETTINGS"],
			// },
			// {
			// 	path: "/configuracoes/cadastro-usuarios",
			// 	title: "Usuários",
			// 	iconType: "nzIcon",
			// 	iconTheme: "outline",
			// 	icon: "user-add",
			// 	permissions: ["SETTINGS"],
			// },
			// {
			// 	path: "/configuracoes/cadastro-equipes",
			// 	title: "Equipes",
			// 	iconType: "nzIcon",
			// 	iconTheme: "outline",
			// 	icon: "team",
			// 	submenu: [],
			// 	permissions: ["SETTINGS"],
			// },
			// {
			// 	path: "/perfis",
			// 	title: "Perfis",
			// 	iconType: "nzIcon",
			// 	iconTheme: "outline",
			// 	icon: "user-add",
			// 	submenu: [],
			// 	permissions: ["CRUD_PROFILES"],
			// }
		],
	}
];

const routes: Routes = [
	{
		path: "home",
		component: DefaultDashboardComponent,
		data: {
			title: "Dashboard ",
			headerDisplay: "none",
		},
	},
	{
		path: "perfis",
		data: {
			title: "Perfis",
		},
		children: [
			{
				path: "",
				component: DurinnV2ProfileSettingsComponent,
			},
		],
	},
	{
		path: "bases",
		component: BasesComponent,
		data: {
			title: "Bases ",
			headerDisplay: "none",
		},
	},
	{
		path: "me/profile",
		component: ProfileComponent,
		data: {
			title: "Meu Perfil ",
		},
	},
	{
		path: "clientes",
		children: [
			{
				path: "",
				component: CustomersComponent,
				data: {
					title: "Clientes",
				},
			},
			{
				path: "novo",
				component: EditCustomersComponent,
				data: {
					title: "Adicionar",
				},
			},
			{
				path: "editar/:id",
				redirectTo: ":id",
			},
			{
				path: ":id",
				data: {
					title: "Editar",
				},
				children: [
					{
						path: "",
						component: EditCustomersComponent,
					},
				],
			},
		],
	},
	{
		path: "me/horarios",
		component: ClosureComponent,
		data: {
			title: "Meus Horários",
		},
	},
	{
		path: "configuracoes",
		children: [
			{
				path: "feriados",
				component: HolidaysCrudBoxComponent,
				data: {
					title: "Feriados",
				},
			},
			{
				path: "agendas",
				component: ScheduleSettingsComponent,
				data: {
					title: "Agendas",
				},
			},
			{
				data: {
					title: "Clientes",
				},
				path: "cadastro-clientes",
				component: DurinnV2CustomerSettingsComponent,
			},
			{
				data: {
					title: "Usuários",
				},
				path: "cadastro-usuarios",
				component: DurinnV2UserSettingsComponent,
			},
			{
				data: {
					title: "Equipes",
				},
				path: "cadastro-equipes",
				component: DurinnV2TeamSettingsComponent,
			}
		]
	},
	{
		path: "relatorios",
		data: {
			title: "Relatórios",
		},
		children: [
			{
				path: "",
				component: RelatoriosComponent,
			},
		]
	},
	{
		path: "usuarios",
		data: {
			title: "Usuários",
		},
		children: [
			{
				path: "",
				component: UsuariosComponent,
			},
			{
				path: "editar/:id",
				data: {
					title: "Editar",
				},
				children: [
					{
						path: "",
						component: UsuarioComponent,
					},
					{
						path: "relatorios",
						data: {
							title: "Relatórios",
						},
						children: [
							{
								path: "fechamento",
								component: RelatorioDeFechamentoComponent,
								data: {
									title: "Relatório de Fechamento",
									type: "users",
								},
							},
						],
					},
				],
			},
			{
				path: ":id",
				redirectTo: "editar/:id"
			},
			{
				path: "novo",
				component: UsuarioComponent,
				data: {
					title: "Adicionar",
				},
			},
		],
	},
	{
		path: "equipes",
		data: {
			title: "Equipes",
		},
		children: [
			{
				path: "",
				component: EquipesComponent,
			},
			{
				path: "editar/:id",
				data: {
					title: "Editar",
				},
				children: [
					{
						path: "",
						component: EditarEquipeComponent,
					},
				],
			},
			{
				path: ":id",
				redirectTo: "editar/:id"
			},
			{
				path: "novo",
				component: EditarEquipeComponent,
				data: {
					title: "Adicionar",
				},
			},
		],
	},
	{
		path: "agendas",
		data: {
			title: "Agendas",
		},
		children: [
			{
				path: "",
				component: AgendasComponent,
			},
			// {
			// 	path: "importacoes",
			// 	component: ImportsListComponent,
			// 	data: {
			// 		title: "Importações",
			// 	},
			// },
			// {
			// 	path: "importacoes/:id",
			// 	component: ImportsViewComponent,
			// 	data: {
			// 		title: "Ver Importação",
			// 	},
			// },
			{
				path: "importar",
				component: ImportarComponent,
				data: {
					title: "Importar",
				},
			},
			{
				path: "importar/:token",
				component: ImportarTokenComponent,
				data: {
					title: "Importar",
				},
			},
			{
				path: "uras",
				component: AgendasUrasComponent,
				data: {
					title: "Uras",
				},
			},
			{
				path: "uras/dashboard",
				component: AgendasUrasDashboardComponent,
				data: {
					title: "Uras",
				},
			},
			{
				path: "uras/nova",
				component: AgendasUrasViewComponent,
				data: {
					title: "Adicionar Agenda",
				},
			},
			{
				path: "uras/editar/:id",
				component: AgendasUrasViewComponent,
				data: {
					title: "Ver Agenda",
				},
			},
			{
				path: "uras/:id",
				redirectTo: "uras/editar/:id"
			},
			{
				path: ":id",
				component: AgendaComponent,
				data: {
					title: "Agenda",
				},
			},
		],
	},
	{
		path: "campanhas",
		data: {
			title: "Campanhas",
		},
		children: [
			{
				path: "",
				component: EmailCampaignComponent,
				data: {
					title: "Campanhas Criadas",
					headerDisplay: "none"
				}
			},
			{
				path: "email",
				component: EmailCampaignComponent,
				data: {
					title: "Campanhas Criadas",
				}
			},
			{
				path: "email/:id",
				component: EmailCampaignViewComponent,
				data: {
					title: "Ver Campanha",
				},
			},
		],
	},
	{
		path: "tecnicos",
		data: {
			title: "Técnicos",
		},
		children: [
			{
				path: "",
				component: TecnicosComponent,
			},
			{
				path: "editar/:id",
				data: {
					title: "Editar",
				},
				children: [
					{
						path: "",
						component: TecnicoComponent,
					},
					{
						path: "relatorios",
						data: {
							title: "Relatórios",
						},
						children: [
							{
								path: "fechamento",
								component: RelatorioDeFechamentoComponent,
								data: {
									title: "Relatório de Fechamento",
									type: "deliverymen",
								},
							},
						],
					},
				],
			},
			{
				path: ":id",
				redirectTo: "editar/:id"
			},
			{
				path: "novo",
				component: TecnicoComponent,
				data: {
					title: "Adicionar",
				},
			},
		],
	},
	{
		path: "clusters",
		data: {
			title: "Clusters",
		},
		children: [
			{
				path: "",
				component: ClustersComponent,
			},
			{
				path: "editar/:id",
				component: EditarClusterComponent,
				data: {
					title: "Editar",
				},
			},
			{
				path: ":id",
				redirectTo: "editar/:id"
			},
			{
				path: "novo",
				component: EditarClusterComponent,
				data: {
					title: "Adicionar",
				},
			},
		],
	},
	{
		path: "almoxarifado",
		data: {
			title: "Almoxarifado",
		},
		children: [
			{
				path: "",
				component: AlmoxarifadoComponent,
			},
			{
				path: "streamings",
				data: {
					title: "Streamings",
				},
				children: [
					{
						path: "dashboard",
						component: StreamingsDashboardComponent,
					},
				],
			},
			{
				path: "caixas",
				data: {
					title: "Caixas",
				},
				children: [
					{
						path: "",
						component: CaixasComponent,
					},
					{
						path: ":id",
						component: EditarCaixaComponent,
					},
				],
			},
			{
				path: "lotes",
				component: LotesComponent,
			},
			{
				path: "com-tecnico",
				component: ComTecnicoComponent,
			},
			{
				path: "lotes/novo",
				component: EditarLoteComponent,
			},
			{
				path: "lotes/:id",
				component: EditarLoteComponent,
			},
			{
				path: "lotes/:idLot",
				component: AlmoxarifadoComponent,
			},
			{
				path: "nova-ocorrencia",
				component: NovaOcorrenciaComponent,
			},
		],
	},
	{
		path: "carga",
		data: {
			title: "Carga",
		},
		children: [
			{
				path: ":barcode",
				component: CargaComponent,
			},
		],
	},
	{
		path: "financeiro",
		data: {
			title: "Financeiro",
		},
		children: [
			{
				data: {
					title: "Financeiro",
				},
				path: "financeiro",
				component: FinanceiroComponent,
			},
			{
				data: {
					title: "Rendimento dos Técnicos",
				},
				path: "rendimento",
				component: RendimentoDosTecnicosComponent,
			},
			{
				data: {
					title: "Contas a Pagar",
				},
				path: "contas-pagar",
				component: ContasPagarComponent,
			},
			{
				data: {
					title: "Contas a Receber",
				},
				path: "contas-receber",
				component: ContasReceberComponent,
			},
		],
	},
	{
		path: "agendamentos",
		data: {
			title: "Agendamentos",
		},
		children: [
			{
				path: "",
				component: AgendamentoListComponent,
			},
			{
				path: "dashboard",
				component: AgendamentosDashboardComponent,
			},
			{
				path: "editar/:id",
				component: AgendamentoComponent,
				data: {
					title: "Editar",
				},
			},
			{
				path: "novo",
				component: AgendamentoComponent,
				data: {
					title: "Adicionar",
				},
			}
		]
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class WithLayoutRoutingModule {}
