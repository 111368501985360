import { Component } from "@angular/core";

@Component({
	selector: "durinn-v2-team-setting",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.css"],
})
export class DurinnV2TeamSettingsComponent {
	teamDefaultFields = [
		{ field: "Nome", mask: "", required: true },
		{ field: "Observações", mask: "", required: false },
	];
}
