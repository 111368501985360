import { Component, OnInit } from "@angular/core";
import {
	EditPage,
	EditPageInterface,
} from "../../../../../../../bases/edit.page";
import { Device_Scans_Type } from "../../../../almoxarifado.component";
import Swal from "sweetalert2";
import * as moment from "moment";
import { DEVICESTATUS_CATEGORY } from "../../../../../../../constants";

export const Device_Type = [
	"APARELHO",
	"NAGRA",
	"CHIP",
	"CAMERA",
	"INDEFINIDO",
];

@Component({
	selector: "app-editar-lote",
	templateUrl: "./editar-lote.component.html",
	styleUrls: ["./editar-lote.component.css"],
})
export class EditarLoteComponent extends EditPage implements EditPageInterface {
	form: any = {
		name:
			moment().format("YYYYMMDD") +
			"-" +
			new Date().getTime().toString(16).toUpperCase().substr(5, 4),
		hasBarcode: 1,
		quantity: 10,
		Devices: [],
	};

	permissions = {};

	new = {
		hasBarcode: 1,
		quantity: 10,
		barcodes: "",
		type: "",
		scanType: 1,
		scanCategory: 0,
		branchId: null,
	};

	back_to = "/almoxarifado/lotes";
	route = "warehouselot";

	devices_type = Device_Type;
	Device_Scans_Type = Device_Scans_Type;
	DEVICESTATUS_CATEGORY = DEVICESTATUS_CATEGORY;

	adicionar_modal = false;

	checked = {};

	filters: any = {
		type: "",
		barcode: "",
		status: "",
		category: -1,
		branch: null,
	};

	Object = Object;
	uniqid = Math.random().toString(36).substr(2, 9);

	ngOnInit(): void {
		this.checked = {};
		super.ngOnInit();
	}

	afterGet() {
		const self = this;

		if (!self.form.name) {
			self.form.name =
				moment().format("YYYYMMDD") +
				"-" +
				new Date().getTime().toString(16).toUpperCase().substr(5, 4);
		}
	}

	toggleCheckAll() {
		const self = this;

		if (self.getCheckeds().length > 0) {
			for (const item in self.form.Devices) {
				this.checked[item] = false;
			}
		} else {
			for (const item in self.form.Devices) {
				this.checked[item] = true;
			}
		}
	}

	getCheckeds() {
		const self = this;
		const resp = [];

		for (const item in this.checked) {
			if (self.checked[item]) {
				resp.push(self.form.Devices[item]);
			}
		}

		return resp;
	}

	addDevices() {
		const self = this;

		console.log(`self.new.type`, self.new.type);

		if (self.new.hasBarcode) {
			const barcodes = self.new.barcodes.split("\n");

			for (const barcode of barcodes) {
				let found = false;

				for (const d in self.form.Devices) {
					if (self.form.Devices[d].barcode == barcode) {
						found = true;
						self.form.Devices[d].scanType = +self.new.scanType;
						self.form.Devices[d].type = self.new.type;
						self.form.Devices[d].LastScan = null;
						self.form.Devices[d].scanCategory =
							+self.new.scanCategory;
					}
				}

				if (!found) {
					self.form.Devices.push(
						Object.assign(
							{},
							{
								type: self.new.type,
								scanType: +self.new.scanType,
								branchId: self.new.branchId
									? +self.new.branchId
									: null,
								scanCategory: +self.new.scanCategory,
								barcode: barcode,
							}
						)
					);
					console.log(':', {
						type: self.new.type,
						scanType: +self.new.scanType,
						branchId: self.new.branchId
							? +self.new.branchId
							: null,
						scanCategory: +self.new.scanCategory,
						barcode: barcode,
					});
				}
			}

			self.new.barcodes = "";
			self.app.alert(
				`Sucesso`,
				`Códigos de barras adicionados com sucesso!`,
				`success`
			);
		} else {
			for (let i = 0; i < self.new.quantity; i++) {
				self.form.Devices.push(
					Object.assign(
						{},
						{
							type: self.new.type,
							scanType: self.new.scanType,
							scanCategory: self.new.scanCategory,
							barcode:
								self.uniqid +
								Math.random().toString(36).substr(2, 9),
						}
					)
				);

				console.log(':)', {
					type: self.new.type,
					scanType: self.new.scanType,
					scanCategory: self.new.scanCategory,
					barcode:
						self.uniqid +
						Math.random().toString(36).substr(2, 9),
				});
			}

			self.app.alert(
				`Sucesso`,
				`Códigos de barras gerados e adicionados com sucesso!`,
				`success`
			);
		}

		self.adicionar_modal = false;
	}

	async see(item) {
		if (!item.id_device) {
			return false;
		}

		const confirm = await Swal.fire({
			title: "Almoxarifado",
			html:
				"Você deseja ver o item de código de barras <b> " +
				item.barcode +
				"</b>?",
			icon: "question",
			showCloseButton: true,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: "Ver Item",
			cancelButtonText: "Cancelar",
		});
		if (confirm.value) {
			const win = window.open("carga/" + item.barcode, "_blank");
			win.focus();
		}
	}

	async removeDevice(index) {
		const self = this;

		if (await self.app.confirm()) {
			self.form.Devices.splice(index, 1);
		}
	}

	hidden(item) {
		const self = this;

		if (self.filters.type) {
			if (
				(item.type || "")
					.toUpperCase()
					.indexOf((self.filters.type || "").toUpperCase()) == -1
			) {
				return true;
			}
		}

		if (self.filters.barcode) {
			if (
				(item.barcode || "")
					.toUpperCase()
					.indexOf((self.filters.barcode || "").toUpperCase()) == -1
			) {
				return true;
			}
		}

		if (+self.filters.category === 0 || +self.filters.category === 1) {
			if (
				item.LastScan &&
				+self.filters.category != item.LastScan.category
			) {
				return true;
			}
		}

		return false;
	}

	changeListener($event): void {
		this.readThis($event.target);
	}

	readThis(inputValue: any): void {
		const self = this;
		const file: File = inputValue.files[0];
		const myReader: FileReader = new FileReader();

		myReader.onloadend = (e) => {
			self.form.invoiceUrl = myReader.result as string;
		};

		myReader.readAsDataURL(file);
	}

	newOccurence() {
		const self = this;
		const checkeds = self.getCheckeds();

		self.new.hasBarcode = 1;
		self.new.scanType = null;

		self.new.type = checkeds[0].type;

		if (checkeds[0].LastScan) {
			self.new.scanCategory = checkeds[0].LastScan.category;
		}

		self.new.barcodes = self
			.getCheckeds()
			.map((x) => x.barcode)
			.join("\n");
		self.adicionar_modal = true;
	}

	async removeFromLot(item) {
		const self = this;
		if (await self.app.confirm()) {
			self.call
				.put("device/" + item.barcode, {
					warehouseLotId: null,
					lot: null,
				})
				.subscribe((data) => {
					if (data.success) {
						self.app.alert(
							`Sucesso`,
							`Item removido do lote!`,
							`success`
						);
						self.ngOnInit();
					}
				});
		}
	}

	printLabels() {
		const self = this;
		this.call.openLink(`device/printLabels`, {
			barcodes: self.getCheckeds().map((x) => x.barcode),
		});
	}

	mudar_status = {
		modal: false,
		hasBarcode: 1,
		quantity: 10,
		barcodes: "",
		type: "",
		scanType: 1,
		scanCategory: 0,
		branchId: null,
	};
	changeStatusOccurence() {
		this.mudar_status.modal = true;
		this.mudar_status.barcodes = this.getCheckeds()
			.map((x) => x.barcode)
			.join("\n");
	}

	updateStatus() {
		const self = this;

		for (const item in this.checked) {
			if (self.checked[item]) {
				self.form.Devices[item].scanType = +self.mudar_status.scanType;
				self.form.Devices[item].branchId = +self.mudar_status.branchId;
				self.form.Devices[item].LastScan = null;
				self.form.Devices[item].scanCategory =
					+self.mudar_status.scanCategory;
			}
		}

		this.mudar_status.modal = false;
	}

	async exportToCsv() {
		const self = this;
		const route = `warehouselot/download-csv/${self.id}`;
		self.call.openLink(route);
	}
}
