import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { BasePage } from "../../../../../../../bases/base.page";
import Geolocation from "@helpers/geolocation";

@Component({
	selector: "tracker-map",
	templateUrl: "./tracker-map.component.html",
	styleUrls: ["./tracker-map.component.css"],
})
export class TrackerMapComponent extends BasePage {
	call = this.api.new();
	points = [];
	map: any;
	bounds: any;
	moment = moment;
	index = "tracks";
	google = window["google"];

	@Input() id = null;
	@Input() date = moment().format("DD/MM/YYYY");

	time = 5;
	stopedTime = 0;

	modal = {
		visible: false,
		latitude: 0,
		longitude: 0,
	};

	ngOnInit(): void {
		super.ngOnInit();
	}

	ngOnChanges() {
		this.get();
	}

	mapReady(map) {
		const self = this;
		this.map = map;
	}

	lines: { points: any[]; color: string; visible: boolean }[] = [];
	get() {
		const self = this;
		if (self.id) {
			self.call
				.post("deliverymen/" + self.id + "/geolocation/search/0/1000", {
					where: {
						Geolocation: {
							"Op.and": [
								{
									valid: 1,
								},
								{
									device_date: {
										"Op.gte": moment(
											self.date,
											"DD/MM/YYYY"
										)
											.startOf("day")
											.toDate(),
									},
								},
								{
									device_date: {
										"Op.lte": moment(
											self.date,
											"DD/MM/YYYY"
										)
											.endOf("day")
											.toDate(),
									},
								},
							],
						},
					},
					order: { Geolocation: [["device_date", "desc"]] },
				})
				.subscribe((data) => {
					let i = 0;
					let lastPoint = null;

					for (const item of data.return) {
						if (lastPoint) {
							if (
								moment(lastPoint.device_date).diff(
									moment(item.device_date),
									"minutes"
								) > 10
							) {
								i++;
							}
						}

						if (!self.lines[i]) {
							self.lines[i] = {
								points: [],
								color: self.getRandomColor(),
								visible: true,
							};
						}

						self.lines[i].points.push(item);

						lastPoint = item;
					}

					console.log(`lines`, self.lines);

					self.points = data.return;
					self.bounds = new window["google"].maps.LatLngBounds();

					for (const point of self.points) {
						self.bounds.extend(
							new window["google"].maps.LatLng(
								point.latitude,
								point.longitude
							)
						);
					}

					self.mountStops();
				});
		}
	}

	getRandomColor() {
		const letters = "0123456789".split("");
		let color = "#";
		for (let i = 0; i < 6; i++) {
			color += letters[Math.round(Math.random() * 10)];
		}
		return color;
	}

	stops: {
		lat: number;
		lng: number;
		radius: number;
		time: number;
		startedAt: Date;
		endedAt: Date;
		infoWindow: boolean;
	}[] = [];
	mountStops() {
		const self = this;
		const radius = 100;

		let lastPoint = null;
		let stop = undefined;

		self.stops = [];

		for (const point of self.points) {
			if (lastPoint) {
				const initialTime = moment(lastPoint.register_date);
				const distance = Geolocation.distance(
					lastPoint.latitude,
					lastPoint.longitude,
					point.latitude,
					point.longitude
				);
				const finalTime = moment(point.register_date);
				const elapsedTime = initialTime.diff(finalTime, "minutes");

				if (distance < radius) {
					if (+elapsedTime > self.time) {
						const middle = Geolocation.middlePoint(
							lastPoint.latitude,
							lastPoint.longitude,
							point.latitude,
							point.longitude
						);
						stop = {
							lat: middle.latitude,
							lng: middle.longitude,
							radius: distance < 20 ? 20 : distance,
							time: elapsedTime,
							endedAt: initialTime.toDate(),
							startedAt: finalTime.toDate(),
							infoWindow: false,
						};
					}
					console.log(`distance < radius`);
				} else {
					console.log(`distance > radius`);
					if (stop) {
						self.stops.push(stop);
						self.stopedTime += stop.time;
					}
					stop = undefined;
					lastPoint = point;
				}

				// console.log(`dis`, distance);
			} else {
				lastPoint = point;
			}
		}

		if (stop) {
			self.stops.push(stop);
			self.stopedTime += stop.time;
			stop = undefined;
		}

		self.stops.reverse();
	}
}
