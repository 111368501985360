import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'maxEllipsis'
})
export class MaxEllipsisPipe implements PipeTransform {

	transform(value: any, max = 20): string {
	  if(value.toString().length <= max){
		  return value.toString();
	  }

	  return value.toString().substr(0, +max -3) + '...';
	}

}
