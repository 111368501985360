import { Component, HostListener, OnInit } from "@angular/core";
import { CrudPage } from "../../../../../../../../bases/crud.page";
import {
	Device_Scans_Type,
	Device_Type,
} from "../../../../../almoxarifado.component";
import * as moment from "moment";
import { DEVICESTATUS_CATEGORY } from "../../../../../../../../constants";
import * as $ from "jquery";

@Component({
	selector: "app-com-tecnico-list",
	templateUrl: "./com-tecnico-list.component.html",
	styleUrls: ["./com-tecnico-list.component.css"],
})
export class ComTecnicoListComponent extends CrudPage {
	public keypressed;

	@HostListener("window:keydown", ["$event"])
	handleKeyboardEvent(event: KeyboardEvent) {
		this.keypressed = event.keyCode || event.which;
		if (this.keypressed == 13 && $(".fade.show").length == 0) {
			if (document.activeElement instanceof HTMLElement) {
				document.activeElement.blur();
			}
			this.get(1);
		}
	}

	route = "warehouse";
	limit = 10;
	filters: any = {
		device: {
			type: "streamings",
		},
		last_scan: {
			type: 0,
		},
		last_scan_branch: {},
	};
	order = {
		last_scan: [["register_date", "asc"]],
	};

	Object = Object;

	moment = moment;
	Device_Scans_Type = Device_Scans_Type;
	status = Device_Scans_Type;

	DEVICESTATUS_CATEGORY = DEVICESTATUS_CATEGORY;

	isLate(date) {
		return moment(date).isBefore(moment().subtract(14, "day"));
	}
}
