import { Component, ElementRef, ViewChild } from "@angular/core";
import { FilterOrderBase } from "../filter-order.base";

@Component({
	selector: "durinn-v2-filter-order-number",
	templateUrl: "./filter-order-number.component.html",
	styleUrls: ["./filter-order-number.component.css"],
})
export class FilterOrderNumberComponent extends FilterOrderBase {
	value: string | string[] = "";
	modalVisible = false;

	type: "value" | "range" = "value";

	from = "";
	to = "";

  @ViewChild("inputTo", { static: false }) inputTo: ElementRef;
  @ViewChild("inputFrom", { static: false }) inputFrom: ElementRef;

  ngOnInit() {}

  isArray(object) {
  	return Array.isArray(object);
  }

  setFocus() {
  	this.inputFrom.nativeElement.focus();
  }

  isInvalid() {
  	const self = this;

  	if (self.type == "value" && !self.from) {
  		return true;
  	}

  	if (self.type == "range" && (!self.from || self.to < self.from)) {
  		return true;
  	}

  	return false;
  }

  handleOk() {
  	const self = this;

  	if (self.isInvalid()) {
  		return false;
  	}

  	if (self.isInvalid()) {
  		return false;
  	}

  	if (self.type == "value") {
  		self.page.filters[self.index][self.column] = { "Op.like": self.from };
  		self.value = self.from;
  	}

  	if (self.type == "range") {
  		self.value = [self.from, self.to];
  		self.page.filters[self.index][self.column] = {
  			"Op.between": [self.from, self.to],
  		};
  	}

  	self.changedModel();
  	self.modalVisible = false;
  }
}
