import { BasePage } from '../../../../../bases/base.page';
import { Component } from '@angular/core';

@Component({
	selector: 'app-comparacao-de-carga',
	templateUrl: './comparacao-de-carga.component.html',
	styleUrls: ['./comparacao-de-carga.component.css']
})
export class ComparacaoDeCargaComponent extends BasePage {
	call = this.api.new();

	args = {
		insideFile: {
			file: null,
			textfile: null
		},
		outsideFile: {
			file: null,
			textfile: null
		}
	};

	get isLoading() {
		const self = this;
		return self.call.loading;
	}

	get isDisabled() {
		const self = this;
    
		const conditions = [
			self.args.insideFile.file != null,
			self.args.outsideFile.file != null,
			self.args.insideFile.textfile != null,
			self.args.outsideFile.textfile != null
		];

		return conditions.includes(false);
	}

	async handleUpload(event, field) {
		const self = this;
		const file = event.target.files[0];
		const fileReader = new FileReader();
		const textReader = new FileReader();

		if (file.type != "text/csv") {
			await self.app.alert("Arquivo", "Arquivo não permitido! Somente arquivos com extensão .csv podem ser utilizados.");
		}

		// File
		fileReader.readAsDataURL(file);
		fileReader.onload = () => {
			self.args[field].file = fileReader.result;
		};

		// Text
		textReader.readAsText(file);
		textReader.onload = () => {
			self.args[field].textfile = textReader.result;
		};
	}

	async onSubmit() {
		const self = this;

		self.call
			.post("warehouse/reports/barcodes-comparison", self.args)
			.subscribe(response => {
				self.window.location.href = response.return.url;
			});
	}

	onReset() {
		const self = this;
		self.args = {
			insideFile: {
				file: null,
				textfile: null
			},
			outsideFile: {
				file: null,
				textfile: null
			}
		};
	}
}
