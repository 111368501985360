import { Component } from "@angular/core";
import {PageBase} from "@durinn.v3/app/bases/page.base";
import {
	DurinnV2ListPageTableComponent,
	HeadColumns
} from "@durinn.v3/app/components/template/tables/list-page-table/list-page-table.component";

@Component({
	selector: "app-clusters",
	templateUrl: "./clusters.component.html",
	styleUrls: ["./clusters.component.css"],
})
export class ClustersComponent extends PageBase {
	filters = {
		Cluster: {
		},
	};

	order = {
		Cluster: [["name", "asc"]],
	};

	headColumns: HeadColumns = DurinnV2ListPageTableComponent.defaultHeadColumnsWith('Cluster', [
		{ index: "Cluster", column: "description", name: "Descrição" }
	]);
}
