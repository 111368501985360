import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	ListBaseInterface,
	ListBasePage,
} from "src/app/durinn/bases/listpage.base";
import * as moment from "moment";

@Component({
	selector: "app-contas-default",
	templateUrl: "./contas-default.component.html",
	styleUrls: ["./contas-default.component.css"],
})
export class ContasDefaultComponent
	extends ListBasePage
	implements ListBaseInterface {
	window = window;

	route = "";
	modalVisible = false;
	fb: FormBuilder = new FormBuilder();
	formGroupBill: FormGroup;
	idBill: number;
	load = this.api.new();
	subsidiaries = [];
	permission = "";
	tab = "pending";

	payToTypeList = [
		{ name: "Não Informado", value: 0 },
		{ name: "Técnico", value: 1 },
		{ name: "Usuário", value: 2 },
		{ name: "Outro", value: 3 },
	];

	payUserTo = null;
	paidUserBy = null;
	payDeliverymanTo = null;
	paidUserByList = [];
	payUserToList = [];
	payDeliverymanToList = [];
	getUsersCall = this.api.new();
	payToTypeModel = "";

	@ViewChild("inputBill", { static: false }) inputBill: ElementRef;
	@ViewChild("inputProof", { static: false }) inputProof: ElementRef;

	resetFileInputs() {
		this.inputBill.nativeElement.value = "";
		this.inputProof.nativeElement.value = "";
	}

	ngOnInit() {
		super.ngOnInit();
		this.getUsers("", "payUserToList", "users", "User");
		this.getUsers("", "paidUserByList", "users", "User");
		this.getUsers("", "payDeliverymanToList", "deliverymen", "Deliveryman");
	}

	get pendingFilters() {
		return {};
	}

	get settledFilters() {
		return {};
	}

	changeTab(tab: "pending" | "settled" | "inactives") {
		const self = this;

		if (self.tab !== tab) {
			self.tab = tab;
			const tabName =
				tab == "pending" ? self.pendingFilters : self.settledFilters;
			const filterName = self.filters.BillToPay
				? "BillToPay"
				: "BillToReceive";

			if (tab == "inactives") {
				self.filters[filterName] = { active: { "Op.eq": 0 } };
			} else {
				self.filters[filterName] = tabName;
			}

			self.ngOnInit();
		}
	}

	isLate(item) {
		return moment(item.dueDate).isSameOrBefore(moment(), "day");
	}

	getUsers(text: string, field: string, route: string, whereIndex: string) {
		const self = this;
		const where = {
			[whereIndex]: {
				"Op.or": [
					{ id: { "Op.like": `%${text.split(" ").join("%")}%` } },
					{ name: { "Op.like": `%${text.split(" ").join("%")}%` } },
					{ cpf: { "Op.like": `%${text.split(" ").join("%")}%` } },
				],
			},
		};

		self.getUsersCall
			.post(`${route}/search/0/10`, { where })
			.subscribe((response) => {
				self[field] = response.return.rows;
			});
	}

	createForm(model) {
		this.formGroupBill = this.fb.group(model);
		this.setValidators();
	}
	setValidators() {
		this.formGroupBill.controls["payToType"].setValidators([
			Validators.required,
		]);
		this.formGroupBill.controls["dueDate"].setValidators([
			Validators.required,
		]);
		this.formGroupBill.controls["amount"].setValidators([
			Validators.required,
		]);
	}
	setFormGroup(bill) {
		this.modalVisible = true;
		this.formGroupBill.patchValue(bill);
		this.payDeliverymanTo = bill.PayDeliveryman;
		this.paidUserBy = bill.PaidUser;
		this.payUserTo = bill.PayUser;
		this.idBill = bill.id;
	}
	modalCancel() {
		this.formGroupBill.reset();
		this.modalVisible = false;
		this.idBill = null;
		this.paidUserBy = null;
		this.payUserTo = null;
		this.payDeliverymanTo = null;
	}

	enterPayName(name) {
		const self = this;
		if (self.filters.BillToPay) {
			if (!name) {
				if (self.filters.BillToPay["Op.or"]) {
					delete self.filters.BillToPay["Op.or"];
				}
			} else {
				self.filters.BillToPay["Op.or"] = [
					...(self.filters.BillToPay["Op.or"] || []),
					{ "$PayUser.name$": { "Op.like": `%${name}%` } },
					{ "$PayDeliveryman.name$": { "Op.like": `%${name}%` } },
					{ other: { "Op.like": `%${name}%` } },
				];
			}
		} else {
			if (!name) {
				if (self.filters.BillToReceive["Op.or"]) {
					if (self.filters.BillToReceive["Op.or"]) {
						delete self.filters.BillToReceive["Op.or"];
					}
				}
			} else {
				self.filters.BillToReceive["Op.or"] = [
					...(self.filters.BillToReceive["Op.or"] || []),
					{ "$PayUser.name$": { "Op.like": `%${name}%` } },
					{ "$PayDeliveryman.name$": { "Op.like": `%${name}%` } },
					{ other: { "Op.like": `%${name}%` } },
				];
			}
		}

		this.get();
	}

	doEnter(event, field, subfield = null) {
		if (this.filters.BillToPay) {
			console.log({
				event,
				field,
				subfield
			});

			if (subfield) {
				if (!this.filters[field]) {
					this.filters[field] = {};
				}

				if (event) {
					this.filters[field][subfield] = event;
				} else {
					delete this.filters[field][subfield];
				}
			} else {
				if (event) {
					this.filters.BillToPay[field] = event;
				} else {
					delete this.filters.BillToPay[field];
				}
			}
		} else {
			if (subfield) {
				if (!this.filters[field]) {
					this.filters[field] = {};
				}

				if (event) {
					this.filters[field][subfield] = event;
				} else {
					delete this.filters[field][subfield];
				}
			} else {
				if (event) {
					this.filters.BillToReceive[field] = event;
				} else {
					delete this.filters.BillToReceive[field];
				}
			}
		}
		this.get();
	}
	orderChanged(event) {
		if (this.order["BillToPay"]) {
			this.order["BillToPay"] = event;
		} else {
			this.order["BillToReceive"] = event;
		}
		this.get();
	}
	handleUpload(event, field) {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			this.formGroupBill.controls[field].setValue(reader.result);
			console.log(this.formGroupBill.value[field]);
		};
	}
	submitBill() {
		if (!this.formGroupBill.valid) {
			this.formGroupBill.markAllAsTouched();
			return;
		}

		const dateType =
			this.route == "bill-to-pay" ? "paymentDate" : "receivementDate";

		const paidUser = this.formGroupBill.value["paidUserBy"];
		const payDate = this.formGroupBill.value[dateType];

		if (payDate && !paidUser) {
			this.formGroupBill.controls["paidUserBy"].setValidators([
				Validators.required,
			]);
			this.formGroupBill.controls["paidUserBy"].updateValueAndValidity();
			this.formGroupBill.controls["paidUserBy"].markAsTouched();
			return;
		}

		if (!payDate && paidUser) {
			this.formGroupBill.controls[dateType].setValidators([
				Validators.required,
			]);
			this.formGroupBill.controls[dateType].updateValueAndValidity();
			this.formGroupBill.controls[dateType].markAsTouched();
			return;
		}

		const paidUserBy = this.formGroupBill.value["paidUserBy"];
		this.formGroupBill.controls["paidUserBy"].setValue(
			paidUserBy ? paidUserBy.id : null
		);

		if (this.formGroupBill.value["payToType"] == 0) {
			this.formGroupBill.controls["other"].setValidators([]);
			this.formGroupBill.controls["payUserTo"].setValidators([]);
			this.formGroupBill.controls["payDeliverymanTo"].setValidators([]);

			this.formGroupBill.controls["other"].setValue(null);
			this.formGroupBill.controls["payUserTo"].setValue(null);
			this.formGroupBill.controls["payDeliverymanTo"].setValue(null);
			this.formGroupBill.updateValueAndValidity();
		}

		if (this.formGroupBill.value["payToType"] == 1) {
			if (!this.formGroupBill.value["payDeliverymanTo"]) {
				this.formGroupBill.controls["payDeliverymanTo"].setValidators([
					Validators.required,
				]);
				this.formGroupBill.controls[
					"payDeliverymanTo"
				].updateValueAndValidity();
				this.formGroupBill.controls["payDeliverymanTo"].markAsTouched();
				return;
			}

			this.formGroupBill.controls["other"].setValidators([]);
			this.formGroupBill.controls["payUserTo"].setValidators([]);

			this.formGroupBill.controls["other"].setValue(null);
			this.formGroupBill.controls["payUserTo"].setValue(null);
			this.formGroupBill.updateValueAndValidity();

			const payDeliverymanTo =
				this.formGroupBill.value["payDeliverymanTo"];
			this.formGroupBill.controls["payDeliverymanTo"].setValue(
				payDeliverymanTo.id
			);
		}

		if (this.formGroupBill.value["payToType"] == 2) {
			if (!this.formGroupBill.value["payUserTo"]) {
				this.formGroupBill.controls["payUserTo"].setValidators([
					Validators.required,
				]);
				this.formGroupBill.controls[
					"payUserTo"
				].updateValueAndValidity();
				this.formGroupBill.controls["payUserTo"].markAsTouched();
				return;
			}

			this.formGroupBill.controls["other"].setValidators([]);
			this.formGroupBill.controls["payDeliverymanTo"].setValidators([]);

			this.formGroupBill.controls["other"].setValue(null);
			this.formGroupBill.controls["payDeliverymanTo"].setValue(null);
			this.formGroupBill.updateValueAndValidity();

			const payUserTo = this.formGroupBill.value["payUserTo"];
			this.formGroupBill.controls["payUserTo"].setValue(payUserTo.id);
		}

		if (this.formGroupBill.value["payToType"] == 3) {
			if (!this.formGroupBill.value["other"]) {
				this.formGroupBill.controls["other"].setValidators([
					Validators.required,
				]);
				this.formGroupBill.controls["other"].updateValueAndValidity();
				this.formGroupBill.controls["other"].markAsTouched();
				return;
			}

			this.formGroupBill.controls["payUserTo"].setValidators([]);
			this.formGroupBill.controls["payDeliverymanTo"].setValidators([]);

			this.formGroupBill.controls["payUserTo"].setValue(null);
			this.formGroupBill.controls["payDeliverymanTo"].setValue(null);
			this.formGroupBill.updateValueAndValidity();
		}

		if (this.idBill) {
			this.call
				.put(`${this.route}/${this.idBill}`, this.formGroupBill.value)
				.subscribe((response) => {
					console.log(response);
					this.app
						.alert(`Sucesso`, response.message, `success`)
						.then(() => {
							this.get();
							this.modalCancel();
						});
				});
		} else {
			this.call
				.post(`${this.route}/`, this.formGroupBill.value)
				.subscribe((response) => {
					console.log(response);
					this.app
						.alert(`Sucesso`, response.message, `success`)
						.then(() => {
							this.get();
							this.modalCancel();
						});
				});
		}
	}

	setPayer() {
		if (!this.paidUserBy) {
			this.paidUserBy = this.user;
		}
	}

	async toggle(item) {
		const self = this;
		if (await self.app.confirm()) {
			self.delete_call
				.put(self.route + "/" + item.id, { active: !item.active })
				.subscribe((data) => this.get());
		}
	}

	onXlsxExport() {
		const self = this;
		const where = {};

		for (const index in self.filters) {
			where[index] = { "Op.and": self.getFilters()[index] };
		}

		self.call.post(`${self.route}/export/xlsx`, { where: where, order: self.order, tab: self.tab }).subscribe(response => {
			if (response.return) {
				window.location.href = response.return.url;
			}
		});
	}
}
