import { Component } from "@angular/core";
import { PageBase } from "@durinn/v.3/app/bases/page.base";
import {
	DurinnV2ListPageTableComponent,
	HeadColumns,
} from "@durinn/v.3/app/components/template/tables/list-page-table/list-page-table.component";

@Component({
	selector: "durinn-v2-subsidiaries-list-view",
	templateUrl: "./list.component.html",
	styleUrls: ["./list.component.css"],
})
export class DurinnV2SubsidiaryListViewComponent extends PageBase {
	binaryOptions = [
		{ label: "Sim", value: { "Op.is": true } },
		{ label: "Não", value: { "Op.is": false } },
	];

	headColumns: HeadColumns =
		DurinnV2ListPageTableComponent.defaultHeadColumnsWith("Subsidiary", [
			{ index: "Subsidiary", column: "descricaoCidade", name: "Cidade" },
			{ index: "Subsidiary", column: "logradouro", name: "Logradouro" },
			{ index: "Subsidiary", column: "numero", name: "Número" },
			{ index: "Subsidiary", column: "active", name: "Ativo", type: 'select', options: this.binaryOptions }
		]);
}
