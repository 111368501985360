import { CrudPage } from "src/app/bases/crud.page";
import { Component, OnInit } from "@angular/core";

import * as XLSX from "xlsx";

@Component({
	selector: "app-email-campaign",
	templateUrl: "./email-campaign.component.html",
	styleUrls: ["./email-campaign.component.css"],
})
export class EmailCampaignComponent extends CrudPage implements OnInit {
	addCampaignCall = this.api.new();
	isVisibleAddCampaign = false;
	route = "email-campaign";
	call = this.api.new();
	Math = Math;

	filters = {
		EmailCampaign: {},
		CreatedBy: {},
	};
	
	order = {
		EmailCampaign: [["createdAt", "desc"]],
		CreatedBy: [[]],
	};
	
	AddCampaignObject = {
		whatsapp: this.defaultWhatsApp,
		message: this.defaultMessage,
		title: this.defaultTitle,
		sms: this.defaultSms,
		textfile: null,
		type: 'whatsapp',
		file: null,
		deleteNotSent: true
	};
	
	dashboardTypeAll = "all";
	dashboardTypes = [];

	dashboardParams = {
		startDate: this.moment().startOf('month').toDate(),
		endDate: this.moment().endOf('month').toDate(),
		type: [this.dashboardTypeAll]
	};

	dashboardMetrics = {
		sms: {
			confirmedSchedules: 0,
			totalSchedules: 0,
			unsent: 0,
			total: 0,
			sent: 0
		},
		email: {
			confirmedSchedules: 0,
			totalSchedules: 0,
			unsent: 0,
			total: 0,
			sent: 0
		},
		whatsapp: {
			confirmedSchedules: 0,
			totalSchedules: 0,
			unsent: 0,
			total: 0,
			sent: 0
		},
		ratio: {
			confirmedSchedules: 0,
			unsent: 0,
			sent: 0,
		}
	};
	
	tab = 'campanhas';

	ngOnInit() {
		const self = this;
		super.ngOnInit();
		self.getTypes();
		self.onDashboardSearch();
	}

	get defaultWhatsApp() {
		return "Olá *%CLIENTE%*, tudo bem?\n" +
			"Eu sou o Darlei, aqui da " + ((this.me.data || {} as any).Enterprise || {}).name + "." +
			"\n"+
			"Nós somos representantes da CLARO e identificamos aqui que existem equipamentos não devolvidos em seu contrato *%CONTRATO%*.\n"+
			"Caso não sejam devolvidos esses aparelhos gerarão cobranças.\n"+
			"\n"+
			"Podemos marcar a retirada dos seus equipamentos?";
		// "Você pode agendar a devolução pelo *link abaixo* 👇🏼👇🏼👇🏼:{{ENTER}}"+
		// "https://padraologistico.com.br/%ID-EMPRESA%/%CONTRATO%{{ENTER}}" +
		// "Caso a devolução ja tenha sido feita, pedimos por gentileza que desconsidere esta mensagem.\n" +
		// "Atenciosamente.";
	}

	get defaultSms() {
		return "CLARO informa, existem equipamentos NET não devolvidos em seu nome. Evite cobrança agendando devolução pelo link: https://padraologistico.com.br/%ID-EMPRESA%/%CONTRATO%";
	}

	get defaultTitle() {
		return "Retirada de Equipamentos NET - Contrato: %CONTRATO%";
	}

	get defaultMessage() {
		const title = "Caro assinante,\n\n";

		const bodyRow1 =
			"Prezando sempre pela transparência em nosso relacionamento, informamos que, após o encerramento de nossos serviços, ";
		const bodyRow2 =
			"identificamos que ainda constam equipamentos (terminais, fontes, controles e cabos) da Claro pendentes de devolução em seu contrato, ";
		const bodyRow3 =
			"%CIDADECONTRATO%, endereço %ENDERECO%. Entretanto, após o encerramento da prestação dos serviços, esses equipamentos devem ser devolvidos à Claro.\n\n";
		const bodyRow4 =
			"Por gentileza, solicitamos que agende o melhor dia e horário para a devolução acessando o link https://padraologistico.com.br/%ID-EMPRESA%/%CONTRATO%\n\n";
		const bodyRow5 =
			"Caso prefira, o agendamento pode ser feito pela Central da Claro através do telefone 0800 744 2222 ou por este mesmo e-mail, preenchendo as informações na tabela abaixo para agendarmos conforme a sua disponibilidade.\n\n";
		const bodyRow6 = "%QUESTIONARIO%\n\n";
		const bodyRow7 =
			"A não devolução desses equipamentos implicará na cobrança dos itens registrados em seu endereço. ";
		const bodyRow8 =
			"Por isso, recomendamos que entre em contato o mais breve possível.\n\n";
		const bodyRow9 = "%TABELA_PRECOS%\n\n";
		const bodyRow10 =
			"Caso já tenha realizado a devolução dos equipamentos, pedimos a gentileza de desconsiderar este comunicado.";
		return (
			title +
			bodyRow1 +
			bodyRow2 +
			bodyRow3 +
			bodyRow4 +
			bodyRow5 +
			bodyRow6 +
			bodyRow7 +
			bodyRow8 +
			bodyRow9 +
			bodyRow10
		);
	}

	get submitAddCampaignDisabled() {
		const self = this;

		if (!self.AddCampaignObject.file) {
			return true;
		}

		if (self.AddCampaignObject.type == 'e-mail') {
			if (!self.AddCampaignObject.message.length) {
				return true;
			}

			if (!self.AddCampaignObject.title.length) {
				return true;
			}
		}

		if (self.AddCampaignObject.type == 'sms') {
			if (!self.AddCampaignObject.sms.length) {
				return true;
			}
		}

		if (self.AddCampaignObject.type == 'whatsapp') {
			if (!self.AddCampaignObject.whatsapp.length) {
				return true;
			}
		}

		return false;
	}

	onSubmitAddCampaign() {
		const self = this;

		self.addCampaignCall
			.post("email-campaign", self.AddCampaignObject)
			.subscribe(async (response) => {
				self.get();
				self.resetAddCampaignObject();
				self.isVisibleAddCampaign = false;
				await self.app.alert("Campanha Criada", response.message);
			});
	}

	resetAddCampaignObject() {
		const self = this;
		self.AddCampaignObject = {
			whatsapp: this.defaultWhatsApp,
			message: this.defaultMessage,
			title: this.defaultTitle,
			sms: this.defaultSms,
			textfile: null,
			type: 'whatsapp',
			file: null,
			deleteNotSent: true
		};
	}

	handleUpload(event) {
		const self = this;
		const file = event.target.files[0];
		const fileReader = new FileReader();
		const textReader = new FileReader();

		// File
		fileReader.readAsBinaryString(file);
		fileReader.onload = (e: any) => {
			const data = e.target.result;
			const workbook = XLSX.read(data, {
				type: 'binary'
			});
			
			workbook.SheetNames.forEach(function(sheetName) {
				// Here is your object
				const json_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
				console.log('json_object', json_object);
				
				
				const fields = [
					'ENDERECO',
					'CD_NET',
					'NM_CIDADE',
					'CEP',
					'TELCEL',
					'TELEFONE',
					'TELCOMERCIAL',
					'OUTROS',
					'E-MAIL',
					'NOME_TITULAR',
					'BASE',
					'TERMINAIS'
				];
				
				let csv = fields.join(';') + "\n";
				const validateEmail = (email) => {
					return String(email)
						.toLowerCase()
						.match(
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						);
				};
				
				for(const item of json_object){
					const arr = [];
					
					if(!item['E-MAIL'] || !validateEmail(item['E-MAIL'])){
						continue;
					}
					
					for(const field of fields){
						arr.push((item[field] || '').toString().replace(/(?:\r\n|\r|\n)/g, '').replace(/\t/g, ' ').replaceAll('  ', ' ').trim());
					}
					
					csv += arr.join(';') + "\n";
				}
				
				console.log('csv', csv);
				
				self.AddCampaignObject.file = csv;
				self.AddCampaignObject.textfile = csv;
			});
		};

		// Text
		// textReader.readAsText(file);
		// textReader.onload = () => {
		// 	self.AddCampaignObject.textfile = textReader.result;
		// };
	}

	getTypes() {
		const self = this;

		self.dashboardTypes = [
			{ id: "sms", name: "Sms" },
			{ id: "e-mail", name: "E-mail" },
			{ id: "whatsapp", name: "WhatsApp" }
		];

		self.dashboardTypes.unshift({
			id: self.dashboardTypeAll,
			name: "Todos os Tipos",
		});
	}

	typesChange(array: string[]) {
		const self = this;

		const intersection = array
			.filter((item) => {
				return !self.dashboardParams.type.includes(item);
			})
			.pop();

		if (intersection === self.dashboardTypeAll) {
			self.dashboardParams.type = [self.dashboardTypeAll];
		} else if (intersection != null) {
			if (self.dashboardParams.type.includes(self.dashboardTypeAll)) {
				self.dashboardParams.type = [
					...self.dashboardParams.type.slice(1),
					intersection,
				];
			} else {
				self.dashboardParams.type = [...self.dashboardParams.type, intersection];
			}
		} else {
			self.dashboardParams.type = array;
		}

		self.onDashboardSearch();
	}

	getEmailDashboardData() {
		const self = this;
		return new Promise((resolve, reject) => {
			self.call.post(`${self.route}/e-mail/count`, self.dashboardParams)
				.subscribe(response => resolve(response.return), error => reject(error));
		});
	}

	getSmsDashboardData() {
		const self = this;
		return new Promise((resolve, reject) => {
			self.call.post(`${self.route}/sms/count`, self.dashboardParams)
				.subscribe(response => resolve(response.return), error => reject(error));
		});
	}

	getWhatsAppDashboardData() {
		const self = this;
		return new Promise((resolve, reject) => {
			self.call.post(`${self.route}/whatsapp/count`, self.dashboardParams)
				.subscribe(response => resolve(response.return), error => reject(error));
		});
	}

	getDashbaordTotal() {
		const self = this;
		const { sms, email, whatsapp } = self.dashboardMetrics;
		return sms.total + email.total + whatsapp.total;
	}

	getDashboardSentRatio() {
		const self = this;
		const { sms, email, whatsapp } = self.dashboardMetrics;
		const sent = sms.sent + email.sent + whatsapp.sent;
		return (sent / (self.getDashbaordTotal() || 1)) * 100;
	}

	getDashboardUnsentRatio() {
		const self = this;
		const { sms, email, whatsapp } = self.dashboardMetrics;
		const unsent = sms.unsent + email.unsent + whatsapp.unsent;
		return (unsent / (self.getDashbaordTotal() || 1)) * 100;
	}

	getDashboardConfirmedSchedulesRatio() {
		const self = this;
		const { sms, email, whatsapp } = self.dashboardMetrics;
		const total = sms.totalSchedules + email.totalSchedules + whatsapp.totalSchedules;
		const confirmedSchedules = sms.confirmedSchedules + email.confirmedSchedules + whatsapp.confirmedSchedules;
		return (confirmedSchedules / (total || 1)) * 100;
	}

	async onDashboardSearch() {
		const self = this;
		const sms = await self.getSmsDashboardData();
		const email = await self.getEmailDashboardData();
		const whatsapp = await self.getWhatsAppDashboardData();

		self.dashboardMetrics.sms = sms as any;
		self.dashboardMetrics.email = email as any;
		self.dashboardMetrics.whatsapp = whatsapp as any;

		self.dashboardMetrics.ratio.sent = self.getDashboardSentRatio();
		self.dashboardMetrics.ratio.unsent = self.getDashboardUnsentRatio();
		self.dashboardMetrics.ratio.confirmedSchedules = self.getDashboardConfirmedSchedulesRatio();
	}
}
