import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "max",
})
export class MaxPipe implements PipeTransform {
	transform(value: string, length = 50): any {
		return value.length <= length ? value : value.substr(0, length) + "...";
	}
}
