import { Api } from "@durinn.v3/helpers/api";
import { Me } from "@durinn.v3/helpers/me";
import { Component } from "@angular/core";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
})
export class AppComponent {
	constructor(public me: Me, public api: Api) {
		me.refresh(api.new().silent()).toPromise();
	}

	location = {
		visible: false,
		latitude: null,
		longitude: null,
	};

	public seeLocation(latitude: number, longitude: number) {
		this.location.latitude = latitude;
		this.location.longitude = longitude;
		this.location.visible = true;
	}
}
