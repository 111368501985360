import { Component, Input, OnInit } from "@angular/core";
import { Api } from "@durinn.v3/helpers/api";

type Permission = {
	id_permission: number;
	register_date: Date;
	permission: string;
	field: string | null;
	key: string | null;
};

export const PERMISSIONS = {
	MASTER: "MASTER",
	EMAIL_BILLS: "EMAIL_BILLS",

	SEE_FINANCIAL: "SEE_FINANCIAL",
	CONFIGURE_HOLIDAYS: "CONFIGURE_HOLIDAYS",

	SEE_ALL_USERS: "SEE_ALL_ADMINS",
	CREATE_ADMINS: "CREATE_ADMINS",
	EDIT_ADMINS: "EDIT_ADMINS",
	DELETE_ADMINS: "DELETE_ADMINS",

	SEE_ALL_DELIVERYMEN: "SEE_ALL_USERS",
	CREATE_DELIVERYMEN: "CREATE_DELIVERYMEN",
	EDIT_DELIVERYMEN: "EDIT_DELIVERYMEN",
	DELETE_DELIVERYMEN: "DELETE_DELIVERYMEN",

	CREATE_TEAMS: "CREATE_TEAMS",
	EDIT_TEAMS: "EDIT_TEAMS",
	DELETE_TEAMS: "DELETE_TEAMS",

	SEE_CLUSTERS: "SEE_CLUSTERS",
	CREATE_CLUSTERS: "CREATE_CLUSTERS",
	EDIT_CLUSTERS: "EDIT_CLUSTERS",
	DELETE_CLUSTERS: "DELETE_CLUSTERS",

	SEE_ADMINS_CLOSING_REPORT: "SEE_ADMINS_CLOSING_REPORT",
	SEE_DELIVERYMEN_CLOSING_REPORT: "SEE_DELIVERYMEN_CLOSING_REPORT",

	EDIT_ADMINS_CLOSING_REPORT: "EDIT_ADMINS_CLOSING_REPORT",
	EDIT_DELIVERYMEN_CLOSING_REPORT: "EDIT_DELIVERYMEN_CLOSING_REPORT",

	SEE_WAREHOUSE: "SEE_WAREHOUSE",

	CREATE_WAREHOUSE_LOTS: "CREATE_WAREHOUSE_LOTS",
	EDIT_WAREHOUSE_LOTS: "EDIT_WAREHOUSE_LOTS",
	DELETE_WAREHOUSE_LOTS: "DELETE_WAREHOUSE_LOTS",

	SEE_CONTRACT_SCHEDULE: "SEE_CONTRACT_SCHEDULE",
	CREATE_CONTRACT_SCHEDULE: "CREATE_CONTRACT_SCHEDULE",
	UPDATE_CONTRACT_SCHEDULE: "UPDATE_CONTRACT_SCHEDULE",
	DELETE_CONTRACT_SCHEDULE: "DELETE_CONTRACT_SCHEDULE"
};

@Component({
	selector: "user-permissions",
	templateUrl: "./permissions.component.html",
	styleUrls: ["./permissions.component.css"],
})
export class PermissionsComponent implements OnInit {
	call = this.api.new().set("success", true);

	@Input() loading = true;
	@Input() user: { id: number; Permissions: Permission[] } = {
		id: 0,
		Permissions: [],
	};

	permissions: { [s: string]: boolean } = {};

	constructor(private api: Api) {}

	ngOnInit() {
		const self = this;

		for (const permission of Object.keys(PERMISSIONS)) {
			self.permissions[permission] = false;
		}

		for (const item of this.user.Permissions) {
			self.permissions[item.permission] = true;
		}
	}

	ngOnChanges() {
		this.ngOnInit();
	}

	changed(key: string, event: boolean) {
		const self = this;

		if (key == "MASTER" && event) {
			for (const permission in self.permissions) {
				self.permissions[permission] = true;
			}
		}
	}

	checkAll() {
		const self = this;

		for (const permission in self.permissions) {
			self.permissions[permission] = true;
		}
	}

	uncheckAll() {
		const self = this;

		for (const permission in self.permissions) {
			self.permissions[permission] = false;
		}
	}

	save() {
		this.call
			.post(`users/${this.user.id}/permissions`, this.permissions)
			.toPromise();
	}
}
