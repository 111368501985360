import { Component, Input, OnInit } from "@angular/core";
import { FinanceiroComponent } from "../../financeiro/financeiro.component";
import * as moment from "moment";

@Component({
	selector: "financeiro-total-mes",
	templateUrl: "./total-mes.component.html",
	styleUrls: ["./total-mes.component.css"],
})
export class TotalMesComponent extends FinanceiroComponent {
	@Input() data = null;
	@Input() search = {
		from: "",
		to: "",
		cluster: null,
		audited: false,
	};
	@Input() previousData = null;

	Math = Math;
	Object = Object;

	total = 0;
	previousTotal = 0;

	devices = {
		aparelho: 0,
		previous_aparelho: 0,
		nagra: 0,
		previous_nagra: 0,
		chip: 0,
		previous_chip: 0,
	};

	codes = {};
	previous_codes = {};

	ngOnInit() {}

	ngOnChanges() {
		this.calc();
	}

	calc() {
		const self = this;

		const data = Object.assign({}, self.data);

		self.total = 0;
		self.previousTotal = 0;
		self.devices = {
			aparelho: 0,
			previous_aparelho: 0,
			nagra: 0,
			previous_nagra: 0,
			chip: 0,
			previous_chip: 0,
		};
		self.codes = {};

		if (!data) {
			return false;
		}

		for (const day of Object.values(data) as any[]) {
			for (const type in day.devices) {
				if (!self.devices[type]) {
					self.devices[type] = 0;
				}

				self.devices[type] += +day.devices[type].value;
			}

			self.total += day.total || 0;

			for (const code in day.codes) {
				if (!self.codes[code]) {
					self.codes[code] = Object.assign({}, day.codes[code]);
				} else {
					self.codes[code].value += day.codes[code].value;
				}

				self.codes[code].code = code;
			}
		}

		if (self.previousData) {
			for (const day of Object.values(self.previousData) as any[]) {
				for (const type in day.devices) {
					if (!self.devices["previous_" + type]) {
						self.devices["previous_" + type] = 0;
					}

					self.devices["previous_" + type] += day.devices[type].value;
				}

				self.previousTotal += day.total || 0;

				for (const code in day.codes) {
					if (!self.previous_codes[code]) {
						self.previous_codes[code] = day.codes[code];
					} else {
						self.previous_codes[code].value = day.codes[code].value;
					}
					self.previous_codes[code].code = code;
				}
			}
		}
	}

	variation(num2, num1) {
		if (num1 == 0) {
			return "0%";
		}

		const variation = ((num2 - num1) / num1) * 100;

		if (isNaN(variation)) {
			return "0%";
		}

		return (variation > 1 ? "+" : "") + Math.round(variation) + "%";
	}

	downloadDevice(type) {
		const self = this;
		const from = moment(this.search.from, "DD/MM/YYYY");
		const to = moment(this.search.to, "DD/MM/YYYY");
		const cluster = this.search.cluster || "";

		self.api.new().openLink("financial/byDay/file/device/" + type, {
			audited: self.search.audited ? 1 : 0,
			from: from.format("YYYY-MM-DD"),
			to: to.format("YYYY-MM-DD"),
			clusterId: cluster,
		});
	}

	downloadCode(code) {
		const self = this;
		const from = moment(this.search.from, "DD/MM/YYYY");
		const to = moment(this.search.to, "DD/MM/YYYY");
		const cluster = this.search.cluster || "";

		self.api.new().openLink("financial/byDay/file/conclude/" + code, {
			audited: self.search.audited ? 1 : 0,
			from: from.format("YYYY-MM-DD"),
			to: to.format("YYYY-MM-DD"),
			clusterId: cluster,
		});
	}
}
