import { Component, Input, OnInit } from "@angular/core";
import { BasePage } from "../../bases/base.page";
import * as moment from "moment";

@Component({
	selector: "app-get-hour-bank",
	templateUrl: "./get-hour-bank.component.html",
	styleUrls: ["./get-hour-bank.component.css"],
})
export class GetHourBankComponent extends BasePage {
	call = this.api.new().silent();

	@Input() deliverymanId: number = null;
	@Input() userId: number = null;

	from = moment().subtract(1, "month").format("21/MM/YYYY");
	to = moment().format("20/MM/YYYY");
	seconds = 0;

	ngOnInit() {}

	ngOnChanges() {
		this.send();
	}

	send() {
		const self = this;

		if (!self.userId && !self.deliverymanId) {
			return false;
		}

		this.call
			.get(
				(self.userId
					? `users/${self.userId}`
					: `deliverymen/${self.deliverymanId}`) +
					`/hour-bank/${moment(self.from, "DD/MM/YYYY")
						.startOf("day")
						.toDate()}/${moment(self.to, "DD/MM/YYYY")
						.startOf("day")
						.toDate()}`
			)
			.subscribe((data) => {
				if (data.return) {
					self.seconds = data.return.seconds;
				}
			});
	}
}
