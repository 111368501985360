import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { Api } from "@durinn.v3/helpers/api";
import { Io } from "@durinn.v3/helpers/api";
import { Me } from "@durinn.v3/helpers/me";

@Component({
	selector: "profile-change-password",
	templateUrl: "./change-password.component.html",
	styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
	call: Io;
	validateForm: FormGroup;
	loading = true;

	constructor(private fb: FormBuilder, public api: Api, public me: Me) {}

	ngOnInit(): void {
		const self = this;
		this.call = self.api.new().set("success", true);

		this.validateForm = this.fb.group({
			password: [null, [Validators.required]],
			newPassword: [null, [Validators.required]],
			confirmPassword: [null, [Validators.required]],
		});

		self.me.get().subscribe((data) => {
			self.loading = false;
			self.validateForm
				.get("password")
				.setValue(data ? data.password : "");
		});
		this.call.get("me/change-password", { x: "x" });
	}

	resetForm(e?: MouseEvent): void {
		const self = this;

		if (e) {
			e.preventDefault();
		}

		self.loading = true;
		self.validateForm.reset();
		self.me.get().subscribe((data) => {
			self.loading = false;
			self.validateForm
				.get("password")
				.setValue(data ? data.password : "");
		});

		for (const key in this.validateForm.controls) {
			this.validateForm.controls[key].markAsPristine();
			this.validateForm.controls[key].updateValueAndValidity();
		}
	}

	submitForm = ($event, value) => {
		const self = this;

		$event.preventDefault();

		for (const key in this.validateForm.controls) {
			this.validateForm.controls[key].markAsDirty();
			this.validateForm.controls[key].updateValueAndValidity();
		}

		this.me.updatePassword(value, this.call).subscribe(async (data) => {
			if (data.success) {
				self.resetForm();
			}
		});
	};
}
