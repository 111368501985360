import { Component } from "@angular/core";

@Component({
	selector: "durinn-v2-customer-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.css"],
})
export class DurinnV2CustomerSettingsComponent {
	costumerDefaultFields = [
		{ field: "Nome", mask: "", required: true },
		{ field: "Documento", mask: "", required: false },
	];
}
