import {Io} from "@durinn.v3/helpers/api";
import { Component } from "@angular/core";
import * as moment from "moment";
import {PageBase} from "@durinn.v3/app/bases/page.base";

@Component({
	templateUrl: "./default-dashboard.component.html",
})
export class DefaultDashboardComponent extends PageBase {
	public window = window;

	warehouse = {
		from: moment().format("DD/MM/YYYY"),
		to: moment().format("DD/MM/YYYY"),
	};

	permissions = {
		SEE_WAREHOUSE: false,
		SEE_FINANCIAL: false,
		MASTER: false,
	};

	private apiCalls: { [a: string]: Io } = {};
	private dashboard = {
		count: {
			users: 0,
			upload: 0,
			sms: 0,
			email: 0,
		},
	};

	ngOnInit() {
		super.ngOnInit();

		const self = this;
		this.apiCalls.metrics = this.api.new();
		// this.apiCalls.metrics.get('dashboard/metrics').subscribe(data => {
		//   if(data.success){
		//     self.dashboard.count = data.return.count;
		//   }
		// })
	}
}
