import { Component, Input } from "@angular/core";
import { FilterOrderBase } from "../filter-order.base";

@Component({
	selector: "durinn-v2-filter-order-text",
	templateUrl: "./filter-order-text.component.html",
	styleUrls: ["./filter-order-text.component.css"],
})
export class FilterOrderTextComponent extends FilterOrderBase {
  @Input() mask = "";
  @Input() type = "text";

  ngOnInit() {}
}
