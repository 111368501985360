import { Component, OnInit } from '@angular/core';
import {PageBase} from "@durinn.v3/app/bases/page.base";
import {FiltersType} from "@durinn.v3/app/bases/listpage.base";
import {
	DurinnV2ListPageTableComponent,
	HeadColumns
} from "@durinn.v3/app/components/template/tables/list-page-table/list-page-table.component";

@Component({
	selector: 'app-numeros-bloqueados',
	templateUrl: './numeros-bloqueados.component.html',
	styleUrls: ['./numeros-bloqueados.component.css']
})
export class NumerosBloqueadosComponent extends PageBase {
	call = this.api.new();
	
	realoadInt = 0;
	order = {
		WhatsAppBlockedNumber: [
			['id', 'desc']
		]
	};
	
	filters: FiltersType = {};
	
	headColumns: HeadColumns = [
		{ index: "WhatsAppBlockedNumber", column: "id", name: "ID" },
		{ index: "WhatsAppBlockedNumber", column: "createdAt", name: "Data de Registro", type: 'date' },
		{ index: "WhatsAppBlockedNumber", column: "number", name: "Número" },
		{}
	];
	
	modal = {
		visible: false,
		number: ''
	};
	
	openModal(){
		this.modal.number = '';
		this.modal.visible = true;
	}
	
	create(){
		const self = this;
		self.call.post('whatsapp-blocked-number', {number: self.modal.number}).subscribe(data => {
			self.toastr.success(`Número bloqueado com sucesso`);
			self.modal.number = '';
			self.modal.visible = false;
			self.realoadInt++;
		});
	}
	
	async delete(item){
		const self = this;
		
		if(await self.app.confirm()){
			self.call.delete('whatsapp-blocked-number/' + item.id).subscribe(data => {
				self.realoadInt++;
			});
		}
	}
}
