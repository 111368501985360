export class Csv {
	static download(
		rows: string[][] = [],
		separator = ";",
		filename = "output.csv"
	) {
		const csvContent =
			"\ufeff" + rows.map((e) => e.join(separator)).join("\n");
		const blob = new Blob([csvContent], {
			type: "text/csv;charset=utf-8;",
		});

		if (navigator.msSaveBlob) {
			// IE 10+
			navigator.msSaveBlob(blob, filename);
		} else {
			const link = document.createElement("a");
			if (link.download !== undefined) {
				// feature detection
				// Browsers that support HTML5 download attribute
				link.setAttribute("href", URL.createObjectURL(blob));
				link.setAttribute("download", filename);
				link.style.visibility = "hidden";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}
}
