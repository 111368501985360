import { TemplateModule as DurinnTemplateModule } from "@durinn/v.3/app/template/template/template.module";
import { NgModule } from "@angular/core";

@NgModule({
	exports: [DurinnTemplateModule],
	imports: [DurinnTemplateModule],
	declarations: [],
	providers: [],
})
export class TemplateModule {}
