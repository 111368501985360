import { CrudPage } from "../../../bases/crud.page";
import { Io } from "@durinn.v3/helpers/api";
import { Component } from "@angular/core";

@Component({
	selector: "app-equipes",
	templateUrl: "./equipes.component.html",
	styleUrls: ["./equipes.component.css"],
})
export class EquipesComponent extends CrudPage {
	items = [];
	filters = {
		Team: {
			name: null,
			description: null,
		},
	};

	permissions = {
		CREATE_TEAMS: false,
		EDIT_TEAMS: false,
		DELETE_TEAMS: false,
	};

	order = {
		Team: [["name", "asc"]],
	};

	call: Io;
	delete_call: Io;
	route = "team";

	protected getFilters() {
		const self = this;

		return super.getFilters();
	}
}
