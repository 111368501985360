import { Component, OnInit } from '@angular/core';
import {PageBase} from "@durinn.v3/app/bases/page.base";
import {FiltersType} from "@durinn.v3/app/bases/listpage.base";
import {HeadColumns} from "@durinn.v3/app/components/template/tables/list-page-table/list-page-table.component";

@Component({
	selector: 'app-contratos-bloqueados',
	templateUrl: './contratos-bloqueados.component.html',
	styleUrls: ['./contratos-bloqueados.component.css']
})
export class ContratosBloqueadosComponent extends PageBase {
	call = this.api.new();
	
	realoadInt = 0;
	order = {
		WhatsAppBlockedContract: [
			['id', 'desc']
		]
	};
	
	filters: FiltersType = {};
	
	headColumns: HeadColumns = [
		{ index: "WhatsAppBlockedContract", column: "id", name: "ID" },
		{ index: "WhatsAppBlockedContract", column: "createdAt", name: "Data de Registro", type: 'date' },
		{ index: "WhatsAppBlockedContract", column: "number", name: "Contrato" },
		{}
	];
	
	modal = {
		visible: false,
		contract: ''
	};
	
	openModal(){
		this.modal.contract = '';
		this.modal.visible = true;
	}
	
	create(){
		const self = this;
		self.call.post('whatsapp-blocked-contract', {contract: self.modal.contract}).subscribe(data => {
			self.toastr.success(`Número bloqueado com sucesso`);
			self.modal.contract = '';
			self.modal.visible = false;
			self.realoadInt++;
		});
	}
	
	async delete(item){
		const self = this;
		
		if(await self.app.confirm()){
			self.call.delete('whatsapp-blocked-contract/' + item.id).subscribe(data => {
				self.realoadInt++;
			});
		}
	}
}
