import { ThemeConstantService } from "@durinn.v3/app/template/services/theme-constant.service";
import { App } from "@durinn.v3/helpers/app";
import { Api } from "@durinn.v3/helpers/api";
import { Me } from "@durinn.v3/helpers/me";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import config from "../../../config";
import * as moment from "moment";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
})
export class HeaderComponent {
	searchVisible = false;
	quickViewVisible = false;
	isFolded: boolean;
	isExpand: boolean;
	user: any = {};
	config = config;

	notificationSeeMore = false;
	notificationList = [];

	constructor(
		private themeService: ThemeConstantService,
		private app: App,
		private me: Me,
		private router: Router,
		private api: Api
	) {}

	ngOnInit(): void {
		this.me.get().subscribe((data) => {
			this.user = data;
			this.generateNotifications();
			this.getTime();
			this.formatTime();
			this.getEnterprises();
		});
		this.themeService.isMenuFoldedChanges.subscribe(
			(isFolded) => (this.isFolded = isFolded)
		);
		this.themeService.isExpandChanges.subscribe(
			(isExpand) => (this.isExpand = isExpand)
		);
	}

	calls = {
		getNotifications: this.api.new(),
		validateEmail: this.api.new(),
		validatePhone: this.api.new(),
		confirmEmail: this.api.new().set("success", true),
		confirmPhone: this.api.new().set("success", true),
		online: this.api.new(),
		enterprise: this.api.new(),
	};

	enterprises = [];

	getEnterprises(){
		const self = this;
		self.calls.enterprise.post(`enterprise/search/0/100`).subscribe(data => {
			self.enterprises = data.return.rows;
		});
	}

	updateEnterprise(){
		const self = this;
		self.me.update(self.user).then(data => {
			self.me.get(false).subscribe(data => {
				window.location.reload();
			});
		});
	}

	modals = {
		preValidateEmail: false,
		validateEmail: false,
		preValidatePhone: false,
		validatePhone: false,
	};

	tokens = {
		sms: "",
		email: "",
	};

	generateNotifications() {
		const self = this;

		self.notificationList = [];

		if (!self.user.emailValidated) {
			self.notificationList.push({
				title: "Você precisa confirmar seu e-mail",
				time: "",
				icon: "mail",
				color: "ant-avatar-" + "blue",
				click: () => {
					self.modals.preValidateEmail = true;
				},
			});
		}

		if (!self.user.phoneValidated) {
			self.notificationList.push({
				title: "Você precisa confirmar seu telefone",
				time: "",
				icon: "phone",
				color: "ant-avatar-" + "cyan",
				click: () => {
					self.modals.preValidatePhone = true;
				},
			});
		}
	}

	confirmEmail() {
		const self = this;
		self.calls.confirmEmail
			.post(`me/validate-email/confirm`, { token: self.tokens.email })
			.subscribe((data) => {
				if (data.success) {
					self.me.refresh().toPromise();
					self.modals.validateEmail = false;
				}
			});
	}

	showConfirmEmail() {
		const self = this;
		self.modals.preValidateEmail = false;
		self.modals.validateEmail = true;
		self.calls.validateEmail
			.post(`me/validate-email/send-confirmation`, {
				email: self.user.email,
			})
			.subscribe((data) => {
				self.me.refresh().toPromise();
				console.log(data);
			});
	}

	confirmSms() {
		const self = this;
		self.calls.confirmPhone
			.post(`me/validate-phone/confirm`, { token: self.tokens.sms })
			.subscribe((data) => {
				if (data.success) {
					self.me.refresh().toPromise();
					self.modals.validatePhone = false;
				}
			});
	}

	showConfirmSms() {
		const self = this;
		self.modals.preValidatePhone = false;
		self.modals.validatePhone = true;
		self.calls.validatePhone
			.post(`me/validate-phone/send-confirmation`, {
				phone: self.user.phone,
			})
			.subscribe((data) => {
				self.me.refresh().toPromise();
				console.log(data);
			});
	}

	toggleFold() {
		this.isFolded = !this.isFolded;
		this.themeService.toggleFold(this.isFolded);
	}

	toggleExpand() {
		this.isFolded = false;
		this.isExpand = !this.isExpand;
		this.themeService.toggleExpand(this.isExpand);
		this.themeService.toggleFold(this.isFolded);
	}

	searchToggle(): void {
		this.searchVisible = !this.searchVisible;
	}

	quickViewToggle(): void {
		this.quickViewVisible = !this.quickViewVisible;
	}

	time_register = null;
	time_desc = null;

	getTime() {
		this.calls.online
			.get("me/time-register")
			.subscribe((data) => (this.time_register = data.return));
	}

	addTime() {
		this.calls.online
			.post("me/time-register")
			.subscribe((data) => this.getTime());
	}

	formatTime() {
		if (this.time_register && this.time_register.departure_date === null) {
			const diff = moment().diff(
				moment(this.time_register.entry_date),
				"seconds"
			);
			this.time_desc = moment()
				.startOf("day")
				.seconds(diff)
				.format("HH:mm:ss");
		} else {
			this.time_desc = "Ficar Online";
		}

		setTimeout(() => {
			this.formatTime();
		}, 1000);
	}

	async logout() {
		const self = this;
		if (await self.app.confirm(`Logout`, `Você deseja sair do sistema?`)) {
			await self.me.logout();
			self.router.navigateByUrl("/authentication");
		}
	}
}
