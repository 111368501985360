import { Response } from "@durinn/v.3/helpers/api";
import { PageBase } from "./page.base";
import { Observable } from "rxjs";
import swal from "sweetalert2";

export interface ModelBaseInterface {
  fields: any[];
  form: any;
  variables: { field: string; mask: string; required: boolean }[];
  route: string;
  permission: string;
  backPage: string;
  variableUrl: string;
}

export class ModelPageBase extends PageBase {
	call = this.api.new();
	load = this.api.new();
	callVariables = this.api.new().silent(true);

	id: number = null;
	fields = [];
	originalForm: any = { variables: {} };
	form: any = { variables: {} };
	variables: { field: string; mask: string; required: boolean }[] = [];

	route = "team";
	permission = "";
	backPage = "";

	variableUrl = "";

	isModal = false;

	permissionsLoaded() {
		const self = this;
		super.permissionsLoaded();
		if (self.permission) {
			console.log(`self.permission`, self.permission);
			if (!self.id && !self.hasPermission(self.permission)) {
				self.router.navigateByUrl("/" + self.backPage);
			}
		}
	}

	enterprise: any = {};
	enterpriseCall = this.api.new();

	ngOnInit(): void {
		const self = this;

		if (!self.id) {
			self.id = self.isModal
				? null
				: +self.activatedRoute.snapshot.params["id"] || null;
		}

		self.enterpriseCall.get(`enterprise/`).subscribe((data) => {
			if (data.return) {
				self.enterprise = data.return;
			}
		});

		if (self.variableUrl) {
			self.callVariables
				.get(`enterprise/variable/${self.variableUrl}`)
				.subscribe((data) => {
					if (data.return) {
						self.variables = JSON.parse(data.return.variable);
					}
				});
		}

		this.get();
	}

	get() {
		const self = this;
		
		self.ngZone.run(() => {
			if (self.id) {
				self.load.get(self.route + "/" + self.id).subscribe((response) => {
					const data = response.return;
					
					if (data) {
						data.variables = {};
						self.form = data;
						
						if (data.Variables) {
							for (const item of data.Variables) {
								self.form.variables[item.field] = item.variable;
							}
						}
						
						self.originalForm = JSON.parse(JSON.stringify(self.form));
						self.modelLoaded(self.form);
					}
				});
			} else {
				self.form = { variables: {} };
				self.originalForm = JSON.parse(JSON.stringify(self.form));
				self.modelLoaded(self.form);
			}
		});
	}

	modelLoaded(model) {}

	sendToApi(): Observable<Response> {
		const self = this;
		if (self.id) {
			return self.call.put(self.route + "/" + self.id, self.form);
		} else {
			return self.call.post(self.route + "/", self.form);
		}
	}

	submitForm(
		callbackAlert: "toast" | "swal" = "swal",
		reloadWindow = true
	): Promise<any> | void {
		const self = this;
		return new Promise(async resolve => {
			self.sendToApi().subscribe((response) => {
				if (callbackAlert == "swal") {
					if (self.id) {
						swal
							.fire({
								title: `Sucesso`,
								text: response.message,
								icon: "success",
								showCancelButton: true,
								// confirmButtonColor: '#d33',
								// cancelButtonColor: '#999',
								confirmButtonText: "Continuar Editando",
								cancelButtonText: "Voltar",
							})
							.then((result) => {
								if (result.value) {
									if (reloadWindow) {
										window.location.reload();
									} else {
										self.ngOnInit();
									}
								} else {
									self.router.navigateByUrl("/" + self.backPage);
								}
							});
					} else {
						self.app.alert(`Sucesso`, response.message, `success`).then(() => {
							self.router.navigateByUrl(
								"/" + self.backPage + "/" + response.return.id
							);
							self.id = response.return.id;
							self.ngOnInit();
						});
					}
				}
				
				if (callbackAlert == "toast") {
					self.toastr.success(
						response.message || "Sucesso",
						!response.message ? undefined : "Sucesso"
					);
					
					if (self.id) {
						self.ngOnInit();
					} else {
						self.router.navigateByUrl(
							"/" + self.backPage + "/" + response.return.id
						);
						self.id = response.return.id;
						self.ngOnInit();
					}
				}
				
				resolve(true);
			});
		});
	}

	async delete() {
		const self = this;
		if (await self.app.confirm()) {
			self.call.delete(self.route + "/" + self.id).subscribe((response) => {
				self.app
					.alert(`Sucesso`, response.message, `success`)
					.then(() => self.router.navigateByUrl("/" + self.backPage));
			});
		}
	}

	changeListener($event, field = 'picture'): void {
		this.readThis($event.target, field);
	}

	readThis(inputValue: any, field = "picture"): void {
		const file: File = inputValue.files[0];
		const myReader: FileReader = new FileReader();

		myReader.onloadend = (e) => {
			this.form[field] = myReader.result as string;
		};

		myReader.readAsDataURL(file);
	}

	isLoading() {
		return this.load.loading || this.call.loading;
	}

	madeChanges() {
		return JSON.stringify(this.form) != JSON.stringify(this.originalForm);
	}

	revertForm() {
		this.form = Object.assign({}, this.originalForm);
	}
}
