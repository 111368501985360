import { Component, Input, OnInit } from "@angular/core";
import { EditarLoteComponent } from "../../editar-lote.component";
import { BasePage } from "../../../../../../../../../bases/base.page";

@Component({
	selector: "app-editar-lote-arquivos",
	templateUrl: "./arquivos.component.html",
	styleUrls: ["./arquivos.component.css"],
})
export class ArquivosComponent extends BasePage {
	@Input() page: EditarLoteComponent;

	modal = false;

	add = {
		name: "",
		link: "",
		description: "",
	};

	changeListener($event): void {
		this.readThis($event.target);
	}

	readThis(inputValue: any): void {
		const self = this;
		const file: File = inputValue.files[0];

		console.log("file", file);

		self.add.name = file.name;

		const myReader: FileReader = new FileReader();

		myReader.onloadend = (e) => {
			self.add.link = myReader.result as string;
		};

		myReader.readAsDataURL(file);
	}

	async remove(index) {
		const self = this;

		if (await self.app.confirm()) {
			self.page.form.Files.splice(index, 1);
		}
	}

	addFile() {
		const self = this;

		if (!self.page.form.Files) {
			self.page.form.Files = [];
		}

		self.page.form.Files.push(Object.assign({}, self.add));

		self.add = { link: "", name: "", description: "" };
		self.modal = false;
	}

	public formatName(item) {
		if (item.name) {
			return item.name;
		}

		const link = item.link || "";

		if (link.substr(0, 4) == "http") {
			const arr = link.split("/");
			return arr[arr.length - 1];
		}

		return "Sem nome";
	}
}
