import { Component } from "@angular/core";
import { FiltersType, Orders } from "@durinn/v.3/app/bases/listpage.base";
import { PageBase } from "@durinn/v.3/app/bases/page.base";
import {
	DurinnV2ListPageTableComponent,
	HeadColumns,
} from "@durinn/v.3/app/components/template/tables/list-page-table/list-page-table.component";

@Component({
	selector: "durinn-v2-userlist-view",
	templateUrl: "./list.component.html",
	styleUrls: ["./list.component.css"],
})
export class DurinnV2UserListViewComponent extends PageBase {
	order: Orders = { User: [["name", "asc"]] };
	filters: FiltersType = {
		User: { username: "", email: "", phone: "", name: "" },
	};

	activeOptions = [
		{ label: 'Sim', value: { "Op.is": true } },
		{ label: 'Não', value: { "Op.is": false } }
	];

	headColumns: HeadColumns =
		DurinnV2ListPageTableComponent.defaultHeadColumnsWith("User", [
			{ index: "User", column: "username", name: "Documento" },
			{ index: "User", column: "email", name: "E-mail" },
			{ index: "User", column: "phone", name: "Celular" },
			{ index: "User", column: "active", name: "Ativo", type: "select", options: this.activeOptions }
		]);
}
