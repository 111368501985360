import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Api } from "@durinn/v.3/helpers/api";
import { App } from "@durinn/v.3/helpers/app";
import { Me } from "@durinn/v.3/helpers/me";
import config from "../../../../../../config";

@Component({
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.css"],
})
export class LoginComponent {
	height = window.innerHeight;

	loginForm: FormGroup;
	config = config;
	apiCall = this.api.new();
	passwordVisible = false;

	async submitForm() {
		const self = this;
		for (const i in this.loginForm.controls) {
			this.loginForm.controls[i].markAsDirty();
			this.loginForm.controls[i].updateValueAndValidity();
		}

		if (self.loginForm.valid) {
			let username = (self.loginForm.get("userName").value as string) || "";
			const password = self.loginForm.get("password").value;

			if (
				username.indexOf("@") === -1 &&
        [11, 14].indexOf(username.length) !== -1
			) {
				username = username.replace(/\D/g, "");
			}

			const login = await self.me.login(username, password, this.apiCall);

			console.log("login", login);

			if (login) {
				self.app.alert(`Sucesso`, `Login efetuado com sucesso!`, `success`);
				self.router.navigateByUrl("/home");
			} else {
				self.app.alert(`Erro`, `Usuário ou senha incoretos :/`, `error`);
			}
		}
	}

	constructor(
    private fb: FormBuilder,
    private me: Me,
    private app: App,
    private router: Router,
    private api: Api
	) {}

	recover_password = {
		modal: false,
		model: "",
		call: this.api.new().set("success", true),
	};

	ngOnInit(): void {
		this.loginForm = this.fb.group({
			userName: [null, [Validators.required]],
			password: [null, [Validators.required]],
		});
	}

	async recoverPassword() {
		const self = this;
		self.me
			.recoverPassword(self.recover_password.model, this.recover_password.call)
			.subscribe((data) => (this.recover_password.modal = false));
	}
}
