import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-aparelhos-por-tecnico",
	templateUrl: "./aparelhos-por-tecnico.component.html",
	styleUrls: ["./aparelhos-por-tecnico.component.css"],
})
export class AparelhosPorTecnicoComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
