import { ThemeConstantService } from "../../services/theme-constant.service";
import { PageBase } from "@durinn/v.3/app/bases/page.base";
import {Component, Injector, Input, TemplateRef} from "@angular/core";
import config from "../../../../../../config";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
})
export class HeaderComponent extends PageBase {
	isDropdownNotificationsVisible = false;
	hasUnreadNotifications = false;
	unreadNotificationsCount = 0;
	
	quickViewVisible = false;
	searchVisible = false;
	isFolded: boolean;
	isExpand: boolean;
	config = config;

	@Input() customItems: TemplateRef<any>;
	@Input() customMenuItemBefore: TemplateRef<any>;

	constructor(protected injector: Injector, protected themeService: ThemeConstantService) {
		super(injector);
	}

	ngOnInit(): void {
		this.themeService.isExpandChanges.subscribe((isExpand) => (this.isExpand = isExpand));
		this.themeService.isMenuFoldedChanges.subscribe((isFolded) => (this.isFolded = isFolded));
	}

	toggleFold() {
		this.isFolded = !this.isFolded;
		this.themeService.toggleFold(this.isFolded);
	}

	toggleExpand() {
		this.isFolded = false;
		this.isExpand = !this.isExpand;
		this.themeService.toggleExpand(this.isExpand);
		this.themeService.toggleFold(this.isFolded);
	}

	searchToggle(): void {
		this.searchVisible = !this.searchVisible;
	}

	quickViewToggle(): void {
		this.quickViewVisible = !this.quickViewVisible;
	}

	async logout() {
		const self = this;
		if (await self.app.confirm(`Logout`, `Você deseja sair do sistema?`)) {
			await self.me.logout();
			self.router.navigateByUrl("/authentication");
		}
	}
}
