import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {HeadColumns} from "@durinn.v3/app/components/template/tables/list-page-table/list-page-table.component";
import {PageBase} from "@durinn.v3/app/bases/page.base";

@Component({
	selector: 'app-agendamentos-dashboard',
	templateUrl: './agendamentos-dashboard.component.html',
	styleUrls: ['./agendamentos-dashboard.component.css']
})
export class AgendamentosDashboardComponent extends PageBase {
	order = { ContractSchedule: [['disconnectionDate', 'desc']] };
	route = "contract-schedule";
	moment = moment;
	
	from = moment().startOf('month').format('DD/MM/YYYY');
	to = moment().endOf('month').format('DD/MM/YYYY');
	
	call = this.api.new();
	data = null;
	
	ngOnInit() {
		super.ngOnInit();
		this.getOverview();
	}
	
	getOverview(){
		const self = this;
		self.call.get(`contract-schedule/overview/${moment(self.from, 'DD/MM/YYYY').toISOString()}/${moment(self.to, 'DD/MM/YYYY').toISOString()}`).subscribe(data => {
			self.data = data.return;
			console.log('self.data', self.data);
		});
	}
	
	showTable = true;
	date = moment().format('DD/MM/YYYY');
	
	filters: any = {
		LastHistory_CreatedBy: {id: {'Op.gt': 0}},
		LastHistory: {createdAt: {'Op.gte': moment().startOf('day').toDate()}},
	};
	
	changedDate(){
		this.showTable = false;
		
		this.filters = {
			LastHistory_CreatedBy: {id: {'Op.gt': 0}},
			LastHistory: {createdAt: {'Op.between': [
				moment(this.date, 'DD/MM/YYYY').startOf('day').toDate(),
				moment(this.date, 'DD/MM/YYYY').endOf('day').toDate(),
			]}},
		};
		
		setTimeout(() => this.showTable = true, 200);
	}
	
	headColumns: HeadColumns = [
		{ index: "ContractSchedule", column: "id", name: "ID", type: 'number' },
		{ index: "ContractSchedule", column: "contract", name: "Contrato" },
		{ index: "ContractSchedule", column: "orderServiceType", name: "Tipo de OS" },
		{ index: "ContractSchedule", column: "status", name: "Status" },
		{ index: "ContractSchedule", column: "customer", name: "Cliente" },
		{ index: "ContractSchedule", column: "neighborhood", name: "Bairro" },
		{ index: "ContractSchedule", column: "city", name: "Cidade" },
		{ index: "ContractSchedule", column: "disconnectionType", name: "Tipo de Desconexão" },
		{ index: "ContractSchedule", column: "disconnectionDate", name: "Data da Desconexão", type: 'date' },
		{ index: "LastHistory", column: 'situation', name: "Última Situação" },
		{ index: "LastHistory", column: 'origin', name: "Última Origem" },
		{ index: "LastHistory", column: "createdAt", name: "Data do Último Histórico", type: 'date' },
		{ index: "LastHistory_CreatedBy", column: "name", name: "Último Atendente" },
		{ index: "ContractSchedule", column: 'historyQuantity', name: "Número de Históricos", type: 'number' },
		{ name: "Agendas Relacionadas"},
		{}
	];
}
