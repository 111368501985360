import { Component, Input, OnInit } from "@angular/core";
import { Api } from "@durinn.v3/helpers/api";
import * as moment from "moment";

declare var google: any;

@Component({
	selector: "deliverymen-active-map",
	templateUrl: "./active-map.component.html",
	styleUrls: ["./active-map.component.css"],
})
export class ActiveMapComponent implements OnInit {
	@Input("height") height = 600;

	public deliverymen = [];
	public map: any;
	public teamId = 0;
	public bounds;
	public markers = [];

	interval;
	constructor(private api: Api) {}

	ngOnInit() {}

	ngOnDestroy() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	changeTeam(id) {
		const self = this;
		self.teamId = id;
		self.getPoints().then(() => self.map.fitBounds(self.bounds));
	}

	getPoints() {
		const self = this;
		return new Promise((resolve) => {
			self.bounds = new window["google"].maps.LatLngBounds();
			self.api
				.new()
				.silent(true)
				.get(
					"deliverymen/active-map/1/" +
						(self.teamId ? self.teamId : "")
				)
				.subscribe((data) => {
					self.deliverymen = data.return;

					for(const index in this.markers){
						this.markers[index].setMap(null);
					}
					
					this.markers = [];
					
					console.log('this.markers', this.markers.length);
					
					for (const deliveryman of this.deliverymen) {
						if (deliveryman.Lastlocation) {
							self.bounds.extend({
								lat: deliveryman.Lastlocation.latitude,
								lng: deliveryman.Lastlocation.longitude,
							});
							
							for(const deliveryman of self.deliverymen){
								self.markers.push(new google.maps.Marker({
									position: new google.maps.LatLng(deliveryman.Lastlocation.latitude, deliveryman.Lastlocation.longitude),
									map: self.map,
									icon: self.getIcon(deliveryman),
									label: deliveryman.name.split(' ')[0]
								}));
							}
						}
					}

					resolve(":)");
				});
		});
	}

	open(deliveryman) {
		window.open("tecnicos/editar/" + deliveryman.id);
	}

	async mapReady(map) {
		const self = this;
		this.map = map;
		this.bounds = new window["google"].maps.LatLngBounds();
		this.getPoints().then(() => self.map.fitBounds(self.bounds));

		if (this.interval) {
			clearInterval(this.interval);
		}

		this.interval = setInterval(() => self.getPoints(), 20 * 1000);
	}

	getIcon(item) {
		let color = "";

		if (item.moving) {
			color = "00ff00";

			if (moment().diff(moment(item.device_date), "minutes") > 5) {
				color = "FFA500";
			}
		} else {
			color = "FFFF00";

			if (moment().diff(moment(item.device_date), "minutes") > 15) {
				color = "0000FF";
			}
		}

		if (!moment(item.device_date).isSame(moment(), "day")) {
			color = "CCCCCC";
		}
		
		var pinLabel = "A";
		
		// Pick your pin (hole or no hole)
		var pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
		var labelOriginHole = new google.maps.Point(12,15);
		var pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
		var labelOriginFilled =  new google.maps.Point(12,9);
		
		
		var markerImage = {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
			path: pinSVGHole,
			anchor: new google.maps.Point(12,17),
			fillOpacity: 1,
			fillColor: '#' + color,
			strokeWeight: 1,
			strokeColor: "black",
			scale: 2,
			labelOrigin: labelOriginHole
		};

		return markerImage;
	}
}
