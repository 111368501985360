import { Component, Input } from "@angular/core";
import { SubsidiaryInterface } from "@backend-interfaces/models";
import {
	ModelBaseInterface,
	ModelPageBase,
} from "@durinn/v.3/app/bases/modelpage.base";
import { Address } from "ngx-google-places-autocomplete/objects/address";

@Component({
	selector: "durinn-v2-subsidiary",
	templateUrl: "./subsidiary.component.html",
	styleUrls: ["./subsidiary.component.css"],
})
export class DurinnV2SubsidiaryComponent
	extends ModelPageBase
	implements ModelBaseInterface {
  @Input() page: DurinnV2SubsidiaryComponent = this;

  @Input() tabs = ["Loja"];
  @Input() hiddenTabs = [];
  @Input() defaultTab = "Loja";

  route = "subsidiary";
  backPage = "lojas";
  permission = "SUBSIDIARIES";
  variableUrl = "subsidiary-fieldstofill";

  form: Partial<SubsidiaryInterface> & { variables: any } = { variables: {} };

  fields = [
  	{
  		label: "Nome Interno",
  		placeholder: "Ex: Matriz",
  		name: "name",
  		type: "text",
  		validate: "Por favor, preencha com um nome válido!",
  		required: true,
  	},
  ];

  _teamSelected = null;
  teamSelected(user) {
  	this._teamSelected = user;
  }

  addUser() {
  	const self = this;

  	if (!self.form.Teams) {
  		self.form.Teams = [];
  	}

  	for (const item of self.form.Teams) {
  		if (item.id == self._teamSelected.id) {
  			return false;
  		}
  	}

  	self.form.Teams.push(self._teamSelected);
  }
  removeTeam(i) {
  	const self = this;

  	if (!self.form.Teams) {
  		self.form.Teams = [];
  	}

  	self.form.Teams.splice(i, 1);
  }

  public handleAddressChange(address: Address) {
  	this.form.latitude = address.geometry.location.lat();
  	this.form.longitude = address.geometry.location.lng();
  	this.form.formattedAddress = address.formatted_address;
  }

  get canEdit() {
  	return this.hasPermission("EDIT_" + this.permission) || !this.id;
  }

  loading = false;
}
