import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {Io} from "@durinn.v3/helpers/api";

/**
 * Esse helper impede que componentes diferentes chamem varias vezes, ao mesmo tempo,
 * a EXATA mesma requisição
 */

const calls: {[index: string]: Observable<any>} = {};

@Injectable()
export class ApiCallsManager {
	call(call: Io, method: 'get' | 'post' | 'put' | 'delete', url, args?: { [a: string]: any }){
		const name = `${method}-${url}-${JSON.stringify(args || {})}`;

		if(calls[name]){
			return calls[name];
		}else{
			if(method == 'get'){
				calls[name] = call.get(url, args);
			}

			if(method == 'put'){
				calls[name] = call.put(url, args);
			}

			if(method == 'post'){
				calls[name] = call.post(url, args);
			}

			if(method == 'delete'){
				calls[name] = call.delete(url, args);
			}

			const subject = new Subject();

			calls[name].subscribe(
				data => {
					subject.next(data);

					console.log(`Deletando calls`, name);
					delete calls[name];
				},
				err => {
					subject.error(err);

					console.log(`Deletando calls`, name);
					delete calls[name];
				}
			);

			calls[name] = subject;
			return subject;
		}
	}
}
