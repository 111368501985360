import { Component } from "@angular/core";
import { ProfilePermissionInterface } from "@backend-interfaces/models";
import {
	ListBaseInterface,
	ListPageBase,
} from "@durinn/v.3/app/bases/listpage.base";

export class ProfileClass {
	name: string;
	note: string | null;

	Permissions: ProfilePermissionInterface[] = [];
}

@Component({
	selector: "durinn-v2-profile-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.css"],
})
export class DurinnV2ProfileSettingsComponent
	extends ListPageBase
	implements ListBaseInterface {
	route = "profile";
	modalVisible = false;

	filters = {
		Profile: {
			name: "",
		},
		ProfilePermission: {},
	};
	order = {
		Profile: [["createdAt", "asc"]],
	};

	form = {
		name: "",
		note: "",
		Permissions: [],
	};

	setForm(item?) {
		this.modalVisible = true;
		if (item) {
			this.form = { ...item };
			return;
		}
		this.form = {
			name: "",
			note: "",
			Permissions: [],
		};
		console.log(this.form);
	}

	modalCancel(event) {
		this.form = {
			name: "",
			note: "",
			Permissions: [],
		};
		this.modalVisible = !event;
	}

	concluded(event) {
		if (event) {
			this.get();
		}
	}
}
