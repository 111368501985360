import { BasePage } from "../../../../../bases/base.page";
import { Component } from "@angular/core";

@Component({
	selector: "app-validacao-de-carga",
	templateUrl: "./validacao-de-carga.component.html",
	styleUrls: ["./validacao-de-carga.component.css"],
})
export class ValidacaoDeCargaComponent extends BasePage {
	report = {
		textarea: "",
	};

	loading = false;

	ngOnInit() {}

	call = this.api.new();

	async send() {
		const self = this;
		const barcodes = self.report.textarea.split("\n");

		if (barcodes.length > 5000) {
			return self.app.alert(
				`Erro`,
				`Você só pode consultar no máximo 5000 linhas por vez`,
				`error`
			);
		}

		self.call
			.post("warehouse/reports/validate-barcodes", { barcodes: barcodes })
			.subscribe((data) => {
				console.log(data.return);
				const hiddenElement = document.createElement("a");
				hiddenElement.href =
					"data:text/csv;charset=utf-8," + encodeURI(data.return.csv);
				hiddenElement.target = "_blank";
				hiddenElement.download = "relatorio.csv";
				hiddenElement.click();
			});
	}
}
