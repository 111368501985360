import { Input } from "@angular/core";
import { ListPageBase } from "@durinn/v.3/app/bases/listpage.base";

export class FilterOrderBase {
  @Input() name?: string;
  @Input() placeholder?: string;

  @Input() page: ListPageBase;
  @Input() orderControlHidden = false;

  // Atributos em relação aos filtros e ordenação
  // ---------------------------
  // No exemplo:
  // ---------------------------
  // filters = {
  // 	Costumer: { name: "" },
  // 	Emails: { email: "" },
  // 	Phones: { phone: "" },
  // };
  // ---------------------------
  // order = { Costumer: [["name", "asc"]] };
  // ---------------------------

  @Input() index = ""; // Poderia ser Costumer
  @Input() column = ""; // Poderia ser name

  ngOnInit() {
	  if(this.index){
		  if (!this.page.filters[this.index]) {
			  this.page.filters[this.index] = {};
		  }

		  if (!this.page.order[this.index]) {
			  this.page.order[this.index] = [];
		  }
	  }
  }

  ngOnChanges() {
	  if(this.index){
		  if (!this.page.filters[this.index]) {
			  this.page.filters[this.index] = {
				  [this.column]: "",
			  };
		  }

		  if (!this.page.order[this.index]) {
			  this.page.order[this.index] = [];
		  }
	  }
  }

  get model() {
  	return ((this.page.filters || {})[this.index] || {})[this.column] || "";
  }

  set model(value) {
  	const self = this;

  	if (!this.page.filters) {
  		this.page.filters = {};
  	}

  	if (!this.page.filters[this.index]) {
  		this.page.filters[this.index] = {
  			[this.column]: "",
  		};
  	}

  	this.page.filters[this.index][this.column] = value;
  }

  // Retorna se estamos ordenando por este campo
  get isOrdering(): boolean {
  	const order = this.page.order[this.index];
  	return order && order[0] && order[0][0] == this.column;
  }

  // Retornar a ordenação se a ordenação atual é "asc" ou "desc"
  get actualOrder() {
  	const order = this.page.order[this.index]; // Deve retornar algo como [['name','asc']]

  	if (order && order[0] && order[0][0]) {
  		return (order[0][1] || "asc").toString().toLowerCase();
  	}

  	return "asc";
  }

  setOrdering() {
  	const order = this.page.order[this.index]; // Deve retornar algo como [['name','asc']]

  	this.page.order = {
  		[this.index]: [
  			[
  				this.column,
  				order && order[0] && order[0][0] && order[0][1] == "asc"
  					? "desc"
  					: "asc",
  			],
  		],
  	};

  	this.page.get();
  }

  // Model que guarda os dados
  changedModel() {
  	this.page.get();
  }
}
