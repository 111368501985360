import {WithLayoutComponent as WithLayoutComponentDurinn} from "@durinn.v3/app/views/with-layout/with-layout.component";
import {Component} from "@angular/core";
import * as moment from "moment";

@Component({
	selector: "app-common-layout",
	templateUrl: "./with-layout.component.html",
})
export class WithLayoutComponent extends WithLayoutComponentDurinn{
	userLoaded(user: any) {
		super.userLoaded(user);
		this.getEnterprises();
		this.getTime();
		this.formatTime();
	}

	enterprisesCall = this.api.new();
	enterprises = [];
	getEnterprises(){
		const self = this;
		self.enterprisesCall.post(`enterprise/search/0/100`).subscribe(data => {
			self.enterprises = data.return.rows;
		});
	}

	async updateEnterprise(){
		const self = this;
		await self.me.update(self.user, self.enterprisesCall);
		window.location.reload();
	}


	calls = {
		online: this.api.new()
	};

	time_register = null;
	time_desc = null;

	getTime() {
		this.calls.online
			.get("me/time-register")
			.subscribe((data) => (this.time_register = data.return));
	}

	addTime() {
		this.calls.online
			.post("me/time-register")
			.subscribe((data) => this.getTime());
	}

	formatTime() {
		if (this.time_register && this.time_register.departure_date === null) {
			const diff = moment().diff(
				moment(this.time_register.entry_date),
				"seconds"
			);
			this.time_desc = moment()
				.startOf("day")
				.seconds(diff)
				.format("HH:mm:ss");
		} else {
			this.time_desc = "Ficar Online";
		}

		setTimeout(() => {
			this.formatTime();
		}, 1000);
	}
}