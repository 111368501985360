import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from "@angular/router";
import { take } from "rxjs/operators";
import { Me } from "./me";

@Injectable()
export class Logged implements CanActivate {
	constructor(protected me: Me, protected router: Router) {}

	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> {
		const self = this;
		const user = await self.me.get().pipe(take(1)).toPromise();
		const args = next.data || {};

		if (!user || !("id" in user)) {
			console.error(`Usuário não encontrada`);
			self.router.navigate([args.redirect || "/authentication"]);
			return false;
		}

		if ("permission" in args) {
			if (!self.me.hasPermission(args.permission)) {
				console.error(`Permissão não encontrada`, args.permission);
				return self.router.navigate(["/home"]);
			}
		}

		if ("permissions" in args) {
			for (const permission of args.permissions) {
				if (!self.me.hasPermission(permission)) {
					console.error(`Permissão não encontrada`, args.permission);
					return self.router.navigate(["/home"]);
				}
			}
		}

		return true;
	}
}

@Injectable()
export class NotLogged implements CanActivate {
	constructor(private me: Me, private router: Router) {}

	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> {
		const self = this;
		const user = await self.me.get().pipe(take(1)).toPromise();
		const args = next.data || {};

		if (user && "id" in user) {
			console.error(`Usuário encontrado`, user);
			self.router.navigate([args.redirect || "/dash"]);
			return false;
		}

		return true;
	}
}
