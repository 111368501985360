import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageBase} from "@durinn.v3/app/bases/page.base";
import * as moment from "moment";

@Component({
	selector: 'app-modal-agenda-criar-reversao',
	templateUrl: './modal-agenda-criar-reversao.component.html',
	styleUrls: ['./modal-agenda-criar-reversao.component.css']
})
export class ModalAgendaCriarReversaoComponent extends PageBase {
	@Input() id: number | string;
	@Input() delivery: any;
	
	@Input() openNewTab = true;
	
	@Input() visible = false;
	@Output() visibleChange = new EventEmitter();
	
	@Output() reverted = new EventEmitter();
	
	
	call = this.api.new();
	
	date = '';
	from = '08:00';
	to = '22:00';
	
	Deliveryman = null;
	
	dismiss(){
		this.visible = false;
		this.visibleChange.emit(false);
	}
	
	send(){
		const self = this;
		const from = moment(`${this.date} ${this.from}`, `DD/MM/YYYY HH:mm`);
		const to = moment(`${this.date} ${this.to}`, `DD/MM/YYYY HH:mm`);
		
		if(!from.isValid() || !to.isValid()){
			return self.app.alert(`Erro`, `Por favor, confira as datas`);
		}
		
		if(!self.Deliveryman){
			return self.app.alert(`Erro`, `Por favor, selecione um técnico`);
		}
		
		self.call
			.post(
				"delivery/" + self.id + "/reversion",
				{from, to, deliverymanId: self.Deliveryman.id}
			)
			.subscribe((response) => {
				if (response.success) {
					self.app.alert(`Sucesso`, response.message, `success`);
					self.reverted.emit(response.return);
					
					if(self.openNewTab){
						window.open(`/agendas/${response.return.id_delivery}`);
					}
					
					self.dismiss();
				}
			});
	}
}
