import { ModelInterface } from './Model.interface';
import { UserInterface } from './User.interface';

export type NotificationSetting = {
	encompasses: string[] | true;
    categoryDescription: string;
    permissions: string[];
    description: string;
    musthave: string[];
    disabled: boolean;
    category: string;
    checked: boolean;
	color: string;
	icon: string;
    name: string;
	urgent ?: boolean;
};

export type NotificationSettingsSchema = {
    [name: string]: NotificationSetting;
};

export const NOTIFICATION_SETTINGS: NotificationSettingsSchema = {
	SEE_NOTIFICATIONS: {
		name: 'SEE_NOTIFICATIONS',
		description: 'Desejo receber notificações do sistema',
		permissions: [],
		musthave: [],
		encompasses: [],
		category: 'GENERAL',
		categoryDescription: 'Geral',
		checked: false,
		disabled: false,
		color: null,
		icon: null
	}
};

export interface UserNotificationSettingInterface extends ModelInterface {
    notification: string;
    field: string | null;
    key: string | null;
	checked: boolean;

    userId: number;
    User: UserInterface;
}
