import { CrudPage } from "src/app/bases/crud.page";
import { Component, Input } from "@angular/core";
import * as moment from "moment";

export const AccomplishmentTranslationById = {
	0: "Saída de 7 dias antes",
	1: "Saída de 2 horas por dia",
};

export function getAccomplishmentOptions() {
	const accomplishments = [];

	for (const item in AccomplishmentTranslationById) {
		accomplishments.push({
			label: AccomplishmentTranslationById[item],
			value: +item,
		});
	}

	return accomplishments;
}

@Component({
	selector: "app-termination",
	templateUrl: "./termination.component.html",
	styleUrls: ["./termination.component.css"],
})
export class TerminationComponent extends CrudPage {
	AccomplishmentTranslationById = AccomplishmentTranslationById;
	AccomplishmentOptions = getAccomplishmentOptions();

	onModalCall = this.api.new();
	@Input() userType: string;
	isModalVisible = false;
	route = "termination";
	@Input() id: number;
	moment = moment;

	filters = {
		Termination: {},
	};

	order = {
		Termination: [["from", "desc"]],
	};

	params: any = {
		from: moment().startOf("month").format("DD/MM/YYYY"),
		accomplishment: 0,
	};

	ngOnInit() {
		const id = this.isDeliveryman
			? { userId: this.id }
			: { adminId: this.id };
		this.filters.Termination = { ...id };
		super.ngOnInit();
	}

	get isDeliveryman() {
		const self = this;
		return self.userType == "deliverymen";
	}

	get isLoading() {
		const self = this;
		return self.call.loading || self.delete_call.loading;
	}

	get onModalTitle() {
		const self = this;
		return self.params.id
			? "Editar Desligamento"
			: "Adicionar Desligamento";
	}

	get isModalParamsValid() {
		const self = this;
		return (
			moment(self.params.from, "DD/MM/YYYY").isValid() &&
			self.params.accomplishment != null
		);
	}

	onModalParamsReset() {
		const self = this;
		self.params = {
			from: moment().startOf("month").format("DD/MM/YYYY"),
			accomplishment: 0,
		};
	}

	onModalOpen() {
		const self = this;
		self.onModalParamsReset();
		self.isModalVisible = true;
	}

	onModalUpdate(item) {
		const self = this;
		const params = { ...item };
		params.from = moment(params.from).format("DD/MM/YYYY");
		self.isModalVisible = true;
		self.params = params;
	}

	onModalSubmit() {
		const self = this;
		const body = { ...self.params };

		body.from = moment(body.from, "DD/MM/YYYY")
			.startOf("day")
			.toISOString();

		if (body.id) {
			self.onModalCall
				.put(`${self.route}/${body.id}`, {
					accomplishment: body.accomplishment,
					from: body.from,
				})
				.subscribe((response) => {
					self.isModalVisible = false;
					self.onModalParamsReset();
					self.app.alert("Sucesso", response.message, "success");
					self.get();
				});
		} else {
			const id = self.isDeliveryman
				? { userId: self.id }
				: { adminId: self.id };
			self.onModalCall
				.post(self.route, { ...body, ...id })
				.subscribe((response) => {
					self.isModalVisible = false;
					self.onModalParamsReset();
					self.app.alert("Sucesso", response.message, "success");
					self.get();
				});
		}
	}
}
