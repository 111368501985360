import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Api } from "@durinn/v.3/helpers/api";

export type NotificationSetting = {
	encompasses: string[] | true;
	categoryDescription: string;
	permissions: string[];
	description: string;
	musthave: string[];
	disabled: boolean;
	category: string;
	checked: boolean;
	name: string;
};

@Component({
	selector: "durinn-v3-user-notification-setting",
	templateUrl: "./user-notification-setting.component.html",
	styleUrls: ["./user-notification-setting.component.css"],
})
export class UserNotificationSettingComponent implements OnInit {
	NotificationSettings: { [name: string]: NotificationSetting } = {};
	route = "notification-settings";
	call = this.api.new();
	Object = Object;

	@Output() change = new EventEmitter();
	@Input() maxHeight = 600;
	@Input() isLoading = false;
	@Input() user = null;

	constructor(public api: Api) { }

	ngOnInit() {
		const self = this;
		self.call.get(self.route + '/' + self.user.id).subscribe((data) => {
			self.NotificationSettings = data.return;
			self.checkNotifications();
			self.emitCheckedPermissions();
		});
	}

	ngOnChanges() {
		const self = this;
		self.checkNotifications();
	}

	get loading() {
		const self = this;
		return self.isLoading || self.call.loading;
	}

	checkNotifications() {
		const self = this;

		if (!self.user) {
			return false;
		}

		if (!self.user.NotificationSettings) {
			self.user.NotificationSettings = [];
		}

		const formattedUserNotificationSettings: { [index: string]: boolean } = {};

		for (const item of self.user.NotificationSettings) {
			formattedUserNotificationSettings[item.notification] = item.checked;
		}

		for (const item in self.NotificationSettings) {
			self.NotificationSettings[item].checked = true;

			/* Com as notificações, se o usuário não definir para não receber, o padrão é que ele receba. */
			if (formattedUserNotificationSettings[item] === false) {
				self.NotificationSettings[item].checked = false;
			}
		}
	}

	emit(item) {
		const self = this;
		self.calcEncompasses(item);
		self.emitCheckedPermissions();
	}

	emitCheckedPermissions() {
		const self = this;
		const checkedPermissions = [];
		for (const item of Object.values(self.NotificationSettings)) {
			checkedPermissions.push(item);
		}
		console.log('emitCheckedPermissions');
		self.change.emit(checkedPermissions);
	}

	validMustHave(item) {
		const self = this;
		for (const name of item.musthave) {
			if (!self.NotificationSettings[name] || !self.NotificationSettings[name].checked) {
				if (item.checked) {
					item.checked = false;
					self.emit(item);
				}
				return false;
			}
		}

		return true;
	}

	calcEncompasses(notificationChecked) {
		const self = this;
		if (Array.isArray(notificationChecked.encompasses)) {
			for (const encompass of notificationChecked.encompasses) {
				self.NotificationSettings[encompass].checked = notificationChecked.checked;

				if (self.NotificationSettings[encompass].name != notificationChecked.name) {
					self.NotificationSettings[encompass].disabled = notificationChecked.checked;
				}
			}
		}

		if (notificationChecked.encompasses === true) {
			for (const item in self.NotificationSettings) {
				self.NotificationSettings[item].checked = notificationChecked.checked;

				if (self.NotificationSettings[item].name != notificationChecked.name) {
					self.NotificationSettings[item].disabled = notificationChecked.checked;
				}
			}
		}
	}
}
