import { CrudPage } from "../../bases/crud.page";
import { Component, Input } from "@angular/core";
import * as moment from "moment";

export const TYPE = {
	INFO: "info",
	ERROR: "error",
	WARNING: "warning",
};

@Component({
	selector: "app-logs-table",
	templateUrl: "./logs-table.component.html",
	styleUrls: ["./logs-table.component.css"],
})
export class LogsTableComponent extends CrudPage {
	call = this.api.new();
	limit = 50;

	@Input() deliveryman = null;
	@Input() user = null;

	TYPE = {
		info: "Informação",
		error: "Erro",
		warning: "Atenção",
	};

	search = "";
	items = [];
	moment = moment;

	isVisible = false;

	latitude = null;
	longitude = null;

	filters = {
		SystemLog: {
			type: "",
			log: null,
			userId: null,
		},
	};

	order = {
		SystemLog: [["createdAt", "desc"]],
	};

	route = "deliverymen";

	ngOnInit() {
		const self = this;
		if (self.deliveryman) {
			self.route = "deliverymen/" + this.deliveryman + "/logs";
		} else {
			self.route = "users/" + this.user + "/logs";
		}
		super.ngOnInit();
	}

	seeMap(latitude, longitude) {
		this.latitude = latitude;
		this.longitude = longitude;
		this.isVisible = true;
	}
}
