import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageBase} from "@durinn.v3/app/bases/page.base";
import {DELIVERY_STATUS} from "../../../../../constants";

@Component({
	selector: 'app-modal-agenda-concluir',
	templateUrl: './modal-agenda-concluir.component.html',
	styleUrls: ['./modal-agenda-concluir.component.css']
})
export class ModalAgendaConcluirComponent extends PageBase {
	@Input() id: number | string;
	@Input() delivery: any;
	
	@Input() visible = false;
	@Output() visibleChange = new EventEmitter();
	
	@Output() concluded = new EventEmitter();
	
	status = DELIVERY_STATUS;
	
	dismiss(){
		this.visible = false;
		this.visibleChange.emit(false);
	}
	
	ngOnChanges(changes: any) {
		const self = this;
		
		const arr = [];
		for (const item of self.delivery.Devices || []) {
			if (item.barcode) {
				arr.push(item.barcode);
			}
		}
		
		this.conclude_obj.barcodes = arr.join("\n");
	}
	
	call = this.api.new();
	
	conclude_obj = {
		conclude_code: null,
		conclude_note: "",
		barcodes: "",
	};
	
	public async conclude() {
		const self = this;
		
		const send: any = Object.assign({}, self.conclude_obj);
		send.barcodes = (self.conclude_obj.barcodes || "").split("\n");
		
		self.call
			.post("delivery/" + self.id + "/conclude", send)
			.subscribe((response) => {
				if (response.success) {
					self.toastr.success(`Agenda concluída com sucesso!`);
					self.concluded.emit(response.return);
					self.dismiss();
				}
			});
	}
}
