import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "formathours",
})
export class FormathoursPipe implements PipeTransform {
	transform(seconds_: string | number, signal = true): any {
		const sec_num = Math.abs(parseInt(seconds_.toString(), 10)); // don't forget the second param
		let hours: any = Math.floor(sec_num / 3600);
		let minutes: any = Math.floor((sec_num - hours * 3600) / 60);
		let seconds: any = sec_num - hours * 3600 - minutes * 60;

		if (hours < 10) {
			hours = "0" + hours;
		}
		if (minutes < 10) {
			minutes = "0" + minutes;
		}
		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		return (
			(parseInt(seconds_.toString()) < 0 && signal ? "-" : "") +
			hours +
			"h" +
			minutes +
			"min"
		); //+seconds;
	}
}
