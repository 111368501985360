import {
	Component,
	ElementRef,
	HostListener,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	Device_Scans_Type,
	Device_Scans_Type_Translate,
	Device_Type,
} from "../../almoxarifado.component";
import { OLD_API_LINK } from "../../../../../bases/base.page";
import * as moment from "moment";
import * as $ from "jquery";
import { App } from "@durinn.v3/helpers/app";
import { Api } from "@durinn.v3/helpers/api";

@Component({
	selector: "app-nova-ocorrencia",
	templateUrl: "./nova-ocorrencia.component.html",
	styleUrls: ["./nova-ocorrencia.component.css"],
})
export class NovaOcorrenciaComponent implements OnInit {
	public keypressed;

	tab = "aparelho";

	moment = moment;

	@HostListener("window:keydown", ["$event"])
	handleKeyboardEvent(event: KeyboardEvent) {
		this.keypressed = event.keyCode;
		if (this.keypressed == 13) {
			if (document.activeElement instanceof HTMLElement) {
				document.activeElement.blur();
			}
			this.send();
		}
	}

	new_occurrence = {
		note: "",
		type: 1,
		category: 0,
		device: null,
		barcode: "",
		contract: "",
		loading: false,
		branchId: null,
		deliverymanId: null,
		warehouseBoxId: null,
		boxBarcodes: [],
	};

	response: any[] = [];

	Object = Object;
	status = Device_Scans_Type;
	devices_type = Device_Type;
	translate = Device_Scans_Type_Translate;

	@ViewChild("barcode", { static: true }) barcode: ElementRef<HTMLElement>;
	@ViewChild("addBarcodeBox", { static: true })
		addBarcodeBox: ElementRef<HTMLElement>;

	constructor(private io: Api, private app: App) {}

	ngOnInit() {}

	async send() {
		const self = this;

		if (!self.new_occurrence.device || !self.new_occurrence.barcode) {
			return false;
		}

		self.new_occurrence.loading = true;

		const api = self.io.new();
		api.post("warehouse", self.new_occurrence).subscribe(
			(res) => {
				self.response.reverse();
				self.response.push({
					...res.return,
					...{ barcode: self.new_occurrence.barcode },
				});

				self.response.reverse();

				self.new_occurrence.barcode = "";
				self.new_occurrence.contract = "";
				self.new_occurrence.loading = false;
				setTimeout(() => $("#singleBarcode").focus(), 500);
			},
			(error) => {
				self.response.reverse();
				self.response.push({
					barcode: self.new_occurrence.barcode,
					code: 500,
					error: true,
					message: "Erro ao adicionar código de barras",
					return: {},
					api: undefined,
				});

				self.response.reverse();

				self.new_occurrence.barcode = "";
				self.new_occurrence.contract = "";
				self.new_occurrence.loading = false;
				setTimeout(() => $("#singleBarcode").focus(), 500);
				console.error(error);
			}
		);
	}

	boxDifferentThenScanneds() {
		const self = this;

		let count = 0;

		for (const box of self.boxes) {
			count += (box.Devices || []).length;
		}

		if (self.new_occurrence.boxBarcodes.length != count) {
			return true;
		}

		for (const box of self.boxes) {
			for (const item of box.Devices || []) {
				if (!self.hasInBarcodes(item.Device.barcode)) {
					return true;
				}
			}
		}

		for (const item of self.new_occurrence.boxBarcodes || []) {
			if (!self.hasInBox(item)) {
				return true;
			}
		}

		return false;
	}

	async sendBox() {
		const self = this;

		if (self.boxDifferentThenScanneds()) {
			if (
				!(await self.app.confirm(
					`Caixa Diferente`,
					`Os códigos de barras informados na criação da caixa não conferem com os códigos de barras escaneados, enviar mesmo assim?`
				))
			) {
				return false;
			}
		}

		self.new_occurrence.loading = true;

		const api = self.io.new();
		api.post("warehouse/box", self.new_occurrence).subscribe(
			(res) => {
				self.response.reverse();
				self.response.push({
					...res.return,
					...{
						barcode: (self.new_occurrence.boxBarcodes || []).join(
							","
						),
					},
				});

				self.response.reverse();

				self.boxes = [];
				self.new_occurrence.boxBarcodes = [];
				self.new_occurrence.warehouseBoxId = "";
				self.new_occurrence.barcode = "";
				self.new_occurrence.contract = "";
				self.new_occurrence.loading = false;
				setTimeout(() => $("#boxNumber").focus(), 500);
			},
			(error) => {
				self.response.reverse();
				self.response.push({
					barcode: self.new_occurrence.barcode,
					code: 500,
					error: true,
					message: "Erro ao adicionar código de barras",
					return: {},
					api: undefined,
				});

				self.response.reverse();

				self.new_occurrence.barcode = "";
				self.new_occurrence.contract = "";
				self.new_occurrence.loading = false;
				setTimeout(() => self.barcode.nativeElement.focus(), 500);
				console.error(error);
			}
		);
	}

	async revert(id_scan: number, index: number) {
		const self = this;

		try {
			const api = self.io.new().set("success", true);
			api.delete(OLD_API_LINK + "warehouse/scans/" + id_scan).subscribe(
				(res) => {
					if (res.success) {
						self.response.splice(index, 1);
					}

					setTimeout(() => self.barcode.nativeElement.focus(), 500);
				}
			);
		} catch (e) {
			console.error(e);
		}
	}

	boxes: any = [];
	load = this.io.new();

	loadBox() {
		const self = this;
		self.load
			.get("warehousebox/" + self.new_occurrence.warehouseBoxId)
			.subscribe((data) => {
				const response = data.return;

				for (const box of response) {
					(box.Devices || []).sort(function (a, b) {
						if (a.Device.barcode < b.Device.barcode) {
							return -1;
						}
						if (a.Device.barcode > b.Device.barcode) {
							return 1;
						}
						return 0;
					});
				}

				self.boxes = response;
			});
	}

	addBoxBarcode() {
		const self = this;

		self.new_occurrence.boxBarcodes.push(
			self.new_occurrence.barcode.toString()
		);
		self.new_occurrence.boxBarcodes.sort(function (a, b) {
			if (a < b) {
				return -1;
			}
			if (a > b) {
				return 1;
			}
			return 0;
		});

		self.new_occurrence.barcode = "";
		// this will make the execution after the above boolean has changed
		setTimeout(() => $("#addBarcodeBox").focus(), 100);
	}

	hasInBox(value) {
		const self = this;

		for (const box of self.boxes) {
			for (const item of (box || {}).Devices || []) {
				if (
					(item.Device.barcode || "").toUpperCase() ==
					value.toUpperCase()
				) {
					return true;
				}
			}
		}

		return false;
	}

	hasInBarcodes(value) {
		const self = this;

		for (const item of self.new_occurrence.boxBarcodes || []) {
			if ((item || "").toUpperCase() == value.toUpperCase()) {
				return true;
			}
		}

		return false;
	}
}
