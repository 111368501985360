import { Component, Input } from "@angular/core";
import { DurinnV2SubsidiaryComponent } from "../../subsidiary.component";

@Component({
	selector: "durinn-v2-subsidiary-edit-tab",
	templateUrl: "./edit-subsidiary-tab.component.html",
	styleUrls: ["./edit-subsidiary-tab.component.css"],
})
export class DurinnV2EditSubsidiaryTabComponent {
  @Input() page: DurinnV2SubsidiaryComponent;
}
