import { CrudPage } from "src/app/bases/crud.page";
import { Component, OnInit } from "@angular/core";

@Component({
	selector: 'app-imports-list',
	templateUrl: './imports-list.component.html',
	styleUrls: ['./imports-list.component.css']
})
export class ImportsListComponent extends CrudPage implements OnInit {
	route = "to-import";

	filters = {
		ToImport: {},
		CreatedBy: {},
	};

	order = {
		ToImport: [["createdAt", "desc"]],
		CreatedBy: [[]],
	};

	getProcessedRate(item) {
		return (((item.success + item.mistake) / item.record) * 100) < 100 ? 'red' : 'green';
	}

	getProcessed(item) {
		const percentage = ((item.success + item.mistake) / item.record) * 100;
		return `${item.success + item.mistake} - ${percentage.toFixed(2)}%`; 
	}
}

