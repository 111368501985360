import { CrudPage } from "src/app/bases/crud.page";
import { Component, Input } from "@angular/core";
import * as moment from "moment";

@Component({
	selector: "app-service-takers",
	templateUrl: "./service-takers.component.html",
	styleUrls: ["./service-takers.component.css"],
})
export class ServiceTakersComponent extends CrudPage {
	isServiceTakersAddModalVisible = false;
	serviceTakersCall = this.api.new();
	@Input() userType: string;
	route = "service-takers";
	@Input() id: number;
	moment = moment;

	filters = {
		ServiceTakers: {},
	};

	ngOnInit() {
		const id =
			this.userType == "deliverymen"
				? { userId: this.id }
				: { adminId: this.id };
		this.filters.ServiceTakers = { ...id };
		super.ngOnInit();
	}

	order = {
		ServiceTakers: [["from", "desc"]],
	};

	params: any = {
		from: moment().startOf("month").format("DD/MM/YYYY"),
		customerId: null,
		Customer: null,
		to: null,
	};

	resetParams() {
		const self = this;
		self.params = {
			from: moment().startOf("month").format("DD/MM/YYYY"),
			customerId: null,
			Customer: null,
			to: null,
		};
	}

	isParamsValid() {
		const self = this;

		const to = self.params.to ? moment(self.params.to, "DD/MM/YYYY") : null;
		const from = moment(self.params.from, "DD/MM/YYYY");
		const customerId = self.params.customerId;

		if (self.params.to) {
			return from.isValid() && to.isValid() && customerId != null;
		}

		return from.isValid() && customerId != null;
	}

	openServiceTakersModal() {
		const self = this;
		self.resetParams();
		self.isServiceTakersAddModalVisible = true;
	}

	serviceTakersUpdate(item) {
		const self = this;
		const params = { ...item };
		params.from = moment(params.from).format("DD/MM/YYYY");

		if (params.to) {
			params.to = moment(params.to).format("DD/MM/YYYY");
		}

		self.isServiceTakersAddModalVisible = true;
		self.params = params;
	}

	serviceTakersAction() {
		const self = this;
		const body = { ...self.params };

		body.from = moment(body.from, "DD/MM/YYYY")
			.startOf("day")
			.toISOString();

		if (body.to) {
			body.to = moment(body.to, "DD/MM/YYYY").endOf("day").toISOString();
		}

		if (body.id) {
			self.serviceTakersCall
				.put(`${self.route}/${body.id}`, {
					customerId: body.customerId,
					from: body.from,
					to: body.to,
				})
				.subscribe(() => {
					self.isServiceTakersAddModalVisible = false;
					self.resetParams();
					self.get();
				});
		} else {
			const id =
				self.userType == "deliverymen"
					? { userId: self.id }
					: { adminId: self.id };
			self.serviceTakersCall
				.post(self.route, { ...body, ...id })
				.subscribe(() => {
					self.isServiceTakersAddModalVisible = false;
					self.resetParams();
					self.get();
				});
		}
	}
}
