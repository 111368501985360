import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {concat, concatMap, delay, retry, retryWhen, take, timeout} from 'rxjs/operators';
import {iif, Observable, Observer, of, Subject, throwError} from 'rxjs';
import config from "../../../config";
import { App } from "./app";

export type Callback = (response: Response) => void;

export interface ResponseInterface {
  readonly api;
  readonly code: number;
  readonly message: string;
  readonly return: any;
  readonly error: boolean;
  readonly error_stack: any;
  readonly redirectTo: string | null;
}

const LOGIN_ERROR = 401;
const LOG_TITLE = "app/helpers/api.ts";

function getValidLink(link: string) {
	return link.substr(link.length - 1, 1) === "/" ? link : link + "/";
}

import {typeOfDevice} from "@durinn.v3/helpers/functions";

@Injectable()
export class Api {
	static LOCAL_API = "http://localhost:39899/";

	public url: string;
	public development: boolean;
	public homologation: boolean;
	public app: App = new App();

	constructor(readonly http: HttpClient) {
		const self = this;

		self.development = false;

		if (document.URL.indexOf("localhost") > -1) {
			self.url = getValidLink(config.api.development_link);
			self.development = true;
		} else if (document.URL.indexOf(config.api.is_acceptance) > -1) {
			self.url = getValidLink(config.api.acceptance_link);
			self.homologation = true;
		} else {
			self.url = getValidLink(config.api.production_link);
		}
	}
	
	get browserid(){
		let deviceId = localStorage.getItem('durinn_deviceId');
		
		if(deviceId){
			return deviceId;
		}
		
		// Se não existir, gerar um.
		
		const s = [];
		const hexDigits = '0123456789abcdef';
		for (let i = 0; i < 36; i++) {
			s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
		}
		s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
		// eslint-disable-next-line no-bitwise
		s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
		s[8] = s[13] = s[18] = s[23] = '-';
		
		deviceId = s.join('');
		localStorage.setItem('durinn_deviceId', deviceId);
		
		return deviceId;
	}

	public new() {
		return new Io(this);
	}

	public newLocalApi() {
		const localApi = new Io(this);

		localApi.silent();
		localApi.set("exception", true);
		localApi.retry = 1;
		localApi.timeout = 5000;

		return localApi;
	}

	public promise(observable: Observable<Response>): Promise<Response> {
		return new Promise((resolve) => {
			observable.subscribe((data) => resolve(data));
		});
	}

	public async openLink(url, args?: { [a: string]: any }, newTab = false) {
		const self = this;
		let params = "";

		if (!args) {
			args = {};
		}

		try {
			const user = JSON.parse(
				(await localStorage.getItem(config.me.cache_name)) || "{}"
			);
			args.authorization =
        "Basic " +
        btoa(
        	user[config.me.login_field || "username"] +
            ":" +
            user[config.me.password_field || "password"]
        );
		} catch (e) {}

		for (const key in args) {
			if (params != "") {
				params += "&";
			}
			params += key + "=" + encodeURIComponent(args[key]);
		}

		if (newTab) {
			window.open(
				(url.indexOf("http") == -1 ? self.url : "") + url + "?" + params
			);
		} else {
			window.location.href =
        (url.indexOf("http") == -1 ? self.url : "") + url + "?" + params;
		}
	}
}

export class Io {
	public retry = 5;
	public timeout = 90 * 1000;

	public success = false;
	public log = false;
	public error = true;
	public exception = true;

	public loader = new Subject<boolean>();
	public loading = false;

	private headers: HttpHeaders;

	constructor(private base: Api) {
		this.loader.subscribe((bool) => (this.loading = bool));
	}

	public set(field: "log" | "error" | "exception" | "success", value: boolean) {
		this[field] = value;
		return this;
	}
	public silent(bool = true) {
		this.set("error", !bool);
		this.set("exception", !bool);
		return this;
	}

	public get(url, args?: { [a: string]: any }): Observable<Response> {
		const self = this;

		return Observable.create(async (observer: Observer<Response>) => {
			await self.setHeaders();

			self.loader.next(true);

			self.base.http
				.get(url.indexOf("http") === 0 ? url : self.base.url + url, {
					headers: self.headers,
					params: args || {},
				})
		  .pipe(timeout(self.timeout))
		  .pipe(retryWhen(errors => errors.pipe(concatMap((e, i) =>
			  iif(() => i > self.retry, throwError(e), of(e).pipe(delay(Math.floor(Math.random() * (4000 - 1000 + 1) + 1000) )))
		  ))))
				.subscribe(
					(data) => {
						self.loader.next(false);
						self.responseHttpOk(data, observer);
					},
					(error) => {
						self.loader.next(false);
						self.responseHttpError(error, observer);
					}
				);
		});
	}
	public post(url, args?: { [a: string]: any }): Observable<Response> {
		const self = this;

		return Observable.create(async (observer: Observer<Response>) => {
			await self.setHeaders();

			self.loader.next(true);

			self.base.http
				.post(
					url.indexOf("http") === 0 ? url : self.base.url + url,
					args || {},
					{
						headers: self.headers,
					}
				)
		  .pipe(timeout(self.timeout))
		  .pipe(retryWhen(errors => errors.pipe(concatMap((e, i) =>
			  iif(() => i > self.retry, throwError(e), of(e).pipe(delay(Math.floor(Math.random() * (4000 - 1000 + 1) + 1000) )))
		  ))))
				.subscribe(
					(data) => {
						self.loader.next(false);
						self.responseHttpOk(data, observer);
					},
					(error) => {
						self.loader.next(false);
						self.responseHttpError(error, observer);
					}
				);
		});
	}
	public put(url, args?: { [a: string]: any }): Observable<Response> {
		const self = this;

		return Observable.create(async (observer: Observer<Response>) => {
			await self.setHeaders();

			self.loader.next(true);

			self.base.http
				.put(
					url.indexOf("http") === 0 ? url : self.base.url + url,
					args || {},
					{ headers: self.headers }
				)
		  .pipe(timeout(self.timeout))
		  .pipe(retryWhen(errors => errors.pipe(concatMap((e, i) =>
			  iif(() => i > self.retry, throwError(e), of(e).pipe(delay(Math.floor(Math.random() * (4000 - 1000 + 1) + 1000) )))
		  ))))
				.subscribe(
					(data) => {
						self.loader.next(false);
						self.responseHttpOk(data, observer);
					},
					(error) => {
						self.loader.next(false);
						self.responseHttpError(error, observer);
					}
				);
		});
	}
	public delete(url, args?: { [a: string]: any }): Observable<Response> {
		const self = this;

		return Observable.create(async (observer: Observer<Response>) => {
			await self.setHeaders();

			self.loader.next(true);

			self.base.http
				.delete(url.indexOf("http") === 0 ? url : self.base.url + url, {
					headers: self.headers,
					params: args || {},
				})
		  .pipe(timeout(self.timeout))
		  .pipe(retryWhen(errors => errors.pipe(concatMap((e, i) =>
			  iif(() => i > self.retry, throwError(e), of(e).pipe(delay(Math.floor(Math.random() * (4000 - 1000 + 1) + 1000) )))
		  ))))
				.subscribe(
					(data) => {
						self.loader.next(false);
						self.responseHttpOk(data, observer);
					},
					(error) => {
						self.loader.next(false);
						self.responseHttpError(error, observer);
					}
				);
		});
	}

	public async openLink(url, args?: { [a: string]: any }, newTab = false) {
		this.base.openLink(url, args, newTab);
	}

	private responseHttpOk(data: any, observer: Observer<Response>) {
		const self = this;
		const response = new Response().build(data);

		if (self.log) {
			console.log(LOG_TITLE, response, this);
		}

		if (response.redirectTo) {
			const win = window.open(response.redirectTo, "_blank");
			win.focus();
		}

		if (response.error === false) {
			if (self.success) {
				self.alertSuccess(response);
			}
			return observer.next(response);
		}else{
			if (+response.code === LOGIN_ERROR) {
				localStorage.removeItem(config.me.cache_name + "_token");
				localStorage.removeItem(config.me.cache_name);
				return self.alertUnauthorized(response);
			}
		}

		if (self.error) {
			self.alertError(response);
		}

		if (self.exception) {
			observer.error(response);
		} else {
			observer.next(response);
		}
	}
	private responseHttpError(error: any, observer: Observer<Response>) {
		const self = this;

		console.error(LOG_TITLE, error, this);

		const response = new Response();

		if (error.error) {
			response.build(error.error);
		}

		if (+error.status === LOGIN_ERROR || +error.code === LOGIN_ERROR) {
			localStorage.removeItem(config.me.cache_name + "_token");
			localStorage.removeItem(config.me.cache_name);
			return self.alertUnauthorized(response);
		}

		if (self.error) {
			self.alertError(response, error);
		}

		if (self.exception) {
			observer.error(response);
		} else {
			observer.next(response);
		}
	}

	private async alertSuccess(data: Response) {
		return await this.base.app.alert(
			"Sucesso",
			data.message || "Atividade realizada com sucesso!",
			"success"
		);
	}
	private async alertError(data: Response, error?: HttpErrorResponse) {
		console.log(`error_alert`, data);
		
		if(error){
			return await this.base.app.alert(
				"Erro de Conexão",
				`<p>Não foi possível conectar com o servidor, por favor, <b>cheque sua conexão com a internet</b>.</p> <small>${error.name}: ${error.message}</small>`,
				"error"
			);
		}

		const message = data.message;

		return await this.base.app.alert(
			"Erro",
			message && typeof message == "string"
				? message
				: "Erro de comunicação com o servidor - " + (typeof data === "object")
					? JSON.stringify(data || {})
					: "",
			"error"
		);
	}
	private async alertUnauthorized(data: Response) {
		const message = data.message;
		
		await this.base.app
			.alert(
				"Erro de Login",
				message || "Você não tem permissões para visualizar essa página",
				"error"
			);
		
		console.log('window.location.href', window.location.href);
		if(window.location.href.indexOf("/authentication") == -1){
			window.location.href = "/authentication";
		}
	}

	private async setHeaders() {
	  const self = this;
	  
	  const headers = {
		  "Content-Type": "application/json",
		  "Durinn-Device-Type": typeOfDevice(),
		  "Durinn-BrowserId": self.base.browserid
	  };
	  
	  self.headers = new HttpHeaders(headers);

	  try {
		  const user = JSON.parse(
			  (await localStorage.getItem(config.me.cache_name)) || "{}"
		  );
		  if (user) {
			  self.headers = new HttpHeaders({
				  ...headers,
				  Authorization:
					  "Basic " +
					  btoa(
						  user[config.me.login_field || "username"] +
						  ":" +
						  user[config.me.password_field || "password"]
					  ),
			  });
		  }
	  } catch (e) {
		  console.error(LOG_TITLE, `setHeaders()`, e);
	  }
	}
}

export class Response implements ResponseInterface {
	readonly api;
	readonly code: number;
	readonly message: string;
	readonly return: any;
	readonly error: boolean;
	readonly error_stack: any;
	readonly redirectTo: string | null;

	public build(data: any) {
		for (const item in data) {
			this[item] = data[item];
		}
		return this;
	}

	public get success() {
		return this.error === false;
	}
}
