import { Component, Input } from "@angular/core";
import { DashboardBase } from "../../bases/dashboard.base";

@Component({
	selector: "configuration-fieldstofill",
	templateUrl: "./fieldstofill.component.html",
	styleUrls: ["./fieldstofill.component.css"],
})
export class FieldstofillComponent extends DashboardBase {
  @Input() table: string;
  @Input() defaultFields: {
    field: string;
    mask: string;
    required: boolean;
  }[] = [];
  @Input() fields: { field: string; mask: string; required: boolean }[] = [];

  call = this.api.new();

  newobj = {
  	field: "",
  	mask: "",
  	required: false,
  };

  ngOnInit() {
  	const self = this;
  	self.call
  		.get(`enterprise/variable/${this.table}-fieldstofill`)
  		.subscribe((data) => {
  			if (data.return) {
  				self.fields = JSON.parse(data.return.variable);
  			}
  		});
  }

  add() {
  	this.fields.push(Object.assign({}, this.newobj));
  	this.newobj = { field: "", mask: "", required: false };
  }

  remove(index: number) {
  	this.fields.splice(index, 1);
  }

  save() {
  	const self = this;
  	self.api
  		.new()
  		.set("success", true)
  		.put(`enterprise/variable/${this.table}-fieldstofill`, {
  			variable: JSON.stringify(self.fields),
  		})
  		.subscribe((data) => {
  			console.log(`data`, data);
  		});
  }
}
