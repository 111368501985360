import { ActivatedRoute, Router } from "@angular/router";
import { Injectable, OnInit } from "@angular/core";
import { User, Me } from "@durinn.v3/helpers/me";
import { App } from "@durinn.v3/helpers/app";
import { Api } from "../helpers/api";

@Injectable()
export class DashboardBase implements OnInit {
	public user: User | {} = {};
	public user_permissions: {
		[s: string]: {
			field: string | null;
			key: string | null;
			permission: string;
		};
	} = {};

	constructor(
		public api: Api,
		public me: Me,
		public app: App,
		public activatedRoute: ActivatedRoute,
		public router: Router
	) {
		const self = this;

		self.me.get().subscribe((user) => {
			if (user) {
				self.user = user;
				for (const item of user.Permissions) {
					self.user_permissions[item.permission] = item;
				}
				self.permissionsLoaded();
				self.userLoaded(user);
			} else {
				self.userNotLoaded();
			}
		});
	}

	ngOnInit() {}

	userLoaded(user: User) {}

	userNotLoaded() {}

	permissionsLoaded() {}

	hasPermission(field) {
		return (
			this.user_permissions[field] ||
			this.user_permissions.MASTER ||
			(this.user as any).master
		);
	}
}
