import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";

@Component({
	selector: "app-almoxarifado-overview-box",
	templateUrl: "./box.component.html",
	styleUrls: ["./box.component.scss"],
})
export class BoxComponent implements OnInit {
	show = false;
	Object = Object;

	@Input() i;
	@Input() overview;
	@Input() lot = null;

	TYPE = {
		0: "Coletado",
		1: "Na Base",
		2: "NET",
		3: "Em Campo",
		4: "Assinante",
		5: "Inválido",
		6: "Na Caixa",
		7: "A Caminho",
		8: "Entregue Base",
		9: "Lote Validado",
		10: "Em Triagem",
		11: /*Técnico*/ "Perda",
		12: /*Técnico*/ "Inventário",
		13: /*Técnico*/ "Baixa Indevida",
	};

	constructor() {}

	ngOnInit() {}

	sum(data) {
		let i = 0;

		if (typeof data !== "object") {
			return 0;
		}

		for (const item of Object.values(data)) {
			i = i + (item as number);
		}

		return i;
	}

	async getExcel(link: string) {
		const self = this;
		self.overview.call
			.get(
				"https://io.padraologistico.com.br/dashboard/warehouse/reports/excel/" +
					link +
					"/" +
					moment(self.overview.from, "DD/MM/YYYY").format(
						"YYYY-MM-DD 00:00:00"
					) +
					"/" +
					moment(self.overview.to, "DD/MM/YYYY").format(
						"YYYY-MM-DD 23:59:59"
					)
			)
			.subscribe((response) => {
				window.open(
					"data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;base64," +
						response.return
				);
			});
	}
}
