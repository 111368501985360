import { DragDropModule, DragDropRegistry } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DurinnCardComponent } from "@durinn/v.3/app/components/durinn-card/durinn-card.component";
import { DurinnModalComponent } from "@durinn/v.3/app/components/durinn-modal/durinn-modal.component";
import { DurinnV2TabsComponent } from "@durinn/v.3/app/components/durinn-tabs/durinn-tabs.component";
import { FieldstofillComponent } from "@durinn/v.3/app/components/fieldstofill/fieldstofill.component";
import { ModelSelectComponent } from "@durinn/v.3/app/components/model-select/model-select.component";
import { FilterDateOrderComponent } from "@durinn/v.3/app/components/search-table/thead/filter-date-order/filter-date-order.component";
import { FilterOrderComponent } from "@durinn/v.3/app/components/search-table/thead/filter-order/filter-order.component";
import { AppLoaderModule } from "@durinn/v.3/app/components/template/app-loader/app-loader.module";
import { DurinnV2PageFooterComponent } from "@durinn/v.3/app/components/template/page-footer/page-footer.component";
import { DurinnV2ListPageTableComponent } from "@durinn/v.3/app/components/template/tables/list-page-table/list-page-table.component";
import { FilterOrderDateComponent } from "@durinn/v.3/app/components/template/tables/thead/filter-order/filter-order-date/filter-order-date.component";
import { FilterOrderDisabledComponent } from "@durinn/v.3/app/components/template/tables/thead/filter-order/filter-order-disabled/filter-order-disabled.component";
import { FilterOrderMoneyComponent } from "@durinn/v.3/app/components/template/tables/thead/filter-order/filter-order-money/filter-order-money.component";
import { FilterOrderNumberComponent } from "@durinn/v.3/app/components/template/tables/thead/filter-order/filter-order-number/filter-order-number.component";
import { FilterOrderSelectComponent } from "@durinn/v.3/app/components/template/tables/thead/filter-order/filter-order-select/filter-order-select.component";
import { FilterOrderTextComponent } from "@durinn/v.3/app/components/template/tables/thead/filter-order/filter-order-text/filter-order-text.component";
import { UserPermissionsTableComponent } from "@durinn/v.3/app/components/users/user-permissions-table/user-permissions-table.component";
import { FormatNamePipe } from "@durinn/v.3/app/pipes/format-name.pipe";
import { LoginComponent } from "@durinn/v.3/app/views/no-layout/login/login.component";
import { DurinnV2ChangePasswordComponent } from "@durinn/v.3/app/views/with-layout/me/profile/components/change-password/change-password.component";
import { DurinnV2UpdatePictureComponent } from "@durinn/v.3/app/views/with-layout/me/profile/components/update-picture/update-picture.component";
import { DurinnV2UpdateProfileComponent } from "@durinn/v.3/app/views/with-layout/me/profile/components/update-profile/update-profile.component";
import { DurinnV2ProfileComponent } from "@durinn/v.3/app/views/with-layout/me/profile/profile.component";
import { DurinnV2EditProfileComponent } from "@durinn/v.3/app/views/with-layout/profile/edit-profile.component";
import { DurinnV2ProfileSettingsComponent } from "@durinn/v.3/app/views/with-layout/profile/settings/settings.component";
import { DurinnV2EditSubsidiaryTabComponent } from "@durinn/v.3/app/views/with-layout/subsidiary/components/edit-subsidiary-tab/edit-subsidiary-tab.component";
import { DurinnV2SubsidiaryListViewComponent } from "@durinn/v.3/app/views/with-layout/subsidiary/list/list.component";
import { DurinnV2SubsidiaryComponent } from "@durinn/v.3/app/views/with-layout/subsidiary/subsidiary.component";
import { DurinnV2TeamListViewComponent } from "@durinn/v.3/app/views/with-layout/team/list/list.component";
import { DurinnV2TeamSettingsComponent } from "@durinn/v.3/app/views/with-layout/team/settings/settings.component";
import { DurinnV2TeamComponent } from "@durinn/v.3/app/views/with-layout/team/team.component";
import { DurinnV2UserListViewComponent } from "@durinn/v.3/app/views/with-layout/user/list/list.component";
import { DurinnV2UserSettingsComponent } from "@durinn/v.3/app/views/with-layout/user/settings/settings.component";
import { DurinnV2UserComponent } from "@durinn/v.3/app/views/with-layout/user/user.component";
import { Api } from "@durinn/v.3/helpers/api";
import { App } from "@durinn/v.3/helpers/app";
import { Logged, NotLogged } from "@durinn/v.3/helpers/auth-guard";
import { Me } from "@durinn/v.3/helpers/me";
import { ColorPickerModule } from "@iplab/ngx-color-picker";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgZorroAntdModule } from "ng-zorro-antd";
import { ChartsModule } from "ng2-charts";
import { NgxCurrencyModule } from "ngx-currency";
import { ImageToDataUrlModule } from "ngx-image2dataurl";
import { NgxMaskModule } from "ngx-mask";
import { DurinnV2EditCustomersComponent } from "./app/views/with-layout/customer/edit/edit.component";
import { DurinnV2CustomerListViewComponent } from "./app/views/with-layout/customer/list/list.component";
import { DurinnV2CustomerSettingsComponent } from "./app/views/with-layout/customer/settings/settings.component";
import { DurinnV2EditProviderComponent } from "./app/views/with-layout/provider/edit-provider/edit-provider.component";
import { DurinnV2ProviderListViewComponent } from "./app/views/with-layout/provider/list/list.component";
import { DurinnV2ProviderSettingsComponent } from "./app/views/with-layout/provider/settings/settings.component";
import { UserNotificationSettingComponent } from './app/components/users/user-notification-setting/user-notification-setting.component';
import { DurinnV3NotificationsComponent } from "./app/views/with-layout/notifications/notifications.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { MaxEllipsisPipe } from './app/pipes/max-ellipsis.pipe';
import {ApiCallsManager} from "@durinn.v3/helpers/ApiCallsManager";
import {WithLayoutComponent} from "@durinn.v3/app/views/with-layout/with-layout.component";
import {HeaderComponent} from "@durinn.v3/app/template/template/header/header.component";
import {TemplateModule} from "@durinn.v3/app/template/template/template.module";
import {SideNavComponent} from "@durinn.v3/app/template/template/side-nav/side-nav.component";
import {FooterComponent} from "@durinn.v3/app/template/template/footer/footer.component";
import {Variables} from "@durinn.v3/helpers/Variables";
import {ThermalPrinter} from "@durinn.v3/helpers/ThermalPrinter";

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		HttpClientModule,
		HttpClientJsonpModule,
		ReactiveFormsModule,
		NgZorroAntdModule,
		NgxMaskModule,
		NgxCurrencyModule,
		ChartsModule,
		RouterModule,
		ColorPickerModule,
		AngularEditorModule,
		ImageToDataUrlModule,
		DragDropModule,
		AppLoaderModule,
		PerfectScrollbarModule
	],
	declarations: [
		FooterComponent,
		SideNavComponent,
		HeaderComponent,
		WithLayoutComponent,
		ModelSelectComponent,
		FilterOrderComponent,
		UserPermissionsTableComponent,
		FilterDateOrderComponent,
		FieldstofillComponent,
		DurinnCardComponent,
		DurinnModalComponent,
		FormatNamePipe,
		DurinnV2TabsComponent,
		DurinnV2ListPageTableComponent,
		FilterOrderTextComponent,
		FilterOrderSelectComponent,
		FilterOrderMoneyComponent,
		FilterOrderDateComponent,
		FilterOrderDisabledComponent,
		DurinnV2SubsidiaryListViewComponent,
		DurinnV2UserListViewComponent,
		DurinnV2UserComponent,
		DurinnV2EditProviderComponent,
		DurinnV2ProviderSettingsComponent,
		DurinnV2ProviderListViewComponent,
		DurinnV2TeamComponent,
		DurinnV2TeamListViewComponent,
		DurinnV2SubsidiaryComponent,
		DurinnV2PageFooterComponent,
		DurinnV2EditSubsidiaryTabComponent,
		FilterOrderNumberComponent,
		LoginComponent,
		DurinnV2ProfileComponent,
		DurinnV2ChangePasswordComponent,
		DurinnV2UpdatePictureComponent,
		DurinnV2UpdateProfileComponent,
		DurinnV2UserSettingsComponent,
		DurinnV2TeamSettingsComponent,
		DurinnV2ProfileSettingsComponent,
		DurinnV2EditProfileComponent,
		DurinnV2CustomerSettingsComponent,
		DurinnV2CustomerListViewComponent,
		DurinnV2EditCustomersComponent,
		UserNotificationSettingComponent,
		DurinnV3NotificationsComponent,
		MaxEllipsisPipe
	],
	providers: [
		App, Api, Me, Logged, NotLogged, DragDropRegistry, ApiCallsManager, Variables, ThermalPrinter],
	exports: [
		FormsModule,
		RouterModule,
		CommonModule,
		HttpClientModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		NgZorroAntdModule,
		NgxMaskModule,
		// Components
		FooterComponent,
		SideNavComponent,
		HeaderComponent,
		WithLayoutComponent,
		ModelSelectComponent,
		FilterOrderComponent,
		UserPermissionsTableComponent,
		FilterDateOrderComponent,
		FieldstofillComponent,
		DurinnCardComponent,
		DurinnModalComponent,
		FormatNamePipe,
		DurinnV2TabsComponent,
		DurinnV2ListPageTableComponent,
		FilterOrderTextComponent,
		FilterOrderSelectComponent,
		FilterOrderMoneyComponent,
		FilterOrderDateComponent,
		FilterOrderDisabledComponent,
		DurinnV2SubsidiaryListViewComponent,
		DurinnV2UserListViewComponent,
		DurinnV2UserComponent,
		DurinnV2EditProviderComponent,
		DurinnV2ProviderSettingsComponent,
		DurinnV2ProviderListViewComponent,
		DurinnV2TeamComponent,
		DurinnV2TeamListViewComponent,
		DurinnV2SubsidiaryComponent,
		DurinnV2PageFooterComponent,
		DurinnV2EditSubsidiaryTabComponent,
		FilterOrderNumberComponent,
		AppLoaderModule,
		LoginComponent,
		DurinnV2ProfileComponent,
		DurinnV2ChangePasswordComponent,
		DurinnV2UpdatePictureComponent,
		DurinnV2UpdateProfileComponent,
		DurinnV2UserSettingsComponent,
		DurinnV2TeamSettingsComponent,
		DurinnV2ProfileSettingsComponent,
		DurinnV2EditProfileComponent,
		DurinnV2CustomerSettingsComponent,
		DurinnV2CustomerListViewComponent,
		DurinnV2EditCustomersComponent,
		UserNotificationSettingComponent,
		DurinnV3NotificationsComponent,
		MaxEllipsisPipe
	],
})
export class DurinnModule {}
