import {Component, Injector, Input, NgZone, TemplateRef} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { IBreadcrumb } from "@durinn.v3/app/template/interfaces/breadcrumb.type";
import { ThemeConstantService } from "@durinn.v3/app/template/services/theme-constant.service";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import config from "../../../../../config";
import {PageBase} from "@durinn.v3/app/bases/page.base";
import {SocketIo} from "@durinn.v3/helpers/SocketIo";

@Component({
	selector: "durinn-v2-common-layout",
	templateUrl: "./with-layout.component.html",
})
export class WithLayoutComponent extends PageBase {
	breadcrumbs$: Observable<IBreadcrumb[]>;
	contentHeaderDisplay: string;
	isFolded: boolean;
	isSideNavDark: boolean;
	isExpand: boolean;
	selectedHeaderColor: string;
	full = false;
	padding: boolean | string = true;
	footer: boolean | string = true;

	socket_disconnected = 0;
	socket_disconnected_interval;
	
	@Input() page: WithLayoutComponent = this;
	@Input() header: TemplateRef<any>;
	@Input() sidenav: TemplateRef<any>;

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public themeService: ThemeConstantService,
		public injector: Injector,
		public ngZone: NgZone
	) {
		super(injector);
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => {
					let child = this.activatedRoute.firstChild;
					while (child) {
						if (child.firstChild) {
							child = child.firstChild;
						} else if (
							child.snapshot.data &&
              child.snapshot.data["headerDisplay"]
						) {
							return child.snapshot.data["headerDisplay"];
						} else {
							return null;
						}
					}
					return null;
				})
			)
			.subscribe((data: any) => {
				console.log("data", data);
				this.contentHeaderDisplay = data;
			});

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => {
					let child = this.activatedRoute.firstChild;
					while (child) {
						if (child.firstChild) {
							child = child.firstChild;
						} else if (child.snapshot.data && child.snapshot.data["padding"]) {
							return child.snapshot.data["padding"];
						} else {
							return null;
						}
					}
					return null;
				})
			)
			.subscribe((data: any) => {
				console.log("padding", data);
				this.padding = data;
			});

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => {
					let child = this.activatedRoute.firstChild;
					while (child) {
						if (child.firstChild) {
							child = child.firstChild;
						} else if (child.snapshot.data && child.snapshot.data["footer"]) {
							return child.snapshot.data["footer"];
						} else {
							return null;
						}
					}
					return null;
				})
			)
			.subscribe((data: any) => {
				console.log("footer", data);
				this.footer = data;
			});
		
	}

	ngOnInit() {
		const self = this;
		
		this.breadcrumbs$ = this.router.events.pipe(
			startWith(new NavigationEnd(0, "/", "/")),
			filter((event) => event instanceof NavigationEnd),
			distinctUntilChanged(),
			map((data) => this.buildBreadCrumb(this.activatedRoute.root))
		);
		this.themeService.isMenuFoldedChanges.subscribe(
			(isFolded) => (this.isFolded = isFolded)
		);
		this.themeService.isSideNavDarkChanges.subscribe(
			(isDark) => (this.isSideNavDark = isDark)
		);
		this.themeService.selectedHeaderColor.subscribe(
			(color) => (this.selectedHeaderColor = color)
		);
		this.themeService.isExpandChanges.subscribe(
			(isExpand) => (this.isExpand = isExpand)
		);
		
		this.socket_disconnected_interval = setInterval(() => {
			self.ngZone.run(() => {
				if(self.socket.connected){
					self.socket_disconnected += 0;
				}else{
					self.socket_disconnected += 1;
				}
			});
		}, 2000);
	}
	
	ngOnDestroy() {
		super.ngOnDestroy();
		clearInterval(this.socket_disconnected_interval);
		this.socket_disconnected_interval = null;
	}
	
	private buildBreadCrumb(
		route: ActivatedRoute,
		url = "",
		breadcrumbs: IBreadcrumb[] = []
	): IBreadcrumb[] {
		const self = this;

		let label = "";
		let path = "/";

		self.full = false;

		if (route.routeConfig) {
			if (route.routeConfig.data) {
				console.log("route.routeConfig.data", route.routeConfig.data);
				label = route.routeConfig.data["title"];
				path += route.routeConfig.path;

				if (route.routeConfig.data["style"] == "full") {
					self.full = true;
				}
			}
		} else {
			label = "Dashboard";
			path += "dashboard";
		}

		const nextUrl = path && path !== "/dashboard" ? `${url}${path}` : url;
		const breadcrumb = <IBreadcrumb>{
			label: label,
			url: nextUrl,
		};

		const newBreadcrumbs = label
			? [...breadcrumbs, breadcrumb]
			: [...breadcrumbs];
		if (route.firstChild) {
			return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
		}
		return newBreadcrumbs;
	}

	get ENV() {
		if (document.URL.indexOf("localhost") > -1) {
			return "development";
		} else if (document.URL.indexOf(config.api.is_acceptance) > -1) {
			return "test";
		}

		return "production";
	}
	
	get socket(){
		return SocketIo.socket;
	}
	
	reload(){
		window.location.reload();
	}
}
