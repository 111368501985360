import {HeadColumns} from "@durinn.v3/app/components/template/tables/list-page-table/list-page-table.component";
import languageEncoding from "detect-file-encoding-and-language";
import { Component } from '@angular/core';
import * as moment from 'moment';
import swal from "sweetalert2";
import {ListPageBase, FiltersType} from "@durinn.v3/app/bases/listpage.base";

const FIELDS = {
	"DS_TIPO_DESCONEXAO": "disconnectionType",
	"TELCOMERCIAL": "businessPhones",
	"DATA_PEND": "disconnectionDate",
	"ENDERECO": "formatted_address",
	"TIPO_OS": "orderServiceType",
	"NOME_TITULAR": "customer",
	"MÊS / ANO": "monthYear",
	"TERMINAIS": "terminals",
	"BAIRRO": "neighborhood",
	"A RETIRAR": "quantity",
	"TELCEL": "cellPhones",
	"TELEFONE": "phones",
	"OBS - VOX": "note",
	"CD_NET": "contract",
	"NM_CIDADE": 'city',
	"OUTROS": "others",
	"E-MAIL": "email",
	"MÊS": "month",
	"BASE": "base",
	"ANO": "year"
};

@Component({
	selector: 'app-agendamento-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.css']
})
export class AgendamentoListComponent extends ListPageBase {
	filters: FiltersType = {
		ContractSchedule: {status: {'Op.not': null}},
		Deliveries: {start: {'Op.lte': moment().startOf('day').toDate()}}
	};
	filters_aberto: FiltersType = {
		ContractSchedule: {status: {'Op.is': null}},
		Deliveries: {start: {'Op.lte': moment().startOf('day').toDate()}}
	};
	filters_mine: FiltersType = null;

	order = { ContractSchedule: [['disconnectionDate', 'desc']] };
	route = "contract-schedule";
	moment = moment;

	importSchedules = {
		readingFile: false,
		isVisible: false,
		import: [],
		file: null,
		result: []
	};

	userLoaded(user) {
		super.userLoaded(user);
		this.filters_mine = {
			LastHistory_CreatedBy: {id: user.id},
			Deliveries: {start: {'Op.lte': moment().startOf('day').toDate()}}
		};
	}

	headColumns: HeadColumns = [
		{ index: "ContractSchedule", column: "id", name: "ID", type: 'number' },
		{ index: "ContractSchedule", column: "contract", name: "Contrato" },
		{ index: "ContractSchedule", column: "orderServiceType", name: "Tipo de OS" },
		{ index: "ContractSchedule", column: "status", name: "Status" },
		{ index: "ContractSchedule", column: "customer", name: "Cliente" },
		{ index: "ContractSchedule", column: "neighborhood", name: "Bairro" },
		{ index: "ContractSchedule", column: "city", name: "Cidade" },
		{ index: "ContractSchedule", column: "disconnectionType", name: "Tipo de Desconexão" },
		{ index: "ContractSchedule", column: "disconnectionDate", name: "Data da Desconexão", type: 'date' },
		{ index: "LastHistory", column: 'situation', name: "Última Situação" },
		{ index: "LastHistory", column: 'origin', name: "Última Origem" },
		{ index: "LastHistory", column: "createdAt", name: "Data do Último Histórico", type: 'date' },
		{ index: "LastHistory_CreatedBy", column: "name", name: "Último Atendente" },
		{ index: "ContractSchedule", column: 'historyQuantity', name: "Número de Históricos", type: 'number' },
		{ name: "Agendas Relacionadas"},
		{}
	];

	exportCall = this.api.new();
	export(){
		const self = this;
		const where = {};

		for (const index in self.filters) {
			where[index] = { "Op.and": self.getFilters()[index] };
		}

		self.exportCall.post('contract-schedule/export/xlsx', {where}).subscribe(data => {
			window.location.href = data.return.url;
		});
	}

	async onFileChanged(event) {
		const self = this;
		const reader = new FileReader();

		self.importSchedules.readingFile = true;
		const fileInfo = await languageEncoding(event.target.files[0]);
		reader.readAsText(event.target.files[0], fileInfo.encoding || 'CP1251');

		reader.onload = (x) => {
			self.importSchedules.file = reader.result;
			self.importSchedules.readingFile = false;
			self.onFileGenerate();
		};
	}

	onFileGenerate() {
		const self = this;
		self.importSchedules.import = [];

		try {
			if (typeof self.importSchedules.file !== "string") {
				return swal.fire("Importação", "Erro no arquivo", "error");
			}

			const _import = self.importSchedules.file.split("\n");

			for (const item of _import) {
				const object = item.replace(/(\r\n|\n|\r)/gm, "").replace(/\s\s+/g, " ").split(";");
				self.importSchedules.import.push(object);
			}
		} catch (e) {
			swal.fire("Importação", "Envie um arquivo .csv", "error");
			console.error(e, self.importSchedules.file);
			self.onImportSchedulesReset();
		}
	}

	async onFileBuild() {
		const self = this;

		const header: { [a: number]: string } = {
			/* 0: 'contract' */
		};

		for (const item in self.importSchedules.import[0]) {
			for (const row in FIELDS) {
				if (self.importSchedules.import[0][item].toUpperCase().trim() === row) {
					header[item] = FIELDS[row];
				}
			}
		}

		self.importSchedules.import.shift();

		for (const line of self.importSchedules.import) {
			const obj: any = {};

			for (const item in header) {
				obj[header[item]] = line[item];
				// typeof line[item] === "string"
				// 	? utf8.encode(line[item])
				// 	: line[item];
			}

			if (obj.disconnectionDate) {
				obj.disconnectionDate = moment(obj.disconnectionDate, "DD/MM/YYYY").toDate();
			}

			if (obj.customer) {
				self.importSchedules.result.push(obj);
			}
		}

		if (self.importSchedules.result.length == 0) {
			self.onImportSchedulesReset();
			return await swal.fire(
				"Nenhuma linha válida foi encontrada",
				"Confira se o cabeçalho da tabela ou suas linhas estão no formato correto!",
				"error");
		}
	}

	onFileSend() {
		const self = this;
		const body = self.importSchedules.result;
		console.log(body);
		self.call.post(`${self.route}/bulk`, body).subscribe(async response => {
			await self.app.alert('Importação', response.message);
			self.onImportSchedulesCancel();
			self.get();
		});
	}

	get isImportSchedulesDisabled() {
		const self = this;

		const conditions = [
			self.importSchedules.readingFile,
			!self.importSchedules.isVisible,
			!self.importSchedules.file,
		];

		return conditions.includes(true);
	}

	onImportSchedulesCancel() {
		const self = this;
		self.importSchedules.isVisible = false;
		self.onImportSchedulesReset();
	}

	onImportSchedulesSubmit() {
		const self = this;

		swal.fire({
			icon: "question",
			title: "Importar",
			showCancelButton: true,
			cancelButtonText: "Cancelar",
			confirmButtonText: "Importar",
			confirmButtonColor: "#DD6B55",
			text: `Você deseja importar esses agendamentos?`,
		}).then((result) => {
			if (result.value === true) {
				self.onFileBuild();
				self.onFileSend();
			}
		});
	}

	onImportSchedulesReset() {
		const self = this;
		self.importSchedules = {
			readingFile: false,
			isVisible: false,
			import: [],
			file: null,
			result: []
		};
	}
}
