import {
	Component,
	EventEmitter,
	Input,
	Output,
	TemplateRef,
} from "@angular/core";
import { PageBase } from "../../bases/page.base";

@Component({
	selector: "durinn-v2-tabs",
	templateUrl: "./durinn-tabs.component.html",
	styleUrls: ["./durinn-tabs.component.css"],
})
export class DurinnV2TabsComponent {
  @Input() page: PageBase;
  @Input() pageTabIndex = "durinnTab";
  @Input() tabs: string[] = [];
  @Input() tab: string = null;
  @Input() defaultTab = null;
  @Input() description: string | null = null;
  @Input() bordered = true;
  @Input() style = {
  	padding: '0.5rem'
  };

  @Input() templateLeft: TemplateRef<any> | null = null;
  @Input() templateRight: TemplateRef<any> | null = null;
  @Input() templateSize:
    | "1"
    | "2"
    | "3"
    | "4"
    | "5"
    | "6"
    | "7"
    | "8"
    | "9"
    | "10"
    | "11" = "6";
  @Output() changedTab = new EventEmitter();

  changeTab(tab: string) {
  	this.page[this.pageTabIndex] = tab;
  	this.changedTab.emit(tab);
  }

  ngOnChanges() {
  	if (this.tabs.length && !this.page[this.pageTabIndex]) {
  		this.page[this.pageTabIndex] = this.defaultTab || this.tabs[0];
  	}
  }
}
