import { Component } from '@angular/core';

@Component({
	selector: 'app-relatorios',
	templateUrl: './relatorios.component.html',
	styleUrls: ['./relatorios.component.css']
})
export class RelatoriosComponent {
	isVisible: { [name: string]: boolean } = {};
}
