import { CrudPage } from 'src/app/bases/crud.page';
import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
	selector: 'app-vacation',
	templateUrl: './vacation.component.html',
	styleUrls: ['./vacation.component.css']
})
export class VacationComponent extends CrudPage {
	isVacationAddModalVisible = false;
	vacationCall = this.api.new();
  @Input() userType: string;
  @Input() id: number;
  route = "vacation";
  moment = moment;

  filters = {
  	Vacation: {}
  };

  ngOnInit() {
  	const id = this.userType == 'deliverymen' ? { userId: this.id } : { adminId: this.id };
  	this.filters.Vacation = { ... id };
  	super.ngOnInit();
  }

  order = {
  	Vacation: [['from', 'desc']]
  };

  params: any = {
  	from: moment().startOf("month").format("DD/MM/YYYY"),
  	to: moment().endOf("month").format("DD/MM/YYYY"),
  };

  resetParams() {
  	const self = this;
  	self.params = {
  		from: moment().startOf("month").format("DD/MM/YYYY"),
  		to: moment().endOf("month").format("DD/MM/YYYY")  
  	};
  }

  isParamsValid() {
  	const self = this;
  	const to = moment(self.params.to, "DD/MM/YYYY");
  	const from = moment(self.params.from, "DD/MM/YYYY");
  	return from.isValid() && to.isValid();
  }

  vacationUpdate(item) {
  	const self = this;
  	const params = { ... item };
  	params.from = moment(params.from).format('DD/MM/YYYY');
  	params.to = moment(params.to).format('DD/MM/YYYY');
  	self.isVacationAddModalVisible = true;
  	self.params = params;
  }

  vacationAction() {
  	const self = this;
  	const body = { ...self.params };

  	body.to = moment(body.to, "DD/MM/YYYY").endOf('day').toISOString();
  	body.from = moment(body.from, "DD/MM/YYYY").endOf('day').toISOString();

  	if(body.id) {
  		self.vacationCall.put(`${self.route}/${body.id}`, {
  			from: body.from,
  			to: body.to
  		}).subscribe(() => {
  			self.isVacationAddModalVisible = false;
  			self.resetParams();
  			self.get();
  		});
  	} else {
  		const id = self.userType == "deliverymen" ? { userId: self.id } : { adminId: self.id };
  		self.vacationCall.post(self.route, { ... body, ...id }).subscribe(() => {
  			self.isVacationAddModalVisible = false;
  			self.resetParams();
  			self.get();
  		});
  	}
  }
}
