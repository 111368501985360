import { Component } from "@angular/core";
import { FilterOrderBase } from "../filter-order.base";

@Component({
	selector: "durinn-v2-filter-order-disabled",
	templateUrl: "./filter-order-disabled.component.html",
	styleUrls: ["./filter-order-disabled.component.css"],
})
export class FilterOrderDisabledComponent extends FilterOrderBase {
	ngOnInit() {}
}
