import { NOTIFICATION_SETTINGS, UserInterface, UserNotificationInterface} from '@backend-interfaces/models';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageBase } from '@durinn/v.3/app/bases/page.base';

@Component({
	selector: 'durinn-v3-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss']
})
export class DurinnV3NotificationsComponent extends PageBase {	
	NOTIFICATION_SETTINGS = NOTIFICATION_SETTINGS;
	allNotificationsChecked = false;
	isSimpleViewSelected = false;
	unreadNotificationsTotal = 0;
	localNotificationsTotal = 0;
	notificationsTotal = 0;
	notifications = [];
	formData = [];
	offset = 0;
	limit = 10;

	unreadCountCall = this.api.new();
	loadMoreCall = this.api.new();
	call = this.api.new();

	notificationModalCall = this.api.new();
	isNotificationModalVisible = false;
	notificationModalType = null;
	
	@Output() unreadNotificationsCount = new EventEmitter();
	@Output() hasUnreadNotifications = new EventEmitter();
	@Output() onNotificationClick = new EventEmitter();
	@Input() isFullView = true;

	args = {
		email: {
			code: null
		},
		phone: {
			code: null
		}
	};

	ngOnInit() {
		const self = this;
		self.limit = self.isFullView ? 10 : 3;
	}
	
	userLoaded(user: UserInterface) {
		super.userLoaded(user);
		this.getUserNotifications();
		this.setSockets();
	}
	
	get isLoading() {
		const self = this;
		
		if(self.notifications.length){
			return self.call.loading;
		}
		
		return self.call.loading || self.load_call.loading;
	}

	get indeterminateNotifications() {
		const self = this;

		if (self.formData.some(item => item.checked == true)) {
			if (self.formData.some(item => item.checked == false)) {
				return true;
			}
		}

		return false;
	}

	get hasOneNotificationChecked() {
		const self = this;

		if (self.formData.some(item => item.checked == true)) {
			return true;
		}
	}

	get notificationsCheckedTotal() {
		const self = this;
		let total = 0;

		for (const item of self.formData) {
			if (item.checked == true) {
				total++;
			}
		}

		return total;
	}

	getUnreadUserNotificationsCount() {
		const self = this;
		const userId = (self.user as UserInterface).id;
		const route = `user-notification/unread/count/${userId}`;

		self.unreadCountCall.get(route).subscribe(response => {
			self.unreadNotificationsTotal = response.return;
			self.emitNotificationEvents();
		});
	}

	load_call = this.api.new();
	
	getUserNotifications() {
		const self = this;
		
		if(self.load_call.loading){
			return false;
		}
		
		self.offset = 0;
		const type = 'all';
		const userId = (self.user as UserInterface).id;
		
		if(userId){
			const route = self.isFullView
				? `user-notification/${type}/${userId}/${self.offset}/${self.limit}`
				: `user-notification/unread/${userId}/${self.offset}/${self.limit}`;
			
			self.load_call.get(route).subscribe(response => {
				self.notificationsTotal = response.return.count;
				self.notifications = response.return.rows;
				self.allNotificationsChecked = false;
				self.generateNotifications();
				self.getUnreadUserNotificationsCount();
			});
		}
	}

	getMoreUserNotifications() {
		const self = this;

		if (!self.loadMoreCall.loading) {
			const type = 'all';
			self.offset += self.limit;
			const userId = (self.user as UserInterface).id;
			const route = `user-notification/${type}/${userId}/${self.offset}/${self.limit}`;

			self.loadMoreCall.get(route).subscribe(response => {
				self.formData = [
					...self.formData,
					...self.generateMoreNotifications(response.return.rows)
				];

				self.notifications.push(...response.return.rows);
				self.notificationsTotal = response.return.count;
				self.allNotificationsChecked = false;
				self.getUnreadUserNotificationsCount();
			});
		}
	}

	emitNotificationEvents() {
		const self = this;
		const type = self.isFullView ? 'all' : 'unread';

		const count = self.unreadNotificationsTotal + self.localNotificationsTotal;
		self.unreadNotificationsCount.emit(count);

		if (type == 'unread') {
			if (self.notifications.length > 0) {
				self.hasUnreadNotifications.emit(true);
			}

			if (self.notifications.length == 0) {
				self.hasUnreadNotifications.emit(false);
			}
		}
	}

	generateNotifications() {
		const self = this;

		self.formData = [];
		self.generateLocalNotifications();

		for (const item of self.notifications) {
			const setting = NOTIFICATION_SETTINGS[item.setting];
			const object = Object.assign({}, item) as any;

			if (setting) {
				object.color = setting.color;
				object.icon = setting.icon;
			}
			
			if (!object.color) {
				object.color = 'ant-avatar-red';
			}

			if (!object.icon) {
				object.icon = 'info-circle';
			}

			self.formData.push(object);
		}
	}

	generateMoreNotifications(items: UserNotificationInterface[]) {
		const array = [];

		for (const item of items) {
			const setting = NOTIFICATION_SETTINGS[item.setting];
			const object = Object.assign({}, item) as any;

			if (setting) {
				object.color = setting.color;
				object.icon = setting.icon;
			}
			
			if (!object.color) {
				object.color = 'ant-avatar-red';
			}

			if (!object.icon) {
				object.icon = 'info-circle';
			}

			array.push(object);
		}

		return array;
	}

	generateLocalNotifications() {
		const self = this;
		self.localNotificationsTotal = 0;

		if (!(self.user as UserInterface).phoneValidated) {
			const object = {
				notification: "Você precisa confirmar seu telefone",
				sentAt: (self.user as UserInterface).createdAt,
				id: "generated-local-notication:phone",
				url: "generated-local-notication",
				title: "Telefone Não Confirmado",
				isLocalNotification: true,
				color: "ant-avatar-cyan",
				viewedAt: null,
				icon: "phone",
				onClick: () => {
					self.onNotificationModalOpen("get-phone");
				}
			};
			
			self.notifications = [object].concat(self.notifications);
			self.localNotificationsTotal += 1;
			self.notificationsTotal += 1;
		}

		if (!(self.user as UserInterface).emailValidated) {
			const object = {
				notification: "Você precisa confirmar seu e-mail",
				sentAt: (self.user as UserInterface).createdAt,
				id: "generated-local-notication:email",
				url: "generated-local-notication",
				title: "E-mail Não Confirmado",
				isLocalNotification: true,
				color: "ant-avatar-blue",
				viewedAt: null,
				icon: "mail",
				onClick: () => {
					self.onNotificationModalOpen("get-email");
				}
			};
			
			self.notifications = [object].concat(self.notifications);
			self.localNotificationsTotal += 1;
			self.notificationsTotal += 1;
		}
	}

	onRedirect(item: UserNotificationInterface) {
		const self = this;

		if (!item || !item.url) {
			self.router.navigate(['/notificacoes']);
		} else if (item && (item as any).isLocalNotification) {
			(item as any).onClick();			
		} else {
			self.call.get(`user-notification/${item.id}`).subscribe(response => {
				const url = response.return.url || '';
				if (url.indexOf('http') == 0) {
					window.open(url);
				} else {
					self.router.navigate([response.return.url]);
				}
			});
		}

		self.onNotificationClick.emit(true);
	}

	onCheckAllNotifications() {
		const self = this;

		for (const item of self.formData) {
			item.checked = self.allNotificationsChecked;
		}
	}

	getAllNotificationsChecked() {
		const self = this;
		const ids = [];

		for (const item of self.formData) {
			if (item.checked && !item.isLocalNotification) {
				ids.push(item.id);
			}
		}

		return ids;
	}

	async onUpdateNotifications(route: 'delete' | 'mark-viewedAt' | 'unmark-viewedAt') {
		const self = this;

		const userId = (self.user as UserInterface).id;
		const ids = self.getAllNotificationsChecked();

		if (ids.length) {
			self.call.put(`user-notification/${route}/${userId}`, { ids }).subscribe();
		}
	}

	onUpdateUniqueNotification(item: UserNotificationInterface) {
		const self = this;
		const viewedAt = item.viewedAt ? null : new Date();
		const isLocalNotification = (item as any).isLocalNotification;

		if (!isLocalNotification) {
			self.call.put(`user-notification/mark-unmark-viewedAt/${item.id}`, { viewedAt }).subscribe(data => self.getUserNotifications());
		}
	}

	setSockets() {
		const self = this;
		const userId = (self.user as UserInterface).id;

		self.socketSafeOn(`user-${userId}-new-notification`, (response) => {
			if(!self.isFullView){
				self.newNotificationAlert(response.data);
			}
			self.getUserNotifications();
		});

		self.socketSafeOn(`user-${userId}-update-notification`, () => {
			self.getUserNotifications();
		});

		self.socketSafeOn(`user-${userId}-local-notification`, () => {
			self.me.refresh().subscribe(() => {
				self.getUserNotifications();
			});
		});
	}

	async newNotificationAlert(item: UserNotificationInterface) {
		const self = this;
		self.toastr.toastrConfig.preventDuplicates = true;
		
		if(item.urgent){
			await self.app.fire({
				title: item.title,
				text: item.notification,
				icon: `warning`,
				confirmButtonText: 'Ver Notificação'
			});
			self.onRedirect(item);
		}else{
			const toast = self.toastr.info(item.notification, item.title, {progressBar: true});
			toast.onTap.subscribe(() => self.onRedirect(item));
		}
	}

	get isNotificationModalDisabled() {
		const self = this;
		const conditions = [];

		switch (self.notificationModalType) {
		case 'get-email':
			conditions.push(!!(self.user as UserInterface).email);
			break;
		case 'get-phone':
			conditions.push(!!(self.user as UserInterface).phone);
			break;
		case 'email':
			conditions.push(!!self.args.email.code);
			break;
		case 'phone':
			conditions.push(!!self.args.phone.code);
			break;
		}

		return conditions.includes(false);
	}

	get notificationModalTitle() {
		const self = this;

		switch (self.notificationModalType) {
		case 'get-email':
			return 'Confirmar E-mail';
		case 'get-phone':
			return 'Confirmar Telefone';
		case 'email':
			return 'Confirmar E-mail';
		case 'phone':
			return 'Confirmar Telefone';
		}
	}

	onNotificationModalSubmit() {
		const self = this;

		switch (self.notificationModalType) {
		case 'get-email':
			return self.onSendConfirmEmailSubmit();
		case 'get-phone':
			return self.onSendConfirmPhoneSubmit();
		case 'email':
			return self.onConfirmEmailSubmit();
		case 'phone':
			return self.onConfirmPhoneSubmit();
		}
	}

	onNotificationModalOpen(type: string) {
		const self = this;
		self.notificationModalType = type;
		self.isNotificationModalVisible = true;
	}

	onNotificationModalCancel() {
		const self = this;
		self.isNotificationModalVisible = false;
		self.notificationModalType = null;
	}

	onSendConfirmEmailSubmit() {
		const self = this;
		self.notificationModalCall.post(`me/validate-email/send-confirmation`, {
			email: (self.user as UserInterface).email
		}).subscribe(() => {
			self.onNotificationModalOpen("email");
		});
	}

	onSendConfirmPhoneSubmit() {
		const self = this;

		self.notificationModalCall.post(`me/validate-phone/send-confirmation`, {
			phone: (self.user as UserInterface).phone,
		}).subscribe(() => {
			self.onNotificationModalOpen("phone");
		});
	}

	onConfirmEmailSubmit() {
		const self = this;
		self.notificationModalCall
			.post(`me/validate-email/confirm`, {
				token: self.args.email.code
			})
			.subscribe((data) => {
				if (data.success) {
					self.onNotificationModalCancel();
					self.args.email.code = null;
				}
			});
	}

	onConfirmPhoneSubmit() {
		const self = this;
		self.notificationModalCall
			.post(`me/validate-phone/confirm`, { token: self.args.phone.code })
			.subscribe((data) => {
				if (data.success) {
					self.onNotificationModalCancel();
					self.args.phone.code = null;
				}
			});
	}
}
