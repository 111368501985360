import { Component, OnInit } from "@angular/core";
import {
	EditPage,
	EditPageInterface,
} from "../../../../../bases/edit.page";

@Component({
	selector: "app-tecnico",
	templateUrl: "./tecnico.component.html",
	styleUrls: ["./tecnico.component.css"],
})
export class TecnicoComponent extends EditPage implements EditPageInterface {
	tab = "tecnico";

	form = {
		cpf: "",
		picture: "",
		username: "",
		regimeType: null,
		variables: {
			cep: "",
			address: "",
			complement: "",
			neighborhood: "",
			city: "",
			state: "RS",
		},
	};

	permissions = {
		EDIT_DELIVERYMAN: false,
		SEE_FINANCIAL: false,
	};

	fields = [
		{
			label: "Nome",
			name: "name",
			type: "text",
			validate: "Por favor, preencha seu nome corretamente!",
			required: true,
		},
		{
			label: "Telefone",
			name: "phone",
			type: "text",
			validate: "Por favor, preencha seu telefone corretamente!",
			mask: "(00) 00000-0000",
			placeholder: "(00) 00000-0000",
			required: true,
		},
		{
			label: "CPF",
			name: "cpf",
			type: "text",
			validate: "Por favor, preencha seu cpf corretamente!",
			placeholder: "000.000.000-00",
			mask: "000.000.000-00",
			required: true,
		},
		{
			label: "E-mail",
			name: "email",
			type: "email",
			validate: "Por favor, preencha seu e-mail corretamente!",
			placeholder: "e-mail",
			required: false,
		},
	];

	variables = [];

	address = [
		{
			label: "Endereço",
			name: "address",
			type: "text",
			validate: "Por favor, preencha seu endereço corretamente!",
			required: false,
		},
		{
			label: "Complemento",
			name: "complement",
			type: "text",
			validate: "Por favor, preencha seu complemento corretamente!",
			required: false,
		},
		{
			label: "Bairro",
			name: "neighborhood",
			type: "text",
			validate: "Por favor, preencha seu bairro corretamente!",
			required: false,
		},
		{
			label: "Cidade",
			name: "city",
			type: "text",
			validate: "Por favor, preencha sua cidade corretamente!",
			required: false,
		},
	];

	back_to = "/tecnicos";
	route = "deliverymen";

	show = false;

	clusterChanged(ev) {
		console.log(`ev`, ev);
	}
}
