import { FiltersType, ListBasePage } from "src/app/durinn/bases/listpage.base";
import { Component } from "@angular/core";
import * as moment from "moment";

@Component({
	selector: 'app-schedule-settings-blocked-days',
	templateUrl: './blocked-days.component.html',
	styleUrls: ['./blocked-days.component.css']
})
export class ScheduleSettingsBlockedDaysComponent extends ListBasePage {
	route = "blocked-schedule-setting";
	moment = moment;
	items = [];
	limit = 5;

	modal_call = this.api.new().set("success", true);
	isVisible = false;
	args = {
		date: null
	};

	filters: FiltersType = {
		BlockedScheduleSetting: {}
	};

	order = {
		BlockedScheduleSetting: [["date", "desc"]],
	};

	get isLoading() {
		const self = this;
		return self.call.loading;
	}

	get isModalLoading() {
		const self = this;
		return self.modal_call.loading;
	}

	get isDisabled() {
		const self = this;

		const conditions = [
			(self.args.date || '').length == 10,
			self.moment(self.args.date, 'DD/MM/YYYY').isValid()
		];

		return conditions.includes(false);
	}

	async onSubmit() {
		const self = this;
		const args = self.args;
		args.date = self.moment(args.date, "DD/MM/YYYY").toDate();
		self.modal_call.post(self.route, args).subscribe(response => {
			if (response.success) {
				self.onCancel();
				self.ngOnInit();
			}
		});
	}

	onOpen() {
		const self = this;
		self.isVisible = true;
	}

	onCancel() {
		const self = this;
		self.isVisible = false;
		self.onReset();
	}

	onReset() {
		const self = this;
		self.args = {
			date: null
		};
	}
}
