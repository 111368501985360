import { PERMISSIONS } from "../../../usuarios/pages/usuario/components/permissions/permissions.component";
import { ContasDefaultComponent } from "../contas-default/contas-default.component";
import { FiltersType } from "src/app/durinn/bases/listpage.base";
import { Component } from "@angular/core";

export class ContasReceberModel {
	name?: string = null;
	dueDate: Date = null;
	note?: string = null;
	bill?: string = null;
	category?: string = null;
	amount: number = null;
	proof?: string = null;
	other?: string = null;
	payToType: number = null;
	receivementDate?: Date = null;
	payUserTo?: number = null;
	paidUserBy?: number = null;
	payDeliverymanTo?: number = null;
}

@Component({
	selector: "app-contas-receber",
	templateUrl: "../contas-default/contas-default.component.html",
	styleUrls: ["../contas-default/contas-default.component.css"],
})
export class ContasReceberComponent extends ContasDefaultComponent {
	filters: FiltersType = {
		BillToReceive: this.pendingFilters
	};
	order = { BillToReceive: [["dueDate", "asc"]] };
	permission = PERMISSIONS.SEE_FINANCIAL;
	route = "bill-to-receive";

	ngOnInit() {
		super.ngOnInit();
		this.createForm(new ContasReceberModel());
		this.setValidators();
	}

	get pendingFilters() {
		return {
			receivementDate: { "Op.is": null },
			paidUserBy: { "Op.is": null },
		};
	}

	get settledFilters() {
		return {
			receivementDate: { "Op.gt": 0 },
			paidUserBy: { "Op.gt": 0 },
		};
	}
}
