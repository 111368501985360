import { Component, OnInit } from "@angular/core";
import { CrudPage } from "../../../bases/crud.page";
import { Device_Scans_Type } from "../almoxarifado/almoxarifado.component";
import * as moment from "moment";

@Component({
	selector: "app-carga",
	templateUrl: "./carga.component.html",
	styleUrls: ["./carga.component.css"],
})
export class CargaComponent extends CrudPage {
	device: any = {};
	moment = moment;

	limit = 10;
	route = "warehouse";
	filters: any = { device: {}, last_scan: {} };

	deviceCall = this.api.new();

	Device_Scans_Type = Device_Scans_Type;

	ngOnInit() {
		this.route =
			"device/" +
			this.activatedRoute.snapshot.params["barcode"] +
			"/scans";
		this.get();
		this.getDevice();
		super.ngOnInit();
	}

	getDevice() {
		this.deviceCall
			.get("device/" + this.activatedRoute.snapshot.params["barcode"])
			.subscribe((data) => (this.device = data.return));
	}
}
