import { DragDropModule, DragDropRegistry } from "@angular/cdk/drag-drop";
import { Platform } from "@angular/cdk/platform";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ScrollDispatcher, ViewportRuler } from "@angular/cdk/scrolling";
import { ThemeConstantService } from "@durinn/v.3/app/template/services/theme-constant.service";
import { DurinnModule } from "@durinn/v.3/durinn.module";
import { ColorPickerModule } from "@iplab/ngx-color-picker";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzListModule } from "ng-zorro-antd/list";
import { NzTreeSelectModule } from "ng-zorro-antd/tree-select";
import { ChartsModule, ThemeService } from "ng2-charts";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ImageToDataUrlModule } from "ngx-image2dataurl";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ToastrModule } from "ngx-toastr";
import { SharedModule } from "@template/shared.module";
import { WithLayoutRoutingModule } from "./with-layout-routing.module";
import { DefaultDashboardComponent } from "./default/default-dashboard.component";
import { WithBreadcrumbDashboardComponent } from "./with-breadcrumb/with-breadcrumb-dashboard.component";
import { ProfileComponent } from "./me/profile/profile.component";
import { ChangePasswordComponent } from "./me/profile/components/change-password/change-password.component";
import { UpdateProfileComponent } from "./me/profile/components/update-profile/update-profile.component";
import { UpdatePictureComponent } from "./me/profile/components/update-picture/update-picture.component";
import { UsuariosComponent } from "./usuarios/usuarios.component";
import { UsuarioComponent } from "./usuarios/pages/usuario/usuario.component";
import { TecnicosComponent } from "./tecnicos/tecnicos.component";
import { ClustersComponent } from "./clusters/clusters.component";
import { EditarClusterComponent } from "./clusters/pages/editar-cluster/editar-cluster.component";
import { FinanceiroComponent } from "./financeiro/financeiro/financeiro.component";
import { TotalMesComponent } from "./financeiro/components/total-mes/total-mes.component";
import { GraficoDiaComponent } from "./financeiro/components/grafico-dia/grafico-dia.component";
import { AparelhosPorTecnicoComponent } from "./financeiro/components/aparelhos-por-tecnico/aparelhos-por-tecnico.component";
import { AparelhosPorDiaComponent } from "./financeiro/components/aparelhos-por-dia/aparelhos-por-dia.component";
import { AgendasComponent } from "./agendas/agendas.component";
import { ImportarComponent } from "./agendas/pages/importar/importar.component";
import { ImportarTokenComponent } from "./agendas/pages/importar-token/importar-token.component";
import { TrackerMapComponent } from "./tecnicos/pages/tecnico/components/tracker-map/tracker-map.component";
import { TecnicoComponent } from "./tecnicos/pages/tecnico/tecnico.component";
import { PermissionsComponent } from "./usuarios/pages/usuario/components/permissions/permissions.component";
import { RelatorioDeFechamentoComponent } from "./relatorios/pages/relatorio-de-fechamento/relatorio-de-fechamento.component";
import { AgendaComponent } from "./agendas/pages/agenda/agenda.component";
import { AlmoxarifadoComponent } from "./almoxarifado/almoxarifado.component";
import { OverviewComponent } from "./almoxarifado/components/overview/overview.component";
import { ResumoFinanceiroComponent } from "./financeiro/components/resumo-financeiro/resumo-financeiro.component";
import { CargaComponent } from "./carga/carga.component";
import { ColetadosComponent } from "./almoxarifado/relatorios/coletados/coletados.component";
import { ColetadosPorTecnicoComponent } from "./almoxarifado/relatorios/coletados-por-tecnico/coletados-por-tecnico.component";
import { NovaOcorrenciaComponent } from "./almoxarifado/pages/nova-ocorrencia/nova-ocorrencia.component";
import { TecnicoFinanceiroComponent } from "./tecnicos/pages/tecnico/components/tecnico-financeiro/tecnico-financeiro.component";
import { EquipesComponent } from "./equipes/equipes.component";
import { EditarEquipeComponent } from "./equipes/pages/editar-equipe/editar-equipe.component";
import { RelatorioDePerformanceComponent } from "./relatorios/modals/relatorio-de-performance/relatorio-de-performance.component";
import { GetHourBankComponent } from "@components/get-hour-bank/get-hour-bank.component";
import { ValidacaoDeCargaComponent } from "./almoxarifado/relatorios/validacao-de-carga/validacao-de-carga.component";
import { LotsModalComponent } from "./almoxarifado/components/modals/lots-modal/lots-modal.component";
import { LotesComponent } from "./almoxarifado/pages/lotes/lotes.component";
import { EditarLoteComponent } from "./almoxarifado/pages/lotes/pages/editar-lote/editar-lote.component";
import { HolidaysCrudBoxComponent } from "./configuracoes/components/holidays-crud-box/holidays-crud-box.component";
import { ComTecnicoComponent } from "./almoxarifado/pages/com-tecnico/com-tecnico.component";
import { ExcelComponent } from "./almoxarifado/relatorios/excel/excel.component";
import { BasesComponent } from "./bases/bases.component";
import { StreamingsDashboardComponent } from "./almoxarifado/pages/streamings/streamings-dashboard/streamings-dashboard.component";
import { LotesListComponent } from "./almoxarifado/pages/streamings/streamings-dashboard/components/lotes-list/lotes-list.component";
import { ComTecnicoListComponent } from "./almoxarifado/pages/streamings/streamings-dashboard/components/com-tecnico-list/com-tecnico-list.component";
import { DashboardCardsComponent } from "./almoxarifado/pages/streamings/streamings-dashboard/components/dashboard-cards/dashboard-cards.component";
import { OcorrenciasDoTecnicoComponent } from "./almoxarifado/relatorios/ocorrencias-do-tecnico/ocorrencias-do-tecnico.component";
import { TecnicoComTecnicoComponent } from "./tecnicos/pages/tecnico/components/tecnico-com-tecnico/tecnico-com-tecnico.component";
import { AtualmenteComTecnicoComponent } from "./almoxarifado/relatorios/atualmente-com-tecnico/atualmente-com-tecnico.component";
import { BoxComponent } from "./almoxarifado/components/overview/components/box/box.component";
import { CaixasComponent } from "./almoxarifado/pages/caixas/caixas.component";
import { CaixasDashboardCardComponent } from "./almoxarifado/pages/caixas/components/caixas-dashboard-card/caixas-dashboard-card.component";
import { EditarCaixaComponent } from "./almoxarifado/pages/caixas/pages/editar-caixa/editar-caixa.component";
import { ArquivosComponent } from "./almoxarifado/pages/lotes/pages/editar-lote/components/arquivos/arquivos.component";
import { ContasPagarComponent } from "./financeiro/contas/contas-pagar/contas-pagar.component";
import { ContasDefaultComponent } from "./financeiro/contas/contas-default/contas-default.component";
import { ContasReceberComponent } from "./financeiro/contas/contas-receber/contas-receber.component";
import { RendimentoDosTecnicosComponent } from "./financeiro/rendimento-dos-tecnicos/rendimento-dos-tecnicos.component";
import { AgendamentoComponent } from './agendamento/agendamento.component';
import { ClosureComponent } from "./me/closure/closure.component";
import { AgendamentoListComponent } from './agendamento/components/list/list.component';
import { VacationComponent } from "./relatorios/pages/relatorio-de-fechamento/tabs/vacation/vacation.component";
import { CustomersComponent } from "./customers/customers.component";
import { EditCustomersComponent } from "./customers/pages/edit-customers/edit-customers.component";
import { ServiceTakersComponent } from "./relatorios/pages/relatorio-de-fechamento/tabs/service-takers/service-takers.component";
import { HoursBankPaymentComponent } from "./relatorios/pages/relatorio-de-fechamento/tabs/hours-bank-payment/hours-bank-payment.component";
import { RelatoriosComponent } from "./relatorios/relatorios.component";
import { RelatorioDeAgendasComponent } from "./relatorios/modals/relatorio-de-agendas/relatorio-de-agendas.component";
import { ImportarNetComponent } from "./agendas/pages/importar/components/importar-net/importar-net.component";
import { ImportarBringgComponent } from "./agendas/pages/importar/components/importar-bringg/importar-bringg.component";
import { EmailCampaignComponent } from "./campanhas/email-campaign/email-campaign.component";
import { EmailCampaignViewComponent } from "./campanhas/email-campaign-view/email-campaign-view.component";
import { TerminationComponent } from "./relatorios/pages/relatorio-de-fechamento/tabs/termination/termination.component";
import { WorkloadComponent } from "./relatorios/pages/relatorio-de-fechamento/tabs/workload/workload.component";
import { EmailCampaignViewListComponent } from "./campanhas/email-campaign-view/components/email-campaign-view-list/email-campaign-view-list.component";
import { ImportarUrasComponent } from "./agendas/pages/importar/components/importar-uras/importar-uras.component";
import { AgendasUrasComponent } from "./agendas/pages/agendas-uras/agendas-uras.component";
import { AgendasUrasViewComponent } from "./agendas/pages/agendas-uras/agendas-uras-view/agendas-uras-view.component";
import { AgendasUrasDashboardComponent } from "./agendas/pages/agendas-uras/agendas-uras-dashboard/agendas-uras-dashboard.component";
import { ImportsListComponent } from "./agendas/pages/imports-list/imports-list.component";
import { ImportsViewComponent } from "./agendas/pages/imports-list/imports-view/imports-view.component";
import { ImportViewListComponent } from "./agendas/pages/imports-list/imports-view/import-view-list/import-view-list.component";
import { AwayComponent } from "./relatorios/pages/relatorio-de-fechamento/tabs/away/away.component";
import { EmailCampaignViewListSmsComponent } from "./campanhas/email-campaign-view/components/email-campaign-view-list-sms/email-campaign-view-list-sms.component";
import { EnterprisesCrudBoxComponent } from "./configuracoes/components/enterprises-crud-box/enterprises-crud-box.component";
import { ScheduleSettingsComponent } from "./configuracoes/components/schedule-settings/schedule-settings.component";
import { ScheduleSettingsBlockedDaysComponent } from "./configuracoes/components/schedule-settings/components/blocked-days/blocked-days.component";
import { ScheduleSettingsStandardDaysComponent } from "./configuracoes/components/schedule-settings/components/standard-days/standard-days.component";
import { ComparacaoDeCargaComponent } from "./almoxarifado/relatorios/comparacao-de-carga/comparacao-de-carga.component";
import { ImportarNet2Component } from "./agendas/pages/importar/components/importar-net2/importar-net2.component";
import { AlmoxarifadoListagemComponent } from './almoxarifado/components/almoxarifado-listagem/almoxarifado-listagem.component';
import { NumerosBloqueadosComponent } from './campanhas/numeros-bloqueados/numeros-bloqueados.component';
import { ModalAgendaPreviaComponent } from './agendas/components/modal-agenda-previa/modal-agenda-previa.component';
import { ModalAgendaConcluirComponent } from './agendas/components/modal-agenda-concluir/modal-agenda-concluir.component';
import { ModalAgendaDarBaixaComponent } from './agendas/components/modal-agenda-dar-baixa/modal-agenda-dar-baixa.component';
import { ContratosBloqueadosComponent } from './campanhas/contratos-bloqueados/contratos-bloqueados.component';
import { AgendamentosDashboardComponent } from './agendamento/components/agendamentos-dashboard/agendamentos-dashboard.component';
import { ModalAgendaCriarReversaoComponent } from './agendas/components/modal-agenda-criar-reversao/modal-agenda-criar-reversao.component';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		SharedModule,
		WithLayoutRoutingModule,
		GooglePlaceModule,
		ColorPickerModule,
		NzTreeSelectModule,
		NzListModule,
		ImageToDataUrlModule,
		NzIconModule,
		AngularEditorModule,
		ToastrModule,
		InfiniteScrollModule,
		DragDropModule,
		ChartsModule,
		DurinnModule,
	],
	exports: [],
	declarations: [
		DefaultDashboardComponent,
		WithBreadcrumbDashboardComponent,
		ProfileComponent,
		ChangePasswordComponent,
		UpdateProfileComponent,
		UpdatePictureComponent,
		UsuariosComponent,
		UsuarioComponent,
		TecnicosComponent,
		ClustersComponent,
		EditarClusterComponent,
		FinanceiroComponent,
		TotalMesComponent,
		GraficoDiaComponent,
		AparelhosPorTecnicoComponent,
		AparelhosPorDiaComponent,
		AgendasComponent,
		ImportarComponent,
		ImportarTokenComponent,
		UsuarioComponent,
		TrackerMapComponent,
		TecnicoComponent,
		PermissionsComponent,
		RelatorioDeFechamentoComponent,
		AgendaComponent,
		AlmoxarifadoComponent,
		OverviewComponent,
		ResumoFinanceiroComponent,
		CargaComponent,
		ColetadosComponent,
		ColetadosPorTecnicoComponent,
		NovaOcorrenciaComponent,
		TecnicoFinanceiroComponent,
		EquipesComponent,
		EditarEquipeComponent,
		RelatorioDePerformanceComponent,
		GetHourBankComponent,
		ValidacaoDeCargaComponent,
		LotsModalComponent,
		LotesComponent,
		EditarLoteComponent,
		HolidaysCrudBoxComponent,
		ComTecnicoComponent,
		ExcelComponent,
		BasesComponent,
		StreamingsDashboardComponent,
		LotesListComponent,
		ComTecnicoListComponent,
		DashboardCardsComponent,
		OcorrenciasDoTecnicoComponent,
		TecnicoComTecnicoComponent,
		AtualmenteComTecnicoComponent,
		BoxComponent,
		CaixasComponent,
		CaixasDashboardCardComponent,
		EditarCaixaComponent,
		ArquivosComponent,
		ContasPagarComponent,
		ContasDefaultComponent,
		ContasReceberComponent,
		RendimentoDosTecnicosComponent,
		ClosureComponent,
		AgendamentoComponent,
		AgendamentoListComponent,
		VacationComponent,
		CustomersComponent,
		EditCustomersComponent,
		ServiceTakersComponent,
		HoursBankPaymentComponent,
		RelatoriosComponent,
		RelatorioDeAgendasComponent,
		ImportarNetComponent,
		ImportarBringgComponent,
		EmailCampaignComponent,
		EmailCampaignViewComponent,
		TerminationComponent,
		WorkloadComponent,
		EmailCampaignViewListComponent,
		ImportarUrasComponent,
		AgendasUrasComponent,
		AgendasUrasViewComponent,
		AgendasUrasDashboardComponent,
		ImportsListComponent,
		ImportsViewComponent,
		ImportViewListComponent,
		AwayComponent,
		EmailCampaignViewListSmsComponent,
		EnterprisesCrudBoxComponent,
		ScheduleSettingsComponent,
		ScheduleSettingsBlockedDaysComponent,
		ScheduleSettingsStandardDaysComponent,
		ComparacaoDeCargaComponent,
		ImportarNet2Component,
		AlmoxarifadoListagemComponent,
		NumerosBloqueadosComponent,
		ModalAgendaPreviaComponent,
		ModalAgendaConcluirComponent,
		ModalAgendaDarBaixaComponent,
		ContratosBloqueadosComponent,
		AgendamentosDashboardComponent,
		ModalAgendaCriarReversaoComponent,
	],
	providers: [
		ThemeConstantService,
		ViewportRuler,
		Platform,
		DragDropRegistry,
		ScrollDispatcher,
		ThemeService
	],
})
export class WithLayoutModule {}