import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "durinn-modal",
	templateUrl: "./durinn-modal.component.html",
	styleUrls: ["./durinn-modal.component.css"],
})
export class DurinnModalComponent implements OnInit {
	@Input() title: string;

	@Input() visible = false;
	@Input() loading = false;

	@Input() okText = "Enviar";
	@Input() okDisabled = false;

	@Input() cancelText = "Cancelar";
	@Input() cancelDisabled = false;

	@Output() send = new EventEmitter();
	@Output() visibleChange = new EventEmitter();

	@Input() style = {};

	constructor() {}

	ngOnInit() {}

	dismiss() {
		this.visible = false;
		this.visibleChange.emit(false);
	}
}
