import { retry, timeout } from "rxjs/operators";
import { Io } from "@durinn.v3/helpers/api";
import { Injectable } from "@angular/core";
import { BasePage } from "./base.page";

export interface EditPageInterface {
	form: { [s: string]: any };
	route: string;
	back_to: string;
}

@Injectable()
export class EditPage extends BasePage {
	call: Io;
	load: Io;
	cep_loading = false;

	form: { [s: string]: any };
	originalForm: { [s: string]: any };
	permissions: { [s: string]: boolean };

	id = null;
	route: string;
	back_to: string;

	ngOnInit(): void {
		super.ngOnInit();

		const self = this;
		self.id =
			self.activatedRoute.snapshot.params["id"] == "new"
				? null
				: +self.activatedRoute.snapshot.params["id"];

		self.call = self.api.new();
		self.load = self.api.new();

		if (self.id) {
			self.get();
		}

		self.checkPermissions();
	}

	afterGet() {}

	async get() {
		const self = this;
		self.load.get(self.route + "/" + self.id).subscribe((response) => {
			const data = response.return;

			if (data) {
				data.variables = {};
				self.form = data;
				self.originalForm = data;

				if ("Variables" in data) {
					for (const item of data.Variables) {
						self.form.variables[item.field] = item.variable;
					}
				}

				self.afterGet();
			}
		});
	}

	async delete() {
		const self = this;
		if (await self.app.confirm()) {
			self.call
				.delete(self.route + "/" + self.id)
				.subscribe((response) => {
					self.app
						.alert(`Sucesso`, response.message, `success`)
						.then(() => self.router.navigateByUrl(self.back_to));
				});
		}
	}

	async submitForm() {
		const self = this;
		if (self.id) {
			self.call
				.put(self.route + "/" + this.id, this.form)
				.subscribe((response) => {
					self.app
						.alert(`Sucesso`, response.message, `success`)
						.then(() => self.router.navigateByUrl(self.back_to));
				});
		} else {
			self.call
				.post(self.route + "/", this.form)
				.subscribe((response) => {
					self.app
						.alert(`Sucesso`, response.message, `success`)
						.then(() => {
							self.router.navigateByUrl(self.back_to);
						});
				});
		}
	}

	async cepChanged() {
		const self = this;
		const cep = (self.form.variables.cep || "").replace(/[^0-9]/g, "");

		self.cep_loading = true;
		self.http
			.get(`https://viacep.com.br/ws/${cep}/json/`)
			.pipe(retry(5))
			.pipe(timeout(3000))
			.subscribe(
				(data: any) => {
					self.form.variables.address = data.logradouro;
					self.form.variables.neighborhood = data.bairro;
					self.form.variables.city = data.localidade;
					self.form.variables.state = data.uf.toUpperCase();
				},
				(error) => {
					console.error(error);
				},
				() => {
					self.cep_loading = false;
				}
			);
	}

	changeListener($event): void {
		this.readThis($event.target);
	}

	readThis(inputValue: any): void {
		const file: File = inputValue.files[0];
		const myReader: FileReader = new FileReader();

		myReader.onloadend = (e) => {
			this.form.picture = myReader.result as string;
		};

		myReader.readAsDataURL(file);
	}
}
