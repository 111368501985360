import { EditPage, EditPageInterface } from "../../../../bases/edit.page";
import { Component } from '@angular/core';
import * as moment from "moment";

const EmailStatus = {
	0: "Não enviado",
	1: "Enviado",
	2: "E-mail já enviado anteriormente"
};

@Component({
	selector: 'app-email-campaign-view',
	templateUrl: './email-campaign-view.component.html',
	styleUrls: ['./email-campaign-view.component.css']
})
export class EmailCampaignViewComponent extends EditPage implements EditPageInterface {
	back_to = "/campanhas/email";
	EmailStatus = EmailStatus;
	route = "email-campaign";
	moment = moment;
	show = false;
	Math = Math;

	form = {
		CreatedBy: {}
	};
}
