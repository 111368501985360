import variables from "../../backend/src/config/variables";

const config = {
	...variables,
	name: "Padrão Logístico",
	api: {
		production_link: "https://api4.padraologistico.com.br/dashboard/",
		acceptance_link: "https://api.homolog.padraologistico.com.br/dashboard/",
		development_link: "http://localhost:4000/dashboard/",
		is_acceptance: "homolog.padrao"
	},
	firebaseConfig: {
		apiKey: "AIzaSyBfewEaNXO7PoNXsPr4_mRQwJyBPQrnUI4",
		authDomain: "banana-joe-297617.firebaseapp.com",
		projectId: "banana-joe-297617",
		storageBucket: "banana-joe-297617.appspot.com",
		messagingSenderId: "716734360581",
		appId: "1:716734360581:web:d92e7165197a8dd338006c",
		measurementId: "G-3TKS6V3D09",
	},
	template: {
		isMenuFolded: false,
		isSideNavDark: true,
		headerColor: "primary",
		defaultLogo: "/assets/images/logo/logo-fold-white.png",
		loginImg: "/assets/images/logo/logo.png",
		loginStyle: {width: '100%', height: 'auto', backgroundColor: '#fff', padding: '8px', borderRadius: '8px'},
		logoImg: "/assets/images/logo/logo-white.png",
		logoStyle: {maxHeight: '70px', padding: '0px', marginLeft: 'auto'},
		logoFoldImg: "/assets/images/logo/logo-white.png",
		logoFoldStyle: null
	},
	me: {
		cache_name: "user",
		login_field: "username",
		login_description: "CPF",
		password_field: "password"
	},
};

export default config;
