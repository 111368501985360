import { Component, OnInit } from "@angular/core";
import { DashboardBase } from "../../../../../../durinn/bases/dashboard.base";
import * as moment from "moment";
import {
	Device_Scans_Type_Number,
	Device_Scans_Type_Translate,
	Device_Type,
	Device_Scans_Type,
	DEVICESTATUS_CATEGORY
} from "@views/with-layout/almoxarifado/components/almoxarifado-listagem/almoxarifado-listagem.component";

@Component({
	selector: "app-streamings-dashboard",
	templateUrl: "./streamings-dashboard.component.html",
	styleUrls: ["./streamings-dashboard.component.css"],
})
export class StreamingsDashboardComponent extends DashboardBase {

	Object = Object;

	moment = moment;
	Device_Scans_Type = Device_Scans_Type;
	status = Device_Scans_Type;
	devices_type = Device_Type;
	translate = Device_Scans_Type_Translate;
	typeNumber = Device_Scans_Type_Number;

	DEVICESTATUS_CATEGORY = DEVICESTATUS_CATEGORY;

	isLate(date) {
		return moment(date).isBefore(moment().subtract(14, "day"));
	}
}
