import { Device_Scans_Type_Number } from "../../almoxarifado.component";
import { CrudPage } from "../../../../../bases/crud.page";
import { Io } from "@durinn.v3/helpers/api";
import { Component } from "@angular/core";

@Component({
	selector: "app-caixas",
	templateUrl: "./caixas.component.html",
	styleUrls: ["./caixas.component.css"],
})
export class CaixasComponent extends CrudPage {
	items = [];
	filters = {
		WarehouseBox: {
			createdAt: null,
		},
		Deliveryman: {
			name: null,
		},
		CollectedBy: {
			name: null,
		},
		WarehouseBoxDevice: {
			name: null,
		},
		Device: {
			name: null,
		},
	};

	order = {
		WarehouseBox: [["createdAt", "desc"]],
		Deliveryman: [["name", "desc"]],
		CollectedBy: [["name", "desc"]],
		WarehouseBoxDevice: [["name", "desc"]],
		Device: [["name", "desc"]],
	};

	call: Io;
	route = "warehousebox";

	limit = 20;

	TYPE_NUMBER = Device_Scans_Type_Number;

	ngOnChanges() {
		this.ngOnInit();
	}

	count(devices, type) {
		const self = this;

		let int = 0;

		for (const item of devices) {
			if (typeof (item.Device || {}).LastScan === "object") {
				if (type == self.TYPE_NUMBER.COLLECTED) {
					if (
						item.Device.LastScan.type ==
							self.TYPE_NUMBER.COLLECTED ||
						item.Device.LastScan.type == self.TYPE_NUMBER.IN_BOX
					) {
						int++;
					}
				} else {
					if (item.Device.LastScan.type == type) {
						int++;
					}
				}
			}
		}

		return int;
	}
}
