import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CrudPage } from "../../../bases/crud.page";
import { Io } from "@durinn.v3/helpers/api";

@Component({
	selector: "app-teams-select",
	templateUrl: "./teams-select.component.html",
	styleUrls: ["./teams-select.component.css"],
})
export class TeamsSelectComponent extends CrudPage {
	@Input() teamId = 0;
	@Input() input = false;
	@Input() showAllOption = true;
	@Output() change = new EventEmitter();

	items = [];
	filters = {
		team: {
			name: null,
		},
	};

	order = {
		team: [["name", "asc"]],
	};

	call: Io;
	delete_call: Io;
	route = "team";

	protected getFilters() {
		const self = this;

		// if(self.filters.team.active){
		//   self.filters.team.active = +self.filters.DeliveryMan.active;
		// }
		self.call.silent(true);
		return super.getFilters();
	}
}
