import { Device_Scans_Type_Number } from "../../../../../almoxarifado.component";
import { CrudPage } from "../../../../../../../../bases/crud.page";
import { Component, Input } from "@angular/core";
import { Io } from "@durinn.v3/helpers/api";

@Component({
	selector: "app-lotes-list",
	templateUrl: "./lotes-list.component.html",
	styleUrls: ["./lotes-list.component.css"],
})
export class LotesListComponent extends CrudPage {
	@Input() integer = 0;

	items = [];
	filters = {
		Deliveryman: {
			name: null,
		},
		WarehouseLot: {
			id: null,
			name: null,
			createdAt: null,
			invoiceNumber: null,
		},
	};

	permissions = {
		CREATE_WAREHOUSE_LOTS: false,
	};

	order = {
		WarehouseLot: [["createdAt", "asc"]],
		Deliveryman: [["name", "desc"]],
	};

	TYPE_NUMBER = Device_Scans_Type_Number;

	call: Io;
	route = "warehouselot/streamings";

	limit = 10;

	pendent = true;

	ngOnChanges() {
		this.ngOnInit();
	}

	async get(page = 1): Promise<void> {
		const self = this;
		const _page = page - 1;
		const offset = _page * self.limit;

		self.page = page;

		let args: any = { order: self.order };

		if (Object.values(self.getFilters()).length > 0) {
			const where = {};
			const items = self.getFilters();

			for (const item in items) {
				where[item] = { "Op.and": items[item] };
			}

			args = { order: self.order, where: where };
		}

		args.pendent = this.pendent ? "1" : "0";

		self.call
			.post(self.route + `/search/${offset}/` + self.limit, args)
			.subscribe((data) => {
				self.items = data.success
					? self.foreachItems((data.return || {}).rows)
					: [];
				self.pages = (data.return || {}).count;
				self.offset = offset;
				self.listLoaded();
			});
	}

	count(devices, type) {
		let int = 0;

		for (const item of devices) {
			if (item.LastScan) {
				if (item.LastScan.type == type) {
					int++;
				}
			}
		}

		return int;
	}
}
