import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { DurinnModule } from "@durinn/v.3/durinn.module";
import { SideNavDirective } from "../directives/side-nav.directive";
import { SearchPipe } from "../pipes/search.pipe";
import { ThemeConstantService } from "../services/theme-constant.service";
import { FooterComponent } from "./footer/footer.component";
import { QuickViewComponent } from "./quick-view/quick-view.component";
import { SearchComponent } from "./search/search.component";

@NgModule({
	exports: [
		BrowserModule,
		SearchComponent,
		QuickViewComponent,
		SideNavDirective,
		DurinnModule
	],
	imports: [
		BrowserModule,
		DurinnModule
	],
	declarations: [
		SearchComponent,
		QuickViewComponent,
		SideNavDirective,
		SearchPipe,
	],
	providers: [ThemeConstantService],
})
export class TemplateModule {}
