import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { App } from "@durinn.v3/helpers/app";
import { Api } from "@durinn.v3/helpers/api";
import swal from "sweetalert2";

declare const google: any;

@Component({
	selector: "app-importar-token",
	templateUrl: "./importar-token.component.html",
	styleUrls: ["./importar-token.component.css"],
})
export class ImportarTokenComponent implements OnInit {
	token = null;
	objects = [];
	call = this.api.new();
	load = this.api.new();
	
	get google(){
		if(typeof (window as any).google !== 'undefined'){
			return (window as any).google;
		}
		
		if(typeof google !== 'undefined'){
			return google;
		}
		
		return false;
	}
	
	constructor(
		private route: ActivatedRoute,
		private api: Api,
		private router: Router,
		private app: App
	) {
		this.token = this.route.snapshot.params["token"];
	}

	getExcel() {
		this.call.openLink("importer/" + this.token + "/excel");
	}

	ngOnInit() {
		const self = this;
		self.load.get("importer/" + this.token).subscribe((data) => {
			for (const item of data.return) {
				const object = JSON.parse(item.object);
				object.error = item.errorStack;
				object.validationError = item.validationError;
				self.objects.push(object);
			}
		});
	}

	fillAddress(address, item) {
		item.address = address.formatted_address;
		item.latitude = address.geometry.location.lat();
		item.longitude = address.geometry.location.lng();
	}

	remove(index) {
		const self = this;
		if (confirm("Você tem certeza?")) {
			self.objects.splice(index, 1);
		}
	}

	async deleteAll() {
		const self = this;
		if (await self.app.confirm()) {
			self.call
				.set("success", true)
				.delete("importer/" + self.token)
				.subscribe((data) => {
					self.router.navigateByUrl("/agendas/importar");
				});
		}
	}

	async send() {
		const self = this;
		self.call
			.set("success", false)
			.post("importer/" + self.token, self.objects)
			.subscribe((data) => {
				if (!data.return.error) {
					swal.fire(
						"Importação",
						"Importação feita com sucesso!",
						"success"
					);
					self.router.navigateByUrl("/agendas/importar");
				} else {
					window.location.reload();
				}
			});
	}

	formatError(error) {
		if (!error) {
			return "";
		}

		if (typeof error === "string") {
			try {
				const obj = JSON.parse(error);
				return (obj || {}).message || "";
			} catch (e) {
				return error;
			}
		}

		return "Não Especificado";
	}
}
