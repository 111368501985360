import { Device_Scans_Type_Number } from "../../../almoxarifado.component";
import { CrudPage } from "../../../../../../bases/crud.page";
import { Component, Input, OnInit } from "@angular/core";
import { Io } from "@durinn.v3/helpers/api";

@Component({
	selector: "app-lots-modal",
	templateUrl: "./lots-modal.component.html",
	styleUrls: ["./lots-modal.component.css"],
})
export class LotsModalComponent extends CrudPage {
	@Input() integer = 0;

	items = [];
	filters = {
		Deliveryman: {
			name: null,
		},
		WarehouseLot: {
			id: null,
			name: null,
			createdAt: null,
		},
	};

	permissions = {
		CREATE_WAREHOUSE_LOTS: false,
	};

	order = {
		WarehouseLot: [["createdAt", "desc"]],
		Deliveryman: [["name", "desc"]],
	};

	TYPE_NUMBER = Device_Scans_Type_Number;

	call: Io;
	route = "warehouselot";

	ngOnChanges() {
		this.ngOnInit();
	}

	count(devices, type) {
		let int = 0;

		for (const item of devices) {
			if (item.LastScan) {
				if (item.LastScan.type == type) {
					int++;
				}
			}
		}

		return int;
	}
}
