import { Component } from "@angular/core";
import * as moment from "moment";
import {
	URAS_SCHEDULE_STATUS,
	URAS_SCHEDULE_STATUS_OPTIONS,
} from "src/app/constants";
import { EditPage, EditPageInterface } from "src/app/bases/edit.page";
import Swal from "sweetalert2";
import { SocketIo } from "@durinn.v3/helpers/SocketIo";

@Component({
	selector: "app-agendas-uras-view",
	templateUrl: "./agendas-uras-view.component.html",
	styleUrls: ["./agendas-uras-view.component.css"],
})
export class AgendasUrasViewComponent
	extends EditPage
	implements EditPageInterface {
	statusOptions = URAS_SCHEDULE_STATUS_OPTIONS;
	format = "DD/MM/YYYY HH:mm:ss";
	back_to = "/agendas/uras";
	route = "uras-schedule";
	timer = "00:00:00";
	dateTimer = null;
	moment = moment;
	isTimer = false;
	diffTimer = 0;

	form = {
		importDate: new Date(),
		startDate: null,
		endDate: null,
		contract: null,
		lowType: null,
		lowCode: null,
		adminId: null,
		status: null,
		serial: null,
		clerk: null,
		type: null,
		city: null,
		note: null,
		date: null,
		from: null,
		to: null,
		id: null,
		User: {
			name: null,
			id: null,
		},
		similar: [{
			created: false,
			checked: true,
			serial: null,
			root: true,
			type: null,
			id: null,
		}]
	};

	viewers = [];

	getViewers(){
		const self = this;

		const viewers = {};

		if(self.mineUser){
			viewers[self.mineUser.id] = self.mineUser.name;
		}

		for(const item of self.viewers){
			viewers[item.id] = (item.name || 'Desconhecido');
		}

		return Object.values(viewers).join(', ');
	}

	ngOnInit() {
		super.ngOnInit();
	}

	ngOnDestroy() {
		const self = this;

		if(self.form.id && self.form.contract){
			SocketIo.leave('ura-' + self.form.contract);
		}

		super.ngOnDestroy();
	}

	afterGet() {
		const self = this;

		if (self.form.similar.length) {
			for (const item of self.form.similar) {
				item.created = false;
				item.checked = true;

				if (item.id == self.form.id) {
					item.root = true;
				}
			}
		}

		if (self.hasPermission("MASTER")) {
			self.form.startDate = moment(self.form.from).format(self.format);
			self.form.endDate = moment(self.form.to).format(self.format);
		}

		if(self.form.id && self.form.contract) {
			self.socketSafeOn('ura-' + self.form.contract + '-changed', (data) => {
				self.viewers = data;
			});

			SocketIo.join('ura-' + self.form.contract);
		}
	}

	addNewSerial() {
		const self = this;

		self.form.similar.push({
			created: true,
			checked: true,
			serial: null,
			root: false,
			type: null,
			id: null,
		});
	}

	removeLastSerial() {
		const self = this;

		if (self.form.similar.length > 1) {
			const lastIndex = self.form.similar.length - 1;
			const item = self.form.similar[lastIndex];
			if (item.created == true && item.root == false) {
				self.form.similar.pop();
			}
		}
	}

	async remove(i){
		const self = this;

		if(await self.app.confirm()){
			self.form.similar.splice(i, 1);
		}
	}

	get removeLastSerialDisabled() {
		const self = this;

		if (self.form.similar.length == 1) {
			return true;
		}

		if (self.form.similar.length > 1) {
			const lastIndex = self.form.similar.length - 1;
			const item = self.form.similar[lastIndex];
			if (item.created == false || item.root == true) {
				return true;
			}
		}

		return false;
	}

	get startDate() {
		const self = this;

		if (self.form.from) {
			return moment(self.form.from).format(self.format);
		}
	}

	get endDate() {
		const self = this;

		if (self.form.to) {
			return moment(self.form.to).format(self.format);
		}
	}

	get isAssigned() {
		const self = this;

		if (self.form.adminId == (self.mineUser as any).id) {
			return true;
		}

		return false;
	}

	get saveDisabled() {
		const self = this;

		if(self.form.status == 0){
			return false;
		}

		const conditions = [
			self.form.status !== null,
			self.form.adminId > 0,
			!!self.form.contract,
			!!self.form.lowType,
			!!self.form.lowCode,
			!!self.form.clerk,
			!!self.form.city,
			!!self.form.date,
		];

		if (conditions.includes(false)) {
			return true;
		}

		for (const item of self.form.similar) {
			if (item.checked) {
				const conditions = [
					!!item.serial,
					!!item.type
				];
  
				if (conditions.includes(false)) {
					return true;
				}
			}
		}

		if (!self.hasPermission("MASTER")) {
			if (!(!!self.form.from && !!self.form.to)) {
				return true;
			}
		}

		if (self.hasPermission("MASTER")) {
			if (self.form.startDate && self.form.startDate.length == 19) {
				if (!moment(self.form.startDate, self.format).isValid()) {
					return true;
				}
			} else {
				return true;
			}

			if (self.form.endDate && self.form.endDate.length == 19) {
				if (!moment(self.form.endDate, self.format).isValid()) {
					return true;
				}
			} else {
				return true;
			}
		}

		if (
			self.form.status == URAS_SCHEDULE_STATUS.NAO_BAIXADO &&
      !self.form.note
		) {
			return true;
		}

		return false;
	}

	getTimer() {
		const self = this;

		if (self.dateTimer) {
			self.diffTimer = moment().diff(moment(self.dateTimer), "seconds");
			self.timer = moment()
				.startOf("day")
				.seconds(self.diffTimer)
				.format("HH:mm:ss");

			if (!self.hasPermission("MASTER")) {
				self.form.from = moment(self.dateTimer).toDate();
				self.form.to = moment(self.form.from)
					.add(self.diffTimer, "seconds")
					.toDate();
			}

			if (self.hasPermission("MASTER")) {
				self.form.startDate = moment(self.dateTimer).format(self.format);
				self.form.endDate = moment(self.form.startDate, self.format)
					.add(self.diffTimer, "seconds")
					.format(self.format);
			}

			setTimeout(() => {
				self.getTimer();
			}, 1000);
		}
	}

	startTimer() {
		const self = this;
		self.isTimer = true;
		self.dateTimer = moment().toDate();
		self.getTimer();
	}

	stopTimer() {
		const self = this;

		self.timer = "00:00:00";
		self.dateTimer = null;
		self.isTimer = false;
		self.diffTimer = 0;
	}

	assignMe() {
		const self = this;

		self.me.get(true).subscribe(user => {
			if(user){
				self.form.adminId = user.id;

				self.form.User = {
					name: user.name,
					id: user.id,
				};
			}else{
				self.app.alert('Erro', 'Erro ao atribuir agenda', 'error');
			}
		});
	}

	async submitForm(): Promise<void> {
		const self = this;

		if (self.isTimer) {
			self.stopTimer();
		}

		if (self.hasPermission("MASTER")) {
			self.form.from = moment(self.form.startDate, self.format).toDate();
			self.form.to = moment(self.form.endDate, self.format).toDate();
		}

		super.submitForm();
	}

	async onCancel() {
		const self = this;
		const confirm = await Swal.fire({
			title: "Agenda",
			html: "Se você cancelar, todas as alterações serão perdidas. Você deseja continuar?",
			icon: "question",
			showCloseButton: true,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: "Descartar Alterações",
			cancelButtonText: "Cancelar",
		});
		if (confirm.value) {
			self.router.navigateByUrl(self.back_to);
		}
	}
}
