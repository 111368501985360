import { Component } from "@angular/core";
import { FiltersType, Orders } from "@durinn/v.3/app/bases/listpage.base";
import { PageBase } from "@durinn/v.3/app/bases/page.base";
import {
	DurinnV2ListPageTableComponent,
	HeadColumns,
} from "@durinn/v.3/app/components/template/tables/list-page-table/list-page-table.component";

@Component({
	selector: "durinn-v2-teamlist-view",
	templateUrl: "./list.component.html",
	styleUrls: ["./list.component.css"],
})
export class DurinnV2TeamListViewComponent extends PageBase {
	order: Orders = { Team: [["name", "asc"]] };
	filters: FiltersType = { Team: { name: "" } };

	headColumns: HeadColumns =
		DurinnV2ListPageTableComponent.defaultHeadColumnsWith("Team", [
			{ name: "Membros" },
		]);
}
