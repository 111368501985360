import {Injectable} from "@angular/core";
import {Api} from "@durinn.v3/helpers/api";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class ThermalPrinter {
	constructor(protected api: Api, protected toastr: ToastrService) {}
	
	public async print(object: {commands ?: string, html ?: string}){
		const self = this;
		
		if(object.commands){
			try{
				return await self.printCommands(object.commands);
			}catch (e) {
				if(object.html){
					return self.printHtml(object.html);
				}
			}
		}
		
		if(object.html){
			return self.printHtml(object.html);
		}
		
		self.toastr.error(`Nenhum comando válido para imprimir`, `Erro`);
	}
	
	public printCommands(commands: string) {
		const self = this;
		
		return new Promise((resolve, reject) => {
			const call = self.api.newLocalApi();
			
			call.get(Api.LOCAL_API + "printer").subscribe(
				async (data) => {
					call.post(Api.LOCAL_API + "print", {commands: commands}).subscribe(
						() => {
							resolve(true); self.toastr.success(`Imprimindo...`); 
						},
						(err) => reject(err)
					);
				},
				(err) => reject(err)
			);
		});
	}
	
	public printHtml(html: string){
		const win = window.open("", "Imprimir", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=600,top=100,right=100");
		win.document.body.innerHTML = html;
		win.window.print();
		setTimeout(() => win.close(), 500);
	}
}
