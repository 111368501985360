import { Component, Injector } from "@angular/core";
import { ROUTES, SideNavItem } from "./side-nav-routes.config";
import { ThemeConstantService } from "../../services/theme-constant.service";
import { PageBase } from "@durinn/v.3/app/bases/page.base";

interface menuNav extends SideNavItem {
  submenu1?: any;
  submenu2?: any;
}

@Component({
	selector: "app-sidenav",
	templateUrl: "./side-nav.component.html",
})
export class SideNavComponent extends PageBase {
	public isSideNavDark: boolean;
	public menuItems: menuNav[];
	public isFolded: boolean;
	
	badgesCall = this.api.new();
	badgesData: Record<string, number> = {};

	constructor(public injector: Injector, private themeService: ThemeConstantService) {
		super(injector);
	}

	ngOnInit(): void {
		const self = this;

		self.menuItems = ROUTES.filter((menuItem) => menuItem);
		self.themeService.isMenuFoldedChanges.subscribe(
			(isFolded) => (self.isFolded = isFolded)
		);
		self.themeService.isSideNavDarkChanges.subscribe(
			(isDark) => (self.isSideNavDark = isDark)
		);

		self.getBadgesData();
	}

	getBadgesData() {
	  const self = this;

	  for (const item of self.menuItems) {
		  if (item.badge) {
				self.badgesCall.get(item.badge.url).subscribe(response => {
					if (response.success) {
						self.badgesData[item.badge.id] = response.return;
					}
				});
		  }
	  }
	}

	visible(item: SideNavItem) {
		const self = this;
		if (item.hasOneOfPermissions && Array.isArray(item.hasOneOfPermissions)) {
			for (const permission of item.hasOneOfPermissions) {
				if (self.hasPermission(permission)) {
					return true;
				}
			}

			return false;
		} else {
			if (!item.permissions || item.permissions.length === 0) {
				return true;
			}

			for (const permission of item.permissions) {
				if (!self.hasPermission(permission)) {
					return false;
				}
			}

			return true;
		}
	}
}
