import { Component } from "@angular/core";
import config from "../../../../../../config";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
})
export class FooterComponent {
	config = config;
}
