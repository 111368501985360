import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "app-types-select",
	templateUrl: "./types-select.component.html",
	styleUrls: ["./types-select.component.css"],
})
export class TypesSelectComponent implements OnInit {
	loading = false;
	types = [];
	name = "";

	@Input() disabled = false;
	@Input() type = null;
	@Output() selected = new EventEmitter();

	constructor() {}

	async ngOnInit() {
		this.search();
	}

	async search() {
		const self = this;
		self.types = [
			"COMBO MULTI",
			"MALLING",
			"DX22",
			"VOX",
			"DESC TOA",
			"ENTREGA DE CAMERA",
			"ENTREGA DE STREAMINGS",
			"ENTREGA DE CONTROLE",
			"CORTE",
			"FIELD",
			"NOTURNA",
			"ENTREGA DE EXAMES",
			"AGENDA FUTURA",
			"AGENDAMENTO E-MAIL",
			"AGENDAMENTO P.A",
			"AGENDAMENTO TOA",
			"REAGENDAMENTO LINK",
			"INSTALACAO CONTACT",
			"RETORNO DE CARTA"
		];
	}

	emit() {
		this.selected.emit(this.type);
	}
}
