import { Component, OnInit } from "@angular/core";
import { Api, Io } from "@durinn/v.3/helpers/api";
import { Me } from "@durinn/v.3/helpers/me";

@Component({
	selector: "durinn-v2-update-picture",
	templateUrl: "./update-picture.component.html",
	styleUrls: ["./update-picture.component.css"],
})
export class DurinnV2UpdatePictureComponent implements OnInit {
	chaged_image = false;
	image = null;
	call: Io;

	constructor(public api: Api, public me: Me) {}

	ngOnInit() {
		this.call = this.api.new();
		this.me.get().subscribe((data) => {
			if (data) {
				this.image = data.picture;
			}
		});
	}

	changeListener($event): void {
		this.readThis($event.target);
	}

	readThis(inputValue: any): void {
		const file: File = inputValue.files[0];
		const myReader: FileReader = new FileReader();

		myReader.onloadend = (e) => {
			this.chaged_image = true;
			this.image = myReader.result;
		};

		myReader.readAsDataURL(file);
	}

	send() {
		const self = this;
		self.call
			.set("success", true)
			.put("me/picture", { picture: self.image })
			.subscribe((data) => {
				self.me
					.get(false, self.call.set("success", false))
					.subscribe((data) => {
						if (data) {
							self.chaged_image = false;
						}
					});
			});
	}
}
