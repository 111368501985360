import { Component, HostListener } from "@angular/core";
import { DELIVERY_TYPES } from "../../../constants";
import { CrudPage } from "../../../bases/crud.page";
import Swal from "sweetalert2";
import { Io } from "@durinn.v3/helpers/api";

export default class Delivery {
	public static MADE = {
		"-1": "Perdida",
		"1": "Realizada",
		"2": "Baixada",
		"7": "Esperando NET",
		"8": "Ausente Cancelado",
	};

	public static STATUS = {
		1: "Ativo",
		2: "Realizado",
		3: "Atrasado",
		4: "Tempo Apertado",
		5: "Perdida",
		6: "Baixada",
		100: "Agendamento Não Cumprido",
		101: "Endereço Não Localizado",
		102: "Área De Risco",
		106: "Ausente",
		107: "Entrada Não Autorizada",
		109: "Desistência da desconexão",
		111: "Reagendamento Solicitado Pelo Cliente",
		306: "Mudou-se",
		409: "Serviço Concluído",
		428: "Desconexão C/RET Equipamento",
		429: "Desconexão S/RET Equipamento",
		430: "Retirada De Equipamentos",
		431: "Equipamento Já Retirado por outro Técnico",
		706: "Entrega de Chip com Sucesso",
		302: "Desistência de Serviço",
		312: "Não Solicitou o Serviço",
		10: "Entrega de Exames com Sucesso",
		110: "Sem acesso ao DG",
		998: "Entregue na Loja da Claro",
		999: "Agendamento Fake",
	};

	public static NET_STATUS = [
		{ id: 100, description: "Agendamento Não Cumprido" },
		{ id: 101, description: "Endereço Não Localizado" },
		{ id: 102, description: "Área De Risco" },
		{ id: 106, description: "Ausente" },
		{ id: 107, description: "Entrada Não Autorizada" },
		{ id: 109, description: "Desistência da desconexão" },
		{ id: 111, description: "Reagendamento Solicitado Pelo Cliente" },
		{ id: 112, description: "Sem Acesso ao DG ou Sotão" },
		{ id: 302, description: "Desistência de Serviço" },
		{ id: 306, description: "Mudou-se" },
		{ id: 312, description: "Não solicitou o Serviço" },
		{ id: 401, description: "Cabo Não Identificado" },
		{ id: 409, description: "Serviço Concluído" },
		{ id: 428, description: "Desconexão C/RET Equipamento" },
		{ id: 429, description: "Desconexão S/RET Equipamento" },
		{ id: 430, description: "Retirada De Equipamentos" },
		{ id: 431, description: "Equipamento Já Retirado por outro Técnico" },
		{ id: 706, description: "Entrega de Chip com Sucesso" },
		{ id: 10, description: "Entrega de Exames com Sucesso" },
		{ id: 110, description: "Sem acesso ao DG" },
		{ id: 998, description: "Entregue na Loja da Claro" },
		{ id: 999, description: "Agendamento Fake" },
	];
}

@Component({
	selector: "app-agendas",
	templateUrl: "./agendas.component.html",
	styleUrls: ["./agendas.component.css"],
})
export class AgendasComponent extends CrudPage {
	public keypressed;

	limit = 50;

	@HostListener("window:keydown", ["$event"])
	handleKeyboardEvent(event: KeyboardEvent) {
		this.keypressed = event.keyCode;
		if (this.keypressed == 13) {
			if (document.activeElement instanceof HTMLElement) {
				document.activeElement.blur();
			}
			this.get(1);
		}
	}

	items = [];
	filters: any = { delivery: {}, deliveryman: {} };

	Object = Object;

	checked = {};
	types = DELIVERY_TYPES;


	local_filter = {
		from: this.moment().format("DD/MM/YYYY"),
		to: this.moment().add(3, "day").format("DD/MM/YYYY"),
		fromHour: '00:00',
		toHour: '23:59',
		teamId: 0
	};

	order = {
		delivery: [
			["start", "desc"],
			["made", "asc"],
			["tries", "desc"],
		],
	};

	call: Io;
	delete_call: Io;
	route = "delivery";
	delivery = Delivery;

	deliveryman_name = "";
	
	apenasConfirmadas = !!+(localStorage.getItem('agendas-apenasConfirmadas') || 0);
	apenasSemOsDigital = !!+(localStorage.getItem('agendas-apenasSemOsDigital') || 0);
	apenasComTecnico = !!+(localStorage.getItem('agendas-apenasComTecnico') || 0);
	
	apenasChanged(index: string){
		this[index] = !this[index];
		localStorage.setItem('agendas-' + index, !!this[index] ? '1' : '0');
		this.get();
	}

	protected getFilters() {
		const self = this;
		
		self.filters.delivery.start = {
			"Op.between": [
				self.moment(self.local_filter.from, "DD/MM/YYYY").startOf('day').toDate(),
				self.moment(self.local_filter.to, "DD/MM/YYYY").endOf('day').toDate()
			],
		};

		if (
			self.filters.delivery.opening_date &&
			self
				.moment(self.filters.delivery.opening_date, "DD/MM/YYYY")
				.isValid()
		) {
			self.filters.delivery.opening_date = self
				.moment(self.filters.delivery.opening_date, "DD/MM/YYYY")
				.toDate();
		} else {
			self.filters.delivery.opening_date = null;
		}

		if (
			self.filters.delivery.backend_date &&
			self
				.moment(self.filters.delivery.backend_date, "DD/MM/YYYY")
				.isValid()
		) {
			self.filters.delivery.backend_date = self
				.moment(self.filters.delivery.backend_date, "DD/MM/YYYY")
				.toDate();
		} else {
			self.filters.delivery.backend_date = null;
		}

		if ((self.deliveryman_name || "").toLowerCase() != "nenhum") {
			self.filters.delivery.user_id = null;
			self.filters.deliveryman.name = self.deliveryman_name;
		} else {
			self.filters.delivery.user_id = { "Op.is": null };
			self.filters.deliveryman.name = null;
		}

		self.filters.delivery.confirmed_date = self.apenasConfirmadas
			? { "Op.gt": 0 }
			: null;
		
		if(self.apenasSemOsDigital){
			self.filters.delivery.type = 'AGENDAMENTO TOA';
			// self.filters.delivery.made = 1;
			self.filters.delivery.osDigital = {'Op.is': null};
		}
		
		if(self.apenasComTecnico){
			self.filters.delivery.user_id = {'Op.not': null};
		}

		return super.getFilters();
	}

	filtersClean() {
		this.filters = { delivery: {}, deliveryman: {} };
		this.get(1);
	}
	
	doExport(){
		const self = this;
		
		let args: any = { order: self.order };
		
		if (Object.values(self.getFilters()).length > 0) {
			const where = {};
			const items = self.getFilters();
			
			for (const item in items) {
				where[item] = { "Op.and": items[item] };
			}
			
			args = { order: self.order, where: where };
		}
		
		args.fromHour = self.local_filter.fromHour;
		args.toHour = self.local_filter.toHour;
		args.teamId = self.local_filter.teamId;
		
		self.apiCallsManager.call(
			self.call,
			'post',
			self.route + `/search/export-excel`,
			args
		).subscribe((data) => {
			window.open(data.return.url);
		});
	}

	async get(page = 1): Promise<void> {
		this.check_all = false;
		this.checked = {};
		
		const self = this;
		
		const args: any = {};
		args.fromHour = self.local_filter.fromHour;
		args.toHour = self.local_filter.toHour;
		args.teamId = self.local_filter.teamId;
		
		super.get(page, args);
	}

	check_all = false;
	checkAll(bool: boolean) {
		const self = this;
		self.checked = {};

		if (!bool) {
			return true;
		}

		for (const item of self.items) {
			self.checked[item.id_delivery] = true;
		}
	}

	async see(delivery) {
		const self = this;
		
		self.preview.delivery = delivery;
		self.preview.modal = true;
		
		// const confirm = await Swal.fire({
		// 	title: "Agendas",
		// 	html:
		// 		"Você deseja ver a <b> agenda " +
		// 		delivery.id_delivery +
		// 		"</b> de <b>" +
		// 		((delivery.Deliveryman || {}).name || "Nenhum").split(" ")[0] +
		// 		"</b>?",
		// 	icon: "question",
		// 	showCloseButton: true,
		// 	showCancelButton: true,
		// 	focusConfirm: false,
		// 	confirmButtonText: "Ver Agenda",
		// 	cancelButtonText: "Cancelar",
		// });
		// if (confirm.value) {
		// 	const win = window.open(
		// 		"agendas/" + delivery.id_delivery,
		// 		"_blank"
		// 	);
		// 	win.focus();
		// }
	}

	private tries = null;
	private async loadTries(item: any) {
		const self = this;
		self.tries = null;

		self.call
			.get("delivery/" + item.id_delivery + "/tries")
			.subscribe((response) => {
				if (response.success) {
					self.tries = response.return;
				} else {
					self.tries = [];
				}
			});
	}

	private getChecked() {
		const self = this;
		const array = [];

		for (const item of Object.keys(self.checked)) {
			if (self.checked[item]) {
				array.push(item);
			}
		}

		return array;
	}

	async terminate_excel() {
		const self = this;
		const ids = self.getChecked();

		self.api
			.new()
			.openLink("delivery/report/terminate-report/", { ids: ids })
			.then(() => {
				self.terminateAllChecked(
					"Você gostaria também de baixar essas agendas?"
				);
			});
	}

	async terminateAllChecked(
		text = "Você tem certeza de que gostaria de baixar essas agendas?"
	) {
		const self = this;
		const ids = self.getChecked();

		Swal.fire({
			title: "Baixar Agendas",
			text: text,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			confirmButtonText: "Baixar",
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			if (result.value) {
				self.me.get(true, self.call).subscribe((user) => {
					if (user) {
						self.call
							.put("delivery", {
								status: 6, // Baixada
								made: 2,
								terminate: 1,
								terminate_date: new Date(),
								terminate_admin_id: user.id,
								terminate_note: "",
								ids: ids,
							})
							.subscribe((ws) => {
								Swal.fire(
									`Dar Baixa`,
									ws.success
										? "Agendas baixadas com sucesso"
										: ws.message,
									ws.success ? "success" : "error"
								);
								self.get();
							});
					}
				});
			}
		});
	}

	async updateAllChecked(args: any, string: string) {
		const self = this;

		if (self.getChecked().length == 0) {
			return Swal.fire("Erro", "Selecione no mínimo uma agenda", "error");
		}

		Swal.fire({
			title: "Você tem certeza?",
			html:
				"Você tem certeza de que deseja marcar essas agendas como <b>" +
				string +
				"</b>?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "rgb(70, 127, 207)",
			confirmButtonText: "Marcar",
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			if (result.value) {
				args.ids = self.getChecked();
				self.call.put("delivery/", args).subscribe((data) => {
					if (data.success) {
						Swal.fire(
							string,
							"Agendas marcadas com sucesso",
							"success"
						);
						self.get();
					}
				});
			}
		});
	}

	changeDate = {
		modal: false,
		start: this.moment().format("DD/MM/YYYY 08:00"),
		end: this.moment().format("DD/MM/YYYY 22:00"),
	};

	changeDeliveryman = {
		modal: false,
		id: null,
	};

	changeDateAllChecked() {
		const self = this;
		const start = this.moment(this.changeDate.start, "DD/MM/YYYY HH:mm");
		const end = this.moment(this.changeDate.end, "DD/MM/YYYY HH:mm");

		if (start.isValid() && end.isValid()) {
			self.changeDate.modal = false;
			self.updateAllChecked(
				{
					start: start.toDate(),
					end: end.toDate(),
					made: 0,
					status: 1,
				},
				"mudança de data"
			);
		} else {
			self.app.alert(
				`Trocar Data`,
				`Por favor, preencha uma data válida`,
				`error`
			);
		}
	}

	export = {
		from: this.moment().subtract(7, "day").format("DD/MM/YYYY"),
		to: this.moment().format("DD/MM/YYYY"),
		modal: false,
		apenasConfirmadas: false,
	};

	async exportTo() {
		const self = this;
		self.export.modal = false;
		self.call.openLink(
			"delivery/export/" +
				self
					.moment(self.export.from, "DD/MM/YYYY")
					.format("YYYY-MM-DD") +
				"/" +
				self.moment(self.export.to, "DD/MM/YYYY").format("YYYY-MM-DD") +
				"/" +
				self.export.apenasConfirmadas
		);
	}

	deliverySent(event) {
		console.log(`event`, event);
		const win = window.open("agendas/" + event.id_delivery, "_blank");
		win.focus();
	}

	validation = {
		modal: false,
		modal2: false,
		where: [],
		data: [],
		notFound: [],
		model: '',
		call: this.api.new()
	};

	validateSchedules(){
		const self = this;
		const where = [];

		for(const line of self.validation.model.split("\n")){
			const arr = line.trim().split((line.indexOf(' ') > -1) ? " " : "\t");
			const contract = arr[0];
			const os = arr[1] || '';

			where.push((os) ? {contract, os} : {contract});
		}

		self.validation.where = where;
		self.validation.call.post('delivery/search/', {
			where: {
				delivery: {
					'Op.and': [
						{'Op.or': where}
					]
				}
			}
		}).subscribe(data => {
			self.validation.modal = false;
			self.validation.modal2 = true;
			self.validation.data = (data.return || {}).rows || [];

			self.validation.notFound = [];
			for(const item of self.validation.where){
				let found = false;

				for(const or of self.validation.data){
					if(!found){
						if(item.os){
							if(item.os == or.os && item.contract == or.contract){
								found = true;
							}
						}else{
							if(item.contract == or.contract){
								found = true;
							}
						}
					}
				}

				if(!found){
					self.validation.notFound.push(item);
				}
			}
		});
	}

	validationTerminate(item, index){
		const self = this;
		self.me.get(true, self.validation.call).subscribe((user) => {
			if (user) {
				self.validation.call
					.put("delivery", {
						status: 6, // Baixada
						made: 2,
						terminate: 1,
						terminate_date: new Date(),
						terminate_admin_id: user.id,
						terminate_note: "",
						ids: [item.id],
					})
					.subscribe((ws) => {
						self.validation.data[index].made = 2;
					});
			}
		});
	}

	validationWaitingNET(item, index){
		const self = this;
		self.validation.call.put("delivery/", {ids: [item.id], made: 7})
			.subscribe((ws) => {
				self.validation.data[index].made = 7;
			});
	}
	
	preview = {
		modal: false,
		delivery: null
	};
}
