import {Component, Input, TemplateRef} from "@angular/core";
import { TeamInterface } from "@backend-interfaces/models";
import { ModelPageBase } from "@durinn/v.3/app/bases/modelpage.base";

@Component({
	selector: "durinn-v2-edit-team",
	templateUrl: "./team.component.html",
	styleUrls: ["./team.component.css"],
})
export class DurinnV2TeamComponent extends ModelPageBase {
	@Input() page = this;
	@Input() afterMembersTemplate: TemplateRef<any>;

	route = "team";
	backPage = "equipes";
	permission = "TEAMS";
	variableUrl = "team-fieldstofill";

	form: Partial<TeamInterface> & {variables: any} = { name: "", obs: "", subsidiaryId: 0, Members: [], variables: {} };
	fields = [
		{
			label: "Nome",
			name: "name",
			type: "text",
			validate: "Por favor, preencha com um nome válido!",
			required: true,
		},
	];

	_userSelected = null;
	userSelected(user) {
		this._userSelected = user;
	}

	addUser() {
		const self = this;

		if (!self.form.Members) {
			self.form.Members = [];
		}

		for (const item of self.form.Members) {
			if (item.id == self._userSelected.id) {
				return false;
			}
		}

		self.form.Members.push(self._userSelected);
	}
	removeUser(i) {
		const self = this;

		if (!self.form.Members) {
			self.form.Members = [];
		}

		self.form.Members.splice(i, 1);
	}
}
