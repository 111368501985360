import { Component, OnInit } from "@angular/core";
import { DashboardBase } from "../../../../../../../../durinn/bases/dashboard.base";

@Component({
	selector: "app-dashboard-cards",
	templateUrl: "./dashboard-cards.component.html",
	styleUrls: ["./dashboard-cards.component.css"],
})
export class DashboardCardsComponent extends DashboardBase {
	call = this.api.new();

	ngOnInit() {
		super.ngOnInit();
		this.getCount();
	}

	data;
	getCount() {
		this.call.get(`warehouselot/dashboard`).subscribe((data) => {
			this.data = data.return;
			console.log(data);
		});
	}

	downloadCsv(deviceType, scanType) {
		this.api.openLink(
			`warehouselot/dashboard/download-csv/${deviceType}/${scanType}`
		);
	}
}
