import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { BasePage } from "../../../../../bases/base.page";

@Component({
	selector: "almoxarifado-overview",
	templateUrl: "./overview.component.html",
	styleUrls: ["./overview.component.scss"],
})
export class OverviewComponent extends BasePage {
	Object = Object;

	@Input() date = true;
	@Input() from = null;
	@Input() to = null;
	@Input() lot = null;

	overview = {
		from: "",
		to: "",
		call: this.api.new(),
		data: {},
	};

	show = false;

	ngOnInit() {
		this.getOverview();
	}

	getOverview() {
		const self = this;

		if (!self.overview.from) {
			self.overview.from =
				this.from || moment().subtract(1, "month").format("21/MM/YYYY");
		}

		if (!self.overview.to) {
			self.overview.to = this.to || moment().format("20/MM/YYYY");
		}

		self.overview.call
			.get(
				self.overview.from && self.overview.to && !self.lot
					? `warehouse/overview/${moment(
						self.overview.from,
						"DD/MM/YYYY"
					  ).toISOString()}/${moment(self.overview.to, "DD/MM/YYYY")
						.endOf("day")
						.toISOString()}`
					: `warehouse/overview/`,
				self.lot ? { lot: self.lot } : {}
			)
			.subscribe((data) => {
				if (data.success) {
					self.overview.data = data.return;
				}
			});
	}

	sum(data) {
		let i = 0;

		if (typeof data !== "object") {
			return 0;
		}

		for (const item of Object.values(data)) {
			i = i + (item as number);
		}

		return i;
	}
}
