import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { Api } from "@durinn.v3/helpers/api";

declare const google: any;

@Component({
	selector: "app-edit-delivery",
	templateUrl: "./edit-delivery.component.html",
	styleUrls: ["./edit-delivery.component.scss"],
})
export class EditDeliveryComponent implements OnInit {
	@Input() deliveryO: any = false;
	@Output() saved = new EventEmitter();
	delivery: any = false;
	
	get google(){
		if(typeof (window as any).google !== 'undefined'){
			return (window as any).google;
		}
		
		if(typeof google !== 'undefined'){
			return google;
		}
		
		return false;
	}

	Object = Object;
	edit = false;
	status = {
		1: "Ativo",
		// 2: 'Realizado',
		// 3: 'Atrasado',
		// 4: 'Tempo Apertado',
		5: "Perdida",
		6: "Baixada",
		100: "Agendamento Não Cumprido",
		101: "Endereço Não Localizado",
		102: "Área De Risco",
		106: "Ausente",
		107: "Entrada Não Autorizada",
		109: "Desistência da desconexão",
		111: "Reagendamento Solicitado Pelo Cliente",
		306: "Mudou-se",
		409: "Serviço Concluído",
		428: "Desconexão C/RET Equipamento",
		429: "Desconexão S/RET Equipamento",
		430: "Retirada De Equipamentos",
		431: "Equipamento Já Retirado por outro Técnico",
		706: "Entrega de Chip com Sucesso",
		302: "Desistência de Serviço",
		312: "Não Solicitou o Serviço",
		10: "Entrega de Exames com Sucesso",
		110: "Sem acesso ao DG",
		998: "Entregue na Loja da Claro",
		999: "Agendamento Fake",
	};

	constructor(public api: Api) {}

	ngOnInit() {}

	ngOnChanges() {
		const self = this;
		const delivery = Object.assign({}, self.deliveryO);

		if (!delivery.Devices) {
			delivery.Devices = [];
		}

		if (!delivery.address) {
			delivery.address = "";
		}

		if (delivery.opening_date) {
			delivery.opening_date = moment(delivery.opening_date).format(
				"DD/MM/YYYY"
			);
		}

		if (delivery.backend_date) {
			delivery.backend_date = moment(delivery.backend_date).format(
				"DD/MM/YYYY"
			);
		}

		delivery.date = moment(self.deliveryO.start).format("DD/MM/YYYY");

		if (delivery.start) {
			delivery.start = moment(self.deliveryO.start).format("HH:mm");
		}

		if (delivery.end) {
			delivery.end = moment(delivery.end).format("HH:mm");
		}

		self.delivery = delivery;
	}

	call = this.api.new().set("success", true);
	save() {
		const self = this;

		const put = Object.assign({}, self.delivery);
		put.start =
			moment(put.date, "DD/MM/YYYY").format("YYYY-MM-DD") +
			" " +
			put.start;
		put.end =
			moment(put.date, "DD/MM/YYYY").format("YYYY-MM-DD") + " " + put.end;
		put.opening_date = put.opening_date
			? moment(put.opening_date, "DD/MM/YYYY").endOf("day").toDate()
			: null;
		put.backend_date = put.backend_date
			? moment(put.backend_date, "DD/MM/YYYY").endOf("day").toDate()
			: null;
		
		const isOnlyNumbers = (string) => {
			return /^\d+$/.test(string);
		};
		
		if(!isOnlyNumbers(put.user_id)){
			put.user_id = null;
		}

		if (self.delivery.id_delivery) {
			self.call
				.put("delivery/" + self.delivery.id_delivery, put)
				.subscribe((response) => {
					self.saved.emit(response.return);
				});
		} else {
			self.call.post("delivery/", put).subscribe((response) => {
				self.delivery = {
					Devices: [],
					address: "",
				};
				self.saved.emit(response.return);
			});
		}
	}

	deleteDevice(index) {
		const self = this;
		self.delivery.Devices.splice(index, 1);
	}

	fillAddress(address) {
		const self = this;
		if (address) {
			self.delivery.address = address.formatted_address;
			self.delivery.latitude = address.geometry.location.lat();
			self.delivery.longitude = address.geometry.location.lng();
		}
	}
}
